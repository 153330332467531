import React from "react";
import CheckRadioBtn from "../../../components/CheckRadioBtn";

const MultiOptionsFilter = ({ title, options, selectedOptions, setSelectedOptions }) => {

    const changeHandler = selectedOption => {
        const tempOptions = [...selectedOptions];
        if (tempOptions?.find(item => item.value === selectedOption.value)) {
            const updatedOptions = tempOptions?.filter(item => item.value !== selectedOption.value);
            setSelectedOptions(updatedOptions);
        } else {
            setSelectedOptions([...tempOptions, selectedOption]);
        }
    };

    const removeChoice = () => {
        setSelectedOptions([]);
    };
    return (
        <div className="d-flex justify-content-between flex-column mt-4">
            <div className="d-flex justify-content-between">
                <div className="filter-heading">
                    {title} {selectedOptions?.length > 0 && `(${selectedOptions.length})`}
                </div>
                {selectedOptions && selectedOptions.length > 0 && (
                    <div className="mb-3 text-muted cursor-pointer" onClick={removeChoice}>
                        X
                    </div>
                )}
            </div>
            <div className="d-flex flex-wrap mt-2" style={{ columnGap: "8px" }}>
                {options.map((option, i) => (
                    <CheckRadioBtn
                        checked={!!selectedOptions?.find(item => item.value === option.value)}
                        key={i}
                        option={option}
                        onChange={() => changeHandler(option)}
                    />
                ))}
            </div>
        </div>
    );
};

export default MultiOptionsFilter;
