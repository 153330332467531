import React, { useState, useEffect } from "react";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import moment from "moment";
import { post } from "../../../../../../networking/RequestService";

import styles from "../Page2.module.scss";
import commonStyles from "../../../final-report-common.module.scss";
import { getF2FComparisonText } from "../../../../../../app/functions/finalReportUtil";

const Comparison = ({ startDate, endDate, unitId }) => {
    const [comparisonData, setComparisonData] = useState();

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "f2fVsLvtComparison",
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            };

            if (unitId) requestObj["unitId"] = unitId;

            post(requestObj)
                .then(({ data }) => {
                    setComparisonData(data);
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const f2fPercentage = parseFloat(comparisonData?.f2fPercent || 0) + "%";
    const lvtPercentage = parseFloat(comparisonData?.LvtPercent || 0) + "%";
    const difference = parseFloat(comparisonData?.increase || 0);
    const comparisonText = `${difference >= 0 ? "Increase" : "Decrease"} of ${Math.abs(
        difference
    )}% in F2F sessions ${getF2FComparisonText(startDate, endDate)}`;

    return (
        <div className="w-100">
            <div className={styles.itemTitle}>F2F VS LVT COMPARISON</div>
            <div className={styles.graph}>
                <div className={styles.ftof}>
                    <div className={styles.fillBar} style={{ width: f2fPercentage }} />
                    <div className={styles.barText}>
                        <span className={styles.percentageText}>{f2fPercentage}</span>
                        <span className={styles.typeText}>F2F</span>
                    </div>
                </div>
                <div className={styles.versus}>vs.</div>
                <div className={styles.ftofRight}>
                    <div className={styles.fillBar} style={{ width: lvtPercentage }} />
                    <div className={styles.barText}>
                        <span className={styles.typeText}>LVT</span>
                        <span className={styles.percentageText}>{lvtPercentage}</span>
                    </div>
                </div>
            </div>
            {difference !== 0 &&
                <div className={styles.infoGraph}>
                    <div className={styles.icon}>
                        {difference > 0 ? (
                            <span className={`svg-icon svg-icon-2x my-2 ${commonStyles.primaryGreenText}`}>
                                <SVG src={toAbsoluteUrl("/media/svg/popleads/trending-up.svg")}></SVG>
                            </span>
                        ) : (
                            <span className={`svg-icon svg-icon-2x my-2 ${commonStyles.primaryPinkText}`}>
                                <SVG src={toAbsoluteUrl("/media/svg/popleads/trending-down.svg")}></SVG>
                            </span>
                        )}
                    </div>
                    <div className={styles.infoText}>{comparisonText}</div>
                </div>
            }
        </div>
    );
};

export default Comparison;
