import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import TextInput from "./components/inputs/textInput";
import NumberInput from "./components/inputs/numberInput";
import SelectInput from "./components/inputs/selectInput";
import DateInput from "./components/inputs/dateInput";
import * as campaignsRedux from "../campaigns/redux/campaignsRedux";

function NewFilter(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.searchFilter) {
            setData(props.searchFilter);
        }
    }, []);

    const scrollTo = (idx) => {
        setTimeout(() => {
            const header = 50;
            const scrollTo = header + idx * 57.5;
            document.getElementById("newFilter").scrollTo(0, scrollTo);
        }, 20);
    };

    return (
        <div className="navi navi-hover newFilter" id="newFilter">
            {data &&
                data.map((object, i) => (
                    <div className="row m-0 mb-2" key={"filter_" + i}>
                        <div className="col-sm-12 p-0">
                            <span className="navi-item">
                                <div className="navi-link">
                                    <div className="subHeader-dropdownIcon navi-icon mr-2">
                                        <SVG src={object.svgUrl} />
                                    </div>
                                    <div className="navi-text">
                                        <div className="font-weight-bold">
                                            {object.type === "text" && (
                                                <TextInput
                                                    onClick={() => scrollTo(i)}
                                                    onInputChange={(input) => {
                                                        object.value = input;
                                                        setData([...data]);
                                                        const search = data.slice();
                                                        props.setSearchFilter(search);
                                                    }}
                                                    onActionChange={(action) => {
                                                        object.action = action;
                                                        setData([...data]);
                                                        const search = data.slice();
                                                        props.setSearchFilter(search);
                                                    }}
                                                    object={object}
                                                />
                                            )}
                                            {object.type === "number" && (
                                                <NumberInput
                                                    onClick={() => scrollTo(i)}
                                                    onInputChange={(input, no) => {
                                                        if (object.action === "[]") {
                                                            if (!no) {
                                                                object.valueFrom = input;
                                                            } else if (no === 2) {
                                                                object.valueTo = input;
                                                            }
                                                        } else {
                                                            object.value = input;
                                                        }

                                                        setData([...data]);
                                                        const search = data.slice();
                                                        props.setSearchFilter(search);
                                                    }}
                                                    onActionChange={(action) => {
                                                        if (object.action === "[]" || action === "[]") {
                                                            object.value = null;
                                                            object.valueFrom = null;
                                                            object.valueTo = null;
                                                        }

                                                        object.action = action;
                                                        setData([...data]);
                                                        const search = data.slice();
                                                        props.setSearchFilter(search);
                                                    }}
                                                    object={object}
                                                />
                                            )}

                                            {object.type === "list" && (
                                                <SelectInput
                                                    onClick={() => {
                                                        scrollTo(i);
                                                    }}
                                                    scrollTo={() => scrollTo(i)}
                                                    onChange={(input) => {
                                                        object.value = input;
                                                        setData([...data]);
                                                        const search = data.slice();
                                                        props.setSearchFilter(search);
                                                    }}
                                                    object={object}
                                                />
                                            )}

                                            {object.type === "date" && (
                                                <DateInput
                                                    onClick={() => scrollTo(i)}
                                                    onInputChange={(input, no) => {
                                                        if (object.action === "[]") {
                                                            if (!no) {
                                                                object.valueFrom = input;
                                                            } else if (no === 2) {
                                                                object.valueTo = input;
                                                            }
                                                        } else {
                                                            object.value = input;
                                                        }
                                                        setData([...data]);
                                                        const search = data.slice();
                                                        props.setSearchFilter(search);
                                                    }}
                                                    onActionChange={(action) => {
                                                        // console.log("obj: ", object);
                                                        if (object.action === "[]" || action === "[]") {
                                                            object.value = null;
                                                            object.valueFrom = null;
                                                            object.valueTo = null;
                                                        }
                                                        object.action = action;
                                                        setData([...data]);
                                                        const search = data.slice();
                                                        props.setSearchFilter(search);
                                                        // console.log("obj: ", object);
                                                    }}
                                                    object={object}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                ))}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        searchFilter: state.campaigns.searchFilter,
    };
};

const mapDispatchToProps = {
    ...campaignsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewFilter);
