import React from "react";
import Select, { components } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { post } from "../../../../networking/RequestService";
import * as util from "../../../../app/functions/util";
import CreatableSelect from "react-select/creatable";

// import { connect } from "react-redux";
// import * as app from "../../../../app/redux/appRedux"
import { connect } from "react-redux";
import * as app from "../../../../app/redux/appRedux";
import LoadingSpin from "app/popleads/app/partials/LoadingSpin";

const { ValueContainer, Placeholder } = components;

class SelectInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.object.data ? this.props.object.data : [],
            page: 1,
            rowsPerPage: 10,
            value: this.props.object.value ? this.props.object.value : "",
            inputValue: "",
            isSelectedAll: false,
            trainerList: [],
            salesPageLoading: this.props.salesPageLoading,
        };

        this.switchCheckbox = this.switchCheckbox.bind(this);
        this.loadOptionsASYNC = this.loadOptionsASYNC.bind(this);
    }

    switchCheckbox(value) {
        if (value !== null && value != undefined) this.setState({ isSelectedAll: value });
        else this.setState({ isSelectedAll: !this.state.isSelectedAll });
    }

    handleDepartmentFilter(selected, action) {
        if (action) {
            //const id = action?.option?.value;
            //const item = this.state.data.options.find(item => item.value === id)
            //console.log("item: ",item)

            for (let i = 0; i < this.state.data.options?.length; i++) {
                const department = this.state.data.options[i];
                const unitList = department.options;
                for (let j = 0; j < unitList?.length; j++) {
                    const unit = unitList[j];
                    if (unit.value === action?.option?.value) {
                        if (unit.isUnit) {
                            for (let k = 0; k < unit.options?.length; k++) {
                                if (action?.action === "select-option") {
                                    const temp = {
                                        label: unit.options[k].name,
                                        value: unit.options[k].id,
                                        selected: true,
                                    };
                                    selected = [...selected, temp];
                                }
                                if (action?.action === "deselect-option") {
                                    selected = selected.filter((item) => item.value !== unit.options[k].id);
                                }
                            }
                        } else {
                            const parentUnit = unitList.find((item) => item.value === action.option.unitId);
                            const parentUnitOptions = parentUnit.options;
                            let count = 0;
                            for (let k = 0; k < parentUnitOptions?.length; k++) {
                                if (selected.find((item) => item.value === parentUnitOptions[k].id)) {
                                    count++;
                                }
                            }
                            if (count === parentUnitOptions.length) selected = [...selected, parentUnit];
                            else selected = selected.filter((item) => item.value !== parentUnit.value);
                        }
                    }
                }
            }
        }
        return selected;
    }

    async loadOptionsASYNC() {
        let requestObj = {
            fn: this.props.object.fn,
            columnName: this.props.object.columnName,
            module: this.props.object.module,
            start: (this.state.page - 1) * this.state.rowsPerPage,
            isExport: 0,
            rowsPerPage: this.state.rowsPerPage,
            isPreCampaign: this.props.object.isPreCampaign,
            field_name: this.props.object.field_name,
        };
        if (this.props.object.fn === "campaignListHomePage") {
            if (this.props.object.isTemplate) requestObj.isTemplate = this.props.object.isTemplate;
            if (this.props.object.filterValuesList) requestObj.filterValuesList = this.props.object.filterValuesList;
            if (this.props.object.status) requestObj.status = this.props.object.status;
            if (this.props.object.type) requestObj.type = this.props.object.type;
            if (this.props.object.minRevenue) requestObj.minRevenue = this.props.object.minRevenue;
            if (this.props.object.maxRevenue) requestObj.maxRevenue = this.props.object.maxRevenue;
            if (this.props.object.minScore) requestObj.minScore = this.props.object.minScore;
            if (this.props.object.maxScore) requestObj.maxScore = this.props.object.maxScore;
            if (this.props.object.minDelegate) requestObj.minDelegate = this.props.object.minDelegate;
            if (this.props.object.maxDelegate) requestObj.maxDelegate = this.props.object.maxDelegate;
            if (this.props.object.minLVTPrice) requestObj.minLVTPrice = this.props.object.minLVTPrice;
            if (this.props.object.maxLVTPrice) requestObj.maxLVTPrice = this.props.object.maxLVTPrice;
            if (this.props.object.minF2FPrice) requestObj.minF2FPrice = this.props.object.minF2FPrice;
            if (this.props.object.maxF2FPrice) requestObj.maxF2FPrice = this.props.object.maxF2FPrice;
        }
        if (this.props.object.fn === "companyUsers") {
            requestObj = {
                fn: this.props.object.fn,
            };
            if (this.props.object.unitId) {
                requestObj.unitId = this.props.object.unitId;
            }
        }
        if (this.props.object.fn === "accountListHomePage") {
            requestObj = {
                fn: this.props.object.fn,
                rowsPerPage: this.state.rowsPerPage,
                start: this.state.page === 0 ? this.state.page++ : this.state.page,
                orderByColumn: this.props.object.orderByColumn ? this.props.object.orderByColumn : "lastClosedDealDate",
                orderType: this.props.object.orderType ? this.props.object.orderType : "desc",
            };
            // requestObj.orderByColumn = "lastClosedDealDate"
            // requestObj.orderType = "desc"
        }
        // console.log("this.state.inputValue: ",this.state.inputValue)
        if (this.state.inputValue !== "") {
            if (this.props.object.fn === "accountListHomePage") {
                requestObj.search = this.state.inputValue;
            } else {
                requestObj.columnName = this.props.object.name;
                requestObj.columnValue = this.state.inputValue;
            }
        }

        if (this.props.object.fn === "getListFromDataSource") {
            requestObj = {
                fn: "getListFromDataSource",
                search_string: this.state.inputValue,
                field_name: this.props.object.field_name,
            };
        }

        let returnObj = {
            options: [],
            hasMore: false,
        };
        // console.log("requestObj: ",requestObj)
        await post(requestObj)
            .then(
                ({
                    data: {
                        searchResults,
                        count,
                        dynamicSchemaFields,
                        result_list,
                        users,
                        departments,
                        accountList,
                        countTotal,
                    },
                }) => {
                    // console.log(result_list)
                    // console.log(count)
                    let data = "";
                    if (this.props.object.fn === "campaignListHomePage") {
                        data = util
                            .createSelectDataFromArray(searchResults)
                            .filter((item, i) => !(i === 0 && !isNaN(item?.label))); //to hide count values as an option
                    } else if (this.props.object.fn === "getCampaignDynamicSchemaAllFields") {
                        let defaultValue = [];
                        data = util.getDynamicSchemaForSelect(dynamicSchemaFields, "Preliminary", defaultValue);
                        count = data.length;
                        this.setState({ value: defaultValue[0] });
                    } else if (
                        this.props.object.fn === "getListFromDataSource" &&
                        this.props.object.filterName === "industryFilter"
                    ) {
                        let tempOptions = [{ value: 0, label: "All Industries" }];
                        result_list.forEach(function(e) {
                            const option = { value: e.sector_name, label: e.sector_name };
                            tempOptions.push(option);
                        });
                        data = tempOptions;
                    } else if (this.props.object.fn === "companyUsers") {
                        let tempOptions = [];
                        for (let i = 0; i < users.length; i++) {
                            const obj = { value: users[i].id, label: users[i].name };
                            tempOptions.push(obj);
                        }
                        data = tempOptions;
                    } else if (this.props.object.fn === "accountListHomePage") {
                        let tempOptions = [];
                        for (let i = 0; i < accountList.length; i++) {
                            const obj = { value: accountList[i].id, label: accountList[i].accountName };
                            if (obj.label) {
                                tempOptions.push(obj);
                            }
                        }
                        // console.log("options returnObj", returnObj)
                        if (this.state.page * this.state.rowsPerPage < countTotal) {
                            returnObj.hasMore = true;
                            this.setState({ page: this.state.page + 1 });
                        }
                        data = tempOptions;
                    } else if (this.props.object.fn === "getAllUserByUnitIds") {
                        let tempOptions = [];
                        for (let i = 0; i < departments.length; i++) {
                            const dept_units = [];
                            for (let j = 0; j < departments[i].unitList.length; j++) {
                                const obj = {
                                    label: departments[i].unitList[j].name,
                                    value: departments[i].unitList[j].id,
                                    selected: false,
                                };
                                dept_units.push(obj);
                            }

                            const obj = {
                                label: departments[i].name,
                                options: dept_units,
                                value: departments[i].id,
                                selected: false,
                            };
                            tempOptions.push(obj);
                        }
                        data = tempOptions;
                    } else if (this.props.object.fn === "getAllUnits") {
                        let tempOptions = [];
                        for (let i = 0; i < departments.length; i++) {
                            const dept_units = [];
                            for (let j = 0; j < departments[i].unitList.length; j++) {
                                const obj = {
                                    label: departments[i].unitList[j].name,
                                    value: departments[i].unitList[j].id,
                                    selected: false,
                                };
                                dept_units.push(obj);
                            }

                            const obj = {
                                label: departments[i].name,
                                options: dept_units,
                                value: departments[i].id,
                                selected: false,
                            };
                            tempOptions.push(obj);
                        }
                        data = tempOptions;
                    } else if (this.props.object.fn === "getListFromDataSource") {
                        let tempOptions = [];
                        result_list.forEach(function(e) {
                            const option = { value: parseInt(e.id), label: e.academy_name };
                            tempOptions.push(option);
                        });
                        data = tempOptions;
                    }

                    // console.log("data: ", data)

                    returnObj.options = data;
                    if (this.state.page * this.state.rowsPerPage < count) {
                        returnObj.hasMore = true;
                        this.setState({ page: this.state.page + 1 });
                    }

                    if (this.props.scrollTo) this.props.scrollTo();
                }
            )
            .catch((error) => {
                // console.log("error: ", error)
                this.setState({ isLoading: false, isError: true });
                returnObj = {
                    options: [],
                    hasMore: false,
                };
            });

        return returnObj;
    }

    onInputChange(input) {
        this.setState({ inputValue: input, page: 1 });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.object.value !== this.props.object.value) {
            if (this.props.object.fn !== "getListFromDataSource") {
                this.setState({ value: this.props.object.value });
            }
        }

        if (prevProps.object.data !== this.props.object.data) {
            this.setState({ data: this.props.object.data });
        }

        if (prevProps.salesPageLoading !== this.props.salesPageLoading)
            this.setState({ salesPageLoading: this.props.salesPageLoading });
    }
    render() {
        // console.log("CURRENT", this.state.value)
        let components = {};
        if (this.props.isGrouped) {
            components = {
                Option,
                MultiValue,
                Control,
                Input,
                ValueContainer: CValueContainerMulti,
                GroupHeading,
                Menu,
            };
        } else {
            components = { Option, MultiValue, Control, Input, ValueContainer: CValueContainerMulti, Menu };
        }
        return (
            <>
                {this.props.infinite === false || this.props.object.infinite === false ? (
                    <>
                        {this.props.object.multi ? (
                            this.props.object.creatable ? (
                                <CreatableSelect
                                    options={this.state.data.options}
                                    className={this.props.className ? this.props.className : "selectInputNewFilterC"}
                                    classNamePrefix={
                                        this.props.classNamePrefix ? this.props.classNamePrefix : "selectInputNewFilter"
                                    }
                                    value={this.state.value}
                                    onChange={(selected, action) => {
                                        // console.log("selected: ", selected);
                                        this.setState({ value: selected });
                                        if (this.props.onChange) {
                                            this.props.onChange(selected);
                                        }
                                        //if(this.props.onInputChange) this.props.onInputChange(selected, action)
                                        if (selected.length === 0) this.switchCheckbox(false);
                                        else if (selected.length === this.state.data.length) this.switchCheckbox(true);
                                    }}
                                    onInputChange={(input, { action }) => {
                                        // console.log(action)
                                        // console.log("input: ", input);
                                        if (this.props.onInputChange) this.props.onInputChange(input, action);
                                        this.onInputChange(input);
                                    }}
                                    inputValue={this.state.inputValue}
                                    isMulti={this.props.object.multi}
                                    hideSelectedOptions={false}
                                    backspaceRemovesValue={false}
                                    blurInputOnSelect={false}
                                    closeMenuOnSelect={false}
                                    isClearable={this.props.object.clearable}
                                    // components={components}
                                    setIsSelectAll={(value) => this.switchCheckbox(value)}
                                    isSelectAll={this.state.isSelectedAll}
                                    isSearchable={this.props.object.search}
                                    placeholder={this.props.object.name}
                                    allSelectable={this.props.object.allSelectable}
                                    selectedDepartment={this.props.isGrouped ? this.props.selectedDepartment : null}
                                    selectDepartment={(units) =>
                                        this.props.isGrouped ? this.selectUnits(units) : null
                                    }
                                    isSelectAllDepartments={() =>
                                        this.props.isGrouped ? this.props.isSelectAllDepartments : null
                                    }
                                    setIsSelectAllDepartments={() =>
                                        this.props.isGrouped ? this.switchCheckbox() : null
                                    }
                                    isLoading={
                                        this.props.object.isLoading &&
                                        util.isSalesFullPageLoading(this.state.salesPageLoading)
                                    }
                                />
                            ) : (
                                <Select
                                    key={JSON.stringify(this.state.data)}
                                    onFocus={() => this.props.onClick && this.props.onClick()}
                                    className={this.props.className ? this.props.className : "selectInputNewFilterC"}
                                    classNamePrefix={
                                        this.props.classNamePrefix ? this.props.classNamePrefix : "selectInputNewFilter"
                                    } // or selectInputNewFilterSecondary
                                    options={this.state.data.options}
                                    value={this.state.value}
                                    onChange={(selected, action) => {
                                        // console.log("selected: ", selected);
                                        if (this.props.object.name === "Unit") {
                                            selected = this.handleDepartmentFilter(selected, action);
                                        }
                                        this.setState({ value: selected });
                                        if (this.props.onChange) {
                                            this.props.onChange(selected);
                                        }
                                        //if(this.props.onInputChange) this.props.onInputChange(selected, action)
                                        if (selected?.length === 0) this.switchCheckbox(false);
                                        else if (selected?.length === this.state.data.length) this.switchCheckbox(true);
                                    }}
                                    onInputChange={(input, { action }) => {
                                        // console.log(action)
                                        // console.log("input: ", input);
                                        if (this.props.onInputChange) this.props.onInputChange(input, action);
                                        this.onInputChange(input);
                                    }}
                                    inputValue={this.state.inputValue}
                                    isMulti={this.props.object.multi}
                                    hideSelectedOptions={false}
                                    backspaceRemovesValue={false}
                                    blurInputOnSelect={false}
                                    closeMenuOnSelect={false}
                                    isClearable={this.props.object.clearable}
                                    components={this.props.object.defaultComponents ? undefined : components}
                                    setIsSelectAll={(value) => this.switchCheckbox(value)}
                                    isSelectAll={this.state.isSelectedAll}
                                    isSearchable={this.props.object.search}
                                    placeholder={this.props.object.name}
                                    allSelectable={this.props.object.allSelectable}
                                    selectedDepartment={this.props.isGrouped ? this.props.selectedDepartment : null}
                                    selectDepartment={(units) =>
                                        this.props.isGrouped ? this.selectUnits(units) : null
                                    }
                                    isSelectAllDepartments={() =>
                                        this.props.isGrouped ? this.props.isSelectAllDepartments : null
                                    }
                                    setIsSelectAllDepartments={() =>
                                        this.props.isGrouped ? this.switchCheckbox() : null
                                    }
                                    isLoading={
                                        this.props.object.isLoading &&
                                        util.isSalesFullPageLoading(this.state.salesPageLoading)
                                    }
                                />
                            )
                        ) : this.props.object.creatable ? (
                            <>
                                <CreatableSelect
                                    isClearable={this.props.object.clearable}
                                    options={this.state.data.options}
                                    className={this.props.className ? this.props.className : "selectInputNewFilterC"}
                                    classNamePrefix={
                                        this.props.classNamePrefix ? this.props.classNamePrefix : "selectInputNewFilter"
                                    }
                                    value={
                                        this.state.value && {
                                            label: this.state.value.label,
                                            value: this.state.value.value,
                                        }
                                    }
                                    onChange={(selected, action) => {
                                        if (selected.__isNew__) selected.label = selected.value;
                                        this.setState({ value: selected });
                                        if (this.props.onChange) {
                                            this.props.onChange(selected);
                                        }
                                    }}
                                    onInputChange={(input, { action }) => {
                                        if (this.props.onInputChange) this.props.onInputChange(input, action);
                                        this.onInputChange(input);
                                    }}
                                    isLoading={
                                        this.props.object.isLoading &&
                                        util.isSalesFullPageLoading(this.state.salesPageLoading)
                                    }
                                />

                                {/*<CreatableSelect
                                
                                
                                
                                inputValue={this.state.inputValue}
                                isMulti={this.props.object.multi}
                                hideSelectedOptions={false}
                                backspaceRemovesValue={false}
                                blurInputOnSelect={false}
                                onclear
                                closeMenuOnSelect={false}
                                isClearable={this.props.object.clearable}
                                // components={components}
                                setIsSelectAll={value => this.switchCheckbox(value)}
                                isSelectAll={this.state.isSelectedAll}
                                isSearchable={this.props.object.search}
                                placeholder={
                                    this.props.object.placeholder
                                        ? this.props.object.placeholder
                                        : this.props.object.name
                                }
                                allSelectable={this.props.object.allSelectable}
                                selectedDepartment={this.props.isGrouped ? this.props.selectedDepartment : null}
                                selectDepartment={units => (this.props.isGrouped ? this.selectUnits(units) : null)}
                                isSelectAllDepartments={() =>
                                    this.props.isGrouped ? this.props.isSelectAllDepartments : null
                                }
                                setIsSelectAllDepartments={() => (this.props.isGrouped ? this.switchCheckbox() : null)}
                            />*/}
                            </>
                        ) : (
                            <Select
                                key={JSON.stringify(this.state.data)}
                                className={this.props.className ? this.props.className : "selectInputNewFilter_single"}
                                classNamePrefix={
                                    this.props.classNamePrefix ? this.props.classNamePrefix : "selectInputNewFilter"
                                }
                                options={this.state.data.options}
                                onChange={(selected) => {
                                    this.setState({ value: selected });
                                    this.props.onChange(selected);
                                }}
                                onInputChange={(input) => this.onInputChange(input)}
                                value={this.state.value}
                                isSearchable={this.props.object.search}
                                components={{ ValueContainer: CValueContainerSingle }}
                                placeholder={
                                    this.props.object.placeholder
                                        ? this.props.object.placeholder
                                        : this.props.object.name
                                }
                                menuPortalTarget={this.props.outerDropdown && document.getElementById("newFilterMenu")}
                                isLoading={
                                    this.props.object.isLoading &&
                                    util.isSalesFullPageLoading(this.state.salesPageLoading)
                                }
                            />
                        )}
                    </>
                ) : (
                    <>
                        {this.props.object.multi ? (
                            <AsyncPaginate
                                key={JSON.stringify(this.state.data)}
                                onFocus={() => this.props.onClick && this.props.onClick()}
                                className={this.props.className ? this.props.className : "selectInputNewFilterC"}
                                classNamePrefix={
                                    this.props.classNamePrefix ? this.props.classNamePrefix : "selectInputNewFilter"
                                } // or selectInputNewFilterSecondary
                                loadOptions={
                                    this.state.data.options && this.state.data.options.length > 0
                                        ? () => {
                                              // console.log("options: ", this.state.data)

                                              return this.state.data;
                                          }
                                        : async () => {
                                              const options = await this.loadOptionsASYNC();
                                              // console.log("options: ", options)
                                              return options;
                                          }
                                }
                                value={this.state.value}
                                onChange={(selected, { action }) => {
                                    // console.log(action)
                                    this.setState({ value: selected });
                                    if (this.props.onChange) {
                                        this.props.onChange(selected);
                                    }
                                    //if(this.props.onInputChange) this.props.onInputChange(selected, action)
                                    if (selected.length === 0) this.switchCheckbox(false);
                                    else if (selected.length === this.state.data.length) this.switchCheckbox(true);
                                }}
                                onInputChange={(input, { action }) => {
                                    // console.log(action)
                                    if (action === "set-value") return;
                                    if (this.props.onInputChange) this.props.onInputChange(input, action);
                                    this.onInputChange(input);
                                }}
                                inputValue={this.state.inputValue}
                                isMulti={this.props.object.multi}
                                hideSelectedOptions={false}
                                backspaceRemovesValue={false}
                                blurInputOnSelect={false}
                                closeMenuOnSelect={false}
                                isClearable={this.props.object.clearable}
                                components={components}
                                setIsSelectAll={(value) => this.switchCheckbox(value)}
                                isSelectAll={this.state.isSelectedAll}
                                isSearchable={this.props.object.search}
                                placeholder={
                                    this.props.object.placeholder
                                        ? this.props.object.placeholder
                                        : this.props.object.name
                                }
                                allSelectable={this.props.object.allSelectable}
                                selectedDepartment={this.props.isGrouped ? this.props.selectedDepartment : null}
                                selectDepartment={(units) => (this.props.isGrouped ? this.selectUnits(units) : null)}
                                isSelectAllDepartments={() =>
                                    this.props.isGrouped ? this.props.isSelectAllDepartments : null
                                }
                                setIsSelectAllDepartments={() => (this.props.isGrouped ? this.switchCheckbox() : null)}
                                debounceTimeout={this.props.debounceTimeout ? this.props.debounceTimeout : 300}
                                cacheUniqs={this.props.object.dependencies ? this.props.object.dependencies : []}
                                isLoading={
                                    this.props.object.isLoading &&
                                    util.isSalesFullPageLoading(this.state.salesPageLoading)
                                }
                            />
                        ) : (
                            <AsyncPaginate
                                key={JSON.stringify(this.state.data)}
                                className={this.props.className ? this.props.className : "selectInputNewFilter_single"}
                                classNamePrefix={
                                    this.props.classNamePrefix ? this.props.classNamePrefix : "selectInputNewFilter"
                                }
                                loadOptions={
                                    this.state.data.options && this.state.data.options.length > 0
                                        ? () => {
                                              // console.log("options data: ", this.state.data)
                                              return this.state.data;
                                          }
                                        : async () => {
                                              const options = await this.loadOptionsASYNC();
                                              // console.log("options: ", options)
                                              return options;
                                          }
                                }
                                // defaultOptions={this.state.value && [this.state.value]}
                                onChange={(selected) => {
                                    this.setState({ value: selected });
                                    this.props.onChange(selected);
                                }}
                                onInputChange={(input) => this.onInputChange(input)}
                                value={this.state.value}
                                isSearchable={this.props.object.search}
                                components={{ ValueContainer: CValueContainerSingle }}
                                placeholder={
                                    this.props.object.placeholder
                                        ? this.props.object.placeholder
                                        : this.props.object.name
                                }
                                menuPortalTarget={this.props.outerDropdown && document.getElementById("newFilterMenu")}
                                debounceTimeout={this.props.debounceTimeout ? this.props.debounceTimeout : 300}
                                cacheUniqs={this.props.object.dependencies ? this.props.object.dependencies : []}
                                isLoading={
                                    this.props.object.isLoading &&
                                    util.isSalesFullPageLoading(this.state.salesPageLoading)
                                }
                            />
                        )}
                    </>
                )}
            </>
        );
    }
}

const groupStyles = {
    borderBottom: `2px solid #dddddd`,
    color: "white",
    padding: "5px 0px",
    display: "flex",
};

const GroupHeading = function(props) {
    function checkGroup() {
        // console.log("check!")
        // console.log("props! ",props)
        let selected = props.selectProps.selectedDepartment;
        for (let i = 0; i < props.selectProps.options.length; i++) {
            const department = props.selectProps.options[i];
            department.selected = true;
            // console.log("departments: ", department)
            for (let j = 0; j < department.options.length; j++) {
                const unit = department.options[j];
                let found = false;
                if (selected) {
                    for (let k = 0; k < selected.length; k++) {
                        // console.log("unit.label: ", unit.label);
                        if (selected[k].label === unit.label) found = true;
                    }
                }
                if (!found) department.selected = false;
            }
        }
    }

    function switchChecked(children, options) {
        for (let i = 0; i < options.length; i++) {
            // if(!options[i].options){
            if (children === options[i].label) {
                options[i].selected = !options[i].selected;
                return { status: options[i].selected, index: i };
            }
            // }
            // else if (options[i].options){
            //     for(let k = 0; k < options[i].options.length; k++){
            //         if (children === options[i].options[k].label) {
            //             options[i].options[k].selected = !options[i].options[k].selected;
            //             return { status: options[i].options[k].selected, index: k };
            //     }
            //     }
            // }
        }
    }

    function isChecked(children, options) {
        // console.log("children 302: ", children);
        // console.log("options 303: ", options);
        for (let i = 0; i < options.length; i++) {
            // if(!options[i].options){
            if (children === options[i].label) {
                return options[i].selected;
            }
            // }
            // else if(options[i].options){
            //     console.log("options[i].options")
            //     for(let k = 0; k < options[i].options.length; k++){
            //         if (children === options[i].options[k].label) {
            //             return options[i].options[k].selected;
            //         }
            //     }
            // }
        }
    }

    function selectDepartment(selectedDepartment, selectedOption) {
        let selected = "";
        if (!selectedDepartment) selected = [];
        else selected = selectedDepartment.slice();

        for (let i = 0; i < selectedOption.length; i++) {
            let matched = false;
            for (let j = 0; j < selected.length; j++) {
                if (selectedOption[i].label === selected[j].label) matched = true;
            }
            if (!matched) selected.push(selectedOption[i]);
        }
        return selected;
    }

    function unselectDepartment(selectedDepartment, selectedOption) {
        let selected = [];

        for (let i = 0; i < selectedDepartment.length; i++) {
            let matched = false;
            for (let j = 0; j < selectedOption.length; j++) {
                if (selectedOption[j].label === selectedDepartment[i].label) matched = true;
            }
            if (!matched) selected.push(selectedDepartment[i]);
        }
        return selected;
    }

    function onClickDepartment() {
        let check = switchChecked(props.children, props.selectProps.options);
        let selected;
        props.selectProps.options[check.index].selected = check.status;
        if (check.status)
            selected = selectDepartment(
                props.selectProps.selectedDepartment,
                props.selectProps.options[check.index].options
            );
        else
            selected = unselectDepartment(
                props.selectProps.selectedDepartment,
                props.selectProps.options[check.index].options
            );

        props.selectProps.onChange(selected);
        // console.log("props: ", props);
    }

    checkGroup();
    //  className='selectGroupInput'
    return (
        <div
            style={groupStyles}
            className={`selectGroupInput ${
                !!isChecked(props.children, props.selectProps.options) ? " groupSelected " : ""
            }`}
            onClick={onClickDepartment}
        >
            <input
                type="checkbox"
                name="departmentCheck"
                value={props.children}
                checked={!!isChecked(props.children, props.selectProps.options)}
                style={{ marginLeft: "10px" }}
            />
            <components.GroupHeading {...props} />
        </div>
    );
};

const Input = (props) => {
    return (
        <div>
            <components.Input {...props} />
        </div>
    );
};

const Option = ({ ...props }) => {
    return (
        <div>
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={(e) => null} />{" "}
                <label style={{ fontWeight: props.data.isUnit ? "bold" : "inherit" }}>{props.label} </label>
            </components.Option>
        </div>
    );
};

const Control = ({ children, ...props }) => {
    return (
        <>
            <components.Control {...props}>
                {props.selectProps.allSelectable && (
                    <input
                        className="selectAllInput"
                        type="checkbox"
                        name="departmentheck"
                        value="1"
                        checked={!!props.selectProps.isSelectAll}
                        onChange={(e) => {
                            if (props.selectProps.setIsSelectAllDepartments) {
                                props.selectProps.setIsSelectAllDepartments();
                            }
                            props.selectProps.setIsSelectAll();
                            let options = [];
                            if (e.target.checked) {
                                for (let i = 0; i < props.options.length; i++) {
                                    if (!props.options[i].options) {
                                        options.push(props.options[i]);
                                    } else {
                                        for (let j = 0; j < props.options[i].options.length; j++) {
                                            options.push(props.options[i].options[j]);
                                        }
                                    }
                                }
                            }
                            props.setValue(options);
                        }}
                        style={{ marginLeft: "10px" }}
                    />
                )}{" "}
                {children}
            </components.Control>
        </>
    );
};

/*const GroupControl = ({ children, ...props }) => {
    return (
        <components.Control {...props}>
            <input
                type="checkbox"
                name="departmentheck"
                className="selectAllInput"
                value="1"
                checked={!!props.selectProps.isSelectAllDepartments}
                onChange={e => {
                    props.selectProps.setIsSelectAllDepartments();
                    let options = [];
                    if (e.target.checked) {
                        for (let i = 0; i < props.options.length; i++) {
                            for (let j = 0; j < props.options[i].options.length; j++) {
                                options.push(props.options[i].options[j]);
                            }
                        }
                    }
                    props.setValue(options);
                }}
                style={{ marginLeft: "10px" }}
            />{" "}
            {children}
        </components.Control>
    );
};*/

const MultiValue = ({ children, ...innerProps }) => {
    return <></>;
};

const CValueContainerSingle = ({ children, ...props }) => {
    var placeholder = props.selectProps.placeholder;
    return (
        <ValueContainer {...props}>
            {!props.selectProps.value && props.selectProps.inputValue === "" && (
                <Placeholder {...props} isFocused={props.isFocused}>
                    {placeholder}
                </Placeholder>
            )}
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
        </ValueContainer>
    );
};

const CValueContainerMulti = ({ children, ...props }) => {
    var placeholder = props.selectProps.placeholder;
    return (
        <ValueContainer {...props}>
            {props.selectProps.inputValue === "" && (
                <Placeholder {...props} isFocused={props.isFocused}>
                    {placeholder}
                </Placeholder>
            )}
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
        </ValueContainer>
    );
};

/*const CValueContainer = ({ children, ...props }) => {
    var placeholder = props.selectProps.placeholder;
    return (
        <ValueContainer {...props}>
            {props.selectProps.inputValue === "" && (
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
            )}
            {React.Children.map(children, child => (child && child.type !== Placeholder ? child : null))}
        </ValueContainer>
    );
};*/

const Menu = ({ children, ...props }) => {
    const isLoading = props.selectProps.isLoading;
    return (
        <>
            <components.Menu {...props}>
                {isLoading && <LoadingSpin classNames="blocking-spinner" />}

                {children}
            </components.Menu>
        </>
    );
};

// const mapStateToProps = (state) => {
//     return {
//         department: state.reports.selectedDepartment,
//         employee: state.reports.selectedEmployee,
//         companyDepartments: state.app.companyDepartments,
//         companyUsers: state.app.companyUsers,
//         isSelectAllDepartments: state.reports.isSelectAllDepartments
//     };
// };
const mapStateToProps = (state) => {
    return {
        companyUsers: state.app.companyUsers,
        salesPageLoading: state.reports.salesPageLoading,
    };
};

const mapDispatchToProps = {
    ...app.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectInput);
