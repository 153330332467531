import React from "react";
import { useDispatch, useSelector } from "react-redux";

import DateDropdown from "../components/DateDropdown";
import Button from "app/popleads/components/Button";
import { actions } from "app/popleads/modules/accounts/redux/accountsRedux";
import AccountFilterDrawer from "../drawers/AccountFilterDrawer";

import { toAbsoluteUrl } from "_metronic/_helpers";

const AccountDetailActions = () => {
    const { selectedDate, isOpenAccountEditButton } = useSelector((state) => state.accounts);
    const dispatch = useDispatch();

    return (
        <div className="d-flex align-items-center">
            <DateDropdown
                selectedDate={selectedDate}
                setSelectedDate={(date) => dispatch(actions.setSelectedDate(date))}
            />
            <AccountFilterDrawer isForAccountDetail />
            {isOpenAccountEditButton ? (
                <Button
                    variant="success"
                    className="ml-2 ml-sm-4"
                    outline
                    onClick={() => dispatch(actions.toggleAccountEditButton(false))}
                    title="Done"
                />
            ) : (
                <Button
                    variant="success"
                    icon
                    src={toAbsoluteUrl("/media/svg/icons/General/edit.svg")}
                    outline
                    className="ml-2 ml-sm-4"
                    onClick={() => dispatch(actions.toggleAccountEditButton(true))}
                    title="Edit"
                />
            )}
            <Button
                variant="secondary"
                icon
                src={toAbsoluteUrl("/media/svg/icons/General/merge.svg")}
                outline
                className="ml-2 ml-sm-4"
                onClick={() => dispatch(actions.toggleAccountMergeButton(true))}
                title="Merge"
            />
        </div>
    );
};

export default AccountDetailActions;
