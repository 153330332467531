import React, { useState, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import { connect } from "react-redux";
import * as reportsRedux from "../../reports/redux/reportsRedux";
import * as util from "../../../app/functions/util";
import * as app from "../../../app/redux/appRedux";

function DateFilter(props) {
    const [date, setDate] = useState([
        {
            startDate: moment()
                .startOf("year")
                .toDate(),
            endDate: moment()
                .endOf("year")
                .toDate(),
            key: "selection",
        },
    ]);

    const [dateSurvey, setDateSurvey] = useState([
        {
            startDate: null,
            endDate: new Date(),
            key: "selection",
        },
    ]);

    useEffect(() => {
        if (!props.dateFilterSurvey && props.date) {
            setDate([
                {
                    startDate: props.date.startDate,
                    endDate: props.date.endDate,
                    key: "selection",
                },
            ]);
        } else if (props.dateSurvey) {
            setDateSurvey([
                {
                    startDate: props.dateSurvey.startDate,
                    endDate: props.dateSurvey.endDate,
                    key: "selection",
                },
            ]);
        }
    }, [props.dateFilterSurvey, props.date, props.dateSurvey]);

    // const selectDate = () => {
    //     if (!props.date.startDate) {
    //         props.selectDate(date);
    //     } else {
    //         setDate([
    //             {
    //                 startDate: moment(props.date.startDate, "DD/MM/YYYY").format("MM/DD/YYYY"),
    //                 endDate: moment(props.date.endDate, "DD/MM/YYYY").format("MM/DD/YYYY"),
    //                 predefinedTag: props.date.predefinedTag,
    //                 dateString: props.date.dateString,
    //             },
    //         ]);
    //     }
    // };

    // const selectDateSurvey = () => {
    //     if (!props.dateSurvey.startDate) {
    //         props.selectDateSurvey(dateSurvey);
    //     } else {
    //         setDateSurvey([
    //             {
    //                 startDate: moment(props.date.startDate, "DD/MM/YYYY").format("MM/DD/YYYY"),
    //                 endDate: moment(props.date.endDate, "DD/MM/YYYY").format("MM/DD/YYYY"),
    //                 predefinedTag: props.date.predefinedTag,
    //                 dateString: props.date.dateString,
    //             },
    //         ]);
    //     }
    // };

    return (
        <span id="dateFilterContainer">
            <DateRangePicker
                ranges={props.dateFilterSurvey ? dateSurvey : date}
                className={props.classname ? props.classname : ""}
                staticRanges={util.customDateRanges(null, null)}
                inputRanges={[]}
                direction="vertical"
                onChange={(item) => {
                    let selectedDate = util.getDateObject(item.selection);
                    if (selectedDate.dateString === "Invalid date - Invalid date") {
                        selectedDate = { ...selectedDate, predefinedTag: "allTime", dateString: "All Time" };
                    }
                    if (props.dateFilterSurvey) {
                        setDateSurvey([selectedDate]);
                    } else if (!props.dateFilter) {
                        setDate([selectedDate]);
                    }

                    if (item.selection.predefinedTag === "thisYear" || item.selection.predefinedTag === "thisMonth") {
                        props.SetKeyOfTopEmployee(item.selection.predefinedTag);
                    }
                    if (
                        (item.selection.startDate === "" && item.selection.endDate === "") ||
                        item.selection.startDate !== item.selection.endDate
                    ) {
                        if (props.dateFilterSurvey) {
                            props.selectDateSurvey(selectedDate);
                        } else if (!props.dateFilter) {
                            props.selectDate(selectedDate);
                        }
                    }
                }}
            />
        </span>
    );
}

const mapStateToProps = (state) => {
    return {
        date: state.reports.selectedDate,
        dateSurvey: state.reports.selectedDateSurvey,
        keyOfTopEmployee: state.reports.keyOfTopEmployee,
        dateFilterSurvey: state.app.dateFilterSurvey,
    };
};

const mapDispatchToProps = {
    ...app.actions,
    ...reportsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
