import React from "react";
import { connect } from "react-redux";
import * as travelRedux from "../redux/travelRedux"
import RadioButtons from "app/popleads/components/RadioButtons";

class TravelSelectType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    onChange = selectedOption => {
        // this.state.removeFilter ? this.props.setDealSelfInvestmentSelect("") : 
        this.props.setState(selectedOption.value);
    };

    componentDidMount() { }
    
    render() {
        return (
            <div className="d-flex flex-column mt-4 mb-2">
                <div className="filterbar-heading d-flex justify-content-between">
                    <div>{this.props.title}</div>
                    <div className=" text-muted cursor-pointer" onClick={() => this.props.setState(null)}>
                        {this.props.selectedItem ? "X" : ""}
                    </div>
                </div>
                <div className="mt-2" style={{ maxWidth: "425px" }}>
                    <RadioButtons
                        options={this.props.option}
                        currentSelected={this.state.removeFilter ? this.props.setState(null) : this.props.selectedItem}
                        onChange={this.onChange}
                        bgColorApplied
                        removeFilter={this.state.removeFilter}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        travelVisaAndPassportTypeSelect: state.travel.travelVisaAndPassportTypeSelect
    };
};

const mapDispatchToProps = {
    ...travelRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TravelSelectType);
