import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

/**
 * @typedef {Object} LoadingSpinProps
 * @property {string} [classNames] - Additional class names for the component
 */

/**
 * @type {React.FC<LoadingSpinProps>} A component that can be used to display an empty error
 *  message and provides onClick handler to allow the user to click on it to try again.
 */

const LoadingSpin = ({ classNames, centered, onClick }) => {
    return (
        <div className={`${classNames ? classNames : ""}  centerEverything indicatorBox`}>
            <Spinner animation="border" role="status">
                <span className="sr-only">
                    <FormattedMessage id="POPLEADS.LOADING" />
                </span>
            </Spinner>
        </div>
    );
};

LoadingSpin.propTypes = {
    classNames: PropTypes.string
};

export default LoadingSpin;
