import React from "react";
import FinalReportHeader from "../common/FinalReportHeader";

import commonStyles from "../../final-report-common.module.scss";
import DealSellersandClients from "../common/DealSellersandClients";

const Page7 = ({ last3TopSellersCount, last3DealsData }) => {
    return (
        <>
            <div className={commonStyles.page}>
                <FinalReportHeader />
                <div className={commonStyles.content}>
                    <h2 className={`${commonStyles.pageTitle} text-center mb-6`}>DELIVERED CONSULTING PROJECTS</h2>
                    <DealSellersandClients last3DealsData={last3DealsData?.Consulting} />
                    <h2 className={`${commonStyles.pageTitle} text-center mt-16 mb-6`}>DELIVERED ELEARNING PROJECTS</h2>
                    <DealSellersandClients last3DealsData={last3DealsData?.Elearning} />
                    {last3TopSellersCount < 6 && (
                        <>
                            <h2 className={`${commonStyles.pageTitle} text-center mt-16 mb-6`}>
                                DELIVERED COACHING PROJECTS
                            </h2>
                            <DealSellersandClients last3DealsData={last3DealsData?.Coaching} />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Page7;
