import React from "react";
import { getUrlQueryParam } from "../../../../../app/functions/util";

import DealsTable from "../common/DealsTable";
import PercentSummary from "./Components/PercentSummary";
import TopCourses from "./Components/TopCourses";
import PortfolioMostSession from "./Components/PortfolioMostSession";
import FinalReportHeader from "../common/FinalReportHeader";

import commonStyles from "../../final-report-common.module.scss";

const Page4 = ({ type }) => {
    const startDate = getUrlQueryParam("startDate");
    const endDate = getUrlQueryParam("endDate");
    const unitId = getUrlQueryParam("unitId")?.split(",");

    const title = type === "Public" ? "DELIVERED PUBLIC PROJECTS" : type === "LVTPublic" ? "DELIVERED LVT PUBLIC PROJECTS" : "";

    return (
        <>
            <div className={commonStyles.page}>
                <FinalReportHeader />
                <div className={commonStyles.content}>
                    <div className="mb-16">
                        <h2 className={commonStyles.pageTitle}>{title}</h2>
                        <DealsTable startDate={startDate} endDate={endDate} unitId={unitId} type={type} />
                    </div>
                    <div className="mb-24 mx-auto">
                        <PercentSummary startDate={startDate} endDate={endDate} unitId={unitId} type={type} />
                    </div>
                    <div className="mb-26">
                        <TopCourses startDate={startDate} endDate={endDate} unitId={unitId} type={type} />
                    </div>
                    <div>
                        <PortfolioMostSession startDate={startDate} endDate={endDate} unitId={unitId} type={type} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Page4;
