import moment from "moment";
import React, { useEffect, useState } from "react";
import { post } from "../../../../../../networking/RequestService";
import styles from "../Page5.module.scss";
import * as util from "../../../../../../app/functions/util";

const FrequentClients = ({ startDate, endDate, unitId, type }) => {
    const [clientsData, setClientsData] = useState();
    const [notLoadedImages, setNotLoadedImages] = useState([]);

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "getFinalReportsFrequentClients",
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            };

            if (unitId) requestObj["unitId"] = unitId;

            if (type === "InHouse") requestObj["campaignType"] = "in-house";
            if (type === "LVTInHouse") requestObj["campaignType"] = "lvt in-house";

            post(requestObj)
                .then(({ data }) => {
                    setClientsData(data?.frequentClients);
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.frequentContainer}>
            <div className={styles.title}> MOST FREQUENT CLIENTS</div>
            {clientsData && clientsData.length > 0 ? (
                clientsData.map(item => (
                    <div key={item?.clientName} className={styles.frequentInfo}>
                        <div className={styles.brand}>{item.clientName}</div>
                        <div className={styles.logo}>
                            {item?.clientLogoUrl && !notLoadedImages.includes(item?.clientName) ? (
                                <div className={styles.brandLogo}>
                                    <img
                                        className={styles.brandImg}
                                        src={item.clientLogoUrl}
                                        alt="client"
                                        onError={() => setNotLoadedImages([...notLoadedImages, item?.clientName])}
                                    />
                                </div>
                            ) : (
                                <div className={styles.fallbackNameTag}>{util.nameTag(item?.clientName)}</div>
                            )}
                        </div>
                        <div className={styles.session}>
                            <div className={styles.qty}>{item.sessionCount}</div>
                            <div className={styles.qtyText}>{item?.sessionCount !== 0 && item?.sessionCount === 1 ? "SESSION" : "SESSIONS" }</div>
                        </div>
                    </div>
                ))
            ) : (
                <div>There is no data available.</div>
            )}
        </div>
    );
};

export default FrequentClients;
