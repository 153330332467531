export const actionTypes = {
    ShowAddSurveyButton: "[Action] Show Add Survey Button",
    ShowEditSurveyButton: "[Action] Show Edit Survey Button",
    StatusEditSurveyButton: "[Action] Status Edit Survey Button",
    ShowPreviewSurveyButton: "[Action] Show Preview Survey Button",
    SetSurveyId: "[Action] SetSurveyId",
    SetCampaignId: "[Action] SetCampaignId",
    SetCampaignName: "[Action] SetCampaignName",
    SetCampaignType: "[Action] SetCampaignType",
    ShowSurveyCampaignList: "[Action] Show Survey Campaign List Action Button",
};

const initialReportsState = {
    showAddSurveyButton: false,
    showEditSurveyButton: false,
    statusEditSurveyButton: false,
    showPreviewSurveyButton: false,
    surveyId: undefined,
    campaignId: undefined,
    campaignName: undefined,
    campaignType: undefined,
    showSurveyCampaignList: false,
};

export const reducer = (state = initialReportsState, action) => {
    switch (action.type) {
        case actionTypes.ShowAddSurveyButton: {
            const { showAddSurveyButton } = action.payload;
            return { ...state, showAddSurveyButton };
        }

        case actionTypes.ShowEditSurveyButton: {
            const { showEditSurveyButton } = action.payload;
            return { ...state, showEditSurveyButton };
        }

        case actionTypes.StatusEditSurveyButton: {
            const { statusEditSurveyButton } = action.payload;
            return { ...state, statusEditSurveyButton };
        }

        case actionTypes.ShowPreviewSurveyButton: {
            const { showPreviewSurveyButton } = action.payload;
            return { ...state, showPreviewSurveyButton };
        }

        case actionTypes.SetSurveyId: {
            const { surveyId } = action.payload;
            return { ...state, surveyId };
        }

        case actionTypes.SetCampaignId: {
            const { campaignId } = action.payload;
            return { ...state, campaignId };
        }

        case actionTypes.SetCampaignName: {
            const { campaignName } = action.payload;
            return { ...state, campaignName };
        }

        case actionTypes.SetCampaignType: {
            const { campaignType } = action.payload;
            return { ...state, campaignType };
        }

        case actionTypes.ShowSurveyCampaignList: {
            const { showSurveyCampaignList } = action.payload;
            return { ...state, showSurveyCampaignList };
        }

        default:
            return state;
    }
};

export const actions = {
    toggleShowAddSurveyButton: (showAddSurveyButton) => ({
        type: actionTypes.ShowAddSurveyButton,
        payload: { showAddSurveyButton },
    }),

    toggleShowEditSurveyButton: (showEditSurveyButton) => ({
        type: actionTypes.ShowEditSurveyButton,
        payload: { showEditSurveyButton },
    }),

    setStatusEditSurveyButton: (statusEditSurveyButton) => ({
        type: actionTypes.StatusEditSurveyButton,
        payload: { statusEditSurveyButton },
    }),

    togglePreviewSurveyButton: (showPreviewSurveyButton) => ({
        type: actionTypes.ShowPreviewSurveyButton,
        payload: { showPreviewSurveyButton },
    }),

    setSurveyId: (surveyId) => ({
        type: actionTypes.SetSurveyId,
        payload: { surveyId },
    }),

    setCampaignId: (campaignId) => ({
        type: actionTypes.SetCampaignId,
        payload: { campaignId },
    }),

    setCampaignName: (campaignName) => ({
        type: actionTypes.SetCampaignName,
        payload: { campaignName },
    }),

    setCampaignType: (campaignType) => ({
        type: actionTypes.SetCampaignType,
        payload: { campaignType },
    }),
    setShowSurveyCampaignList: (showSurveyCampaignList) => ({
        type: actionTypes.ShowSurveyCampaignList,
        payload: { showSurveyCampaignList },
    }),
};
