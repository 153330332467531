import { toAbsoluteUrl } from "_metronic/_helpers";
import { customDateRanges } from "app/popleads/app/functions/util";
import { TooltipOverlay } from "app/popleads/components/TooltipOverlay";
import CustomDrawer, { DrawerBody, DrawerContent, DrawerHeader } from "app/popleads/components/drawer/CustomDrawer";
import useDisclosure from "app/popleads/hooks/useDisclosure";
import React from "react";
import { Button } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import InlineSVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../popleads/assets/css/design_3.0/CustomDatePicker.module.scss";
import { actions } from "app/popleads/modules/accounts/redux/accountsRedux";

const PartnerFilterDrawer = () => {
    const { isOpen, onClose, onToggle } = useDisclosure();

    const { partnerSelectedDate } = useSelector((state) => state.accounts);
    const dispatch = useDispatch();

    return (
        <>
            <TooltipOverlay placement="bottom" tooltip="Filters">
                <Button variant="secondary" className="ml-1 ml-sm-4 customBootstrapBtn" onClick={onToggle}>
                    <InlineSVG src={toAbsoluteUrl("/media/svg/icons/Text/Filters2.svg")} />
                </Button>
            </TooltipOverlay>
            <CustomDrawer position="right" size="md" open={isOpen} onClose={onClose}>
                <DrawerContent>
                    <DrawerHeader onClose={onClose} sx={{ border: "none" }}>
                        Filters
                    </DrawerHeader>
                    <DrawerBody>
                        <div className="secondary-color font-weight-bold mt-4 mb-2">Filter by Date</div>
                        <DateRangePicker
                            className={styles.rdrDateRangePickerWrapper}
                            ranges={[partnerSelectedDate]}
                            staticRanges={customDateRanges(new Date(null), new Date())}
                            onChange={(item) => {
                                dispatch(actions.setPartnerSelectedDate(item.selection));
                                // DOM manipulation to highlight "All Time"
                                const range = document.querySelector(".rdrStaticRanges")?.firstElementChild;
                                if (range && !item.selection.startDate) {
                                    range.classList.add("rdrStaticRangeSelected");
                                } else {
                                    range.classList.remove("rdrStaticRangeSelected");
                                }
                            }}
                            inputRanges={[]}
                            direction="vertical"
                        />
                    </DrawerBody>
                </DrawerContent>
            </CustomDrawer>
        </>
    );
};

export default PartnerFilterDrawer;
