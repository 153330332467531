import React from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchFilter from "app/popleads/modules/filters/components/SearchFilter";
import { actions } from "app/popleads/modules/accounts/redux/accountsRedux";
import Button from "app/popleads/components/Button";

const OfficialAccountsActions = () => {
    const { user } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    return (
        <div className="d-flex align-items-center">
            <SearchFilter module="account" className="searchPL" />
            {user?.permissions?.ACCOUNT_OFFICIAL === 1 && (
                <Button
                    title="Add New Account"
                    variant="success"
                    icon
                    src="/media/svg/popleads/plus.svg"
                    outline
                    className="d-none d-sm-flex buttonDesktop mx-2"
                    onClick={() => dispatch(actions.toggleAccountAddButton(true))}
                />
            )}
        </div>
    );
};

export default OfficialAccountsActions;
