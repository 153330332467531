export const NA = "-";
export const PRECAMPAIGN_TYPE = [
    { value: "LVT Public", label: "LVT Public" },
    { value: "Public", label: "Public" }
];
export const CAMPAIGN_STATUS = [
    { value: "Planning", label: "Planning" },
    { value: "Active", label: "Active" },
    { value: "Closed", label: "Closed" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Postponned", label: "Postponned" }
];
export const DATE_FORMAT = "DD.MM.yyyy";
export const MONTH_NAMES = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december"
];
export const DATA_MAN_REQ_OBJ = { fn: "getCampaignDynamicSchemaAllFields", module: "data management" };
export const MOBILE_BREAKPOINT = 450;
export const SMALL_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1390;
export const XXXL_BREAKPOINT = 1919;
export const XXL_BREAKPOINT = 1199;
export const XL_BREAKPOINT = 992;
export const BREAKPOINTS = {
    sm: 450,
    md: 768,
    lg: 992,
    xl: 1390,
    xxl: 1919,
    "bootstrap-sm": 576,
    "bootstrap-md": 768,
    "bootstrap-lg": 992,
    "bootstrap-xl": 1200,
    "bootstrap-xxl": 1400
};