import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as accounts from "../../accounts/redux/accountsRedux";
import * as campaigns from "../../campaigns/redux/campaignsRedux";
import SearchInput from "./../../../app/partials/SearchInput";

function SearchFilter(props) {
    const [inputValue, setInputValue] = useState("");

    const handleSearch = (value) => {
        if (props.module === "account") props.search(value);
        else if (props.module === "precampaign") props.searchPrecampaign(value);
        else if (props.module === "return") {
            props.searchReturn(value);
            setInputValue(value);
        }
    };

    useEffect(() => {
        if (props.module === "account") setInputValue(props.searchInput_accounts);
        else if (props.module === "precampaign") setInputValue(props.searchInput_precampaign);
    }, [props.module]);

    return (
        <SearchInput
            minLength={1}
            debounceTimeout={300}
            onChange={(event) => handleSearch(event.target.value)}
            className="w-100 form-control"
            variant={props.variant}
            placeholder={props.placeholder ? props.placeholder : "Search..."}
            id="kt_datatable_search_query"
            value={inputValue}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        searchInput_accounts: state.accounts.searchInput,
        searchInput_precampaign: state.campaigns.searchInput_precampaign,
    };
};

const mapDispatchToProps = {
    ...accounts.actions,
    ...campaigns.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
