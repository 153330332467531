import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import { post } from "../../../popleads/networking/RequestService";
import { withRouter } from 'react-router-dom'
import * as util from "../../../popleads/app/functions/util";

class Logout extends Component {
  componentDidMount() {
    const requestObj = {
      "fn": "cikis",
    };
    post(requestObj)
      .then(() => {
        this.logoutLocal();
      })
      .catch((err) => {
        this.logoutLocal();
      });

  }

  logoutLocal() {
    util.eraseCookie(util.getCookieName());
    localStorage.removeItem('sid');
    this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(withRouter(Logout));
