import React, { useEffect, useState } from "react";
import { post } from "../networking/RequestService";
import { connect, useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../app/redux/appRedux";
import * as util from "../app/functions/util";

const useGetTypesByModule = () => {
    const dispatch = useDispatch();
    if (!useSelector(state => state.app.dynamicActivityTypes)) {
        let reqObj = {
            fn: "getTypesByModule",
            module: "activity"
        };
        post(reqObj)
            .then(({ data: { typeList } }) => {
                dispatch({
                    type: actionTypes.SetDynamicActivityTypes,
                    payload: { dynamicActivityTypes: typeList?.map(item => item.name) }
                });
            })
            .catch(error => {
                console.log("error: ", error);
            });
    }
};

export default useGetTypesByModule;
