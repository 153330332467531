export const GROUPBY_OPTIONS = [
    { value: "status", label: "Status" },
    { value: "dueDate", label: "Due Date" },
    { value: "priority", label: "Priority" }
];

export const GROUPBYSORT_OPTIONS = [
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" }
];

export const FILTERBY_OPTIONS = [
    { value: "assignedToMe", label: "Assigned To Me" },
    { value: "createdByMe", label: "Created By Me" },
    { value: "followedByMe", label: "Followed By Me" },
    { value: "approvedByMe", label: "Approved By Me" }
];

export const ACTIVITY_STATUS_OPTIONS = [
    { value: "To Do", label: "To Do" },
    { value: "In Progress", label: "In Progress" },
    { value: "Completed", label: "Completed" }
];

export const ACTIVITY_PRIORITY_OPTIONS = [
    { value: "Low", label: "Low" },
    { value: "Normal", label: "Normal" },
    { value: "High", label: "High" },
    { value: "Urgent", label: "Urgent" }
];

export const ACTIVITY_PRIORITY_INTEGERS = [
    { value: 1, label: "Low" },
    { value: 2, label: "Normal" },
    { value: 3, label: "High" },
    { value: 4, label: "Urgent" }
];

export const QUICK_ACTIONS_SORT_OPTIONS = [
    { value: "subject", label: "Activity Name" },
    { value: "campaignName", label: "Campaign" },
    { value: "activityType", label: "Activity Type" },
    { value: "startDate", label: "Start Date" },
    { value: "dueDate", label: "Due Date" },
    { value: "createdDate", label: "Created Date" },
    { value: "modifiedDate", label: "Modified Date" },
    { value: "priority", label: "Priority" }
];
export const HIDE_ACTION_PLAN_OPTIONS = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" }
];
