import React, { Component } from "react";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

class CheckRadioBtn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: false,
            isDisabled: false,
        };
    }

    toggleChange = () => {
        if (!this.state.isDisabled) {
            this.setState({
                isChecked: !this.state.isChecked,
            });
            this.props.onChange();
        }
    };

    componentDidMount() {
        if (this.props.checked) {
            this.setState({ isChecked: true });
        }
        if (this.props.disabled) {
            this.setState({ isDisabled: true });
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.checked !== this.props.checked) {
            this.setState({ isChecked: this.props.checked });
        }
    }

    render() {
        let classNameInputCheck = "form-check-input";
        let classNameContainer = "checkradio-container";
        let classNameCheckMark = "custommark";
        if (this.state.isChecked === true) {
            classNameContainer += " is-checked";
            classNameCheckMark += " is-checked";
        }

        return (
            <label className={classNameContainer}>
                <span>
                    <input className={classNameInputCheck} type="checkbox" onChange={this.toggleChange} />
                    <span className={classNameCheckMark}>
                        <SVG src={toAbsoluteUrl("/media/svg/popleads/success.svg")}></SVG>
                    </span>
                </span>
                {this.props.before ? this.props.before : ""}
                <span className="text-card">
                    {this.props.option?.label
                        ? this.props.option?.label
                        : this.props.option?.name || this.props.option?.assigneeName}
                </span>
            </label>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CheckRadioBtn);
