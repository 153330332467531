import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
    getCitiesForSelect,
    getCountriesForSelect,
    getCountryCustomisation,
    getDynamicSchemaForSelect,
} from "../../../app/functions/util";
import LoadingSpin from "../../../app/partials/LoadingSpin";
import SearchInput from "../../../app/partials/SearchInput";
import CheckRadioBtn from "../../../components/CheckRadioBtn";
import { post } from "../../../networking/RequestService";
import * as app from "../../../../popleads/app/redux/appRedux";

const NewMultiSelectFilter = forwardRef(
    (
        {
            inputLabel,
            fn,
            disabledSearch,
            disabledForOptions,
            selectedItems,
            requiredField,
            staticOptions,
            setSelectedItems,
            singleSelection,
            numOfOptionsToDisplay = 4,
            showHeading = true,
            single,
            defaultOptions,
            from,
            isForDeals,
            ...props
        },
        ref
    ) => {
        const [isLoading, setIsLoading] = useState(false);
        const [searchInput, setSearchInput] = useState("");
        const [options, setOptions] = useState(defaultOptions ? defaultOptions : []);
        const onChange = (clickedItem) => {
            const tempOptions = [...selectedItems];
            if (single) {
                setSelectedItems([clickedItem]);
            } else {
                if (singleSelection) {
                    if (tempOptions.find((item) => item.value === clickedItem.value)) {
                        setSelectedItems(tempOptions.filter((item) => item.value !== clickedItem.value));
                    } else {
                        setSelectedItems([clickedItem]);
                    }
                } else if (tempOptions.find((item) => item.value === clickedItem.value)) {
                    setSelectedItems(tempOptions.filter((item) => item.value !== clickedItem.value));
                } else {
                    setSelectedItems([...tempOptions, clickedItem]);
                }
            }
        };
        useImperativeHandle(
            ref,
            () => ({
                clearSearch: () => setSearchInput(""),
            }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [searchInput]
        );

        const getOptions = useCallback(() => {
            let reqObj = { fn };
            if (fn === "allUsers") {
                console.log("fn", fn);
                setIsLoading(true);
                let reqObj2 = { fn: "companyUsers" };
                post(reqObj2)
                    .then(({ data: { users } }) => {
                        const userOptions = [];
                        if (users) {
                            for (let i = 0; i < users.length; i++) {
                                const obj = { value: users[i].id, label: users[i].name };
                                userOptions.push(obj);
                            }
                        }
                        setOptions(userOptions);

                        return post({ fn: "companyGroups" });
                    })
                    .then(({ data: { groups } }) => {
                        const groupOptions = [];
                        if (groups) {
                            for (let i = 0; i < groups.length; i++) {
                                const obj = { value: groups[i].id, label: groups[i].name, isGroup: true };
                                groupOptions.push(obj);
                            }
                        }
                        setOptions((prevOptions) => [...prevOptions, ...groupOptions]);
                    })
                    .catch((err) => console.log(err))
                    .finally(() => setIsLoading(false));
            }

            if (fn === "companyUsers") {
                setIsLoading(true);
                post(reqObj)
                    .then(({ data: { users } }) => {
                        const options = [];
                        if (users) {
                            for (let i = 0; i < users.length; i++) {
                                const obj = { value: users[i].id, label: users[i].name };
                                options.push(obj);
                            }
                        }
                        setOptions(options);
                    })
                    .catch((err) => console.log(err))
                    .finally(() => setIsLoading(false));
            } else if (fn === "getAllUnits") {
                setIsLoading(true);
                post(reqObj)
                    .then(({ data: { departments } }) => {
                        const options = [];
                        for (let i = 0; i < departments.length; i++) {
                            for (let j = 0; j < departments[i].unitList.length; j++) {
                                const obj = {
                                    label: departments[i].unitList[j].name,
                                    value: departments[i].unitList[j].id,
                                };
                                options.push(obj);
                            }
                            setOptions(options);
                        }
                    })
                    .catch((err) => console.log(err))
                    .finally(() => setIsLoading(false));
            }

            /*if (fn === "getTypesByModule") {
                setIsLoading(true);
                if (inputLabel === "Filter By Activity Type") {
                    reqObj.module = "Activity";
                }
                post(reqObj)
                    .then(({ data: { typeList } }) => {
                        const options = [];
                        if (typeList) {
                            for (const type of typeList) {
                                options.push({ value: type.id, label: type.name });
                            }
                        }
                        setOptions(options);
                    })
                    .catch(err => console.log(err))
                    .finally(() => setIsLoading(false));
            }*/
            if (fn === "getActivityTagList") {
                setIsLoading(true);
                reqObj = {
                    ...reqObj,
                    start: 1,
                    rowsPerPage: 100,
                };
                post(reqObj)
                    .then(({ data: { labels } }) => {
                        const options = [];
                        if (labels) {
                            for (const label of labels) {
                                options.push({ value: label.id, label: label.tagName });
                            }
                        }
                        setOptions(options);
                    })
                    .catch((err) => console.log(err))
                    .finally(() => setIsLoading(false));
            }
        }, [fn, inputLabel, staticOptions]);

        const getFilteredOptions = useCallback(() => {
            let reqObj = { fn };
            if (fn === "searchDealFields" && searchInput && searchInput.length >= 2) {
                reqObj.fieldValue = searchInput;
                if (inputLabel === "Deal Name") reqObj.fieldName = "name";
                if (inputLabel === "Marketing Channel") reqObj.fieldName = "typeChannel";
                if (inputLabel === "Title") reqObj.fieldName = "title";
                setIsLoading(true);
                post(reqObj)
                    .then(({ data: { searchResults } }) => {
                        const options = [];
                        if (searchResults) {
                            for (let i = 0; i < searchResults.length; i++) {
                                const obj = { value: searchResults[i], label: searchResults[i] };
                                options.push(obj);
                            }
                        }
                        setOptions(options);
                    })
                    .catch((err) => console.log(err))
                    .finally(() => setIsLoading(false));
            }
            if (fn === "accountListHomePage" && searchInput && searchInput.length >= 2) {
                reqObj.start = 1;
                reqObj.rowsPerPage = 70;
                reqObj.search = searchInput;
                if (from === "dealsFilters") {
                    reqObj.orderByColumn = "lastClosedDealDate";
                    reqObj.orderType = "desc";
                } else {
                    reqObj.orderByColumn = "ad";
                    reqObj.orderType = "asc";
                }
                setIsLoading(true);
                post(reqObj)
                    .then(({ data: { accountList } }) => {
                        const options = [];
                        if (accountList) {
                            for (let i = 0; i < accountList.length; i++) {
                                const obj = { value: accountList[i].id, label: accountList[i].accountName };
                                options.push(obj);
                            }
                        }
                        setOptions(options);
                    })
                    .catch((err) => console.log(err))
                    .finally(() => setIsLoading(false));
            }
            if (fn === "campaignListHomePage" && searchInput && searchInput.length >= 2) {
                reqObj.start = 1;
                reqObj.rowsPerPage = 30;
                reqObj.orderByColumn = "ad";
                reqObj.orderType = "asc";
                reqObj.campName = "*" + searchInput + "*";
                reqObj.isExport = 0;
                if(isForDeals) reqObj.subCampaigns = 0
                setIsLoading(true);
                post(reqObj)
                    .then(({ data: { campaignList } }) => {
                        const options = [];
                        if (campaignList) {
                            for (let i = 0; i < campaignList.length; i++) {
                                const obj = { value: campaignList[i].id, label: campaignList[i].ad };
                                options.push(obj);
                            }
                        }
                        setOptions(options);
                    })
                    .catch((err) => console.log(err))
                    .finally(() => setIsLoading(false));
            }
            if (fn === "getCampaignTemplate" && searchInput && searchInput.length >= 2) {
                reqObj.fn = "getCampaignTemplate";
                setIsLoading(true);
                post(reqObj)
                    .then(({ data: { campaignTemplates } }) => {
                        const options = [];
                        if (campaignTemplates) {
                            for (let i = 0; i < campaignTemplates.length; i++) {
                                const obj = { value: campaignTemplates[i].id, label: campaignTemplates[i].name };
                                options.push(obj);
                            }
                        }
                        setOptions(options);
                    })
                    .catch((err) => console.log(err))
                    .finally(() => setIsLoading(false));
            }
            if (fn === "getCampaignDynamicSchemaAllFields") {
                let dynamicData = [];
                if (!props.dynamicSchemaDataManagement) {
                    let requestObj = {
                        fn: "getCampaignDynamicSchemaAllFields",
                        module: " data management",
                    };
                    post(requestObj).then(({ data: { dynamicSchemaFields } }) => {
                        props.setDynamicSchemaDataManagement(dynamicSchemaFields);
                        if (inputLabel === "Country") {
                            dynamicData = getCountriesForSelect(dynamicSchemaFields);
                            setOptions(dynamicData);
                        } else if (inputLabel === "City" || inputLabel === "From" || inputLabel === "To") {
                            dynamicData = getCitiesForSelect(dynamicSchemaFields, props.travelCityInCountry);
                            setOptions(dynamicData);
                        } else {
                            dynamicData = getDynamicSchemaForSelect(dynamicSchemaFields, null, null);
                        }
                    });
                } else {
                    if (inputLabel === "Country") {
                        dynamicData = getCountriesForSelect(props.dynamicSchemaDataManagement);
                        setOptions(dynamicData);
                    } else if (inputLabel === "City" || inputLabel === "From" || inputLabel === "To") {
                        dynamicData = getCitiesForSelect(props.dynamicSchemaDataManagement, props.travelCityInCountry);
                        setOptions(dynamicData);
                    } else {
                        dynamicData = getDynamicSchemaForSelect(props.dynamicSchemaDataManagement, null, null);
                    }
                }
            }
        }, [fn, inputLabel, searchInput]);

        useEffect(() => {
            if (selectedItems?.length > 0) {
                setSearchInput("");
            }
        }, [selectedItems]);

        useEffect(() => {
            getOptions();
        }, [getOptions]);

        useEffect(() => {
            getFilteredOptions();
        }, [getFilteredOptions]);

        const optionsToDisplay =
            fn === "getCampaignDynamicSchemaAllFields" && inputLabel === "Country"
                ? [
                      ...options?.filter((item) =>
                          getCountryCustomisation(props.dynamicSchemaDataManagement, searchInput)
                              .map((item) => item.toLowerCase())
                              .includes(item.label.toLowerCase())
                      ),
                      ...options?.filter((item) => item.label.toLowerCase().includes(searchInput.toLowerCase())),
                  ]
                      .filter((value, index, self) => self.indexOf(value) === index)
                      .filter(({ value: val1 }) => !selectedItems?.some(({ value: val2 }) => val1 === val2))
                      .slice(0, numOfOptionsToDisplay)
                : options
                      ?.filter((item) => item.label.toLowerCase().includes(searchInput.toLowerCase()))
                      .filter(({ value: val1 }) => !selectedItems?.some(({ value: val2 }) => val1 === val2))
                      .slice(0, numOfOptionsToDisplay);

        return (
            <div className="d-flex flex-column mt-4 mb-2">
                {showHeading && (
                    <div className="d-flex justify-content-between mb-2">
                        <div className="filterbar-heading">
                            {inputLabel} {requiredField && <span className="error-color ml-3">*</span>}{" "}
                            {!singleSelection && selectedItems?.length > 0 ? `(${selectedItems?.length})` : ""}
                        </div>
                        {!singleSelection && selectedItems?.length > 0 && (
                            <div
                                className="mb-3 text-muted cursor-pointer"
                                onClick={() => {
                                    setSelectedItems([]);
                                    setSearchInput("");
                                }}
                            >
                                X
                            </div>
                        )}
                    </div>
                )}
                <div className="mb-2">
                    <SearchInput
                        minLength={2}
                        debounceTimeout={500}
                        value={searchInput}
                        onChange={(event) => setSearchInput(event.target.value)}
                        className="form-control searchForm w-100"
                        placeholder="Search..."
                        id="kt_datatable_search_query"
                        disabled={disabledSearch}
                    />
                </div>
                <div className="d-flex flex-wrap" style={{ columnGap: "8px" }}>
                    <>
                        {selectedItems?.map((item) => (
                            <CheckRadioBtn
                                key={item.value}
                                option={{
                                    value: item.value,
                                    label: item.label,
                                }}
                                onChange={() => !disabledForOptions && onChange(item)}
                                checked
                                disabled={disabledForOptions}
                            />
                        ))}
                        {isLoading && <LoadingSpin />}
                        {!isLoading &&
                            searchInput &&
                            optionsToDisplay.length > 0 &&
                            optionsToDisplay.map((option) => (
                                <CheckRadioBtn
                                    key={option.value}
                                    option={option}
                                    onChange={() => !disabledForOptions && onChange(option)}
                                    disabled={disabledForOptions}
                                />
                            ))}
                    </>
                </div>
                {!isLoading && searchInput && selectedItems.length === 0 && optionsToDisplay.length === 0 && (
                    <div className="color-warning">
                        <FormattedMessage id="POPLEADS.NO_DATA" />
                    </div>
                )}
            </div>
        );
    }
);

const mapStateToProps = (state) => {
    return {
        travelCityInCountry: state.travel.travelCityInCountry,
        dynamicSchemaDataManagement: state.app.dynamicSchemaDataManagement,
    };
};

const mapDispatchToProps = {
    ...app.actions,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(NewMultiSelectFilter);
