

import React from "react";
import { FormattedNumber } from "react-intl";
import commonStyles from "../../final-report-common.module.scss";

const TopClients = ({ last3DealsData }) => {
    const data = last3DealsData?.items?.find(item => item?.title === "Top Clients")
     const firstData = data?.sortData[0]?.amount

    return (
        <div className={commonStyles.revenueContainerForProjects}>
            <div className={commonStyles.sellersAndClientTitle}>Top Clients</div>
            {data?.sortData ?
                data?.sortData.map(
                    (item, index) =>
                        parseFloat(item?.amount) > 0 && (
                            <div key={index}>
                                <div
                                    style={{
                                        color: `${index === 0 ? "#328456" : "#ffffff"}`,
                                        backgroundColor: `${index === 0 ? "#DAF2E5" : index === 1 ? "#db406c" : "#2c2c2c"
                                            }`,
                                        width: `${(item.amount * 100) / firstData + 25 < 100 ? (item?.amount * 100) / firstData + 40 : 100}%`
                                        // width: "300px"
                                    }}
                                    className={commonStyles.statusBar}>
                                    <div className={commonStyles.amount}>
                                        <span>$</span>
                                        <FormattedNumber value={item?.amount} />
                                    </div>
                                </div>
                                <div className={commonStyles.subTitle}>{item?.accountName}</div>
                            </div>
                        )
                )
                : (
                    <div> There is no data available.</div>
                )
            }
        </div>
    );
};

export default TopClients;
