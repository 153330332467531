import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import * as reportsRedux from "../../reports/redux/reportsRedux";
import SelectInput from "../components/inputs/selectInput";

function CampaignTypeFilter(props) {
    const options = [
        { value: "Coaching", label: "Coaching" },
        { value: "In-house", label: "In-house" },
        { value: "LVT In-House", label: "LVT In-House" },
        { value: "LVT Public", label: "LVT Public" },
        { value: "Mentoring", label: "Mentoring" },
        { value: "Online", label: "Online" },
        { value: "Public", label: "Public" },
    ];

    const onChange = (selectedOption) => {
        props.selectCampaignTypeList(selectedOption);
    };

    const onInputChange = (str, { action }) => {
        if (action === "set-value") return false;
    };

    return (
        <>
            {props.isMultiSelect ? (
                <SelectInput
                    object={{
                        name: "Campaign Type",
                        data: {
                            options: options,
                        },
                        multi: true,
                        allSelectable: true,
                    }}
                    onChange={onChange}
                    onInputChange={onInputChange}
                    infinite={false}
                />
            ) : (
                <Select
                    className="selectInputNewFilter_single"
                    classNamePrefix="selectInputNewFilter"
                    options={options}
                    defaultValue={props.campaignTypeList}
                    value={props.campaignTypeList}
                    onChange={onChange}
                    closeMenuOnSelect={true}
                    isMulti={props.isMultiSelect}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        campaignTypeFilter: state.reports.selectedCampaignTypeFilter,
    };
};

const mapDispatchToProps = {
    ...reportsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignTypeFilter);
