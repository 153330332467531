import React, { Fragment } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

export const TooltipOverlay = ({ placement, tooltip, children }) => {
    return (
        <OverlayTrigger
            key={Math.random()}
            placement={placement}
            overlay={
                <Tooltip
                    id={`tooltip-
                ${placement}
                `}>
                    {tooltip}
                </Tooltip>
            }>
            {children}
        </OverlayTrigger>
    );
};

export default Tooltip;
