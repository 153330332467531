import React from 'react'
import CheckRadioBtn from '../../../../components/CheckRadioBtn'

const DealSelectType = (props) => {

    const onChange = clickedItem => {
        const tempOptions = [...props.selected];
        if (tempOptions.find(item => item.value === clickedItem.value)) {
            props.setSelected(tempOptions.filter(item => item.value !== clickedItem.value));
        } else {
            props.setSelected([...tempOptions, clickedItem]);
        }
    };
    
    return (
        <div className="d-flex justify-content-between flex-column mt-4 mb-1">
            <div className='d-flex justify-content-between '>
                <div className="filterbar-heading ">{props.heading} { props?.selected?.length > 0 &&`(${props?.selected?.length})` }  </div>
                {props.selected && props.selected.length > 0 && (
                    <div className="mb-3 text-muted cursor-pointer" onClick={() => props.setSelected([])}>
                        X
                    </div>
                    
                )}
 
            </div>
            <div className="d-flex flex-wrap mt-2" style={{ columnGap: "8px" }}>
                {props.constant.map((option, i) => (
                    <CheckRadioBtn
                        checked={props.selected?.map(item => item.value).includes(option.value)}
                        key={i} option={option} onChange={() => onChange(option)} />
                ))}
            </div>
        </div>
    )
}

export default DealSelectType