import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";

import styles from "./ChatSkeleton.module.scss";

const ChatSkeleton = () => {
    return (
        <div className={`${styles.chatItem}`}>
            <div className={styles.avatar}>
                <SVG src={toAbsoluteUrl("/media/svg/popleads/hr_assistant_sarah.svg")} />
            </div>
            <div className={styles.loadingIcon}>
                <SVG src={toAbsoluteUrl("/media/svg/popleads/three-dots-loading.svg")} />
            </div>
        </div>
    );
};

export default ChatSkeleton;
