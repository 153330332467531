import React from "react";
import { connect } from "react-redux";
import * as dealsRedux from "../../../../modules/deals/redux/dealsRedux";
import RadioButtons from "../../../../components/RadioButtons";
import { dealBooleanOptions } from "../../../deals/constants";

class DealFullPrice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: dealBooleanOptions,
             
        };
    }

    onChange = selectedOption => {
        // this.state.removeFilter ? this.props.setDealSelfInvestmentSelect("") : 
        this.props.setDealFullPrice(selectedOption.value);
    };

    componentDidMount() { }


    render() {
        // console.log(this.props.dealSelfInvestment,"selected");
        //  const isSelected = this.props.dealFullPrice === "1" || this.props.dealFullPrice === null || this.props.dealFullPrice === "0"
        return (
            <div className="d-flex flex-column mt-4 mb-6">
                <div className="filterbar-heading d-flex justify-content-between">
                    <div>Full Price</div>
                    <div className=" text-muted cursor-pointer" onClick={() => this.props.setDealFullPrice(undefined)}>
                        {this.props.dealFullPrice ? "X" : ""}
                    </div>
                </div>
                <div className="mt-2" style={{ maxWidth: "425px" }}>
                    <RadioButtons
                        options={this.state.options}
                        currentSelected={this.state.removeFilter ? this.props.setDealFullPrice(undefined) : this.props.dealFullPrice}
                        onChange={this.onChange}
                        bgColorApplied
                        removeFilter={this.state.removeFilter}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        dealFullPrice: state.deals.dealFullPrice
    };
};

const mapDispatchToProps = {
    ...dealsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealFullPrice);
