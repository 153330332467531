import React, { useEffect, useState } from "react";
import { post } from "../networking/RequestService";
import { connect, useDispatch, useSelector } from "react-redux";
import { actionTypes } from "../app/redux/appRedux";
import * as util from "../app/functions/util";

const useDynamicSchemaDataManagement = fieldName => {
    const dispatch = useDispatch();
    if (!useSelector(state => state.app.dynamicSchemaDataManagement)) {
        let reqObj = {
            fn: "getCampaignDynamicSchemaAllFields",
            module: "data management"
        };
        post(reqObj)
            .then(({ data: { dynamicSchemaFields } }) => {
                dispatch({
                    type: actionTypes.SetDynamicSchemaDataManagement,
                    payload: { dynamicSchemaDataManagement: dynamicSchemaFields }
                });
            })
            .catch(error => {
                console.log("error: ", error);
            });
    }
};

export default useDynamicSchemaDataManagement;
