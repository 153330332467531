import { useLayoutEffect, useState } from "react";

import { BREAKPOINTS } from "../app/contants";

/**
 * @param {("sm" | "md" | "lg" | "xl" | "xxl" | "bootstrap-sm" | "bootstrap-md" | "bootstrap-lg" | "bootstrap-xl" | "bootstrap-xxl")} breakpoint
 */
const useIsLessThan = breakpoint => {
    const [isLessThan, setIsLessThan] = useState(window.innerWidth < BREAKPOINTS[breakpoint]);

    useLayoutEffect(() => {
        const handleWindowResize = () => {
            if (window.innerWidth < BREAKPOINTS[breakpoint]) {
                setIsLessThan(true);
                return;
            }
            setIsLessThan(false);
        };
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [breakpoint]);

    return isLessThan;
};

export default useIsLessThan;