import React, { useEffect } from "react";
import SelectInput from "../components/inputs/selectInput";
import { connect } from "react-redux";
import * as app from "../../../app/redux/appRedux";
import * as accounts from "../../accounts/redux/accountsRedux";

function TypeFilter(props) {
    const options = [
        { value: "All Types", label: "All Types" },
        { value: "Master Key", label: "Master Key" },
        { value: "Opened Master Key", label: "Opened Master Key" },
        { value: "No Call", label: "No Call" },
        { value: "Marketing Inquiries", label: "Marketing Inquiries" },
        { value: "Other", label: "Other" },
    ];

    useEffect(() => {
        if (!props.selectedType) {
            props.selectType(options[0]);
        }
    }, []);

    const onChange = (selectedOption) => {
        props.selectType(selectedOption);
    };

    return (
        <SelectInput
            object={{
                data: {
                    options: options,
                },
                name: "Type",
                ...(props.selectedType && { value: props.selectedType }),
            }}
            onChange={(selected) => onChange(selected)}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        selectedType: state.accounts.selectedType,
    };
};

const mapDispatchToProps = {
    ...accounts.actions,
    ...app.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TypeFilter);
