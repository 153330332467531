import React, { useState, useEffect } from "react";
import { FormattedNumber } from "react-intl";
import * as util from "../../../../../app/functions/util";

import CompWithPrevious from "./CompWithPrevious";
import DealDataItem from "./DealDataItem";

import styles from "../Page7/Page7.module.scss";
import commonStyles from "../../final-report-common.module.scss";
import moment from "moment";
import { post } from "../../../../../networking/RequestService";

const DealsTable = ({ startDate, endDate, unitId, type }) => {
    const [dealsData, setDealsData] = useState();
    const [notLoadedImages, setNotLoadedImages] = useState([]);
    console.log(type, "type");
    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            };

            if (type === "Public") {
                requestObj.fn = "getFinalPublicReports";
                requestObj.type = "public";
            }
            if (type === "LVTPublic") {
                requestObj.fn = "getFinalPublicReports";
                requestObj.type = "lvt public";
            }
            if (type === "InHouse") {
                requestObj.fn = "getFinalInhouseReports";
                requestObj.type = "in-house";
            }
            if (type === "LVTInHouse") {
                requestObj.fn = "getFinalInhouseReports";
                requestObj.type = "lvt in-house";
            }
            if (type === "Consulting") {
                requestObj.fn = "getCampaignTypeFinalReports";
                requestObj.campaignType = "consulting";
            }
            if (type === "Elearning") {
                requestObj.fn = "getCampaignTypeFinalReports";
                requestObj.campaignType = "e-learning";
            }
            if (type === "Coaching") {
                requestObj.fn = "getCampaignTypeFinalReports";
                requestObj.campaignType = "coaching";
            }

            if (unitId) requestObj.unitId = unitId;

            post(requestObj)
                .then(({ data }) => {
                    const modifiedData = {
                        totalAmount: +parseFloat(data?.amount || 0).toFixed(0),
                        items: []
                    };

                    if (["Public", "LVTPublic", "InHouse", "LVTInHouse"].includes(type)) {
                        const sessionCountComparison = parseFloat(
                            data?.averageNumberOfSession || data?.avrNumberOfSession || 0
                        );
                        const sessionAmountComparison = +parseFloat(
                            data?.lastYearAmountVsAmount || data?.lastYearAmountAvrPerSession || 0
                        ).toFixed(0);

                        (data?.numberOfSession || parseInt(data?.numberOfSession) === 0) &&
                            modifiedData.items.push({
                                title: "# OF SESSIONS",
                                amount: data?.numberOfSession,
                                comparison: {
                                    amount: sessionCountComparison,
                                    formattedAmount: Math.abs(sessionCountComparison),
                                    text: `${sessionCountComparison >= 0 ? "larger" : "less"} vs. last year`,
                                    isAbove: sessionCountComparison >= 0
                                },
                                color: "primaryOrange"
                            });

                        (data?.amountAveragePerSession || parseFloat(data?.amountAveragePerSession) === 0) &&
                            modifiedData.items.push({
                                title: "AVERAGE PER SESSION",
                                amount: parseFloat(data?.amountAveragePerSession || 0).toFixed(0),
                                comparison: {
                                    amount: sessionAmountComparison,
                                    formattedAmount: new Intl.NumberFormat("en", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0
                                    }).format(Math.abs(sessionAmountComparison)),
                                    text: `${sessionAmountComparison >= 0 ? "larger" : "less"} vs. last year`,
                                    isAbove: sessionAmountComparison >= 0
                                },
                                color: "primaryOrange"
                            });

                        ((type === "InHouse" || type === "LVTInHouse") && data?.numberOfDeal || parseInt(data?.numberOfDeal) === 0) &&
                            modifiedData.items.push({
                                title: "# OF DEALS",
                                amount: data?.numberOfDeal,
                                color: "primaryPink"
                            });

                        (data?.numberOfDelegate || parseInt(data?.numberOfDelegate) === 0) &&
                            modifiedData.items.push({
                                title: "# OF DELEGATES",
                                amount: data?.numberOfDelegate,
                                color: "primaryDarkBlue"
                            });

                        (data?.delegateAveragePerSession || parseFloat(data?.delegateAveragePerSession) === 0) &&
                            modifiedData.items.push({
                                title: "AVERAGE # OF DELEGATES PER SESSION",
                                amount: data?.delegateAveragePerSession,
                                color: "primaryDarkBlue"
                            });
                    }

                    if (["Consulting", "Elearning", "Coaching"].includes(type)) {
                        data?.topSeller &&
                            data.topSeller?.length > 0 &&
                            modifiedData.items.push({
                                title: "TOP SELLERS",
                                topSellers: data.topSeller,
                                amount: data.topSeller.length,
                                color: "primaryOrange"
                            });

                        data?.partner &&
                            modifiedData.items.push({
                                title: "TOP SELLING PARTNER",
                                topSellingPartner: data.partner,
                                amount: 1,
                                color: "primaryPink"
                            });

                        data?.customer &&
                            modifiedData.items.push({
                                title: "TOP SELLING CUSTOMER",
                                topSellingCustomer: data.customer,
                                amount: 1,
                                color: "primaryDarkBlue"
                            });
                    }

                    setDealsData(modifiedData);
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className={commonStyles.dealsData}>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <h3 className={commonStyles.dealPrimaryTitle}>AMOUNT</h3>
                <h2
                    className={`${commonStyles.dealPrimaryValue} ${dealsData?.totalAmount === 0 ? commonStyles.primaryPinkText : commonStyles.primaryGreenText
                        }`}>
                    {"$ "}
                    <FormattedNumber value={dealsData?.totalAmount} />
                </h2>
                <div
                    className={`${commonStyles.horizontalLine} ${dealsData?.totalAmount === 0 ? commonStyles.primaryPinkBg : commonStyles.primaryGreenBg
                        }`}
                />
            </div>

            <div className="d-flex justify-content-center w-100 mt-6">
                {dealsData?.items?.map((item, i) => (
                    <DealDataItem
                        key={`${type}__dealItem__${i}`}
                        title={item?.title}
                        value={item?.amount}
                        color={item?.color}
                        className={"dealItem" + type}>
                        {item?.comparison && item.comparison.amount !== 0 && (
                            <CompWithPrevious
                                className="d-flex flex-column align-items-center"
                                comparison={item.comparison}
                            />
                        )}
                        {item?.topSellers && (
                            <div className="d-flex justify-content-center w-100 mt-4" style={{ gap: "24px" }}>
                                {item.topSellers?.map(seller => (
                                    <div
                                        className="d-flex flex-column justify-content-center align-items-center"
                                        key={seller?.id}>
                                        {seller?.pictureUrl && !notLoadedImages.includes(seller?.id) ? (
                                            <img
                                                className={styles.sellerImg}
                                                src={seller?.pictureUrl}
                                                alt="seller"
                                                onError={() => setNotLoadedImages([...notLoadedImages, seller?.id])}
                                            />
                                        ) : (
                                            <div className={styles.fallbackNameTag}>{util.nameTag(seller?.name)}</div>
                                        )}
                                        <span className={commonStyles.dealsecondaryTitle}>{seller?.name}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                        {item?.topSellingPartner && (
                            <div className={styles.partnerImg}>
                                {item.topSellingPartner?.imgSrc &&
                                    !notLoadedImages.includes(item.topSellingPartner?.id) ? (
                                    <img
                                        src={item.topSellingPartner?.imgSrc}
                                        alt="partner"
                                        onError={() =>
                                            setNotLoadedImages([...notLoadedImages, item.topSellingPartner?.id])
                                        }
                                    />
                                ) : (
                                    <span className={commonStyles.dealsecondaryTitle}>
                                        {item.topSellingPartner?.abbreviation?.toUppercase()}
                                    </span>
                                )}
                            </div>
                        )}
                        {item?.topSellingCustomer && (
                            <div className="d-flex justify-content-center align-items-center mt-4">
                                {item.topSellingCustomer?.logoUrl &&
                                    !notLoadedImages.includes(item.topSellingCustomer?.id) && (
                                        <div className={styles.customerImg}>
                                            <img
                                                src={item.topSellingCustomer?.logoUrl}
                                                alt="customer"
                                                onError={() =>
                                                    setNotLoadedImages([
                                                        ...notLoadedImages,
                                                        item.topSellingCustomer?.id
                                                    ])
                                                }
                                            />
                                        </div>
                                    )}
                                <span className={commonStyles.dealsecondaryTitle}>
                                    {item.topSellingCustomer?.accountName}
                                </span>
                            </div>
                        )}
                    </DealDataItem>
                ))}
            </div>
        </div>
    );
};

export default DealsTable;
