import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedNumber } from "react-intl";
import { post } from "../../../../../../networking/RequestService";
import styles from "../Page5.module.scss";

const HighestRevenue = ({ startDate, endDate, unitId, type }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "getFinalReportsHighestRevenueAccounts",
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            };

            if (unitId) requestObj["unitId"] = unitId;

            if (type === "InHouse") requestObj["campaignType"] = "in-house";
            if (type === "LVTInHouse") requestObj["campaignType"] = "lvt in-house";

            post(requestObj)
                .then(({ data }) => {
                    const sortData = [...data?.accounts].sort((a, b) => b.totalAmount - a.totalAmount);
                    const topData = sortData[0]?.totalAmount;

                    const filterData = data?.accounts?.map(item => {
                        return {
                            ...item,
                            class:
                                (item.totalAmount * 100) / topData + 25 < 100
                                    ? (item.totalAmount * 100) / topData + 25
                                    : 100
                        };
                    });

                    setData(filterData);
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.revenueContainer}>
            <div className={styles.title}>TOP CLIENTS BY REVENUE</div>
            {data && data.length > 0 ? (
                data.map(
                    (item, index) =>
                        parseFloat(item.totalAmount) > 0 && (
                            <div key={index}>
                                <div
                                    style={{
                                        color: `${index === 0 ? "#328456" : "#ffffff"}`,
                                        backgroundColor: `${
                                            index === 0 ? "#DAF2E5" : index === 1 ? "#db406c" : "#2c2c2c"
                                        }`,
                                        width: `${item.class}%`
                                    }}
                                    className={styles.statusBar}>
                                    <div className={styles.amount}>
                                        <span>$</span>
                                        <FormattedNumber value={item.totalAmount} />
                                    </div>
                                </div>
                                <div className={styles.subTitle}>{item.accountName}</div>
                            </div>
                        )
                )
            ) : (
                <div>There is no data available.</div>
            )}
        </div>
    );
};

export default HighestRevenue;
