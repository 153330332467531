import React from "react";
import SelectInput from "../components/inputs/selectInput";
import { connect } from "react-redux";
import * as app from "../../../app/redux/appRedux";
import * as accounts from "../../accounts/redux/accountsRedux";

function IndustryFilter(props) {
    const onChange = (selectedOption) => {
        props.selectIndustry(selectedOption);
    };

    return (
        <SelectInput
            object={{
                fn: "getListFromDataSource",
                filterName: "industryFilter",
                field_name: "sector",
                name: "Industry",
                ...(props.selectedIndustry && { value: props.selectedIndustry }),
            }}
            onChange={(selected) => onChange(selected)}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        selectedIndustry: state.accounts.selectedIndustry,
    };
};

const mapDispatchToProps = {
    ...accounts.actions,
    ...app.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndustryFilter);
