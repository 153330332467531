import React from "react";
import { getUrlQueryParam } from "../../../../../app/functions/util";

import FinalReportHeader from "../common/FinalReportHeader";
import HighestRevenue from "./components/HighestRevenue";
import FrequentClients from "./components/FrequentClients";
import DealsTable from "../common/DealsTable";
import TopThreeDelivery from "./components/TopThreeDelivery";

import commonStyles from "../../final-report-common.module.scss";

const Page5 = ({ type }) => {
    const startDate = getUrlQueryParam("startDate");
    const endDate = getUrlQueryParam("endDate");
    const unitId = getUrlQueryParam("unitId")?.split(",");

    const title = type === "InHouse" ? "DELIVERED IN-HOUSE PROJECTS" : type === "LVTInHouse" ? "DELIVERED LVT IN-HOUSE PROJECTS" : "";

    return (
        <div className={commonStyles.page}>
            <FinalReportHeader />
            <div className={commonStyles.content}>
                <div>
                    <h2 className={commonStyles.pageTitle}>{title}</h2>
                    <DealsTable startDate={startDate} endDate={endDate} unitId={unitId} type={type} />
                </div>
                <TopThreeDelivery startDate={startDate} endDate={endDate} unitId={unitId} type={type} />
                <div className="d-flex justify-content-between">
                    <FrequentClients startDate={startDate} endDate={endDate} unitId={unitId} type={type} />
                    <HighestRevenue startDate={startDate} endDate={endDate} unitId={unitId} type={type} />
                </div>
            </div>
        </div>
    );
};

export default Page5;
