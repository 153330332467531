import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";

import { getUrlQueryParam } from "../../../../../app/functions/util";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { getFormattedDate } from "../../../../../app/functions/finalReportUtil";
import { post } from "../../../../../networking/RequestService";

import styles from "./Page1.module.scss";
import commonStyles from "../../final-report-common.module.scss";

const Page1 = () => {
    const [unitNames, setUnitNames] = useState();

    const startDate = getUrlQueryParam("startDate");
    const endDate = getUrlQueryParam("endDate");

    useEffect(() => {
        post({ fn: "getAllUnits" })
            .then(({ data: { departments } }) => {
                const unitIds = getUrlQueryParam("unitId")?.split(",");
                const labels = [];
                if (unitIds && unitIds.length > 0) {
                    for (const { unitList } of departments) {
                        for (const unit of unitList) {
                            if (unitIds.includes(unit?.id?.toString())) {
                                labels.push(unit.name);
                            }
                        }
                    }
                }
                setUnitNames(labels);
            })
            .catch(err => console.log(err));
    }, []);

    const formattedDate = getFormattedDate(startDate, endDate);

    return (
        <div
            className={`${commonStyles.page} ${styles.pageContainer}`}
            style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/users/final-report.png")}`
            }}>
            <div className={styles.titleContainer}>
                <div className={styles.textBig}>FINAL RESULTS</div>
                <div className={styles.border}></div>
                {unitNames &&
                    unitNames.length > 0 &&
                    unitNames.map(unit => <div key={unit} className={styles.subText}>{unit}</div>)}
                <div className={styles.subText}>{formattedDate}</div>
            </div>
            <div className={styles.logo}>
                <SVG fill="white" src={toAbsoluteUrl("/media/svg/popleads/learn-online.svg")} />
            </div>
        </div>
    );
};

export default Page1;
