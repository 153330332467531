import React, { useEffect } from "react";
import styles from "../Page4.module.scss";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import moment from "moment";
import { post } from "../../../../../../networking/RequestService";
import { useState } from "react";
import getCourseImage from "../../../../../../app/functions/getCourseImage";

const PortfolioMostSession = ({ startDate, endDate, unitId, type }) => {
    const [sessionData, setSessionData] = useState();

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "getFinalReportsPortfolios",
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            };

            if (unitId) requestObj["unitId"] = unitId;

            if (type === "Public") requestObj["campaignType"] = "public";
            if (type === "LVTPublic") requestObj["campaignType"] = "lvt public";

            post(requestObj)
                .then(({ data }) => {
                    setSessionData(data?.portfolios);
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.portfolioContainer}>
            <div className={styles.portfolioTitle}>PORTFOLIO WITH MOST SESSION</div>
            <div className={styles.container}>
                {sessionData && sessionData.length > 0 ? (
                    sessionData.map((item, i) => (
                        <div className={styles.portfolioItem} key={i}>
                            <img className={styles.img} src={toAbsoluteUrl(getCourseImage(item?.portfolioName))} alt="img" />
                            <div className={styles.info}>
                                <div className={styles.title}>{item?.portfolioName}</div>
                                <div className={styles.session}>{item?.sessionCount !== 0 && item.sessionCount === 1 ? `${item.sessionCount} session` : `${item.sessionCount} sessions`} </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>There is no data available.</div>
                )}
            </div>
        </div>
    );
};

export default PortfolioMostSession;
