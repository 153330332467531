import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import StyledDateInput from "../../../../app/partials/DateInput";
// src/app/popleads/app/partials/DateInput/DateInput.js

export default class DateInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: this.props.object.value ? new Date(this.props.object.value) : "",
            dateFrom: "",
            dateTo: "",
            action: ""
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.object.value !== this.props.object.value) {
            this.setState({ date: this.props.object.value });
        }
    }

    render() {
        let actionBetween = false;
        if (this.state.action === "[]") actionBetween = true;
        return (
            <>
                <div className="row">
                    {this.props.object.actions && (
                        <div className="col-sm-3">
                            <select
                                className="actionSelect"
                                onChange={action => {
                                    this.setState({ action: action.target.value });
                                    if (actionBetween || action === "[]")
                                        this.setState({ date: undefined, dateFrom: undefined, dateTo: undefined });
                                    this.props.onActionChange(action.target.value);
                                }}>
                                {this.props.object.actions.map((object, i) => (
                                    <option key={"option_" + i}>{object}</option>
                                ))}
                            </select>
                        </div>
                    )}
                    <div className="col-sm-9">
                        <div className="row">
                            <div
                                className={`${
                                    this.props.object.actions
                                        ? actionBetween
                                            ? "col-sm-12"
                                            : "col-sm-12"
                                        : "col-sm-12"
                                }`}>
                                <StyledDateInput
                                    maxDate={this.state.dateTo ? this.state.dateTo : undefined}
                                    placeholderText={
                                        actionBetween ? this.props.object.name + " From" : this.props.object.name
                                    }
                                    // className="newFilterDatePicker newFilterDatePickerFrom"
                                    dateFormat="dd.MM.yyyy"
                                    selected={actionBetween ? this.state.dateFrom : this.state.date}
                                    onChange={date => {
                                        if (actionBetween) this.setState({ dateFrom: date });
                                        else this.setState({ date });
                                        this.props.onInputChange(date);
                                    }}
                                    className="w-100"
                                />
                            </div>

                            {actionBetween && (
                                <div className="col-sm-12 mt-1rem">
                                    <StyledDateInput
                                        minDate={this.state.dateFrom ? this.state.dateFrom : undefined}
                                        placeholderText={
                                            actionBetween ? this.props.object.name + " To" : this.props.object.name
                                        }
                                        // className="newFilterDatePicker newFilterDatePickerTo"
                                        dateFormat="dd.MM.yyyy"
                                        selected={actionBetween ? this.state.dateTo : this.state.date}
                                        onChange={date => {
                                            if (actionBetween) this.setState({ dateTo: date });
                                            else this.setState({ date });
                                            this.props.onInputChange(date, 2);
                                        }}
                                        className="w-100"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
