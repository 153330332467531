import React from "react";
import { useDispatch, useSelector } from "react-redux";

import IndustryDropdown from "../components/IndustryDropdown";
import TypeDropdown from "../components/TypeDropdown";
import DateDropdown from "../components/DateDropdown";
import AccountFilterDrawer from "../drawers/AccountFilterDrawer";
import ViewTypeToggle from "../components/ViewTypeToggle";
import SearchFilter from "app/popleads/modules/filters/components/SearchFilter";
import { actions } from "app/popleads/modules/accounts/redux/accountsRedux";

const AccountListActions = () => {
    const { gridOrTable, selectedDate } = useSelector((state) => state.accounts);

    const dispatch = useDispatch();
    return (
        <div className="d-flex align-items-center">
            <SearchFilter module="account" className="searchPL" />
            <IndustryDropdown module="accounts" />
            <TypeDropdown module="accounts" />
            <DateDropdown
                selectedDate={selectedDate}
                setSelectedDate={(date) => dispatch(actions.setSelectedDate(date))}
            />
            <AccountFilterDrawer />
            <ViewTypeToggle
                viewType={gridOrTable}
                setViewType={(viewType) => dispatch(actions.setGridOrTable(viewType))}
            />
        </div>
    );
};

export default AccountListActions;
