// import moment from "moment";

export const actionTypes = {
    ViewType: "[Action] Settings View Type",
    IsActivityDrawerOpen: "[Action] Settings Is Activity Drawer Open",
    SelectedCampaignFilter: "[Action] Settings Campaign Filter",
    CampaignListForFilter: "[Action] Settings Campaign List For Filter",
    selectedCampaignListFromFilter: "[Action] Settings Selected Campaign List From Filter",
    IsActivitiesLoading: "[Action] Settings Activities Loading",
    IsFilterByCampaignModalShown: "[Action] Settings Is Filter By Campaign Modal Shown",
    CampaignDetailModalId: "[Action] Settings Campaign Detail Modal Id",
    ShowCampaignSidebar: "[Action] Settings Show Campaign Sidebar",
    IsCampaignListDrawerShown: "[Action] Settings Is Campaign List Drawer Shown",
    MailData: "[Action] Mail Data",
    ReFetchMailData: "[Action] Re-Fetch MailData",
};

const initialSettingsState = {
    viewType: null,
    isActivityDrawerOpen: false,
    selectedCampaignFilter: {
        status: [],
        campaignType: [],
        year: [],
        month: [],
        week: [],
        campaignAccount: [],
        campaignPm: [],
        campaignAssistant: [],
        campaignCountry: [],
    },
    campaignListForFilter: [],
    selectedCampaignListFromFilter: {},
    isActivitiesLoading: false,
    isFilterByCampaignModalShown: false,
    CampaignDetailModalId: undefined,
    showCampaignSidebar: true,
    isCampaignListDrawerShown: false,
    mailData: [],
    reFetchMailData: false,
};

export const reducer = (state = initialSettingsState, action) => {
    switch (action.type) {
        case actionTypes.ViewType: {
            const { viewType } = action.payload;
            return {
                ...state,
                viewType,
            };
        }
        case actionTypes.IsActivityDrawerOpen: {
            const { isActivityDrawerOpen } = action.payload;
            return {
                ...state,
                isActivityDrawerOpen,
            };
        }
        case actionTypes.SelectedCampaignFilter: {
            const { selectedCampaignFilter } = action.payload;
            return {
                ...state,
                selectedCampaignFilter,
            };
        }
        case actionTypes.CampaignListForFilter: {
            const { campaignListForFilter } = action.payload;
            return {
                ...state,
                campaignListForFilter,
            };
        }
        case actionTypes.selectedCampaignListFromFilter: {
            const { selectedCampaignListFromFilter } = action.payload;
            return {
                ...state,
                selectedCampaignListFromFilter,
            };
        }
        case actionTypes.IsActivitiesLoading: {
            const { isActivitiesLoading } = action.payload;
            return {
                ...state,
                isActivitiesLoading,
            };
        }
        case actionTypes.ShowCampaignSidebar: {
            const { showCampaignSidebar } = action.payload;
            return {
                ...state,
                showCampaignSidebar,
            };
        }
        case actionTypes.IsFilterByCampaignModalShown: {
            const { isFilterByCampaignModalShown } = action.payload;
            return {
                ...state,
                isFilterByCampaignModalShown,
            };
        }
        case actionTypes.CampaignDetailModalId: {
            const { campaignDetailModalId } = action.payload;
            return {
                ...state,
                campaignDetailModalId,
            };
        }
        case actionTypes.IsCampaignListDrawerShown: {
            const { isCampaignListDrawerShown } = action.payload;
            return {
                ...state,
                isCampaignListDrawerShown,
            };
        }
        case actionTypes.MailData: {
            const { mailData } = action.payload;
            return {
                ...state,
                mailData,
            };
        }
        case actionTypes.ReFetchMailData: {
            const { reFetchMailData } = action.payload;
            return {
                ...state,
                reFetchMailData,
            };
        }
        default:
            return state;
    }
};

export const actions = {
    setViewType: (viewType) => ({
        type: actionTypes.ViewType,
        payload: { viewType },
    }),
    setIsActivityDrawerOpen: (isActivityDrawerOpen) => ({
        type: actionTypes.IsActivityDrawerOpen,
        payload: { isActivityDrawerOpen },
    }),
    setSelectedCampaignFilter: (selectedCampaignFilter) => ({
        type: actionTypes.SelectedCampaignFilter,
        payload: { selectedCampaignFilter },
    }),
    setCampaignListForFilter: (campaignListForFilter) => ({
        type: actionTypes.CampaignListForFilter,
        payload: { campaignListForFilter },
    }),
    setSelectedCampaignListFromFilter: (selectedCampaignListFromFilter) => ({
        type: actionTypes.selectedCampaignListFromFilter,
        payload: { selectedCampaignListFromFilter },
    }),
    setActivitiesLoading: (isActivitiesLoading) => ({
        type: actionTypes.IsActivitiesLoading,
        payload: { isActivitiesLoading },
    }),
    setShowCampaignSidebar: (showCampaignSidebar) => ({
        type: actionTypes.ShowCampaignSidebar,
        payload: { showCampaignSidebar },
    }),
    setIsFilterByCampaignModalShown: (isFilterByCampaignModalShown) => ({
        type: actionTypes.IsFilterByCampaignModalShown,
        payload: { isFilterByCampaignModalShown },
    }),
    setCampaignDetailModalId: (campaignDetailModalId) => ({
        type: actionTypes.CampaignDetailModalId,
        payload: { campaignDetailModalId },
    }),
    setIsCampaignListDrawerShown: (isCampaignListDrawerShown) => ({
        type: actionTypes.IsCampaignListDrawerShown,
        payload: { isCampaignListDrawerShown },
    }),
    setMailData: (mailData) => ({
        type: actionTypes.MailData,
        payload: { mailData },
    }),
    setRefetchMailData: (reFetchMailData) => ({
        type: actionTypes.ReFetchMailData,
        payload: { reFetchMailData },
    }),
};
