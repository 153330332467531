import moment from "moment";

export const actionTypes = {
  DealsFilter: "[Action] Deals Filter",
  DealTableList: "[Action] Deals Table List",
  DealPartnerSelect: "[Action] Deals Partner Select",
  DealDateSelect: "[Action] Deals Date Select",
  DealNameSelect: "[Action] Deals Name Select",
  DealTypeSelect: "[Action] Deals Type Select",
  DealSelfInvestmentSelect: "[Action] Deals SelfInvesment Select",
  DealCampaignTypeSelect: "[Action] Deals Campaign Select",
  DealFullPrice: "[Action] Deals Full Price",
  DealsSelectedWonType: "[Action] Deals Selected Won Types",
  DealSelectedEmployees: "[Action] Deals Selected Employees",
  DealQueryString: "[Action] Deal Query String",
  DealExcelFile: "[Action] Deal Excel File",
  DealsSelectedUnits: "[Action] Deals Selected Units",
  DealsSelectedMarketingChannels: "[Action] Deals Selected Marketing Channels",
  DealsSelectedAccounts: "[Action] Deals Selected Accounts",
  DealsSelectedTitles: "[Action] Deals Selected Titles",
  DealsSelectedCampaignNames: "[Action] Deals Selected Campaign Names",
  DealsGetCampaignConstant: "[Action] Deals Get Campaign Types",
  DealsCourseNameSelect: "[Action] Deals CourseNameSelect",
  DealsSelectedDate: "[Action] Deals Selected Date",
  DealsSelectedStatus : "[Action] Deals Selected Status",
  // DealMarketingChnl: "[Action] Deals Marketing Channel Search",
  // DealAccountSearch: "[Action] Deals Account Search",
  // DealTitleSearch: "[Action] Deals Title Search",
  // DealCampaignNameSearch: "[Action] Deals Campaign Name Search",
  // DealCourseNameSearch: "[Action] Deals Course Name Search",
  // DealPartner: "[Action] Deals Partner Search",
  DealExcelExport:"[Action] Deals Excel Export",
  ShowDealsActions: "[Action] Show Deals Actions",
};

const initialReportsState = {
  dealsFilter: undefined,
  dealsTableList: [],
  dealNameSelect: [],
  dealDateSelect: null,
  dealsSelectedStatus:undefined,
  dealSelfInvestment: undefined,
  dealFullPrice: undefined,
  dealSelectedType: [],
  dealCampaignConstant: [],
  dealSelectedCampaignType: [],
  dealsSelectedWonType: [],
  dealSelectedEmployees: undefined,
  dealQueryString: "",
  dealExcelFile: null,
  dealsSelectedUnits: [],
  dealsSelectedMarketingChannels: [],
  dealsSelectedAccounts: [],
  dealsSelectedTitles: [],
  dealsSelectedCampaignNames: [],
  dealsCourseNameSelect: [],
  dealPartnerSelect: [],
  dealsSelectedDate: {
    startDate: moment().startOf('year').valueOf(),
    endDate: moment().endOf('year').valueOf()
  },
  dealExcelExport:false,
  // dealPartnerSearch: "",
  // dealMarketingChnl: "",
  // dealAccountSearch: "",
  // dealTitleSearch: "",
  // dealCampaignName: "",
  // dealCourseName: "",
  // dealPartner: "",
  showDealsActions: false,
};

export const reducer = (state = initialReportsState, action) => {
  switch (action.type) {

    case actionTypes.DealsFilter: {
      const { dealsFilter } = action.payload;
      return { ...state, dealsFilter }
    }
    case actionTypes.DealDateSelect: {
      const { dealDateSelect } = action.payload;
      return { ...state, dealDateSelect }
    }
    case actionTypes.DealPartnerSelect: {
      const { dealPartnerSelect } = action.payload;
      return { ...state, dealPartnerSelect }
    }

    case actionTypes.DealNameSelect: {
      const { dealNameSelect } = action.payload;
      return { ...state, dealNameSelect }
    }
    case actionTypes.DealSelfInvestmentSelect: {
      const { dealSelfInvestment } = action.payload;
      return { ...state, dealSelfInvestment }
    }
    case actionTypes.DealFullPrice: {
      const { dealFullPrice } = action.payload;
      return { ...state, dealFullPrice }
    }
    case actionTypes.DealsSelectedCampaignNames: {
      const { dealsSelectedCampaignNames } = action.payload;
      return { ...state, dealsSelectedCampaignNames }
    }
    case actionTypes.DealCampaignTypeSelect: {
      const { dealSelectedCampaignType } = action.payload;
      return { ...state, dealSelectedCampaignType }
    }
    case actionTypes.DealsCourseNameSelect: {
      const { dealsCourseNameSelect } = action.payload;
      return { ...state, dealsCourseNameSelect }
    }
    case actionTypes.DealTypeSelect: {
      const { dealSelectedType } = action.payload;
      return { ...state, dealSelectedType }
    }
    case actionTypes.DealsSelectedWonType: {
      const { dealsSelectedWonType } = action.payload;
      return { ...state, dealsSelectedWonType }
    }
    case actionTypes.DealSelectedEmployees: {
      const { dealSelectedEmployees } = action.payload;
      return { ...state, dealSelectedEmployees }
    }
    case actionTypes.DealQueryString: {
      const { dealQueryString } = action.payload
      return { ...state, dealQueryString }
    }
    case actionTypes.DealExcelFile: {
      const { dealExcelFile } = action.payload
      return { ...state, dealExcelFile }
    }
    case actionTypes.DealsSelectedUnits: {
      const { dealsSelectedUnits } = action.payload;
      return { ...state, dealsSelectedUnits }
    }
    case actionTypes.DealsSelectedMarketingChannels: {
      const { dealsSelectedMarketingChannels } = action.payload;
      return { ...state, dealsSelectedMarketingChannels }
    }
    case actionTypes.DealsSelectedAccounts: {
      const { dealsSelectedAccounts } = action.payload;
      return { ...state, dealsSelectedAccounts }
    }
    case actionTypes.DealsSelectedTitles: {
      const { dealsSelectedTitles } = action.payload;
      return { ...state, dealsSelectedTitles }
    }
    case actionTypes.DealsGetCampaignConstant: {
      const { dealCampaignConstant } = action.payload
      return { ...state, dealCampaignConstant }
    }
    case actionTypes.DealsSelectedDate: {
      const { dealsSelectedDate } = action.payload
      return { ...state, dealsSelectedDate }
    }
    case actionTypes.DealsSelectedStatus:{
      const {dealsSelectedStatus} = action.payload
      return {...state, dealsSelectedStatus}
    } 
    case actionTypes.DealExcelExport:{
      const {dealExcelExport} = action.payload
      return {...state, dealExcelExport}
    } 
    case actionTypes.ShowDealsActions: {
      const { showDealsActions } = action.payload;
      return { ...state, showDealsActions }
    }

    default:
      return state;
  }
}

export const actions = {

  setDealsFilter: (dealsFilter) => ({
    type: actionTypes.DealsFilter,
    payload: { dealsFilter }
  }),

  setDealsTableList: (dealsTableList) => ({
    type: actionTypes.DealTableList,
    payload: { dealsTableList }
  }),
  setDealsDateSelect: (dealDateSelect) => ({
    type: actionTypes.DealDateSelect,
    payload: { dealDateSelect }
  }),
  setDealsTableNameSelect: (dealNameSelect) => ({
    type: actionTypes.DealNameSelect,
    payload: { dealNameSelect }
  }),
  setDealsTypeNameSelect: (dealSelectedType) => ({
    type: actionTypes.DealTypeSelect,
    payload: { dealSelectedType }
  }),

  setDealsTablePartnerSelect: (dealPartnerSelect) => ({
    type: actionTypes.DealPartnerSelect,
    payload: { dealPartnerSelect }
  }),

  setDealSelfInvestmentSelect: (dealSelfInvestment) => ({
    type: actionTypes.DealSelfInvestmentSelect,
    payload: { dealSelfInvestment }
  }),
  setDealFullPrice: (dealFullPrice) => ({
    type: actionTypes.DealFullPrice,
    payload: { dealFullPrice }
  }),

  setDealsCampaignTypeSelect: (dealSelectedCampaignType) => ({
    type: actionTypes.DealCampaignTypeSelect,
    payload: { dealSelectedCampaignType }
  }),
  setDealsCourseNameSelect: (dealsCourseNameSelect) => ({
    type: actionTypes.DealsCourseNameSelect,
    payload: { dealsCourseNameSelect }
  }),

  setDealSelectedWonType: (dealsSelectedWonType) => ({
    type: actionTypes.DealsSelectedWonType,
    payload: { dealsSelectedWonType }
  }),
  setDealSelectedEmployees: (dealSelectedEmployees) => ({
    type: actionTypes.DealSelectedEmployees,
    payload: { dealSelectedEmployees }
  }),
  setDealQueryString: (dealQueryString) => ({
    type: actionTypes.DealQueryString,
    payload: { dealQueryString }
  }),
  setDealExcelFile: (dealExcelFile) => ({
    type: actionTypes.DealExcelFile,
    payload: { dealExcelFile }
  }),

  setDealsSelectedUnits: (dealsSelectedUnits) => ({
    type: actionTypes.DealsSelectedUnits,
    payload: { dealsSelectedUnits }
  }),
  setDealsSelectedMarketingChannels: (dealsSelectedMarketingChannels) => ({
    type: actionTypes.DealsSelectedMarketingChannels,
    payload: { dealsSelectedMarketingChannels }
  }),
  setDealsSelectedAccounts: (dealsSelectedAccounts) => ({
    type: actionTypes.DealsSelectedAccounts,
    payload: { dealsSelectedAccounts }
  }),
  setDealsSelectedTitles: (dealsSelectedTitles) => ({
    type: actionTypes.DealsSelectedTitles,
    payload: { dealsSelectedTitles }
  }),
  setDealsCampaignNameSelect: (dealsSelectedCampaignNames) => ({
    type: actionTypes.DealsSelectedCampaignNames,
    payload: { dealsSelectedCampaignNames }
  }),
  setDealCampaignConstant: (dealCampaignConstant) => ({
    type: actionTypes.DealsGetCampaignConstant,
    payload: { dealCampaignConstant }
  }),
  setSelectedDate: (dealsSelectedDate) => ({
    type: actionTypes.DealsSelectedDate,
    payload: { dealsSelectedDate }
  }),
  setDealsSelectedStatus: (dealsSelectedStatus) => ({
    type: actionTypes.DealsSelectedStatus,
    payload : {dealsSelectedStatus}
  }),
  setDealExcelExport: (dealExcelExport) => ({
    type: actionTypes.DealExcelExport,
    payload : {dealExcelExport}
  }),
  setShowDealsActions: (showDealsActions) => ({
    type: actionTypes.ShowDealsActions,
    payload: { showDealsActions }
  }),
};