import React from 'react';
import SearchInput from './../../../../app/partials/SearchInput';

export default class NumberInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            inputValue: this.props.object.value ? this.props.object.value : '',
            inputValueFrom: '',
            inputValueTo: '',
            action: ''
        }
    }

    componentDidMount() {
        if (this.props.object.actions) {
            this.setState({ action: this.props.object.actions[0] });
            this.props.onActionChange(this.props.object.actions[0])
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.object.value !== this.props.object.value){
            this.setState({inputValue:this.props.object.value})
        }
    }

    render() {
        let actionBetween = false;
        if (this.state.action === "[]")
            actionBetween = true;
        return <>
            <div className='row'>

                {this.props.object.actions &&
                    <div className='col-sm-2'>
                        <select className='actionSelect' onChange={(action) => {
                            this.setState({ action: action.target.value, inputValue: '', inputValueFrom: '', inputValueTo: '' });
                            this.props.onActionChange(action.target.value)
                        }}>
                            {
                                this.props.object.actions.map((object) => <option>{object}</option>)
                            }
                        </select>
                    </div>
                }

                <div className={`${this.props.object.actions ? actionBetween ? "col-sm-5" : "col-sm-10" : 'col-sm-12'}`}>
                    <SearchInput
                        type='number'
                        min={this.props.min}
                        max={this.props.max}
                        minLength={1}
                        debounceTimeout={300}
                        onChange={(e) => {
                            if (actionBetween)
                                this.setState({ inputValueFrom: e.target.value });
                            else
                                this.setState({ inputValue: e.target.value });
                            this.props.onInputChange(e.target.value)
                        }}
                        className="form-control numberInput"
                        placeholder={actionBetween ? this.props.object.name + " From" : this.props.object.name}
                        id="kt_datatable_search_query"
                        value={actionBetween ? this.state.inputValueFrom : this.state.inputValue}
                    />
                </div>

                {actionBetween &&
                    <div className='col-sm-5'>
                        <SearchInput
                            type='number'
                            min={this.props.min}
                            max={this.props.max}
                            minLength={1}
                            debounceTimeout={300}
                            onChange={(e) => {
                                if (actionBetween)
                                    this.setState({ inputValueTo: e.target.value });
                                else
                                    this.setState({ inputValue: e.target.value });
                                this.props.onInputChange(e.target.value, 2)
                            }}
                            className="form-control"
                            placeholder={actionBetween ? this.props.object.name + " To" : this.props.object.name}
                            id="kt_datatable_search_query"
                            value={actionBetween ? this.state.inputValueTo : this.state.inputValue}
                        />
                    </div>
                }
            </div>
        </>
    }
}
