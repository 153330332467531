import React, { useState, useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
// import * as app from "../../../app/redux/appRedux";
import * as reportsRedux from "../../reports/redux/reportsRedux";
import { post } from "../../../networking/RequestService";
import SelectInput from "../components/inputs/selectInput";

function TrainerFilter(props) {
    const [options, setOptions] = useState([]);

    const onChange = (selectedOption) => {
        props.selectTrainer(selectedOption);
    };

    const onInputChange = (str, { action }) => {
        if (action === "set-value") return false;
    };

    const saveUsersToState = (users) => {
        const options = [];
        if (users) {
            for (let i = 0; i < users.length; i++) {
                const obj = { value: users[i].id, label: users[i].name };
                options.push(obj);
            }
        }
        setOptions(options);
    };

    const getUsers = () => {
        const companyUsers = {
            fn: "getTrainerList",
            rowsPerPage: "1000",
            start: 0,
        };

        post(companyUsers).then(({ data: { trainerList } }) => {
            saveUsersToState(trainerList);
        });
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <>
            {props.isMultiSelect ? (
                <SelectInput
                    object={{
                        name: "Trainer",
                        data: {
                            options: options,
                        },
                        multi: true,
                        allSelectable: true,
                    }}
                    onChange={onChange}
                    onInputChange={onInputChange}
                    infinite={false}
                />
            ) : (
                <Select
                    className="selectInputNewFilter_single"
                    classNamePrefix="selectInputNewFilter"
                    options={options}
                    defaultValue={props.trainer}
                    value={props.trainer}
                    onChange={onChange}
                    closeMenuOnSelect={true}
                    isMulti={props.isMultiSelect}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        trainer: state.reports.selectedTrainer,
    };
};

const mapDispatchToProps = {
    ...reportsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainerFilter);
