import React from "react";
import { FormattedNumber } from "react-intl";
import commonStyles from "../../final-report-common.module.scss";

const DealDataItem = ({ children, title, value, color, className }) => {
    return (
        <div className={commonStyles[className]}>
            <div className={commonStyles.dealsecondaryTitleContainer}>
                <h3 className={commonStyles.dealsecondaryTitle}>{title}</h3>
            </div>
            <h2 className={`${commonStyles.dealSecondaryValue} ${commonStyles[color + "Text"]}`}>
                {title === "AVERAGE PER SESSION" && <span>{"$ "}</span>}
                <FormattedNumber value={value} />
            </h2>
            <div className={`${commonStyles.horizontalLine} ${commonStyles[color + "Bg"]}`} />
            {children}
        </div>
    );
};

export default DealDataItem;
