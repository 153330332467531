/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";

//PopLeads Change
import Filter from "../../../../app/popleads/modules/filters/Filter";

import Pdf from "../../../../app/popleads/app/partials/Pdf";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import SurveyBar from "./components/SurveyBar";
import PdfFinalReport from "../../../../app/popleads/app/partials/PdfFinalReport";
import CourseListActions from "../../../../app/popleads/subheader/actionButtons/CourseListActions";
import { connect, useSelector } from "react-redux";
import * as campaignsRedux from "../../../../app/popleads/modules/campaigns/redux/campaignsRedux";
import * as deals from "../../../../app/popleads/modules/deals/redux/dealsRedux";
import * as app from "app/popleads/app/redux/appRedux";
import clsx from "clsx";
import ChatGptDrawer from "app/popleads/components/Chat/ChatGptDrawer";
import AccountListActions from "../../../../app/popleads/subheader/actionButtons/AccountListActions";
import AccountDetailActions from "app/popleads/subheader/actionButtons/AccountDetailActions";
import OfficialAccountsActions from "app/popleads/subheader/actionButtons/OfficialAccountsActions";
import useIsLessThan from "../../../../app/popleads/hooks/useIsLessThan";
import DealsFilters from "app/popleads/modules/filters/DealsFilters";
import PrecampaignsActions from "app/popleads/subheader/actionButtons/PrecampaignsActions";
import CourseDetailActions from "app/popleads/subheader/actionButtons/CourseDetailActions";
import ActivitiesActions from "app/popleads/subheader/actionButtons/ActivitiesActions";
import SalesFilters from "app/popleads/modules/filters/SalesFilters";
import SurveyCampaignListActions from "app/popleads/subheader/actionButtons/SurveyCampaignListActions";
import AttendanceActions from "app/popleads/subheader/actionButtons/AttendanceActions";
import PartnerAccountsActions from "app/popleads/subheader/actionButtons/PartnerAccountActions";

function SubHeader({
    showCoursesActions,
    showAccountListActions,
    showAccountDetailActions,
    showOfficialAccountsActions,
    showDealsActions,
    showPrecampaignsActions,
    showCourseDetailActions,
    showActivitiesActions,
    salesFilterActions,
    showSurveyCampaignList,
    showPartnerAccountsActions,
    ...props
}) {
    const isLessThanBsLg = useIsLessThan("bootstrap-lg");
    const uiService = useHtmlClassService();
    const location = useLocation();
    const subheader = useSubheader();

    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            subheaderMobileToggle: objectPath.get(uiService.config, "subheader.mobile-toggle"),
            subheaderCssClasses: uiService.getClasses("subheader", true),
            subheaderContainerCssClasses: uiService.getClasses("subheader_container", true),
        };
    }, [uiService]);

    useLayoutEffect(() => {
        const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
        const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
        const breadcrumbs = aside && aside.breadcrumbs.length > 0 ? aside.breadcrumbs : header.breadcrumbs;
        subheader.setBreadcrumbs(breadcrumbs);
        subheader.setTitle(aside && aside.title && aside.title.length > 0 ? aside.title : header.title);
        // eslint-disable-next-line
    }, [location.pathname]);

    // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
    useEffect(() => {}, [subheader]);
    const { isFromMobileApp } = useSelector((state) => state.app);
    return (
        <>
            <div
                id="kt_subheader"
                className={clsx(
                    "subheader py-4 pl- py-lg-4",
                    layoutProps.subheaderCssClasses,
                    props.isFromMobileApp && "d-none"
                )}
            >
                <div className={`${layoutProps.subheaderContainerCssClasses}`}>
                    {/* Info */}
                    <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            {
                                //PopLeads Change
                            }

                            <div className="subHeaderPL">
                                <div className="d-flex align-items-center mr-5">
                                    <h5 className="text-dark my-0 mr-0 mr-sm-5 card-heading color-secondary line-clamp-1">
                                        <OverlayTrigger
                                            key="top"
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip-top`}>
                                                    <strong>{subheader.title}</strong>
                                                </Tooltip>
                                            }
                                        >
                                            <span>{subheader.title}</span>
                                        </OverlayTrigger>

                                        {/*<small></small>*/}
                                    </h5>
                                </div>

                                <BreadCrumbs items={subheader.breadcrumbs} />
                            </div>
                        </div>
                        {/* DESKTOP SUBHEADER ITEMS */}
                        {!isLessThanBsLg && (
                            <div className="d-flex align-items-center justify-between">
                                {showAccountListActions && <AccountListActions />}
                                {showAccountDetailActions && <AccountDetailActions />}
                                {showOfficialAccountsActions && <OfficialAccountsActions />}
                                {showPartnerAccountsActions && <PartnerAccountsActions />}
                                {showPrecampaignsActions && <PrecampaignsActions />}
                                {showCoursesActions && <CourseListActions />}
                                {showCourseDetailActions && <CourseDetailActions />}
                                {showActivitiesActions && <ActivitiesActions />}
                                {showDealsActions && <DealsFilters />}
                                {showSurveyCampaignList && <SurveyCampaignListActions />}
                                <Filter /> {/* TODO: Remove after all modules finished */}
                                {salesFilterActions && <SalesFilters />}
                                <Pdf></Pdf>
                                <PdfFinalReport />
                                {/* PopLeads Change pdf icon*/ subheader.pdfCallback && (
                                    <div className="d-flex align-items-center">
                                        <h5 className="font-weight-bold mr-5">
                                            <>
                                                <button onClick={subheader.pdfCallback}>PDF</button>
                                            </>
                                            {/* PopLeads Change <small></small>*/}
                                        </h5>
                                    </div>
                                )}
                                <SurveyBar />
                                {location?.pathname.includes("/attendance") &&
                                    props.selectedAttendanceListFromFilter?.length > 0 && <AttendanceActions />}
                                {!isFromMobileApp && <ChatGptDrawer />}
                            </div>
                        )}
                        {/* END OF DESKTOP SUBHEADER ITEMS */}
                    </div>
                </div>
            </div>

            {/* MOBILE SUBHEADER ITEMS */}
            {isLessThanBsLg && (
                <div className={`my-6 ${layoutProps.subheaderContainerCssClasses}`}>
                    <div className="d-flex align-items-center justify-content-end py-0">
                        {showAccountListActions && <AccountListActions />}
                        {showAccountDetailActions && <AccountDetailActions />}
                        {showOfficialAccountsActions && <OfficialAccountsActions />}
                        {showPartnerAccountsActions && <PartnerAccountsActions />}
                        {showPrecampaignsActions && <PrecampaignsActions />}
                        {showCoursesActions && <CourseListActions />}
                        {showCourseDetailActions && <CourseDetailActions />}
                        {showActivitiesActions && <ActivitiesActions />}
                        {showDealsActions && <DealsFilters />}
                        {salesFilterActions && <SalesFilters />}
                        {showSurveyCampaignList && <SurveyCampaignListActions />}
                        <Filter /> {/* TODO: Remove after all modules finished */}
                        <Pdf></Pdf>
                        <PdfFinalReport />
                        {/* PopLeads Change pdf icon*/ subheader.pdfCallback && (
                            <div className="d-flex align-items-center">
                                <h5 className="font-weight-bold mr-5">
                                    <>
                                        <button onClick={subheader.pdfCallback}>PDF</button>
                                    </>
                                    {/* PopLeads Change <small></small>*/}
                                </h5>
                            </div>
                        )}
                        <SurveyBar />
                        {location?.pathname.includes("/attendance") &&
                            props?.selectedAttendanceListFromFilter?.length > 0 && <AttendanceActions />}
                        {!isFromMobileApp && !location?.pathname.includes("/activities") && <ChatGptDrawer />}
                    </div>
                </div>
            )}
            {/* END OF MOBILE SUBHEADER ITEMS */}

            {/* Toolbar */}
            {/* <div className="d-flex align-items-center">
            <a href="#" className="btn btn-light btn-sm font-weight-bold" id="kt_dashboard_daterangepicker"
               data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
              <span className="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today</span>
              <span className="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">Aug 16</span>
            </a>
            <QuickActions/>
          </div> */}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        showCoursesActions: state.campaigns.isCoursesActionButtons,
        isFromMobileApp: state.app.isFromMobileApp,
        showAccountListActions: state.accounts.showAccountListActions,
        showAccountDetailActions: state.accounts.showAccountDetailActions,
        showOfficialAccountsActions: state.accounts.showOfficialAccountsActions,
        showDealsActions: state.deals.showDealsActions,
        showPrecampaignsActions: state.campaigns.showPrecampaignsActions,
        showCourseDetailActions: state.campaigns.showCourseDetailActions,
        showActivitiesActions: state.activities.showActionButtons,
        salesFilterActions: state.reports.salesFilterActions,
        showSurveyCampaignList: state.survey.showSurveyCampaignList,
        selectedAttendanceListFromFilter: state.campaigns.selectedAttendanceListFromFilter,
        showPartnerAccountsActions: state.accounts.showPartnerAccountsActions,
    };
};

const mapDispatchToProps = {
    ...app.actions,
    ...campaignsRedux.actions,
    ...deals.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);
