import React from "react";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { TooltipOverlay } from "app/popleads/components/TooltipOverlay";
import CustomDrawer, { DrawerBody, DrawerContent, DrawerHeader } from "app/popleads/components/drawer/CustomDrawer";
import useDisclosure from "app/popleads/hooks/useDisclosure";
import { Button } from "react-bootstrap";
import InlineSVG from "react-inlinesvg";
import NewFilter from "../../modules/filters/NewFilter";

const PrecampaignsFilterDrawer = () => {
    const { isOpen, onClose, onToggle } = useDisclosure();

    return (
        <>
            <TooltipOverlay placement="bottom" tooltip="Filters">
                <Button variant="secondary" className="ml-1 ml-sm-4 customBootstrapBtn" onClick={onToggle}>
                    <InlineSVG src={toAbsoluteUrl("/media/svg/icons/Text/Filters2.svg")} />
                </Button>
            </TooltipOverlay>
            <CustomDrawer position="right" size="md" open={isOpen} onClose={onClose}>
                <DrawerContent>
                    <DrawerHeader onClose={onClose}>Filters</DrawerHeader>
                    <DrawerBody>
                        <NewFilter />
                    </DrawerBody>
                </DrawerContent>
            </CustomDrawer>
        </>
    );
};

export default PrecampaignsFilterDrawer;
