/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";

export function BreadCrumbs({ items }) {
    if (!items || !items.length) {
        return "";
    }

    return (
        <ul className="breadcrumb breadcrumb-transparent breadcrumb-dot p-0 my-0">
            <li className="breadcrumb-item">
                <Link to="/">
                    <SVG
                        src={toAbsoluteUrl(
                            "/media/svg/icons/General/feather-home.svg"
                        )}
                    />
                </Link>
            </li>
            {items.map((item, index) => (
                <li key={`bc${index}`} className="breadcrumb-item ">
                    <Link
                        // className="text-muted"
                        to={{ pathname: item.pathname }}>
                        {item.title}
                    </Link>
                </li>
            ))}
        </ul>
    );
}
