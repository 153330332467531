import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { customDateRanges, getDateObject } from "app/popleads/app/functions/util";
import { TooltipOverlay } from "app/popleads/components/TooltipOverlay";
import CustomDrawer, { DrawerBody, DrawerContent, DrawerHeader } from "app/popleads/components/drawer/CustomDrawer";
import useDisclosure from "app/popleads/hooks/useDisclosure";
import { Button } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import InlineSVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../popleads/assets/css/design_3.0/CustomDatePicker.module.scss";
import { actions } from "app/popleads/modules/reports/redux/reportsRedux";
import TrainerFilter from "app/popleads/modules/filters/components/TrainerFilter";
import CampaignTypeFilter from "app/popleads/modules/filters/components/CampaignTypeFilter";
import EmployeeFilter from "app/popleads/modules/filters/components/EmployeeFilter";
import moment from "moment";

const SurveyFilterDrawer = () => {
    const { isOpen, onClose, onToggle } = useDisclosure();
    const dispatch = useDispatch();
    const { selectedDateSurvey } = useSelector((state) => state.reports);
    const [dateSurvey, setDateSurvey] = useState([
        selectedDateSurvey.startDate
            ? {
                  startDate: selectedDateSurvey.startDate,
                  endDate: selectedDateSurvey.endDate,
                  predefinedTag: selectedDateSurvey.predefinedTag,
                  dateString: selectedDateSurvey.dateString,
                  key: "selection",
              }
            : {
                  startDate: moment(0)
                      .startOf("year")
                      .toDate(),
                  endDate: moment()
                      .endOf("year")
                      .toDate(),
                  predefinedTag: "allTime",
                  dateString: "All Time",
                  key: "selection",
              },
    ]);

    useEffect(() => {
        if (selectedDateSurvey.startDate) {
            setDateSurvey([selectedDateSurvey]);
        }
    }, [selectedDateSurvey]);

    return (
        <>
            <TooltipOverlay placement="bottom" tooltip="Filters">
                <Button variant="secondary" className="ml-1 ml-sm-4 customBootstrapBtn" onClick={onToggle}>
                    <InlineSVG src={toAbsoluteUrl("/media/svg/icons/Text/Filters2.svg")} />
                </Button>
            </TooltipOverlay>
            <CustomDrawer position="right" size="md" open={isOpen} onClose={onClose}>
                <DrawerContent>
                    <DrawerHeader onClose={onClose} sx={{ border: "none" }}>
                        Filters
                    </DrawerHeader>
                    <DrawerBody>
                        <div className="secondary-color font-weight-bold mt-4 mb-2">Filter by Trainer</div>
                        <TrainerFilter isMultiSelect={true} />
                        <div className="secondary-color font-weight-bold mt-4 mb-2">Filter by Campaign Type</div>
                        <CampaignTypeFilter isMultiSelect={true} />
                        <div className="secondary-color font-weight-bold mt-4 mb-2">Filter by Employee</div>
                        <EmployeeFilter isMultiSelect={true} />
                        <div className="secondary-color font-weight-bold mt-4 mb-2">Filter by Date</div>
                        <DateRangePicker
                            ranges={dateSurvey}
                            className={styles.rdrDateRangePickerWrapper}
                            staticRanges={customDateRanges(new Date(null), new Date())}
                            onChange={(item) => {
                                const date = getDateObject(item.selection);
                                if (!date.startDate && !date.endDate) {
                                    date.startDate = moment(0)
                                        .startOf("year")
                                        .toDate();
                                    date.endDate = moment()
                                        .endOf("year")
                                        .toDate();
                                    date.predefinedTag = "allTime";
                                    date.dateString = "All Time";
                                    date.key = "selection";
                                }
                                setDateSurvey([date]);
                                dispatch(actions.selectDateSurvey(date));
                                // DOM manipulation to highlight "All Time"
                                const range = document.querySelector(".rdrStaticRanges")?.firstElementChild;
                                if (range && date.predefinedTag === "allTime") {
                                    range.classList.add("rdrStaticRangeSelected");
                                } else {
                                    range.classList.remove("rdrStaticRangeSelected");
                                }
                            }}
                            inputRanges={[]}
                            direction="vertical"
                        />
                    </DrawerBody>
                </DrawerContent>
            </CustomDrawer>
        </>
    );
};

export default SurveyFilterDrawer;
