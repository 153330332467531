import React from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import * as reportsRedux from "../reports/redux/reportsRedux";
import * as appRedux from "../../app/redux/appRedux";
import * as accountsRedux from "../accounts/redux/accountsRedux";
import * as trainingRedux from "../training/redux/trainingRedux";
import * as campaignsRedux from "../campaigns/redux/campaignsRedux";
import * as surveyRedux from "../survey/redux/surveyRedux";
import DateFilter from "../filters/components/DateFilter";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import Button from "../../components/Button";
import { MOBILE_BREAKPOINT } from "../../app/contants";
import LoadingSpin from "../../app/partials/LoadingSpin";
import { isSalesFullPageLoading } from "app/popleads/app/functions/util";

class FilterDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggle: false,
            isVisible: false,
            displayDesign: null,
            windowSize: null,
        };
    }

    onToggle = () => {
        if (!this.state.isToggle) {
            document.addEventListener("click", this.handleOutsideClick, true);
        } else {
            document.removeEventListener("click", this.handleOutsideClick, false);
        }
        this.setState({ isToggle: !this.state.isToggle, isVisible: !this.state.isVisible });
    };
    handleOutsideClick = (e) => {
        if (!this.node?.contains(e.target) && !this.props.keepDrawerOpen) this.setState({ isToggle: false });
    };
    componentDidMount() {
        this.setState({
            isToggle: this.props.showAddActivityModal,
            isVisible: this.props.showAddActivityModal,
        });
    }

    render() {
        const { innerWidth } = window;

        return (
            <>
                {this.props.showBackdrop && (
                    <div className={`fullsize-backdrop ${this.state.isToggle ? "enter-right" : "exit-right"}`} />
                )}
                <div
                    ref={(node) => {
                        this.node = node;
                    }}
                >
                    {!this.props.activityDrawerMobile && (
                        <Dropdown align="end" onToggle={this.onToggle} show={this.state.isToggle}>
                            <Dropdown.Toggle
                                id="kt_filter_toggle"
                                variant="secondary"
                                className="ml-1 ml-sm-4 hide-chevron font-weight-normal p-0"
                            >
                                <span className="svg-icon2">
                                    <SVG title="Filters" src={toAbsoluteUrl("/media/svg/icons/Text/Filters2.svg")} />
                                </span>
                            </Dropdown.Toggle>
                        </Dropdown>
                    )}

                    <div
                        className={`mobile-drawer-wrapper `}
                        style={{ display: this.state.isVisible ? "flex" : "none" }}
                        onAnimationEnd={() => {
                            if (this.state.isToggle === false) this.setState({ isVisible: false });
                        }}
                    >
                        <div className={`wrapper-card py-3 ${this.state.isToggle ? "visible" : "hidden"}`}>
                            {this.state.isVisible && (
                                <>
                                    {(this.props.isActivitiesLoading ||
                                        isSalesFullPageLoading(this.props.salesPageLoading)) && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                zIndex: 2,
                                                width: "100%",
                                                height: "100%",
                                                background: "rgba(255,255,255,0.5)",
                                            }}
                                        >
                                            <LoadingSpin />
                                        </div>
                                    )}
                                    <form>
                                        {innerWidth < MOBILE_BREAKPOINT ? (
                                            <div className="d-flex align-items-center justify-content-between px-8 filter-header">
                                                {/* <h4 className="drawer-header m-0">Filters</h4> */}
                                                <span></span>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    onClick={() => {
                                                        this.setState({ isToggle: false });
                                                        {
                                                            this.props.activityDrawerMobile &&
                                                                setTimeout(() => {
                                                                    this.props.setShowAddActivityModal(false);
                                                                }, 500);
                                                        }
                                                    }}
                                                >
                                                    <SVG src={toAbsoluteUrl("/media/svg/popleads/plus.svg")} />
                                                </button>
                                            </div>
                                        ) : null}
                                        <div className="scroll-container">
                                            <div className="navi navi-hover  mx-xs-5">
                                                <div className="dropdowns px-8">
                                                    <div className="row mt-0 mt-md-15 mt-xxl-0">
                                                        {this.props.children
                                                            .filter((child) => child !== false)
                                                            .map((child, index) => (
                                                                <div key={index} className="col-12">
                                                                    <span className="navi-item">
                                                                        <div className="navi-link">
                                                                            <div className="navi-text">
                                                                                <div className="font-weight-bold">
                                                                                    {child}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        {(this.props.dateFilter || this.props.showDateFilter) && (
                                                            <div className="col-12">
                                                                <span className="navi-item mt-6">
                                                                    <div className="navi-link">
                                                                        <div className="navi-text">
                                                                            <div className="font-weight-bold account-date-customize">
                                                                                <DateFilter />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {!this.props.addActivityDrawer && (
                                                <div className="d-flex mt-15 align-items-center justify-content-center close">
                                                    <Button
                                                        title={"Close"}
                                                        variant="disabledbtn"
                                                        outline={false}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.setState({ isToggle: false });
                                                        }}
                                                        className="mr-2"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {this.props.button1title && (
                                            <div className="filterdrawer-footer-buttons d-flex justify-content-center mt-5">
                                                <Button
                                                    className="mr-6"
                                                    title={this.props.button1title}
                                                    variant="disabledbtn"
                                                    outline={false}
                                                    onClick={this.props.button1onclick}
                                                />
                                                <Button
                                                    title={this.props.button2title}
                                                    variant="success"
                                                    outline
                                                    onClick={this.props.button2onclick}
                                                />
                                            </div>
                                        )}
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        selectedEmployee: state.reports.selectedEmployee,
        selectedDepartment: state.reports.selectedDepartment,
        selectedDate: state.reports.selectedDate,
        employeeFilter: state.app.employeeFilter,
        departmentFilter: state.app.departmentFilter,
        dateFilter: state.app.dateFilter,
        gridOrTable: state.accounts.gridOrTable,
        gridOrTableFilter: state.accounts.gridOrTableFilter,
        selectedType: state.accounts.selectedType,
        selectedIndustry: state.accounts.selectedIndustry,
        selectedInquiryType: state.reports.selectedInquiryType,
        selectedFranchise: state.reports.selectedFranchise,
        typeFilter: state.app.typeFilter,
        industryFilter: state.app.industryFilter,
        inquiryTypeFilter: state.app.inquiryTypeFilter,
        franchiseFilter: state.app.franchiseFilter,
        searchInput: state.app.searchInput,
        isOpenAddTrainerButton: state.training.isOpenAddTrainerButton,
        isOpenCalendarButton: state.training.isOpenCalendarButton,
        isEditButton: state.training.isEditButton,
        isOpenEditButton: state.training.isOpenEditButton,
        user: state.auth.user,
        isAccountEditButton: state.accounts.isAccountEditButton,
        isAccountMergeButton: state.accounts.isAccountMergeButton,
        isOpenAccountEditButton: state.accounts.isOpenAccountEditButton,
        gridOrTable_precampaign: state.campaigns.gridOrTable,
        gridOrTableFilter_precampaign: state.campaigns.gridOrTableFilter,
        searchInput_precampaign: state.campaigns.searchInput_precampaign,
        isPreCampaignButton: state.campaigns.isPreCampaignButton,
        editMoreInfoButton: state.campaigns.editMoreInfoButton,
        isOpenEditMoreInfoButton: state.campaigns.isOpenEditMoreInfoButton,
        duplicateButton: state.campaigns.duplicateButton,
        selectedLength: state.campaigns.selectedLength,
        showAddSurveyButton: state.survey.showAddSurveyButton,
        showEditSurveyButton: state.survey.showEditSurveyButton,
        statusEditSurveyButton: state.survey.statusEditSurveyButton,
        surveyId: state.survey.surveyId,
        isActivitiesLoading: state.activities.isActivitiesLoading,
        isCoursesActionButtons: state.campaigns.isCoursesActionButtons,
        salesPageLoading: state.reports.salesPageLoading,
    };
};

const mapDispatchToProps = {
    ...reportsRedux.actions,
    ...appRedux.actions,
    ...accountsRedux.actions,
    ...trainingRedux.actions,
    ...campaignsRedux.actions,
    ...surveyRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterDrawer);
