import React from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { CustomToggleIcon } from "../utils";
import { TooltipOverlay } from "../../../components/TooltipOverlay";
import { Button } from "react-bootstrap";
import * as activitiesRedux from "../redux/activitiesRedux";
import { connect } from "react-redux";
import MultiSelectRadios from "app/popleads/components/MultiSelectRadios";
import { FILTERBY_OPTIONS } from "../constants";
import useIsLessThan from "../../../hooks/useIsLessThan";

const FilterByDropdown = ({ selectedFilterByOptions, setSelectedFilterByOptions }) => {
    const isLessThanBsXl = useIsLessThan("bootstrap-md");

    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggleIcon} id="dropdown-custom-components">
                <TooltipOverlay placement="bottom" tooltip={"Filter By"}>
                    <Button
                        variant={selectedFilterByOptions?.length > 0 ? "secondary" : "outline-secondary"}
                        className="ml-1 ml-sm-2 mr-sm-n2 customBootstrapBtn"
                    >
                        <SVG src={toAbsoluteUrl("/media/svg/popleads/check-circle.svg")} />
                    </Button>
                </TooltipOverlay>
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="my-1 px-3 py-2 dropdown-menu"
                style={isLessThanBsXl ? { width: "200px" } : { minWidth: "250px" }}
            >
                <MultiSelectRadios
                    options={FILTERBY_OPTIONS.filter((option) => {
                        if (
                            selectedFilterByOptions.find((option) => option.value === "assignedToMe") &&
                            option.value !== "assignedToMe"
                        )
                            return false;
                        else if (
                            selectedFilterByOptions.find((option) => option.value === "createdByMe") &&
                            (option.value === "followedByMe" || option.value === "assignedToMe")
                        )
                            return false;
                        else if (
                            selectedFilterByOptions.find((option) => option.value === "followedByMe") &&
                            (option.value === "assignedToMe" || option.value === "createdByMe")
                        )
                            return false;
                        else if (
                            selectedFilterByOptions.find((option) => option.value === "approvedByMe") &&
                            option.value === "assignedToMe"
                        )
                            return false;

                        return true;
                    })}
                    selectedOptions={selectedFilterByOptions}
                    setSelectedOptions={setSelectedFilterByOptions}
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedFilterByOptions: state.activities.selectedFilterByOptions,
    };
};

const mapDispatchToProps = {
    ...activitiesRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterByDropdown);
