import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import commonStyles from "../../final-report-common.module.scss"

const CompWithPrevious = ({ comparison, style, className }) => {
    return (
        <div className={className} style={style}>
            {comparison.isAbove ? (
                <span className={`svg-icon svg-icon-2x my-2 ${commonStyles.primaryGreenText}`}>
                    <SVG src={toAbsoluteUrl("/media/svg/popleads/trending-up.svg")}></SVG>
                </span>
            ) : (
                <span className={`svg-icon svg-icon-2x my-2 ${commonStyles.primaryPinkText}`}>
                    <SVG src={toAbsoluteUrl("/media/svg/popleads/trending-down.svg")}></SVG>
                </span>
            )}
            <span className={commonStyles.comparisonText}>{`${comparison.formattedAmount} ${comparison.text}`}</span>
        </div>
    );
};

export default CompWithPrevious;
