import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import styles from "./DateInput.module.scss";
import { eachDayOfInterval, endOfMonth, startOfMonth } from "date-fns";

/**
 * @type {React.FC<import('react-datepicker').ReactDatePickerCustomHeaderProps & {minDate: ?number, maxDate: ?number}>}
 */
const CustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    // prevYearButtonDisabled,
    // nextYearButtonDisabled,
    minDate = null,
    maxDate = null,
}) => {
    /**
     * @type {Function}
     * @param {number} minDate
     * @param {number} maxDate
     */
    const initYears = (minDate, maxDate) => {
        // generate years array from minDate to maxDate (inclusive)
        return Array((maxDate || new Date().getFullYear() + 10) - (minDate || 1990) + 1)
            .fill(minDate || 1990)
            .map((v, i) => v + i);
    };

    /**
     * @type {[number, React.Dispatch<React.SetStateAction<number>>]}
     */
    const [years, setYears] = useState(initYears(minDate, maxDate));

    useEffect(() => {
        if (date?.getFullYear) {
            // clamp the year to the min/max dates
            // if the maxDate is not set and the selected year is equal to the last year of the years array
            // increase the last year by 1 and push it to the array
            setYears((prev) => {
                if (date.getFullYear() === prev[prev.length - 1] && maxDate === null) {
                    return [...prev, prev[prev.length - 1] + 1];
                }
                return prev;
            });
            // same as above but for the minDate
            setYears((prev) => {
                if (date.getFullYear() === prev[0] && minDate === null) {
                    return [prev[0] - 1, ...prev];
                }
                return prev;
            });
        }
    }, [date, maxDate, minDate]);

    useEffect(() => {
        // reinit the years array when the min/max dates change
        setYears(initYears(minDate, maxDate));
    }, [minDate, maxDate]);

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    const formattedDate = date instanceof Date ? date : new Date(date);

    return (
        <div className={styles.header}>
            <div>
                <select
                    className={`${styles.button} ${styles.monthSelect}`}
                    value={formattedDate?.getMonth ? months[formattedDate.getMonth()] : months[0]}
                    onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                >
                    {months.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <select
                    className={styles.button}
                    value={formattedDate?.getFullYear ? formattedDate.getFullYear() : years[years.length - 1]}
                    onChange={({ target: { value } }) => changeYear(value)}
                >
                    {years.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <button
                    className={`${styles.button} ${styles.prevButton}`}
                    onClick={decreaseMonth}
                    type="button"
                    disabled={prevMonthButtonDisabled}
                >
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/chevron-down.svg")} />
                </button>

                <button
                    className={`${styles.button} ${styles.nextButton}`}
                    onClick={increaseMonth}
                    type="button"
                    disabled={nextMonthButtonDisabled}
                >
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/chevron-down.svg")} />
                </button>
            </div>
        </div>
    );
};

/**
 * @type {React.FC<import('react-datepicker').ReactDatePickerProps>}
 * A wrapper for the react-datepicker component that adds custom styling and header without changing behavior.
 */
const DateInput = forwardRef(
    (
        {
            weekendIndication,
            variant,
            formattedValue,
            maxDate,
            minDate,
            placeholderText = "Pick a date",
            className = "",
            selected,
            ...props
        },
        ref
    ) => {
        function isFridayOrSaturday(date) {
            const day = date.getDay();
            return day === 5 || day === 6;
        }

        const today = new Date();
        const currentYear = today.getFullYear();

        const allMonths = [];
        for (let year = currentYear; year >= currentYear - 5; year--) {
            for (let month = 0; month < 135; month++) {
                allMonths.push(new Date(year, month, 1));
            }
        }
        const highlightedDates = allMonths.flatMap((month) => {
            const startDate = startOfMonth(month);
            const endDate = endOfMonth(month);
            const daysInMonth = eachDayOfInterval({ start: startDate, end: endDate });

            return daysInMonth.filter(isFridayOrSaturday);
        });
        return (
            <div className={`${styles.wrapper} ${props.inline ? styles.inline : ""}`}>
                <DatePicker
                    selected={selected}
                    renderCustomHeader={(props) => (
                        <CustomHeader minDate={minDate?.getFullYear()} maxDate={maxDate?.getFullYear()} {...props} />
                    )}
                    value={formattedValue && formattedValue}
                    maxDate={maxDate}
                    minDate={minDate}
                    highlightDates={weekendIndication && highlightedDates}
                    placeholderText={placeholderText}
                    className={`${
                        variant === "borderless" ? styles.dateInputBorderless : styles.dateInput
                    } ${className}`}
                    ref={ref}
                    {...props}
                />
            </div>
        );
    }
);

export default DateInput;
