import React, { useState, useEffect } from "react";
import moment from "moment";

import { post } from "../../../../../../networking/RequestService";

import styles from "../Page4.module.scss";

const TopCourses = ({ startDate, endDate, unitId, type }) => {
    const [coursesData, setCoursesData] = useState();

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "getFinalReportsMostCoursesWithDelegates",
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            };

            if (unitId) requestObj["unitId"] = unitId;

            if (type === "Public") requestObj["campaignType"] = "public";
            if (type === "LVTPublic") requestObj["campaignType"] = "lvt public";

            post(requestObj)
                .then(({ data }) => {
                    const arr = [...data?.courses];
                    arr.sort((a, b) => b.delegateCount - a.delegateCount);
                    setCoursesData(arr);
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={styles.portfolioTitle}>TOP COURSES WITH MOST DELEGATES</div>
            {coursesData?.length > 0 ? (
                <div className={styles.cardContainer}>
                    {coursesData?.map(item => (
                        <div
                            key={item?.courseId}
                            style={{
                                backgroundImage: `url(${item?.courseImgUrl})`,
                                backgroundSize: "cover"
                            }}
                            className={styles.card}>
                            <img className={styles.logo} src={item?.academyImgUrl} alt="academy" />
                            <div className={styles.courseName}>{item?.courseName}</div>
                            <div className={styles.delegatesCount}>
                                <div className={styles.qty}>{item?.delegateCount}</div>
                                <div className={styles.qtyText}>{item.delegateCount !== 0 && item.delegateCount === 1 ? "DELEGATE" : "DELEGATES"}</div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : <div>There is no data available.</div>}
        </>
    );
};

export default TopCourses;
