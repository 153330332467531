import React, { useEffect, useState } from "react";
import styles from "../Page5.module.scss";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import moment from "moment";
import { post } from "../../../../../../networking/RequestService";
import getCourseImage from "../../../../../../app/functions/getCourseImage";

const TopThreeDelivery = ({ startDate, endDate, unitId, type }) => {
    const [deliveryData, setDeliveryData] = useState();

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "getFinalReportsThreeAreas",
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            };

            if (unitId) requestObj["unitId"] = unitId;

            if (type === "InHouse") requestObj["campaignType"] = "in-house";
            if (type === "LVTInHouse") requestObj["campaignType"] = "lvt in-house";

            post(requestObj)
                .then(({ data }) => {
                    setDeliveryData(data?.threeAreas);
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.portfolioTitle}>TOP 3 DELIVERY AREAS</div>
            <div className={styles.cardContainer}>
                {deliveryData?.length > 0 ? (
                    deliveryData?.map((item, index) => (
                        <div
                            key={index}
                            style={{
                                backgroundImage: `url('${toAbsoluteUrl(getCourseImage(item?.areaName))}')`,
                                backgroundSize: "cover"
                            }}
                            className={styles.card}>
                            <div className={styles.courseName}>{item?.areaName}</div>
                            <div className={styles.delegatesCount}>
                                <div className={styles.qty}>{item?.sessionCount}</div>
                                <div className={styles.qtyText}>{item?.sessionCount !== 0 && item?.sessionCount === 1 ? "SESSION" : "SESSIONS" }</div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div style={{ marginRight: "15px", marginTop: "30px" }}>There is no data available.</div>
                )}
            </div>
        </div>
    );
};

export default TopThreeDelivery;
