import { toAbsoluteUrl } from "_metronic/_helpers";
import { TooltipOverlay } from "app/popleads/components/TooltipOverlay";
import CustomDrawer, { DrawerBody, DrawerContent, DrawerHeader } from "app/popleads/components/drawer/CustomDrawer";
import useDisclosure from "app/popleads/hooks/useDisclosure";
import React from "react";
import InlineSVG from "react-inlinesvg";
import { Button as BootstrapButton } from "react-bootstrap";
import styles from "../../../popleads/assets/css/design_3.0/CustomDatePicker.module.scss";
import CommonDateFilter from "./components/deal-filters/CommonDateFilter";
import { connect } from "react-redux";
import * as trainingRedux from "../training/redux/trainingRedux";

const TrainersFilter = ({ trainerSelectedDate, setTrainerSelecedDate }) => {
    const { isOpen, onClose, onToggle } = useDisclosure();

    return (
        <>
            <TooltipOverlay placement="bottom" tooltip="Filters">
                <BootstrapButton
                    variant="secondary"
                    className="ml-1  mt-md-0 ml-md-4 customBootstrapBtn"
                    onClick={onToggle}
                >
                    <InlineSVG src={toAbsoluteUrl("/media/svg/icons/Text/Filters2.svg")} />
                </BootstrapButton>
            </TooltipOverlay>
            <CustomDrawer position="right" size="md" open={isOpen} onClose={onClose}>
                <DrawerContent isLoading={false}>
                    <DrawerHeader onClose={onClose} sx={{ border: "none" }}>
                        <div>Filters</div>
                    </DrawerHeader>
                    <DrawerBody sx={{ paddingTop: 0 }}>
                        <CommonDateFilter
                            dateObject={trainerSelectedDate}
                            setSelectedDateObject={setTrainerSelecedDate}
                            className={`${styles.rdrDateRangePickerWrapper} mt-4 mb-8`}
                        />
                    </DrawerBody>
                </DrawerContent>
            </CustomDrawer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        trainerSelectedDate: state.training.trainerSelectedDate,
    };
};
const mapDispatchToProps = {
    ...trainingRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrainersFilter);
