import React from 'react';
import Select, { components } from 'react-select';
import { connect } from "react-redux";
import SelectInput from "../components/inputs/selectInput"
import * as app from "../../../app/redux/appRedux";
import * as reportsRedux from "../../reports/redux/reportsRedux";
const { ValueContainer, Placeholder } = components;

class InquiryTypeFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: "",
            options: [
                { value: "LVT In-House", label: "LVT In-House" },
                { value: "In-house", label: "In-house" },
                { value: "Coaching", label: "Coaching" },
                { value: "E-Learning", label: "E-Learning" },
                { value: "Consulting", label: "Consulting" },
            ],
        }
        this.switchCheckbox = this.switchCheckbox.bind(this);
    }

    onChange = selectedOption => {
        console.log(selectedOption);
        this.props.selectInquiryType(selectedOption);
        this.setState({ type: selectedOption })
    };

    onInputChange = (str, { action }) => {
        if (action === "set-value") return false;
        console.log("str ", str)
        this.setState({ inputValue: str });
    };

    switchCheckbox() {
        this.props.setIsSelectAllInquiryTypes(!this.props.isSelectAllInquiryTypes)
    }

    render() {
        const { inputValue } = this.state;
        const isSelectAllInquiryTypes = this.props.isSelectAllInquiryTypes;
        return (
            <>
                {this.props.isMultiSelect ?
                    <SelectInput
                        object={{
                            //  fn: this.getUsers().fn,
                            //  unitId: this.getUsers().unitId,
                            name: "  Inquiry Type",
                            data: {
                                options: this.state.options,
                            },
                            multi: true,
                            allSelectable: true
                        }}
                        onChange={this.onChange}
                        onInputChange={this.onInputChange}
                        infinite={false}

                    />
                    :
                    <Select
                        className="selectInputNewFilter_single"
                        classNamePrefix="selectInputNewFilter"
                        options={this.state.options}
                        defaultValue={this.props.inquiryType}
                        value={this.props.inquiryType}
                        onChange={this.onChange}
                        closeMenuOnSelect={true}
                        isMulti={this.props.isMultiSelect}
                    />
                }

            </>
        );
    }
}

const Input = props => {
    return (
        <div>
            <components.Input {...props} />
        </div>
    );
};

const Option = ({ ...props }) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={e => null}
                />{" "}
                <label>{props.label} </label>
            </components.Option>
        </div>
    );
};

const Control = ({ children, ...props }) => {
    //console.log("props: ", props)
    return (
        <components.Control  {...props}>
            <input
                type="checkbox"
                name='inquiryTypeCheck'
                value='1'
                checked={!!props.selectProps.isSelectAllInquiryTypes}
                onChange={e => {
                    props.selectProps.setIsSelectAllInquiryTypes()
                    if (e.target.checked) props.setValue(props.options);
                    else props.setValue([]);
                }}
                style={{ marginLeft: "10px" }}
            />{" "}

            {children}
        </components.Control>

    );
};

const MultiValue = ({ children, ...innerProps }) => {
    return (
        <></>
    )
};

const CValueContainer = ({ children, ...props }) => {
    var placeholder = props.selectProps.placeholder
    return (
        <ValueContainer {...props}>
            {props.selectProps.inputValue === "" &&
                <Placeholder {...props} isFocused={props.isFocused}>
                    Inquiry Type
                </Placeholder>
            }
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    )
};

const mapStateToProps = (state) => {
    return {
        inquiryType: state.reports.selectedInquiryType,
        isSelectAllInquiryTypes: state.reports.isSelectAllInquiryTypes
    };
};

const mapDispatchToProps = {
    ...reportsRedux.actions,
    ...app.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(InquiryTypeFilter);