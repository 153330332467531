import { toAbsoluteUrl } from "_metronic/_helpers";
import { TooltipOverlay } from "app/popleads/components/TooltipOverlay";
import React from "react";
import InlineSVG from "react-inlinesvg";

const ViewTypeToggle = ({ viewType, setViewType, userPreferences, saveUserPreference }) => {
    return (
        <div className="btn-group btn-group-toggle btn-group-subheader ml-1 ml-sm-4" data-toggle="buttons">
            <TooltipOverlay placement="bottom" tooltip="Grid View">
                <label className={viewType === "grid" ? "selectGridBtn active" : "selectGridBtn"}>
                    <input
                        type="radio"
                        name="viewType"
                        id="option1"
                        onChange={() => {
                            setViewType("grid");
                            if (userPreferences)
                                saveUserPreference("activity_page", { ...userPreferences, view: "grid" });
                        }}
                        autoComplete="off"
                        checked={viewType === "grid" ? true : false}
                    />
                    <InlineSVG src={toAbsoluteUrl("/media/svg/popleads/select_grid_icon.svg")} />
                </label>
            </TooltipOverlay>
            <TooltipOverlay placement="bottom" tooltip="Table View">
                <label className={viewType === "table" ? "selectTableBtn active" : "selectTableBtn"}>
                    <input
                        type="radio"
                        name="viewType"
                        id="option2"
                        onChange={() => {
                            setViewType("table");
                            if (userPreferences)
                                saveUserPreference("activity_page", { ...userPreferences, view: "table" });
                        }}
                        autoComplete="off"
                        checked={viewType === "table" ? true : false}
                    />
                    <InlineSVG src={toAbsoluteUrl("/media/svg/popleads/select_list_icon.svg")} />
                </label>
            </TooltipOverlay>
        </div>
    );
};

export default ViewTypeToggle;
