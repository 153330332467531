import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { Drawer, makeStyles } from "@material-ui/core";

import styles from "./CustomDrawer.module.scss";
import useWindowSize from "app/popleads/hooks/useWindowSize";
import { SMALL_BREAKPOINT } from "app/popleads/app/contants";
import LoadingSpin from "app/popleads/app/partials/LoadingSpin";

const useStyles = makeStyles((theme) => ({
    customDrawer: {
        "& .MuiDrawer-paper": {
            overflowX: "hidden",
            width: (props) => {
                if (["left", "right"].includes(props.position)) {
                    switch (props.size) {
                        case "sm":
                            return "24rem";
                        case "md":
                            return "32rem";
                        case "lg":
                            return "40rem";
                        case "xl":
                            return "48rem";
                        case "full":
                            return "100%";
                        default:
                            return props.size;
                    }
                } else {
                    return "100%";
                }
            },
            height: (props) => {
                if (["top", "bottom"].includes(props.position)) {
                    switch (props.size) {
                        case "sm":
                            return "16rem";
                        case "md":
                            return "20rem";
                        case "lg":
                            return "24rem";
                        case "xl":
                            return "32rem";
                        case "full":
                            return "100%";
                        default:
                            return props.size;
                    }
                } else {
                    return "100%";
                }
            },
            [theme.breakpoints.down("sm")]: {
                width: "100% !important",
            },
        },
        "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.4) !important",
        },
    },
}));

const CustomDrawer = ({ position = "right", size = "md", ...props }) => {
    const classes = useStyles({ position, size });

    const { width } = useWindowSize();
    const isSmallScreen = width < SMALL_BREAKPOINT;
    return (
        <Drawer
            style={{ zIndex: "1049" }}
            {...props}
            className={classes.customDrawer}
            anchor={isSmallScreen ? "bottom" : position}
        >
            {props.children}
        </Drawer>
    );
};

const DrawerContent = ({ isLoading = false, children, sx }) => (
    <div className={styles.content} style={sx}>
        {isLoading && <LoadingSpin classNames="blocking-spinner" />}
        {children}
    </div>
);

const DrawerHeader = ({ showCloseButton = true, onClose, children, sx }) => (
    <div className={`${styles.header} page-heading`} style={sx}>
        <div className="w-100 d-flex justify-content-between flex-wrap">{children}</div>
        {showCloseButton && (
            <button type="button" className={styles.closeButton} onClick={onClose}>
                <SVG src={toAbsoluteUrl("/media/svg/popleads/plus.svg")} width="18px" height="18px" />
            </button>
        )}
    </div>
);

const DrawerBody = ({ children, sx }) => {
    return (
        <div className={styles.body} style={sx}>
            {children}
        </div>
    );
};

const DrawerFooter = ({ children, sx }) => (
    <div className={styles.footer} style={sx}>
        {children}
    </div>
);

export default CustomDrawer;

export { DrawerContent, DrawerHeader, DrawerBody, DrawerFooter };
