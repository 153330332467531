import moment from "moment";

export const isFirstDayOfMonth = date => {
    return (
        moment(date).format("DD") ===
        moment(date)
            .startOf("month")
            .format("DD")
    );
};

export const isLastDayOfMonth = date => {
    return (
        moment(date).format("DD") ===
        moment(date)
            .endOf("month")
            .format("DD")
    );
};

export const isSameMonth = (startDate, endDate) => {
    return moment(startDate).format("MM") === moment(endDate).format("MM");
};

export const isFirstDayOfQuarter = date => {
    return (
        moment(date).format("DD") ===
            moment(date)
                .startOf("quarter")
                .format("DD") &&
        moment(date).format("MM") ===
            moment(date)
                .startOf("quarter")
                .format("MM")
    );
};

export const isLastDayOfQuarter = date => {
    return (
        moment(date).format("DD") ===
            moment(date)
                .endOf("quarter")
                .format("DD") &&
        moment(date).format("MM") ===
            moment(date)
                .endOf("quarter")
                .format("MM")
    );
};

export const isSameQuarter = (startDate, endDate) => {
    return moment(startDate).format("Q") === moment(endDate).format("Q");
};

export const isMonthly = (startDate, endDate) => {
    const isMonthly = isSameMonth(startDate, endDate) && isFirstDayOfMonth(startDate) && isLastDayOfMonth(endDate);

    return isMonthly;
};

export const isQuarterly = (startDate, endDate) => {
    isMonthly(startDate, endDate);

    const isQuarterly =
        isSameQuarter(startDate, endDate) && isFirstDayOfQuarter(startDate) && isLastDayOfQuarter(endDate);

    return isQuarterly;
};

export const isFirstDayOfYear = date => {
    return (
        moment(date).format("DD") ===
            moment(date)
                .startOf("year")
                .format("DD") &&
        moment(date).format("MM") ===
            moment(date)
                .startOf("year")
                .format("MM")
    );
};

export const isLastDayOfYear = date => {
    return (
        moment(date).format("DD") ===
            moment(date)
                .endOf("year")
                .format("DD") &&
        moment(date).format("MM") ===
            moment(date)
                .endOf("year")
                .format("MM")
    );
};

export const isSameYear = (startDate, endDate) => {
    return moment(startDate).format("YYYY") === moment(endDate).format("YYYY");
};

export const isYearly = (startDate, endDate) => {
    const isYearly = isSameYear(startDate, endDate) && isFirstDayOfYear(startDate) && isLastDayOfYear(endDate);
    return isYearly;
};

export const isIncompleteMonth = (startDate, endDate) => {
    const isIncompleteMonth =
        isFirstDayOfMonth(startDate) && isSameMonth(startDate, endDate) && !isLastDayOfMonth(endDate);
    return isIncompleteMonth;
};

export const isIncompleteQuarter = (startDate, endDate) => {
    const isIncompleteQuarter =
        !isMonthly(startDate, endDate) &&
        !isIncompleteMonth(startDate, endDate) &&
        isFirstDayOfQuarter(startDate) &&
        isSameQuarter(startDate, endDate) &&
        !isLastDayOfQuarter(endDate);
    return isIncompleteQuarter;
};

export const isIncompleteYear = (startDate, endDate) => {
    const isIncompleteYear =
        !isMonthly(startDate, endDate) &&
        !isIncompleteMonth(startDate, endDate) &&
        !isQuarterly(startDate, endDate) &&
        !isIncompleteQuarter(startDate, endDate) &&
        isFirstDayOfYear(startDate) &&
        isSameYear(startDate, endDate) &&
        !isLastDayOfYear(endDate);

    return isIncompleteYear;
};

export const getFormattedDate = (startDate, endDate, type = 1) => {
    if (!startDate || !endDate) return;

    if (isYearly(startDate, endDate)) {
        if (type === 1) {
            return `Yearly - ${moment(startDate).format("YYYY")}`;
        }
        if (type === 2) {
            return `Sales made in ${moment(startDate).format("YYYY")}`;
        }
    } else if (isQuarterly(startDate, endDate)) {
        if (type === 1) {
            return "Quarterly - Q" + moment(startDate).format("Q YYYY");
        }
        if (type === 2) {
            return `Sales made in Q${moment(startDate).format("Q YYYY")}`;
        }
    } else if (isMonthly(startDate, endDate)) {
        if (type === 1) {
            return "Monthly - " + moment(startDate).format("MMMM YYYY");
        }
        if (type === 2) {
            return `Sales made in ${moment(startDate).format("MMMM YYYY")}`;
        }
    } else {
        if (type === 1) {
            return moment(startDate).format("MMMM DD, YYYY") + " - " + moment(endDate).format("MMMM DD, YYYY");
        }
        if (type === 2) {
            return `Sales made in ${moment(startDate).format("MMMM DD, YYYY") +
                " - " +
                moment(endDate).format("MMMM DD, YYYY")}`;
        }
    }
};

export const getChartSubtitle = (startDate, endDate) => {
    if (!startDate || !endDate) return "";

    if (isYearly(startDate, endDate) || isIncompleteYear(startDate, endDate)) {
        return "YEAR";
    } else if (isQuarterly(startDate, endDate) || isIncompleteQuarter(startDate, endDate)) {
        return "QUARTER";
    } else return "MONTH";
};

export const getF2FComparisonText = (startDate, endDate) => {
    if (!startDate || !endDate) return "";

    if (isYearly(startDate, endDate) || isIncompleteYear(startDate, endDate)) {
        return "compared to previous year";
    } else if (isQuarterly(startDate, endDate) || isIncompleteQuarter(startDate, endDate)) {
        return "compared to previous quarter";
    } else {
        return "compared to last 3 months";
    }
};
