import React from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";

import IndustryFilter from "app/popleads/modules/filters/components/IndustryFilter";

const IndustryDropdown = ({ module }) => {
    const { selectedIndustry } = useSelector((state) => state[module]);
    const industry = selectedIndustry?.label ?? "All Industries";

    return (
        <Dropdown id="industryDropdown" className="d-none d-xxl-block" align="end">
            <Dropdown.Toggle
                id="kt_industry_toggle"
                variant="outline-primary"
                className="ml-3 hide-chevron font-weight-normal"
            >
                <span id="kt_industrySelect">
                    <span className={industry === "All Industries" ? "disable-color" : ""} id="kt_industrySelect_title">
                        {industry}
                    </span>
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                <form>
                    <div className="navi navi-hover">
                        <div className="row">
                            <div className="col-sm-12">
                                <span className="navi-item">
                                    <div className="navi-link">
                                        <div className="navi-text">
                                            <div className="font-weight-bold">
                                                <IndustryFilter />
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default IndustryDropdown;
