import React, { useState } from 'react'
import { FormattedNumber } from 'react-intl';
import * as util from '../../../../../app/functions/util';
import commonStyles from "../../final-report-common.module.scss";
import styles from "../Page7/Page7.module.scss";

const TopSellers = ({ last3DealsData }) => {
  const [notLoadedImages, setNotLoadedImages] = useState([]);

  const topSellerData = last3DealsData?.items?.find(item => item.title === "TOP SELLERS")
  return (
    <div>
      <div className={commonStyles.sellersAndClientTitle}>Top Sellers</div>
      {
        topSellerData?.topSellers ? topSellerData?.topSellers.map((seller) => (
          <div
            className={styles.sellerContainer}
            key={seller?.id}>
            {seller?.pictureUrl && !notLoadedImages.includes(seller?.id) ? (
              <img
                className={styles.sellerImg}
                src={seller?.pictureUrl}
                alt="seller"
                onError={() => setNotLoadedImages([...notLoadedImages, seller?.id])}
              />
            ) : (
              <div className={styles.fallbackNameTag}>{util.nameTag(seller?.name)}</div>
            )}
            <div>

              <div className={commonStyles.dealProjectsecondaryTitle}>{seller?.name}</div>
              <div className={commonStyles.dealsTotalAmount}>
                $ <FormattedNumber value={seller?.amount} />
              </div>
            </div>
          </div>
        ))
          : (
            <div> There is no data available.</div>
          )
      }
    </div>
  )
}

export default TopSellers