import React, { forwardRef, useImperativeHandle, useRef } from "react";
import ReactSelect from "react-select";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const Select = forwardRef(({ className = "", classNamePrefix = "customSelectInput", value, ...props }, ref) => {
    const selectRef = useRef(null);

    useImperativeHandle(
        ref,
        () => ({
            selectRef
        }),
        [selectRef]
    );

    return (
        <ReactSelect
            ref={selectRef}
            className={`customSelectInput ${className}`}
            classNamePrefix={classNamePrefix}
            isClearable={props.isClearable}
            isSearchable={props.isSearchable}
            value={value}
            {...props}
            components={{
                DropdownIndicator: () => (
                    <div className={`${classNamePrefix}__indicator ${classNamePrefix}__dropdown-indicator`}>
                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/chevron-down.svg")} />
                    </div>
                ),
                ClearIndicator: ({ innerProps: { ref, ...restInnerProps } }) => {
                    return (
                        <div
                            {...restInnerProps}
                            ref={ref}
                            className={`${classNamePrefix}__indicator ${classNamePrefix}__clear-indicator`}>
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/x.svg")} />
                        </div>
                    );
                }
            }}
        />
    );
});

export default Select;
