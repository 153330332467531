import React, { useMemo } from "react";
import objectPath from "object-path";
// LayoutContext
import { useHtmlClassService } from "../_core/MetronicLayout";
// Import Layout components
import { Header } from "./header/Header";
import { HeaderMobile } from "./header-mobile/HeaderMobile";
import { Aside } from "./aside/Aside";
import { Footer } from "./footer/Footer";
import { LayoutInit } from "./LayoutInit";
import SubHeader from "./subheader/SubHeader";
import { ScrollTop } from "./extras/ScrollTop";
import { StickyToolbar } from "./extras/StickyToolbar";
import { XL_BREAKPOINT } from "../../../app/popleads/app/contants";
import { getWindowSize } from "app/popleads/modules/activities/utils";
import { getUrlQueryParam } from "app/popleads/app/functions/util";
import clsx from "clsx";
import { connect } from "react-redux";
import * as app from "app/popleads/app/redux/appRedux";
import { useEffect } from "react";

function _Layout({ children, isFromMobileApp, setIsFromMobileApp }) {
    const uiService = useHtmlClassService();
    // Layout settings (cssClasses/cssAttributes)
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            selfLayout: objectPath.get(uiService.config, "self.layout"),
            asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
            subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
            /*desktopHeaderDisplay: objectPath.get(
                uiService.config,
                "header.self.fixed.desktop"
            ),*/
            contentCssClasses: uiService.getClasses("content", true),
            contentContainerClasses: uiService.getClasses("content_container", true),
            contentExtended: objectPath.get(uiService.config, "content.extended"),
        };
    }, [uiService]);
    const { width } = getWindowSize();

    useEffect(() => {
        setIsFromMobileApp(getUrlQueryParam("fromMobile") === "1");
    }, []);

    return layoutProps.selfLayout !== "blank" ? (
        <>
            {/*begin::Main*/}
            {!isFromMobileApp && <HeaderMobile />}
            <div className="d-flex flex-column flex-root">
                {/*begin::Page*/}
                <div className="d-flex flex-row flex-column-fluid page">
                    {layoutProps.asideDisplay && <Aside />}
                    {/*begin::Wrapper*/}
                    <div
                        className={clsx("d-flex flex-column flex-row-fluid wrapper", isFromMobileApp && "pt-0")}
                        id="kt_wrapper">
                        {width < XL_BREAKPOINT && !isFromMobileApp && <Header />}
                        {/*begin::Content*/}
                        <div
                            id="kt_content"
                            className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}>
                            {layoutProps.subheaderDisplay && <SubHeader />}
                            {/*begin::Entry*/}
                            {!layoutProps.contentExtended && (
                                <div className="d-flex flex-column-fluid">
                                    {/*begin::Container*/}
                                    <div className={layoutProps.contentContainerClasses}>{children}</div>
                                    {/*end::Container*/}
                                </div>
                            )}

                            {layoutProps.contentExtended && { children }}
                            {/*end::Entry*/}
                        </div>
                        {/*end::Content*/}
                        {!isFromMobileApp && <Footer />}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                <span id="newFilterMenu"></span>
                {/*end::Page*/}
            </div>
            {/* <QuickUser/> */}
            {/*<QuickPanel/> popleads commented*/}
            <ScrollTop />
            <StickyToolbar />
            {/*end::Main*/}
            <LayoutInit />
        </>
    ) : (
        // BLANK LAYOUT
        <div className="d-flex flex-column flex-root">{children}</div>
    );
}

const mapStateToProps = (state) => {
    return {
        isFromMobileApp: state.app.isFromMobileApp,
    };
};
const mapDispatchToProps = {
    ...app.actions,
};

export const Layout = connect(mapStateToProps, mapDispatchToProps)(_Layout);
