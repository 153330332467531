/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { FormattedNumber } from "react-intl";
import { getFormattedDate } from "../../../../../../app/functions/finalReportUtil";
import { post } from "../../../../../../networking/RequestService";

import styles from "../Page2.module.scss";
import commonStyles from "../../../final-report-common.module.scss";

const WhenBreakdownTable = ({ startDate, endDate, unitId }) => {
    const [tableData, setTableData] = useState();

    const salesMade = getFormattedDate(startDate, endDate, 2);

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "getBreakdownWhenDealsWillBeDeliverd",
                startDate: startDate,
                endDate: endDate
            };
            if (unitId) requestObj["unitId"] = unitId;

            post(requestObj).then(({ data }) => setTableData(data));
        }
    }, [startDate, endDate, unitId]);

    // const sortedData = [...tableData?.willBeDelivered].sort((a, b) => a.quarter.localeCompare(b.quarter));
    const modifiedData = tableData?.willBeDelivered?.filter(item => parseFloat(item.amount) !== 0);

    const amounts = modifiedData?.map(item => parseFloat(item.amount)) || [];
    const maxAmount = Math.max(...amounts);
    const maxIndex = amounts.indexOf(maxAmount);

    return (
        <div className="d-flex flex-column" style={{ width: "97%" }}>
            <div className={`${styles.itemTitle} text-uppercase`}>
                {"BREAKDOWN OF WHEN THIS "}
                <FormattedNumber value={tableData?.totalAmount} notation="compact" compactDisplay="long" />
                {" WILL BE DELIVERED"}
            </div>
            {modifiedData && modifiedData.length > 0 ? (
                <table className={commonStyles.commonTable}>
                    <thead>
                        <tr>
                            <th scope="col">Delivery</th>
                            {modifiedData.map((item, i) => (
                                <th key={item.quarter} scope="col" className={i === maxIndex ? styles.highestItem : ""}>
                                    {item.name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={styles.semiBold}>{salesMade}</td>
                            {modifiedData.map((item, i) => (
                                <td key={item.quarter} className={i === maxIndex ? styles.highestItem : ""}>
                                    <FormattedNumber
                                        value={item.amount}
                                        style="currency"
                                        currency="USD"
                                        maximumFractionDigits={0}
                                    />
                                </td>
                            ))}
                        </tr>
                        <tr>
                            <td className={styles.semiBold}>%</td>
                            {modifiedData.map((item, i) => (
                                <td key={item.quarter} className={i === maxIndex ? styles.highestItem : ""}>
                                    <FormattedNumber value={item.percent} maximumFractionDigits={3} />%
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            ) : (
                <div>There is no data available.</div>
            )}
        </div>
    );
};
export default WhenBreakdownTable;
