import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ViewTypeToggle from "../components/ViewTypeToggle";
import SearchFilter from "app/popleads/modules/filters/components/SearchFilter";
import Button from "app/popleads/components/Button";
import PrecampaignsFilterDrawer from "../drawers/PrecampaignsFilterDrawer";
import { actions } from "app/popleads/modules/campaigns/redux/campaignsRedux";
import PrecampaignsActionDropdown from "../components/PrecampaignsActionDropdown";

const PrecampaignsActions = () => {
    const { user } = useSelector((state) => state.auth);
    const { gridOrTable } = useSelector((state) => state.campaigns);

    const dispatch = useDispatch();

    return (
        <div className="d-flex align-items-center">
            <SearchFilter module="precampaign" className="searchPL" />
            {user?.permissions?.INSERT_EDIT_PRECAMPAIGNS === 1 && (
                <NavLink to="/campaigns/precampaigns/add" className="cardToolbarMuted">
                    <Button
                        title="New Pre-Campaign"
                        variant="success"
                        icon
                        src="/media/svg/popleads/plus.svg"
                        outline
                        className="trainerFilterButtons buttonDesktop ml-4"
                    />
                    <Button title="Add" variant="primary" outline className="trainerFilterButtons ml-1 buttonMobile" />
                </NavLink>
            )}
            <PrecampaignsFilterDrawer />
            <ViewTypeToggle
                viewType={gridOrTable}
                setViewType={(viewType) => dispatch(actions.gridOrTableButton(viewType))}
            />
            <PrecampaignsActionDropdown />
        </div>
    );
};

export default PrecampaignsActions;
