import React from 'react'
import SearchInput from '../../../../app/partials/SearchInput'

const DealSearchFilter = (props) => {

    return (
        <div className='mb-4 deal-search-filter'>
            <div className='d-flex justify-content-between'>

                <div className="filterbar-heading">{props.heading}</div>
                {props?.value.length > 0 && (
                    <div className="mb-3 text-muted cursor-pointer" onClick={props.dealRemoveFilterType}>
                        X
                    </div>
                )}
            </div>
            <div className="mt-2 mb-2">
                <SearchInput
                    minLength={2}
                    debounceTimeout={300}
                    onChange={props.handleChange}
                    value={props.value}
                    inputName={props.name}
                    className="form-control w-100"
                    placeholder={props.placeholder ? props.placeholder : "Search..."}
                    id="kt_datatable_search_query"
                />
            </div>
        </div>
    )
}

export default DealSearchFilter