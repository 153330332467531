/* eslint-disable react/style-prop-object */
import React from "react";
import { getUrlQueryParam } from "../../../../../app/functions/util";
import commonStyles from "../../final-report-common.module.scss";

import FinalReportHeader from "../common/FinalReportHeader";
import Comparison from "./components/Comparison";
import SalesBreakdownTable from "./components/SalesBreakdownTable";
import WhenBreakdownTable from "./components/WhenBreakdownTable";
import TotalSales from "./components/TotalSales";

const Page2 = () => {
    const startDate = getUrlQueryParam("startDate");
    const endDate = getUrlQueryParam("endDate");
    const unitId = getUrlQueryParam("unitId")?.split(",");
    
    return (
        <div className={commonStyles.page}>
            <FinalReportHeader />
            <div className={commonStyles.content}>
                <div className={`${commonStyles.pageTitle} mb-12`}>TOTAL SALES</div>
                <div className="row mx-0 justify-content-between mb-24">
                    <div className="col-7 px-0">
                        <TotalSales startDate={startDate} endDate={endDate} unitId={unitId} />
                    </div>
                    <div className="col-5 px-0">
                        <SalesBreakdownTable startDate={startDate} endDate={endDate} unitId={unitId} />
                    </div>
                </div>
                <div className="row mx-0 mb-24">
                    <WhenBreakdownTable startDate={startDate} endDate={endDate} unitId={unitId} />
                </div>
                <div className="row mx-0">
                    <Comparison startDate={startDate} endDate={endDate} unitId={unitId} />
                </div>
            </div>
        </div>
    );
};

export default Page2;
