import React, { useState, useRef } from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Button from "../../../components/Button";
import SVG from "react-inlinesvg";
import SearchInput from "../../../app/partials/SearchInput";

const SaveFilter = ({
    usersFilters,
    isFilterApplied,
    selectedFilterId,
    onFilterSelected,
    saveNewFilter,
    deleteSelectedFilter,
    clearAllFilters,
    numOfFilters = 4,
}) => {
    const [showAll, setShowAll] = useState(false);
    const [showSaveInput, setShowSaveInput] = useState(false);
    const [savedFilterName, setSavedFilterName] = useState("");
    const [searchFilter, setSearchFilter] = useState("");

    const inputRef = useRef();

    const onDeleteClicked = (e, id) => {
        e.stopPropagation();
        deleteSelectedFilter(id);
    };

    const onSaveClick = () => {
        saveNewFilter(savedFilterName);
        setShowSaveInput(false);
        setSavedFilterName("");
    };

    const onCancelClick = () => {
        setShowSaveInput(false);
        setSavedFilterName("");
    };

    const handleKeyPress = (e) => {
        if (e?.key === "Enter") {
            e.preventDefault();
            onSaveClick();
        }
    };

    let orderedData =
        usersFilters && usersFilters.length > 0
            ? [...usersFilters].sort((a, b) => {
                  if (a.id === selectedFilterId) {
                      return -1;
                  } else if (b.id !== selectedFilterId) {
                      return 1;
                  } else {
                      return 0;
                  }
              })
            : [];

    if (searchFilter) {
        orderedData = orderedData.filter((item) => item.name.toLowerCase().includes(searchFilter.toLowerCase()));
    }

    const numOfFiltersToDisplay = showAll ? orderedData.length : selectedFilterId ? numOfFilters + 1 : numOfFilters;

    return (
        <>
            <div className="d-flex align-items-center justify-content-between mb-4" style={{ minWidth: "200px" }}>
                {/* <h4 className="drawer-header m-0 py-3">Filters</h4> */}
                <div className="d-flex align-items-center">
                    {isFilterApplied && (
                        <>
                            <Button
                                title={"Clear All"}
                                onClick={clearAllFilters}
                                className="btn-filters clearAll"
                                type="button"
                            />
                            <Button
                                title={"Save Selection"}
                                icon
                                onClick={() => setShowSaveInput(true)}
                                className="btn-filters saveFilter"
                                src="/media/svg/popleads/save_icon.svg"
                                type="button"
                            />
                        </>
                    )}
                </div>
            </div>
            {showSaveInput && (
                <div className="d-flex align-items-center mb-4">
                    <input
                        ref={inputRef}
                        className="save-filter-input"
                        type="text"
                        autoFocus
                        onChange={(e) => setSavedFilterName(e.target.value)}
                        onKeyPress={handleKeyPress}
                        style={{ width: inputRef.current ? inputRef.current.scrollWidth : "50px" }}
                    />
                    <Button
                        title={"Save"}
                        disabled={!savedFilterName}
                        onClick={onSaveClick}
                        className="btn-filters saveFilter"
                        type="button"
                    />
                    <Button title={"Cancel"} onClick={onCancelClick} className="btn-filters clearAll" type="button" />
                </div>
            )}
            {showAll && (
                <div className="mb-4">
                    <SearchInput
                        minLength={2}
                        // debounceTimeout={300}
                        value={searchFilter}
                        onChange={(event) => setSearchFilter(event.target.value)}
                        className="form-control searchForm w-100"
                        placeholder="Search..."
                        id="kt_datatable_search_query"
                    />
                </div>
            )}
            <div className="mb-6">
                {orderedData && orderedData.length > 0 ? (
                    <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
                        {orderedData.slice(0, numOfFiltersToDisplay).map((filter, i) => (
                            <button
                                key={`users__filter__${i}`}
                                className={`d-flex align-items-center existingFilter ${
                                    filter.id === selectedFilterId ? "active" : ""
                                }`}
                                onClick={() => onFilterSelected(filter)}
                                type="button"
                            >
                                <span className="mr-2">{filter.name}</span>
                                <span onClick={(e) => onDeleteClicked(e, filter.id)}>
                                    <SVG src={toAbsoluteUrl("/media/svg/popleads/plus.svg")} />
                                </span>
                            </button>
                        ))}
                        {orderedData.length > numOfFiltersToDisplay && (
                            <div className="existingFilter" onClick={() => setShowAll(true)}>
                                <span className="mr-2">More...</span>
                            </div>
                        )}
                    </div>
                ) : (
                    searchFilter && <div className="color-warning">There is no saved filter found!</div>
                )}
            </div>
        </>
    );
};

export default SaveFilter;
