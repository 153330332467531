import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
// PopLeads change import { login } from "../_redux/authCrud";
// PopLeads change
import { post } from "../../../popleads/networking/RequestService";
import * as util from "../../../popleads/app/functions/util";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

// PopLeads change
const initialValues = {
    email: "",
    password: ""
};

function Login(props) {
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                })
            ),
        password: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required(
                intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                })
            )
    });

    const TwoFaSchema = Yup.object().shape({
        code: Yup.string()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const [isInTwoFactor, setInTwoFactor] = useState(false);

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setTimeout(() => {
                // PopLeads change
                const requestObj = {
                    fn: "mailLogin",
                    email: values.email,
                    password: values.password,
                    appType: "CRM"
                };
                post(requestObj)
                    .then(({ data: { sid, data } }) => {
                        disableLoading();
                        props.login(sid, data.user);
                        util.createCookie(util.getCookieName(), sid, 14);
                        setSubmitting(false);
                        setStatus(null);
                    })
                    .catch(err => {
                        disableLoading();
                        setSubmitting(false);
                        // PopLeads change
                        if (err.response.data) {
                            if (err.response.data.stat === "nok-not-logged-in" && !isInTwoFactor) setInTwoFactor(true);
                            setStatus(err.response.data);
                        }
                    });
            }, 1000);
        }
    });

    const twoFaFormik = useFormik({
        initialValues,
        validationSchema: TwoFaSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setTimeout(() => {
                // PopLeads change
                const requestObj = {
                    fn: "validateTwoFactorAuthCode",
                    email: formik.values.email,
                    code: values.code,
                    appType: "CRM"
                };
                post(requestObj)
                    .then(({ data: { sid, data } }) => {
                        disableLoading();
                        props.login(sid, data.user);
                        util.createCookie(util.getCookieName(), sid, 14);
                        setStatus(null);
                        formik.setStatus(null);
                    })
                    .catch(err => {
                        disableLoading();
                        setSubmitting(false);
                        // PopLeads change
                        if (err.response.data) {
                            setStatus(err.response.data.text);
                            formik.setStatus(null);
                        }
                    });
            }, 1000);
        }
    });

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
            {/* begin::Head */}
            <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">
                    <FormattedMessage id="AUTH.LOGIN.TITLE" />
                </h3>
                <p className="text-muted font-weight-bold">Enter your username and password</p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            {!isInTwoFactor && (
                <form onSubmit={formik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                    {formik.status && (
                        <div
                            className={`mb-10 alert alert-custom ${
                                formik.status.stat === "nok" ? "alert-light-danger" : "alert-light-success"
                            } alert-dismissible`}>
                            <div className="alert-text font-weight-bold">{formik.status.text}</div>
                        </div>
                    )}

                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Email"
                            type="email"
                            className={`form-control form-control-solid h-auto py-5-login px-6 ${getInputClasses(
                                "email"
                            )}`}
                            name="email"
                            {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.email}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Password"
                            type="password"
                            className={`form-control form-control-solid h-auto py-5-login px-6 ${getInputClasses(
                                "password"
                            )}`}
                            name="password"
                            {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.password}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <Link
                            to="/auth/forgot-password"
                            className="text-dark-50 text-hover-primary my-3 mr-2"
                            id="kt_login_forgot">
                            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                        </Link>
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}>
                            <span>Sign In</span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                    </div>
                </form>
            )}
            {isInTwoFactor && (
                <form onSubmit={twoFaFormik.handleSubmit} className="form fv-plugins-bootstrap fv-plugins-framework">
                    {formik.status && (
                        <div
                            className={`mb-10 alert alert-custom ${
                                formik.status.stat === "nok" ? "alert-light-danger" : "alert-light-success"
                            } alert-dismissible`}>
                            <div className="alert-text font-weight-bold">{formik.status.text}</div>
                        </div>
                    )}
                    {twoFaFormik.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{twoFaFormik.status}</div>
                        </div>
                    )}

                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder="Code"
                            type="text"
                            className={`form-control form-control-solid h-auto py-5-login px-6`}
                            name="code"
                            {...twoFaFormik.getFieldProps("code")}
                        />
                        {twoFaFormik.touched.email && twoFaFormik.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{twoFaFormik.errors.email}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <Link
                            onClick={() => {
                                setInTwoFactor(false);
                                formik.setStatus(null);
                            }}
                            className="text-dark-50 text-hover-primary my-3 mr-2"
                            id="kt_2fa_back">
                            Back
                        </Link>
                        <button
                            id="kt_2fa_submit"
                            type="submit"
                            disabled={twoFaFormik.isSubmitting}
                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}>
                            <span>Sign In</span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                    </div>
                </form>
            )}
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
