import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/popleads/modules/reports/redux/reportsRedux";
import SurveyFilterDrawer from "../drawers/SurveyFilterDrawer";
import { Dropdown } from "react-bootstrap";
import TrainerFilter from "app/popleads/modules/filters/components/TrainerFilter";
import CampaignTypeFilter from "app/popleads/modules/filters/components/CampaignTypeFilter";
import EmployeeFilter from "app/popleads/modules/filters/components/EmployeeFilter";
import { DateRangePicker } from "react-date-range";
import { customDateRanges, getDateObject } from "app/popleads/app/functions/util";
import moment from "moment";

const SurveyCampaignListActions = () => {
    const { selectedTrainer } = useSelector((state) => state.reports);
    const { selectedCampaignTypeList } = useSelector((state) => state.reports);
    const { selectedEmployee } = useSelector((state) => state.reports);
    const { selectedDateSurvey } = useSelector((state) => state.reports);
    const dispatch = useDispatch();
    const [trainer, setTrainer] = useState("All Trainers");
    const [campaignTypeList, setCampaignTypeList] = useState("All Campaign Types");
    const [employee, setEmployee] = useState("All Employees");
    const [dateString, setDateString] = useState("All Time");
    const [dateSurvey, setDateSurvey] = useState([
        selectedDateSurvey.startDate
            ? {
                  startDate: selectedDateSurvey.startDate,
                  endDate: selectedDateSurvey.endDate,
                  predefinedTag: selectedDateSurvey.predefinedTag,
                  dateString: selectedDateSurvey.dateString,
                  key: "selection",
              }
            : {
                  startDate: moment(0)
                      .startOf("year")
                      .toDate(),
                  endDate: moment()
                      .endOf("year")
                      .toDate(),
                  predefinedTag: "allTime",
                  dateString: "All Time",
                  key: "selection",
              },
    ]);

    useEffect(() => {
        if (selectedDateSurvey.startDate) {
            setDateSurvey([selectedDateSurvey]);
        }
    }, [selectedDateSurvey]);

    useEffect(() => {
        if (Array.isArray(selectedTrainer)) {
            if (selectedTrainer.length > 0) {
                setTrainer(
                    selectedTrainer.length === 1 ? selectedTrainer[0].label : `${selectedTrainer.length} Trainers`
                );
            } else {
                setTrainer("All Trainers");
            }
        }
        if (Array.isArray(selectedCampaignTypeList)) {
            if (selectedCampaignTypeList.length > 0) {
                setCampaignTypeList(
                    selectedCampaignTypeList.length === 1
                        ? selectedCampaignTypeList[0].label
                        : `${selectedCampaignTypeList.length} Campaign Types`
                );
            } else {
                setCampaignTypeList("All Campaign Types");
            }
        }
        if (Array.isArray(selectedEmployee)) {
            if (selectedEmployee.length > 0) {
                setEmployee(
                    selectedEmployee.length === 1 ? selectedEmployee[0].label : `${selectedEmployee.length} Employees`
                );
            } else {
                setEmployee("All Employees");
            }
        }
        if (selectedDateSurvey.dateString) {
            setDateString(selectedDateSurvey.dateString);
        }
    }, [selectedTrainer, selectedCampaignTypeList, selectedEmployee, selectedDateSurvey]);

    return (
        <div className="d-flex align-items-center">
            <Dropdown id="trainerDropdown" className="d-none d-lg-block" align="end">
                <Dropdown.Toggle
                    id="kt_trainer_toggle"
                    variant="outline-primary"
                    className="ml-4 hide-chevron font-weight-normal"
                >
                    <span id="kt_trainerSelect">
                        <span
                            className={trainer === "All Trainers" ? "disable-color" : ""}
                            title="Select Trainer"
                            id="kt_trainerSelect_title"
                        >
                            {trainer}
                        </span>
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                    <form>
                        <div className="navi navi-hover">
                            <div className="row">
                                <div className="col-sm-12">
                                    <span className="navi-item">
                                        <div className="navi-link">
                                            <div className="navi-text">
                                                <div className="font-weight-bold">
                                                    <TrainerFilter isMultiSelect={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown id="campaignTypeListDropdown" className="d-none d-lg-block" align="end">
                <Dropdown.Toggle
                    id="kt_campaignTypeList_toggle"
                    variant="outline-primary"
                    className="ml-4 hide-chevron font-weight-normal"
                >
                    <span id="kt_campaignTypeListSelect">
                        <span
                            className={campaignTypeList === "All Campaign Types" ? "disable-color" : ""}
                            title="Select Campaign Type"
                            id="kt_campaignTypeListSelect_title"
                        >
                            {campaignTypeList}
                        </span>
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                    <form>
                        <div className="navi navi-hover">
                            <div className="row">
                                <div className="col-sm-12">
                                    <span className="navi-item">
                                        <div className="navi-link">
                                            <div className="navi-text">
                                                <div className="font-weight-bold">
                                                    <CampaignTypeFilter isMultiSelect={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown id="employeeDropdown" className="d-none d-lg-block" align="end">
                <Dropdown.Toggle
                    id="kt_employee_toggle"
                    variant="outline-primary"
                    className="ml-4 hide-chevron font-weight-normal"
                >
                    <span id="kt_employeeSelect">
                        <span
                            className={employee === "All Employees" ? "disable-color" : ""}
                            title="Select Employee"
                            id="kt_employeeSelect_title"
                        >
                            {employee}
                        </span>
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl">
                    <form>
                        <div className="navi navi-hover">
                            <div className="row">
                                <div className="col-sm-12">
                                    <span className="navi-item">
                                        <div className="navi-link">
                                            <div className="navi-text">
                                                <div className="font-weight-bold">
                                                    <EmployeeFilter isMultiSelect={true} isLoading={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown id="datePickerDropdown" className="d-none d-lg-block" align="end">
                <Dropdown.Toggle
                    id="kt_filter_toggle"
                    variant="outline-primary"
                    className="ml-4 hide-chevron font-weight-normal"
                >
                    <span
                        id="kt_dashboard_daterangepicker3"
                        data-toggle="tooltip"
                        title="Select dashboard daterange"
                        data-placement="left"
                    >
                        <span
                            className={dateString === "All Time" ? "disable-color" : ""}
                            id="kt_dashboard_daterangepicker_title3"
                        >
                            {dateString}
                        </span>
                    </span>
                </Dropdown.Toggle>
                <div className="filter-wrapper">
                    <Dropdown.Menu className="dropdown-menu p-0 dropdown-menu-anim-up dropdown-menu-xl dropdown-menu-withDateRangePicker">
                        <form>
                            <div className="navi navi-hover">
                                <span className="navi-item">
                                    <div className="navi-link">
                                        <div className="navi-text">
                                            <div className="font-weight-bold">
                                                <span id="dateFilterContainer">
                                                    <DateRangePicker
                                                        ranges={dateSurvey}
                                                        className={""}
                                                        staticRanges={customDateRanges(new Date(null), new Date())}
                                                        inputRanges={[]}
                                                        direction="vertical"
                                                        onChange={(item) => {
                                                            const date = getDateObject(item.selection);
                                                            if (!date.startDate && !date.endDate) {
                                                                date.startDate = moment(0)
                                                                    .startOf("year")
                                                                    .toDate();
                                                                date.endDate = moment()
                                                                    .endOf("year")
                                                                    .toDate();
                                                                date.predefinedTag = "allTime";
                                                                date.dateString = "All Time";
                                                                date.key = "selection";
                                                            }
                                                            setDateSurvey([date]);
                                                            dispatch(actions.selectDateSurvey(date));
                                                            // DOM manipulation to highlight "All Time"
                                                            const range = document.querySelector(".rdrStaticRanges")
                                                                ?.firstElementChild;
                                                            if (range && date.predefinedTag === "allTime") {
                                                                range.classList.add("rdrStaticRangeSelected");
                                                            } else {
                                                                range.classList.remove("rdrStaticRangeSelected");
                                                            }
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </form>
                    </Dropdown.Menu>
                </div>
            </Dropdown>
            <SurveyFilterDrawer />
        </div>
    );
};

export default SurveyCampaignListActions;
