export const actionTypes = {
    TravelFilter: "[Action] Travel Filter",
    TravelerFilter: "[Action] Traveler Filter",
    TravelSelectedEmployee: "[Action] Travel Selected Employee",
    AddNewTravelBtn: "[Action] Add New Travel Button",
    TravelDateListButton: "[Action] Travel Date List Button",
    TravelGridButton: "[Action] Travel Grid Button",
    TravelSelectDate: "[Action] Travel Select Date",
    TravelEmployee: "[Action] Travel Employee",
    TravelUnits: "[Action] Travel Units",
    TravelSelectedStatus: "[Action] Travel Select Status",
    TravelCountries: "[Action] Travel Countries",
    TravelerName: "[Action] Traveler Name",
    TravelCity: "[Action] Travel City",
    TravelShowCountryFilter: "[Action] Travel Show Country Filter",
    TravelCityInCountry: "[Action] Travel City In Country",
    TravelVisaAndPassportFilter: "[Action] Travel Visa And Passport Filter",
    TravelNewValidVisaForEmployee: "[Action] Travel New Valid Visa For Employee ",
    TravelVisaModalOpen: "[Action] Travel Visa Modal Open ",
    TravelVisaAndPassportTypeSelect: "[Action] Travel Visa And Passport Type Select",
    TravelNewPassportButton: "[Action] Travel Passport Button",
    TravelNewPassportModal: "[Action] Travel Passport Modal",
    TravelVisaStatusSelectFilter: "[Action] Travel Visa Status Select",
    TravelFlightStatusSelect: "[Action] Travel Flight Status Select",
    TravelAccommodationStatusSelectFilter: "[Action] Travel Accommodation Status Select",
    TravelVisaRequestModal: "[Action] Travel Visa Request Modal",
    TravelFlightAndHotelStatusFilter: "[Action] Travel Flight And Hotel Status",
    RefetchTravelList: "[Action] Refetch Travel List",
    SelectedEmployee: "[Action] Selected Employee",
    SelectedType: "[Action] Selected Type",
    OpenEdit: "[Action] Open Edit",
};
const initialTravelState = {
    travelFilter: false,
    travelerFilter: false,
    travelSelectedEmployee: null,
    addNewTravelBtn: false,
    travelDateListButton: false,
    travelGridButton: false,
    travelStatusConstant: [
        { value: "1", label: "Approved" },
        { value: "0", label: "Waiting" },
        // { value: "rejected", label: "Rejected" },
    ],
    visaAndPassportType: [
        { value: "visa", label: "Visa" },
        { value: "passport", label: "Passport" },
    ],
    completedOrInprogressType: [
        { value: 1, label: "Completed" },
        { value: "0", label: "In Progress" },
    ],
    travelSelectedDate: {
        startDate: null,
        endDate: null,
    },
    travelEmployee: [],
    travelUnits: [],
    travelSelectedStatus: [],
    travelerName: "",
    travelCountries: [],
    travelCity: [],
    showTravelCountryFilter: true,
    travelCityInCountry: null,
    travelVisaAndPassportFilter: false,
    travelNewValidVisaForEmployee: false,
    travelVisaModalOpen: false,
    travelVisaAndPassportTypeSelect: null,
    travelNewPassportButton: false,
    travelNewPassportModal: false,
    travelSelectedVisaStatus: null,
    travelSelectedFlightStatus: null,
    travelSelectedAccommodationStatus: null,
    travelVisaRequestModal: false,
    travelFlightAndHotelStatusFilter: false,
    refetchTravelList: false,
    selectedEmployee: {},
    selectedType: "",
    openEdit: false,
};
export const reducer = (state = initialTravelState, action) => {
    switch (action.type) {
        case actionTypes.AddNewTravelBtn: {
            const { addNewTravelBtn } = action.payload;
            return { ...state, addNewTravelBtn };
        }
        case actionTypes.TravelFilter: {
            const { travelFilter } = action.payload;
            return { ...state, travelFilter };
        }
        case actionTypes.TravelSelectedEmployee: {
            const { travelSelectedEmployee } = action.payload;
            return { ...state, travelSelectedEmployee };
        }
        case actionTypes.TravelerFilter: {
            const { travelerFilter } = action.payload;
            return { ...state, travelerFilter };
        }
        case actionTypes.TravelDateListButton: {
            const { travelDateListButton } = action.payload;
            return { ...state, travelDateListButton };
        }
        case actionTypes.TravelGridButton: {
            const { travelGridButton } = action.payload;
            return { ...state, travelGridButton };
        }
        case actionTypes.TravelSelectDate: {
            const { travelSelectedDate } = action.payload;
            return { ...state, travelSelectedDate };
        }
        case actionTypes.TravelEmployee: {
            const { travelEmployee } = action.payload;
            return { ...state, travelEmployee };
        }
        case actionTypes.TravelUnits: {
            const { travelUnits } = action.payload;
            return { ...state, travelUnits };
        }
        case actionTypes.TravelSelectedStatus: {
            const { travelSelectedStatus } = action.payload;
            return { ...state, travelSelectedStatus };
        }
        case actionTypes.TravelerName: {
            const { travelerName } = action.payload;
            return { ...state, travelerName };
        }
        case actionTypes.TravelCountries: {
            const { travelCountries } = action.payload;
            return { ...state, travelCountries };
        }
        case actionTypes.TravelCity: {
            const { travelCity } = action.payload;
            return { ...state, travelCity };
        }
        case actionTypes.TravelShowCountryFilter: {
            const { showTravelCountryFilter } = action.payload;
            return { ...state, showTravelCountryFilter };
        }
        case actionTypes.TravelCityInCountry: {
            const { travelCityInCountry } = action.payload;
            return { ...state, travelCityInCountry };
        }
        case actionTypes.TravelVisaAndPassportFilter: {
            const { travelVisaAndPassportFilter } = action.payload;
            return { ...state, travelVisaAndPassportFilter };
        }
        case actionTypes.TravelNewValidVisaForEmployee: {
            const { travelNewValidVisaForEmployee } = action.payload;
            return { ...state, travelNewValidVisaForEmployee };
        }
        case actionTypes.TravelVisaModalOpen: {
            const { travelVisaModalOpen } = action.payload;
            return { ...state, travelVisaModalOpen };
        }
        case actionTypes.TravelVisaAndPassportTypeSelect: {
            const { travelVisaAndPassportTypeSelect } = action.payload;
            return { ...state, travelVisaAndPassportTypeSelect };
        }
        case actionTypes.TravelNewPassportButton: {
            const { travelNewPassportButton } = action.payload;
            return { ...state, travelNewPassportButton };
        }
        case actionTypes.TravelNewPassportModal: {
            const { travelNewPassportModal } = action.payload;
            return { ...state, travelNewPassportModal };
        }
        case actionTypes.TravelVisaStatusSelectFilter: {
            const { travelSelectedVisaStatus } = action.payload;
            return { ...state, travelSelectedVisaStatus };
        }
        case actionTypes.TravelFlightStatusSelect: {
            const { travelSelectedFlightStatus } = action.payload;
            return { ...state, travelSelectedFlightStatus };
        }
        case actionTypes.TravelAccommodationStatusSelectFilter: {
            const { travelSelectedAccommodationStatus } = action.payload;
            return { ...state, travelSelectedAccommodationStatus };
        }
        case actionTypes.TravelVisaRequestModal: {
            const { travelVisaRequestModal } = action.payload;
            return { ...state, travelVisaRequestModal };
        }
        case actionTypes.TravelFlightAndHotelStatusFilter: {
            const { travelFlightAndHotelStatusFilter } = action.payload;
            return { ...state, travelFlightAndHotelStatusFilter };
        }
        case actionTypes.SelectedEmployee: {
            const { selectedEmployee } = action.payload;
            return { ...state, selectedEmployee };
        }
        case actionTypes.SelectedType: {
            const { selectedType } = action.payload;
            return { ...state, selectedType };
        }
        case actionTypes.OpenEdit: {
            const { openEdit } = action.payload;
            return { ...state, openEdit };
        }
        case actionTypes.RefetchTravelList: {
            return { ...state, refetchTravelList: !state.refetchTravelList };
        }
        default:
            return state;
    }
};

export const actions = {
    setTravelFilter: (travelFilter) => ({
        type: actionTypes.TravelFilter,
        payload: { travelFilter },
    }),
    setTravelerFilter: (travelerFilter) => ({
        type: actionTypes.TravelerFilter,
        payload: { travelerFilter },
    }),
    setNewTravelBtn: (addNewTravelBtn) => ({
        type: actionTypes.AddNewTravelBtn,
        payload: { addNewTravelBtn },
    }),
    setTravelDateListButton: (travelDateListButton) => ({
        type: actionTypes.TravelDateListButton,
        payload: { travelDateListButton },
    }),
    setTravelGridButton: (travelGridButton) => ({
        type: actionTypes.TravelGridButton,
        payload: { travelGridButton },
    }),
    setTravelSelectDate: (travelSelectedDate) => ({
        type: actionTypes.TravelSelectDate,
        payload: { travelSelectedDate },
    }),
    setTravelEmployee: (travelEmployee) => ({
        type: actionTypes.TravelEmployee,
        payload: { travelEmployee },
    }),
    setTravelUnits: (travelUnits) => ({
        type: actionTypes.TravelUnits,
        payload: { travelUnits },
    }),
    setTravelSelectedStatus: (travelSelectedStatus) => ({
        type: actionTypes.TravelSelectedStatus,
        payload: { travelSelectedStatus },
    }),
    setTravelCountries: (travelCountries) => ({
        type: actionTypes.TravelCountries,
        payload: { travelCountries },
    }),
    setTravelerName: (travelerName) => ({
        type: actionTypes.TravelerName,
        payload: { travelerName },
    }),
    setTravelCity: (travelCity) => ({
        type: actionTypes.TravelCity,
        payload: { travelCity },
    }),
    setShowTravelCountryFilter: (showTravelCountryFilter) => ({
        type: actionTypes.TravelShowCountryFilter,
        payload: { showTravelCountryFilter },
    }),
    setTravelCityInCountry: (travelCityInCountry) => ({
        type: actionTypes.TravelCityInCountry,
        payload: { travelCityInCountry },
    }),
    setTravelVisaAndPassportFilter: (travelVisaAndPassportFilter) => ({
        type: actionTypes.TravelVisaAndPassportFilter,
        payload: { travelVisaAndPassportFilter },
    }),
    setTravelNewValidVisaForEmployee: (travelNewValidVisaForEmployee) => ({
        type: actionTypes.TravelNewValidVisaForEmployee,
        payload: { travelNewValidVisaForEmployee },
    }),
    setTravelVisaModalOpen: (travelVisaModalOpen) => ({
        type: actionTypes.TravelVisaModalOpen,
        payload: { travelVisaModalOpen },
    }),
    setTravelVisaAndPassportTypeSelect: (travelVisaAndPassportTypeSelect) => ({
        type: actionTypes.TravelVisaAndPassportTypeSelect,
        payload: { travelVisaAndPassportTypeSelect },
    }),
    setTravelNewPassportButton: (travelNewPassportButton) => ({
        type: actionTypes.TravelNewPassportButton,
        payload: { travelNewPassportButton },
    }),
    setTravelNewPassportModal: (travelNewPassportModal) => ({
        type: actionTypes.TravelNewPassportModal,
        payload: { travelNewPassportModal },
    }),
    setTravelVisStatusSelect: (travelSelectedVisaStatus) => ({
        type: actionTypes.TravelVisaStatusSelectFilter,
        payload: { travelSelectedVisaStatus },
    }),
    setTravelFlightStatusSelect: (travelSelectedFlightStatus) => ({
        type: actionTypes.TravelFlightStatusSelect,
        payload: { travelSelectedFlightStatus },
    }),
    setTravelAccommodationStatusSelect: (travelSelectedAccommodationStatus) => ({
        type: actionTypes.TravelAccommodationStatusSelectFilter,
        payload: { travelSelectedAccommodationStatus },
    }),
    setTravelVisaRequestModal: (travelVisaRequestModal) => ({
        type: actionTypes.TravelVisaRequestModal,
        payload: { travelVisaRequestModal },
    }),
    setTravelFlightAndHotelStatusFilter: (travelFlightAndHotelStatusFilter) => ({
        type: actionTypes.TravelFlightAndHotelStatusFilter,
        payload: { travelFlightAndHotelStatusFilter },
    }),
    setSelectedEmployee: (selectedEmployee) => ({
        type: actionTypes.SelectedEmployee,
        payload: { selectedEmployee },
    }),
    setSelectedType: (selectedType) => ({
        type: actionTypes.SelectedType,
        payload: { selectedType },
    }),
    setOpenEdit: (openEdit) => ({
        type: actionTypes.OpenEdit,
        payload: { openEdit },
    }),

    toggleRefetchTravelList: () => ({
        type: actionTypes.RefetchTravelList,
    }),
};
