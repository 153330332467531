import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import * as appRedux from "../../../../popleads/app/redux/appRedux";

import "../reset-flex.scss";
import Page1 from "./components/Page1";
import Page2 from "./components/Page2";
import Page3 from "./components/Page3";
import { post } from "../../../networking/RequestService";
import { getUrlQueryParam } from "../../../app/functions/util";

class EvaluationFormReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isEmpty: false,
            isError: false,

            campaignId: getUrlQueryParam("campaignId"),
            surveyId: getUrlQueryParam("surveyId"),
            profileId: getUrlQueryParam("profileId"),

            surveyCampaign: {},
            answerList: [],
            fields: {}
        };

        this.retry = this.retry.bind(this);
    }

    componentWillUnmount() {}

    componentDidMount() {
        this.getAnswerList();
        this.getCampaign();
        this.getFields();
    }

    retry() {
        this.getAnswerList();
        this.getCampaign();
        this.getFields();
    }

    getAnswerList() {
        this.setState({ isLoading: true });

        const requestObj = {
            fn: "getSurveyQuestionReport",
            campaignId: this.state.campaignId,
            surveyId: this.state.surveyId,
            profileId: this.state.profileId
        };

        post(requestObj)
            .then(({ data: { answerList } }) => {
                this.setState({
                    answerList,
                    isLoading: false,
                    isEmpty: false,
                    isError: false
                });
            })
            .catch(err => {
                this.setState({
                    isError: true
                });
            });
    }

    getFields() {
        this.setState({ isLoading: true });

        const requestObj = {
            fn: "getCampaignDynamicSchemaAllFields",
            module: "data management"
        };

        post(requestObj)
            .then(({ data: { dynamicSchemaFields } }) => {
                this.setState({
                    fields: JSON.parse(dynamicSchemaFields.find(obj => obj.fieldName === "Survey Fields").fieldType),
                    isLoading: false,
                    isEmpty: false,
                    isError: false
                });
            })
            .catch(err => {
                this.setState({
                    isError: true
                });
            });
    }

    getCampaign() {
        post({
            fn: "getSurveyCampaignById",
            campaignId: this.state.campaignId,
            surveyId: this.state.surveyId
        })
            .then(({ data: { surveyCampaign } }) => {
                this.setState({
                    surveyCampaign,
                    isLoading: false,
                    isEmpty: false,
                    isError: false
                });
            })
            .catch(err => {
                this.setState({
                    isError: true
                });
            });
    }

    render() {
        return (
            <div id="evaluationForm">
                <Page1 surveyCampaign={this.state.surveyCampaign} />

                <Page2 surveyCampaign={this.state.surveyCampaign} />

                <Page3
                    surveyCampaign={this.state.surveyCampaign}
                    fields={this.state.fields?.data}
                    answerList={this.state.answerList}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    ...appRedux.actions
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EvaluationFormReport));
