import React, { useState, useEffect } from "react";
import moment from "moment";
import * as util from "../../../../../../app/functions/util";

import styles from "../Page6.module.scss";
import commonStyles from "../../../final-report-common.module.scss";
import { post } from "../../../../../../networking/RequestService";

const MostSessionTrainers = ({ startDate, endDate, unitId }) => {
    const [trainersData, setTrainersData] = useState();
    const [notLoadedImages, setNotLoadedImages] = useState([]);

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "getFinalReportsMostSessionsByTrainers",
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            };

            if (unitId) requestObj["unitId"] = unitId;

            post(requestObj)
                .then(({ data }) => {
                    const arr = [...data?.trainers];
                    arr.sort((a, b) => b.sessionCount - a.sessionCount);
                    setTrainersData(arr);
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`${commonStyles.pageTitle} mb-8`}>MOST SESSIONS DELIVERED BY TRAINERS</div>
            <div className={styles.gridContainer}>
                {trainersData && trainersData.length > 0 ? (
                    trainersData.map(item => (
                        <div className={styles.trainersContainer} key={item?.trainerName}>
                            {item?.trainerImageUrl && !notLoadedImages.includes(item?.trainerImageUrl) ? (
                                <div className={styles.cardTableImg}>
                                    <img
                                        src={item.trainerImageUrl}
                                        alt="trainer"
                                        onError={() => setNotLoadedImages([...notLoadedImages, item?.trainerImageUrl])}
                                    />
                                </div>
                            ) : (
                                <div className={styles.fallbackNameTag}>{util.nameTag(item?.trainerName)}</div>
                            )}
                            <div className={styles.sessionsInfo}>
                                <div className={styles.title}>{item?.trainerName}</div>
                                <div className={styles.subTitle}>{item?.sessionCount !== 0 && item?.sessionCount === 1 ? `${item.sessionCount} session` : `${item.sessionCount} sessions`}</div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div>There is no data available.</div>
                )}
            </div>
        </>
    );
};

export default MostSessionTrainers;
