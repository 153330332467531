import React, { useState, useImperativeHandle, useEffect } from "react";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { connect } from "react-redux";
import { customDateRanges } from "app/popleads/app/functions/util";

const CommonDateFilter = React.forwardRef((props, ref) => {
    const [date, setDate] = useState([
        {
            startDate: props.dateObject
                ? props.dateObject.startDate
                    ? moment(props.dateObject.startDate).toDate()
                    : null
                : moment()
                      .startOf("year")
                      .toDate(),
            endDate: props.dateObject
                ? props.dateObject.endDate
                    ? moment(props.dateObject.endDate).toDate()
                    : null
                : moment()
                      .endOf("year")
                      .toDate(),
            key: "selection",
        },
    ]);

    useImperativeHandle(
        ref,
        () => ({
            resetDates: () =>
                setDate([
                    {
                        startDate: moment()
                            .startOf("year")
                            .toDate(),
                        endDate: moment()
                            .endOf("year")
                            .toDate(),
                        key: "selection",
                    },
                ]),
            setSelectedDates: (dates) =>
                setDate([
                    {
                        startDate: moment(dates.startDate).toDate(),
                        endDate: moment(dates.endDate).toDate(),
                        key: "selection",
                    },
                ]),
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.dealsDate]
    );
    useEffect(() => {
        if (props.travelFilter) {
            const range = document.querySelector(".rdrStaticRanges")?.firstElementChild;
            if (range && !props.dateObject.startDate && !props.dateObject.endDate) {
                range.classList.add("rdrStaticRangeSelected");
            } else if (props.dateObject.startDate && props.dateObject.endDate) {
                range.classList.remove("rdrStaticRangeSelected");
            }
        }
    }, []);

    return (
        <>
            <div className="custom-date">
                <div className="filterbar-heading">Date</div>
                <div className="mt-2 mb-2">
                    <div className="deal-datepicker-custumzie">
                        <span id="dateFilterContainer">
                            <DateRangePicker
                                ranges={date}
                                staticRanges={customDateRanges()}
                                className={props.className}
                                onChange={(item) => {
                                    props.setSelectedDateObject({
                                        startDate: item.selection.startDate
                                            ? moment(item.selection.startDate)
                                                  .startOf("days")
                                                  .valueOf()
                                            : null,
                                        endDate: item.selection.endDate
                                            ? moment(item.selection.endDate)
                                                  .endOf("days")
                                                  .valueOf()
                                            : null,
                                    });
                                    setDate([item.selection]);
                                    const range = document.querySelector(".rdrStaticRanges")?.firstElementChild;
                                    if (range && !item.selection.startDate && !item.selection.endDate) {
                                        range.classList.add("rdrStaticRangeSelected");
                                    } else {
                                        range.classList.remove("rdrStaticRangeSelected");
                                    }
                                }}
                                inputRanges={[]}
                                direction="vertical"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
});

const mapStateToProps = (state) => {
    return {
        // date: state.deals.dealsSelectedDate,
        // startDate:state.deals.dealsSelectedDate.startDate,
        // endDate:state.deals.dealsSelectedDate.endDate,
    };
};

const mapDispatchToProps = {
    // ...dealsRedux.actions
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CommonDateFilter);
