import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../_helpers";

export function Logo() {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            brandClasses: uiService.getClasses("brand", true),
            asideSelfMinimizeToggle: objectPath.get(
                uiService.config,
                "aside.self.minimize.toggle"
            ),
            headerLogo: uiService.getLogo(),
            headerStickyLogo: uiService.getStickyLogo()
        };
    }, [uiService]);

    return (
        <Link to="" className="brand-logo">
            <div>
                <SVG
                    alt="logo"
                    stroke="#fcfcfc"
                    src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/popleads-logo-collapsed.svg"
                    )}
                />
            </div>
            <div>
                <SVG
                    className="leads-text"
                    alt="logo"
                    stroke="#fcfcfc"
                    src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/popleads-logo-leads-text-only.svg"
                    )}
                />
            </div>
        </Link>
    );
}
