import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { CustomToggleIcon, saveSortingPreferences } from "../utils";
import { QUICK_ACTIONS_SORT_OPTIONS } from "../constants";
import { TooltipOverlay } from "../../../components/TooltipOverlay";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import useIsLessThan from "../../../hooks/useIsLessThan";

const SortDropdown = ({ orderBy, setOrderBy }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const { user } = useSelector(state => state.auth);

    const isLessThanBsXl = useIsLessThan("bootstrap-xl");

    const handleSelect = item => {
        setIsDropdownOpen(true);

        const newOrder = {
            column: item.value,
            type: item.value === orderBy.column && orderBy.type === "asc" ? "desc" : "asc"
        };

        setOrderBy(newOrder);
        saveSortingPreferences(user, newOrder);
    };

    return (
        <Dropdown show={isDropdownOpen} onToggle={isOpen => setIsDropdownOpen(isOpen)}>
            <Dropdown.Toggle as={CustomToggleIcon} id="dropdown-custom-components">
                <TooltipOverlay placement="bottom" tooltip={"Sort By"}>
                    <Button
                        variant={orderBy.column ? "secondary" : "outline-secondary"}
                        className="ml-sm-4 customBootstrapBtn">
                        <SVG src={toAbsoluteUrl("/media/svg/popleads/sort_icon.svg")} />
                    </Button>
                </TooltipOverlay>
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="my-1 py-2 dropdown-menu"
                style={isLessThanBsXl ? { width: "150px" } : { minWidth: "180px" }}>
                <>
                    <Dropdown.Item
                        eventKey={"Disable Sorting"}
                        onSelect={() => {
                            const newOrder = { column: "", type: "" };
                            setOrderBy(newOrder);
                            saveSortingPreferences(user, newOrder);
                        }}
                        className="p-2">
                        <div className="font-size-xs secondary-color ml-auto font-weight-bold">Disable Sorting</div>
                    </Dropdown.Item>
                    {QUICK_ACTIONS_SORT_OPTIONS.map((item, index) => (
                        <Dropdown.Item
                            key={index}
                            eventKey={item.value}
                            onSelect={() => handleSelect(item)}
                            className={orderBy.column === item.value ? "py-0 px-2" : "p-2"}>
                            <div
                                className={`sorting-dropdown-item ${orderBy.column === item.value ? "active" : ""}`}
                                style={{ backgroundColor: orderBy.column === item.value ? "#6490BF" : "" }}>
                                <div className="font-size-xs">{item.label}</div>
                                {orderBy.column === item.value && (
                                    <SVG
                                        src={toAbsoluteUrl(
                                            `/media/svg/icons/General/chevron-${
                                                orderBy.type === "asc" ? "up" : "down"
                                            }.svg`
                                        )}
                                    />
                                )}
                            </div>
                        </Dropdown.Item>
                    ))}
                </>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default SortDropdown;
