import React from "react";
import commonStyles from "../../final-report-common.module.scss";
import { FormattedNumber } from "react-intl";
import TopClients from "./TopClients";
import TopSellers from "./TopSellers";

const DealSellersandClients = ({ last3DealsData }) => {
    console.log(last3DealsData?.items?.topSellers, "asd");
    return (
        <div>
            {last3DealsData?.totalAmount === 0 && (
                <>
                    <div className="d-flex flex-column">
                        <h3 className={commonStyles.dealPrimaryTitle}>Total Amount</h3>
                        <h2 className={`${commonStyles.dealPrimaryValue} ${commonStyles.primaryPinkText}`}>
                            0
                        </h2>
                        <div className={`${commonStyles.horizontalProjectLine} ${commonStyles.primaryPinkBg}`} />
                    </div>
                </>
            )}
            {last3DealsData?.totalAmount > 0 && (
                <div className={commonStyles.dealSellersandClientsContainer}>
                    <div className="d-flex flex-column align-items-start">
                        <div className="d-flex flex-column align-items-center">
                            <h3 className={commonStyles.dealPrimaryTitle}>TOTAL AMOUNT</h3>
                            <h2
                                className={`${commonStyles.dealPrimaryValue} ${last3DealsData?.totalAmount === 0
                                    ? commonStyles.primaryPinkText
                                    : commonStyles.primaryGreenText
                                    }`}>
                                {"$ "}
                                {
                                    !!last3DealsData?.totalAmount ? <FormattedNumber value={last3DealsData?.totalAmount} /> : 0
                                }
                            </h2>
                            <div
                                className={`${commonStyles.horizontalProjectLine} ${last3DealsData?.totalAmount === 0
                                    ? commonStyles.primaryPinkBg
                                    : commonStyles.primaryGreenBg
                                    }`}
                            />
                        </div>
                        <div className={commonStyles.marginTop}>
                            <TopSellers last3DealsData={last3DealsData} />
                        </div>
                    </div>

                    <div className="d-flex flex-column align-items-start">
                        <div className="d-flex flex-column">
                            <h3 className={commonStyles.dealPrimaryTitle}># OF PROJECTS</h3>
                            <h2 className={`${commonStyles.dealPrimaryValue} ${commonStyles.primaryPinkText}`}>
                                {
                                    last3DealsData.numberOfProject ? <FormattedNumber value={last3DealsData?.numberOfProject} /> : 0
                                }
                            </h2>
                            <div className={`${commonStyles.horizontalProjectLine} ${commonStyles.primaryPinkBg}`} />
                        </div>
                        <div className={commonStyles.marginTop}>
                            <TopClients last3DealsData={last3DealsData} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DealSellersandClients;
