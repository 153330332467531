import moment from "moment";

export const actionTypes = {
    GridOrTable: "[Action] Grid or Table",
    GridOrTableFilter: "[Action] Grid or Table Filter",
    SaveSelectedAccount: "[Action] Save Selected Account",
    SelectType: "[Action] Select Type",
    SelectIndustry: "[Action] Select Industry",
    Search: "[Action] Search",
    IsAccountEditButtonExist: "[Action] Is Edit Button",
    IsAccountMergeButtonExist: "[Action] Is Merge Button",
    ToggleAccountAddButton: "[Action] Toggle Add Button",
    ToggleAccountEditButton: "[Action] Toggle Edit Button",
    ToggleAccountMergeButton: "[Action] Toggle Merge Button",
    SetMergeList: "[Action] Set Merge List",
    SetMergePopup: "[Action] Set Merge Popup",
    IsMergedAccount: "[Action] Set Is Merged Account",
    ShowAccountListActions: "[Action] Show Account List Action Buttons",
    ShowAccountDetailActions: "[Action] Show Account Detail Action Buttons",
    ShowOfficialAccountsActions: "[Action] Show Official Accounts Action Buttons",
    ShowPartnerAccountsActions: "[Action] Show Partner Accounts Action Buttons",
    SetSelectedDate: "[Action] Set Selected Date",
    TogglePartnerAccountAddButton: "[Action] Toggle Partner Add Button",
    SetPartnerSelectedDate: "[Action] Set Partner Selected Date",
};

const initialReportsState = {
    gridOrTable: "grid",
    gridOrTableFilter: undefined,
    selectedAccount: undefined,
    selectedType: { value: "All Types", label: "All Types" },
    selectedIndustry: { value: 0, label: "All Industries" },
    searchInput: undefined,
    isAccountEditButton: false,
    isAccountMergeButton: false,
    isOpenAccountAddButton: false,
    isOpenAccountEditButton: false,
    isOpenAccountMergeButton: false,
    isOpenPartnerAccountAddButton: false,
    mergeList: [],
    mergePopup: "list",
    isMergedAccount: false,
    showAccountListActions: false,
    showAccountDetailActions: false,
    showOfficialAccountsActions: false,
    showPartnerAccountsActions: false,
    selectedDate: {
        startDate: moment()
            .startOf("year")
            .toDate(),
        endDate: moment()
            .endOf("year")
            .toDate(),
        key: "selection",
        predefinedTag: "thisYear",
        dateString: "This Year",
    },
    partnerSelectedDate: {
        startDate: moment()
            .startOf("year")
            .toDate(),
        endDate: moment()
            .endOf("year")
            .toDate(),
        key: "selection",
        predefinedTag: "thisYear",
        dateString: "This Year",
    },
};

export const reducer = (state = initialReportsState, action) => {
    switch (action.type) {
        case actionTypes.GridOrTable: {
            const { gridOrTable } = action.payload;
            return { ...state, gridOrTable };
        }

        case actionTypes.GridOrTableFilter: {
            const { gridOrTableFilter } = action.payload;
            return { ...state, gridOrTableFilter };
        }

        case actionTypes.SaveSelectedAccount: {
            const { selectedAccount } = action.payload;
            return { ...state, selectedAccount };
        }

        case actionTypes.SelectType: {
            const { selectedType } = action.payload;
            return { ...state, selectedType };
        }

        case actionTypes.SelectIndustry: {
            const { selectedIndustry } = action.payload;
            return { ...state, selectedIndustry };
        }

        case actionTypes.Search: {
            const { searchInput } = action.payload;
            return { ...state, searchInput };
        }

        case actionTypes.IsAccountEditButtonExist: {
            const { isAccountEditButton } = action.payload;
            return { ...state, isAccountEditButton };
        }

        case actionTypes.IsAccountMergeButtonExist: {
            const { isAccountMergeButton } = action.payload;
            return { ...state, isAccountMergeButton };
        }

        case actionTypes.ToggleAccountAddButton: {
            const { isOpenAccountAddButton } = action.payload;
            return { ...state, isOpenAccountAddButton };
        }

        case actionTypes.TogglePartnerAccountAddButton: {
            const { isOpenPartnerAccountAddButton } = action.payload;
            return { ...state, isOpenPartnerAccountAddButton };
        }

        case actionTypes.ToggleAccountEditButton: {
            const { isOpenAccountEditButton } = action.payload;
            return { ...state, isOpenAccountEditButton };
        }

        case actionTypes.ToggleAccountMergeButton: {
            const { isOpenAccountMergeButton } = action.payload;
            return { ...state, isOpenAccountMergeButton };
        }

        case actionTypes.SetMergeList: {
            const { mergeList } = action.payload;
            return { ...state, mergeList };
        }

        case actionTypes.SetMergePopup: {
            const { mergePopup } = action.payload;
            return { ...state, mergePopup };
        }

        case actionTypes.IsMergedAccount: {
            const { isMergedAccount } = action.payload;
            return { ...state, isMergedAccount };
        }

        case actionTypes.ShowAccountListActions: {
            const { showAccountListActions } = action.payload;
            return { ...state, showAccountListActions };
        }

        case actionTypes.ShowAccountDetailActions: {
            const { showAccountDetailActions } = action.payload;
            return { ...state, showAccountDetailActions };
        }

        case actionTypes.ShowOfficialAccountsActions: {
            const { showOfficialAccountsActions } = action.payload;
            return { ...state, showOfficialAccountsActions };
        }

        case actionTypes.ShowPartnerAccountsActions: {
            const { showPartnerAccountsActions } = action.payload;
            return { ...state, showPartnerAccountsActions };
        }

        case actionTypes.SetSelectedDate: {
            const { selectedDate } = action.payload;
            return { ...state, selectedDate };
        }

        case actionTypes.SetPartnerSelectedDate: {
            const { partnerSelectedDate } = action.payload;
            return { ...state, partnerSelectedDate };
        }

        default:
            return state;
    }
};

export const actions = {
    setGridOrTable: (gridOrTable) => ({
        type: actionTypes.GridOrTable,
        payload: { gridOrTable },
    }),

    setGridOrTableFilter: (gridOrTableFilter) => ({
        type: actionTypes.GridOrTableFilter,
        payload: { gridOrTableFilter },
    }),

    selectType: (selectedType) => ({
        type: actionTypes.SelectType,
        payload: { selectedType },
    }),

    selectIndustry: (selectedIndustry) => ({
        type: actionTypes.SelectIndustry,
        payload: { selectedIndustry },
    }),

    search: (searchInput) => ({
        type: actionTypes.Search,
        payload: { searchInput },
    }),

    saveSelectedAccount: (selectedAccount) => ({
        type: actionTypes.SaveSelectedAccount,
        payload: { selectedAccount },
    }),

    isAccountEditButtonExist: (isAccountEditButton) => ({
        type: actionTypes.IsAccountEditButtonExist,
        payload: { isAccountEditButton },
    }),

    isAccountMergeButtonExist: (isAccountMergeButton) => ({
        type: actionTypes.IsAccountMergeButtonExist,
        payload: { isAccountMergeButton },
    }),

    toggleAccountAddButton: (isOpenAccountAddButton) => ({
        type: actionTypes.ToggleAccountAddButton,
        payload: { isOpenAccountAddButton },
    }),

    togglePartnerAccountAddButton: (isOpenPartnerAccountAddButton) => ({
        type: actionTypes.TogglePartnerAccountAddButton,
        payload: { isOpenPartnerAccountAddButton },
    }),

    toggleAccountEditButton: (isOpenAccountEditButton) => ({
        type: actionTypes.ToggleAccountEditButton,
        payload: { isOpenAccountEditButton },
    }),

    toggleAccountMergeButton: (isOpenAccountMergeButton) => ({
        type: actionTypes.ToggleAccountMergeButton,
        payload: { isOpenAccountMergeButton },
    }),

    setMergeList: (mergeList) => ({
        type: actionTypes.SetMergeList,
        payload: { mergeList },
    }),

    setMergePopup: (mergePopup) => ({
        type: actionTypes.SetMergePopup,
        payload: { mergePopup },
    }),

    setIsMergedAccount: (isMergedAccount) => ({
        type: actionTypes.IsMergedAccount,
        payload: { isMergedAccount },
    }),

    setShowAccountListActions: (showAccountListActions) => ({
        type: actionTypes.ShowAccountListActions,
        payload: { showAccountListActions },
    }),

    setShowAccountDetailActions: (showAccountDetailActions) => ({
        type: actionTypes.ShowAccountDetailActions,
        payload: { showAccountDetailActions },
    }),

    setShowOfficialAccountsActions: (showOfficialAccountsActions) => ({
        type: actionTypes.ShowOfficialAccountsActions,
        payload: { showOfficialAccountsActions },
    }),

    setShowPartnerAccountsActions: (showPartnerAccountsActions) => ({
        type: actionTypes.ShowPartnerAccountsActions,
        payload: { showPartnerAccountsActions },
    }),

    setSelectedDate: (selectedDate) => ({
        type: actionTypes.SetSelectedDate,
        payload: { selectedDate },
    }),

    setPartnerSelectedDate: (partnerSelectedDate) => ({
        type: actionTypes.SetPartnerSelectedDate,
        payload: { partnerSelectedDate },
    }),
};
