import React from 'react'
import { connect } from "react-redux";
import RadioButtons from '../../../../components/RadioButtons'
import * as dealsRedux from "../../../../modules/deals/redux/dealsRedux";
import { dealStatusOptions } from '../../../deals/constants';


const DealStatus = (props) => {
    const handleSelect = selectedOption => {
        props.setDealsSelectedStatus(selectedOption.value);
    };

    return (
        <div className="d-flex flex-column mt-4 mb-6">
            <div className="filterbar-heading d-flex justify-content-between">
            <div>Deal Status</div>
                    <div className=" text-muted cursor-pointer " onClick={() => props.setDealsSelectedStatus(undefined)}>
                        {props.dealsSelectedStatus ? "X" : ""}
                    </div>
            </div>
            <div className="mt-2" style={{ maxWidth: "425px" }}>
                <RadioButtons
                    options={dealStatusOptions}
                     onChange={handleSelect}
                     currentSelected={props.dealsSelectedStatus}

                    bgColorApplied
                />
            </div>
        </div>

    )
}

const mapStateToProps = state => {
    return {
        dealsSelectedStatus: state.deals.dealsSelectedStatus
    };
};

const mapDispatchToProps = {
    ...dealsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealStatus);