import React from "react";
import { Dropdown } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { CustomToggleIcon } from "../utils";
import { TooltipOverlay } from "../../../components/TooltipOverlay";
import { Button } from "react-bootstrap";
import * as activitiesRedux from "../redux/activitiesRedux";
import { connect } from "react-redux";
import Select from "../../../app/partials/Select";
import useIsLessThan from "../../../hooks/useIsLessThan";

const FiterByAssigneeDropdown = ({
    companyUsersAndGroupsData,
    filterByAssignee,
    setFilterByAssignee,
    setSelectedFilterByOptions,
}) => {
    const options = companyUsersAndGroupsData.map((user) => ({ label: user.name, value: user.id }));
    const isLessThanBsXl = useIsLessThan("bootstrap-md");

    return (
        <Dropdown>
            <Dropdown.Toggle as={CustomToggleIcon} id="dropdown-custom-components">
                <TooltipOverlay placement="bottom" tooltip={"Filter By Assignee"}>
                    <Button
                        variant={filterByAssignee?.length > 0 ? "secondary" : "outline-secondary"}
                        className="ml-1 ml-sm-4 customBootstrapBtn"
                    >
                        <SVG src={toAbsoluteUrl("/media/svg/popleads/user-check.svg")} />
                    </Button>
                </TooltipOverlay>
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="my-1 px-3 py-2 dropdown-menu"
                style={isLessThanBsXl ? { width: "250px" } : { minWidth: "350px" }}
            >
                <Select
                    className="activitySelectInput"
                    placeholder="Filter by Assignee"
                    options={options}
                    onChange={(assignees) => {
                        setFilterByAssignee(assignees);
                        if (assignees?.length > 0) setSelectedFilterByOptions([]);
                    }}
                    value={filterByAssignee}
                    isMulti
                />
            </Dropdown.Menu>
        </Dropdown>
    );
};

const mapStateToProps = (state) => {
    return {
        filterByAssignee: state.activities.filterByAssignee,
        companyUsersAndGroupsData: state.activities.companyUsersAndGroupsData,
    };
};

const mapDispatchToProps = {
    ...activitiesRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiterByAssigneeDropdown);
