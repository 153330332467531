import React from "react";
import { getUrlQueryParam } from "../../../../../app/functions/util";

import MostSessionTrainers from "./components/MostSessionTrainers";
import PlatformInfo from "./components/PlatformInfo";
import FinalReportHeader from "../common/FinalReportHeader";

import commonStyles from "../../final-report-common.module.scss";
import DealSellersandClients from "../common/DealSellersandClients";

const Page6 = ({ platformImages, last3TopSellersCount, last3DealsData }) => {
    const startDate = getUrlQueryParam("startDate");
    const endDate = getUrlQueryParam("endDate");
    const unitId = getUrlQueryParam("unitId")?.split(",");

    return (
        <div className={commonStyles.page}>
            <FinalReportHeader />
            <div className={commonStyles.content}>
                {!!last3TopSellersCount && last3TopSellersCount >= 6 && (
                    <>
                        <h2 className={`${commonStyles.pageTitle} text-center mb-16`}>
                            DELIVERED COACHING PROJECTS
                        </h2>
                        <DealSellersandClients last3DealsData={last3DealsData?.Coaching} />
                    </>
                )}
                <div className="mb-16">
                    <MostSessionTrainers startDate={startDate} endDate={endDate} unitId={unitId} />
                </div>
                <div className="mb-16">
                    <PlatformInfo
                        startDate={startDate}
                        endDate={endDate}
                        unitId={unitId}
                        platformImages={platformImages}
                    />
                </div>
            </div>
        </div>
    );
};

export default Page6;
