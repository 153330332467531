import React, { useState, useEffect } from "react";
import styles from "../Page3.module.scss";
import commonStyles from "../../../final-report-common.module.scss";
import { Fragment } from "react";
import { post } from "../../../../../../networking/RequestService";
import CompWithPrevious from "../../common/CompWithPrevious";
import moment from "moment";
import { FormattedMessage, FormattedNumber } from "react-intl";

const YoySessionCompTable = ({ startDate, endDate, unitId }) => {
    const [tableData, setTableData] = useState();

    useEffect(() => {
        let requestObj = {
            fn: "getYoySessionComprasion",
            startDate: startDate,
            endDate: endDate
        };
        if (unitId) requestObj["unitId"] = unitId;

        post(requestObj).then(({ data }) => {
            const previousYear = {
                id: 1,
                year: moment(startDate)
                    .subtract(1, "year")
                    .format("YYYY"),
                public: data?.lastYearPublicCount,
                delegates: data?.lastYearPublicInhouseDelegateCount,
                inhouse: data?.lastYearInhouseCount,
                averageDelegate: parseFloat(data?.lastYearDelegateAvr || 0)
            };
            const currentYear = {
                id: 2,
                year: moment(startDate).format("YYYY"),
                public: data?.currentYearPublicCount,
                delegates: data?.publicInhouseDelegateCount,
                inhouse: data?.currentYearInhouseCount,
                averageDelegate: parseFloat(data?.currentDelegateAvr || 0)
            };
            const compAmount = parseFloat(data?.currentVsLastYearDelegateComparision || 0);

            const comparison = {
                amount: compAmount,
                formattedAmount: new Intl.NumberFormat("en", { notation: "compact" }).format(Math.abs(compAmount)),
                isAbove: compAmount > 0,
                text: `${compAmount > 0 ? "Above" : "Below"} vs. Last Year`
            };
            setTableData({
                comparison,
                data: [previousYear, currentYear]
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const obj = {
    //     public: tableData?.data[1]?.public,
    //     delegates: tableData?.data[1]?.delegates,
    //     inhouse: tableData?.data[1]?.inhouse
    // };
    // const highestKey = Object.keys(obj).reduce((a, b) => (obj[a] > obj[b] ? a : b));

    return (
        <>
            {tableData ? (
                <div className="d-flex">
                    <table className={`${commonStyles.commonTable} ${styles.yoyTable} mt-5`}>
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col" className="text-center">
                                    Public
                                </th>
                                <th scope="col" className={`text-center ${styles.highestItem}`}>
                                    Delegates
                                </th>
                                <th scope="col" className="text-center">
                                    In-House
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.data.map(row => (
                                <Fragment key={row.id}>
                                    <tr>
                                        <td className={`${styles.semiBold} text-center`} width="25%">
                                            {row.year}
                                        </td>
                                        <td className="text-center" width="25%">
                                            <FormattedNumber value={row.public}/>
                                        </td>
                                        <td className={`text-center ${styles.highestItem}`} width="25%">
                                            <FormattedNumber value={row.delegates}/>

                                        </td>
                                        <td className="text-center" width="25%">
                                            <FormattedNumber value={row.inhouse}/>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className={styles.averageText} colSpan="3">
                                            Average: <FormattedNumber value={row.averageDelegate} /> delegates
                                        </td>
                                    </tr>
                                </Fragment>
                            ))}
                        </tbody>
                    </table>
                    {tableData.comparison && tableData.comparison.amount !== 0 && (
                        <CompWithPrevious
                            comparison={tableData?.comparison}
                            className="d-flex flex-column justify-content-center ml-5"
                        />
                    )}
                </div>
            ) : (
                <FormattedMessage id="POPLEADS.NA" />
            )}
        </>
    );
};
export default YoySessionCompTable;
