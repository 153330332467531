import React from "react";
import CheckRadioBtn from "./CheckRadioBtn";

/**
 * @typedef {Object} RadioButtonGroup
 * @property {Array<{value: string, label: string}>} options
 * @property { value: string } selectedOption
 * @property {(option: {value: string, label: string}) => void} setSelectedOption
 */

const SingleSelectRadios = ({ options, selectedValue, setSelectedOption, className }) => {
    const onChange = (option) => {
        if (selectedValue !== option.value) {
            setSelectedOption(option);
        } else {
            setSelectedOption({ label: "", value: "" });
        }
    };

    return (
        <div className="d-flex flex-wrap h-100 overflow-auto " style={{ columnGap: "8px" }}>
            {options?.map((option) => (
                <CheckRadioBtn
                    key={option.value}
                    option={option}
                    onChange={() => onChange(option)}
                    checked={selectedValue === option.value}
                />
            ))}
        </div>
    );
};

export default SingleSelectRadios;
