export const courseListQueryParams = {
    // status: "Published",
    isTemplate: 1,
    exportSave: 0,
    filterCampEndDateSign: "lower",
    defaultExport: 1,
    isExport: 0
};

export const campaignListQueryParams = {
    start: 1,
    rowsPerPage: 1000,
    isExport: 0,
    // orderByColumn: "basTarih",
    // orderType: "asc",
    // status: ["Planning", "Active"],
    type: ["LVT Public", "Public", "Coaching"],
    filterValuesList: JSON.stringify([
        {
            fieldName: "campaign_is_deleted",
            fieldValue: "0",
            fieldType: "calculated",
            fieldFormat: "number",
            fieldSign: "equal"
        },
        {
            fieldName: "campaign_is_for_mobile",
            fieldValue: "1_¡_3",
            fieldType: "calculated",
            fieldFormat: "number",
            fieldSign: "equal"
        }
    ])
};

export const courseStatus = [
    { value: "Published", label: "Published" },
    { value: "Drafted", label: "Draft" }
];

export const subsidizedByHRDFOptions = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" }
];

export const refundTypeOptions = [
    { label: "Partial Refund", value: "Partial Refund" },
    { label: "Full Refund", value: "Full Refund" }
];

export const booleanOptions = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" }
];

export const examPreparationOptions = [
    { label: "Exam Preparation", value: "Exam Preparation"},
    { label: "N/A", value: "N/A" }
];
