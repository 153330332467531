import React, { useState, useEffect } from "react";
import moment from "moment";
import * as util from "../../../../../../app/functions/util";

import styles from "../Page6.module.scss";
import commonStyles from "../../../final-report-common.module.scss";
import { post } from "../../../../../../networking/RequestService";

const PlatformInfo = ({ startDate, endDate, unitId, platformImages }) => {
    const [platformsData, setPlatformsData] = useState();

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "getFinalReportsMostSessionsVirtualPlatforms",
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            };

            if (unitId) requestObj["unitId"] = unitId;

            post(requestObj)
                .then(({ data }) => {
                    setPlatformsData(data?.platforms);
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={`${commonStyles.pageTitle} mb-8`}>VIRTUAL PLATFORMS USED FOR DELIVERED PROJECTS</div>
            <div className={styles.platformContainer}>
                {platformsData && platformsData.length > 0 ? (
                    platformsData.map(item => {
                        let itemImg = undefined;
                        if (platformImages) {
                            Object.keys(platformImages).forEach(key => {
                                const re = RegExp(key, "i");
                                if (re.test(item?.platformName)) itemImg = platformImages[key];
                            });
                        }
                        return (
                            <div className={styles.platformItems} key={item?.platformName}>
                                {itemImg ? (
                                    <div className={styles.cardTableImg}>
                                        <img src={itemImg} alt="platform" />
                                    </div>
                                ) : (
                                    <div className={styles.fallbackNameTag}>{util.nameTag(item?.platformName)}</div>
                                )}
                                <div className={styles.sessionsInfo}>
                                    <div className={styles.title}>{item?.platformName}</div>
                                    <div className={styles.subTitle}>{ item?.sessionCount !== 0 && item?.sessionCount === 1 ? `${item.sessionCount} session delivered` : `${item.sessionCount} sessions delivered` }</div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div>There is no data available.</div>
                )}
            </div>
        </>
    );
};

export default PlatformInfo;
