import React from "react";
import SearchFilter from "app/popleads/modules/filters/components/SearchFilter";
import PartnerFilterDrawer from "../drawers/PartnerFilterDrawer";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/popleads/modules/accounts/redux/accountsRedux";
import PartnerDateDropdown from "../components/PartnerDateDropdown";

const PartnerAccountsActions = () => {
    const { partnerSelectedDate } = useSelector((state) => state.accounts);
    const dispatch = useDispatch();
    return (
        <div className="d-flex align-items-center">
            <SearchFilter module="account" className="searchPL" />
            <PartnerDateDropdown
                selectedDate={partnerSelectedDate}
                setSelectedDate={(date) => dispatch(actions.setPartnerSelectedDate(date))}
            />
            <PartnerFilterDrawer />
        </div>
    );
};

export default PartnerAccountsActions;
