import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import SingleSelectRadios from "../../components/SingleSelectRadios";
import * as activitiesRedux from "./redux/activitiesRedux";
import * as authRedux from "../../../modules/Auth/_redux/authRedux";
import NewMultiSelectFilter from "../filters/components/NewMultiSelectFilter";
import MultiSelectRadios from "../../components/MultiSelectRadios";
import { GROUPBY_OPTIONS, GROUPBYSORT_OPTIONS, ACTIVITY_PRIORITY_OPTIONS, HIDE_ACTION_PLAN_OPTIONS } from "./constants";
import useGetTypesByModule from "../../hooks/useGetTypesByModule";
import { useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import RadioButtons from "app/popleads/components/RadioButtons";
import useDisclosure from "app/popleads/hooks/useDisclosure";
import CustomDrawer, { DrawerBody, DrawerContent, DrawerHeader } from "app/popleads/components/drawer/CustomDrawer";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { TooltipOverlay } from "app/popleads/components/TooltipOverlay";
import InlineSVG from "react-inlinesvg";
import { Button } from "react-bootstrap";
import { customDateRanges, silentlyParseJSON } from "app/popleads/app/functions/util";
import styles from "../../../popleads/assets/css/design_3.0/CustomDatePicker.module.scss";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { post, get } from "app/popleads/networking/RequestService";
import SaveFilter from "../filters/components/SaveFilter";
import SimpleModal from "../modal/simpleModal";
import { toast } from "react-toastify";

const ActivityDrawerFilters = ({
    user,
    selectedGroupByOption,
    selectedGroupBySortOption,
    filterByCreator,
    selectedActivityTypes,
    selectedTags,
    priorityFilter,
    dynamicActivityTypes,
    staticActivityTypes,
    activityDateFilter,
    setActivityDateFilter,
    viewType,
    hideActionPlan,
    setHideActionPlan,
    isActivitiesLoading,
    ...props
}) => {
    useGetTypesByModule();
    const [activityTypes, setActivityTypes] = useState([]);
    const [usersFilters, setUsersFilters] = useState([]);
    const [selectedFilterId, setSelectedFilterId] = useState(null);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [deletedFilterId, setDeletedFilterId] = useState(null);

    const creatorRef = useRef();
    const activityTypeRef = useRef();
    const tagRef = useRef();
    const datePickerRef = useRef();

    const onFilterSelected = (filter) => {
        const { id, filters } = filter;
        setSelectedFilterId(id);
        const dates = [];
        if (filters.dates[0].startDate === null || filters.dates[0].endDate === null) {
            dates.push(filters.dates[0]);
        } else {
            dates.push({
                startDate: moment(filters.dates[0].startDate).toDate(),
                endDate: moment(filters.dates[0].endDate).toDate(),
                key: filters.dates[0].key,
                predefinedTag: filters.dates[0].predefinedTag,
                dateString: filters.dates[0].dateString,
            });
        }

        props.setSelectedGroupByOption(filters.groupBy);
        setActivityDateFilter(dates);
        props.setPriorityFilter(filters.priority);
        props.setFilterByCreator(filters.creator);
        props.setSelectedActivityTypes(filters.activityTypes);
        props.setSelectedTags(filters.tags);
        setHideActionPlan(filters.hideActionPlan);
        props.setFilterByAssignee(filters.filterByAssignee);
        props.setSelectedFilterByOptions(filters.selectedFilterByOptions);
    };

    const saveNewFilter = async (name) => {
        try {
            const newId = uuidv4();
            const dates = [];
            if (activityDateFilter[0].startDate === null || activityDateFilter[0].endDate === null) {
                dates.push(activityDateFilter[0]);
            } else {
                dates.push({
                    startDate: activityDateFilter[0].startDate.valueOf(),
                    endDate: activityDateFilter[0].endDate.valueOf(),
                    key: activityDateFilter[0].key,
                    predefinedTag: activityDateFilter[0].predefinedTag,
                    dateString: activityDateFilter[0].dateString,
                });
            }

            const newFilter = {
                id: newId,
                name: name,
                createdTime: moment().valueOf(),
                filters: {
                    groupBy: selectedGroupByOption,
                    dates: dates,
                    priority: priorityFilter,
                    creator: filterByCreator,
                    activityTypes: selectedActivityTypes,
                    tags: selectedTags,
                    hideActionPlan: hideActionPlan,
                    filterByAssignee: props.filterByAssignee,
                    selectedFilterByOptions: props.selectedFilterByOptions,
                },
            };
            const reqObj = {
                fn: "setUserSettings",
                settingsKey: "activity_filters_settings",
                settingsValue: JSON.stringify([newFilter, ...usersFilters]),
            };
            await post(reqObj);
            const { data } = await get("getLoggedinUser");
            if (data.user) {
                props.setUser(data.user);
            }
            setSelectedFilterId(newId);
            toast("Filter saved successfully", { type: "success" });
        } catch (error) {
            toast("Something went wrong", { type: "error" });
        }
    };

    const deleteSelectedFilter = async () => {
        try {
            const updatedArr = usersFilters.filter((item) => item.id !== deletedFilterId);
            const reqObj = {
                fn: "setUserSettings",
                settingsKey: "activity_filters_settings",
                settingsValue: JSON.stringify(updatedArr),
            };
            await post(reqObj);
            const { data } = await get("getLoggedinUser");
            if (data.user) {
                props.setUser(data.user);
            }
            clearAllFilters();
            toast("Filter deleted successfully", { type: "success" });
        } catch (error) {
            // console.log("error: ", error);
            // toast("Something went wrong", { type: "error" });
        }
    };

    const clearAllFilters = () => {
        setSelectedFilterId(null);

        creatorRef.current.clearSearch();
        activityTypeRef.current.clearSearch();
        tagRef.current.clearSearch();

        props.setSelectedGroupByOption({
            value: "status",
            label: "Status",
        });
        setActivityDateFilter([
            {
                startDate: moment()
                    .startOf("year")
                    .toDate(),
                endDate: moment()
                    .endOf("year")
                    .toDate(),
                key: "selection",
                predefinedTag: "thisYear",
                dateString: "This Year",
            },
        ]);
        props.setPriorityFilter([]);
        props.setFilterByCreator([]);
        props.setSelectedActivityTypes([]);
        props.setSelectedTags([]);
        setHideActionPlan({
            label: "No",
            value: "0",
        });
        props.setFilterByAssignee([]);
        props.setSelectedFilterByOptions([
            {
                value: "assignedToMe",
                label: "Assigned To Me",
            },
        ]);
    };

    useEffect(() => {
        setUsersFilters(silentlyParseJSON(user.activityFilters, "[]"));
    }, [user.activityFilters]);

    useEffect(() => {
        const startDate = activityDateFilter[0].startDate
            ? activityDateFilter[0].startDate.valueOf() !==
              moment()
                  .startOf("year")
                  .toDate()
                  .valueOf()
                ? true
                : false
            : true;
        const endDate = activityDateFilter[0].endDate
            ? activityDateFilter[0].endDate.valueOf() !==
              moment()
                  .endOf("year")
                  .toDate()
                  .valueOf()
                ? true
                : false
            : true;
        if (
            selectedGroupByOption.value !== "status" ||
            startDate ||
            endDate ||
            (priorityFilter && priorityFilter.length > 0) ||
            (filterByCreator && filterByCreator.length > 0) ||
            (selectedActivityTypes && selectedActivityTypes.length > 0) ||
            (selectedTags && selectedTags.length > 0) ||
            hideActionPlan.value !== "0" ||
            (props.filterByAssignee && props.filterByAssignee.length > 0) ||
            props.selectedFilterByOptions[0]?.value !== "assignedToMe"
        ) {
            setIsFilterApplied(true);
        } else {
            setIsFilterApplied(false);
        }
    }, [
        selectedGroupByOption,
        activityDateFilter,
        priorityFilter,
        filterByCreator,
        selectedActivityTypes,
        selectedTags,
        hideActionPlan,
        props.filterByAssignee,
        props.selectedFilterByOptions,
    ]);

    useEffect(() => {
        if (datePickerRef.current) {
            const range = document.querySelector(".rdrStaticRanges")?.firstElementChild;
            if (range && !activityDateFilter[0].startDate && !activityDateFilter[0].endDate) {
                range.classList.add("rdrStaticRangeSelected");
            } else if (activityDateFilter[0].startDate && activityDateFilter[0].endDate) {
                range.classList.remove("rdrStaticRangeSelected");
            }
        }
    }, [activityDateFilter]);

    useEffect(() => {
        if (dynamicActivityTypes && staticActivityTypes)
            setActivityTypes([...dynamicActivityTypes, ...staticActivityTypes]);
    }, [dynamicActivityTypes, staticActivityTypes]);

    const { isOpen, onClose, onToggle } = useDisclosure();

    return (
        <>
            {deletedFilterId && (
                <SimpleModal
                    title=""
                    body="Are you sure you want to delete this filter?<br>"
                    className="d-flex flex-column align-items-center"
                    bodyClass="font-weight-semibold font-size-20px"
                    primaryButton="Delete"
                    secondaryButton="Cancel"
                    onClose={(resp) => {
                        if (resp === "OK") deleteSelectedFilter();
                        setDeletedFilterId(null);
                    }}
                />
            )}
            <TooltipOverlay placement="bottom" tooltip="Filters">
                <Button variant="secondary" className="ml-1 ml-sm-4 customBootstrapBtn" onClick={onToggle}>
                    <InlineSVG src={toAbsoluteUrl("/media/svg/icons/Text/Filters2.svg")} />
                </Button>
            </TooltipOverlay>
            <CustomDrawer position="right" size="28rem" open={isOpen} onClose={onClose}>
                <DrawerContent isLoading={isActivitiesLoading}>
                    <DrawerHeader onClose={onClose} sx={{ border: "none" }}>
                        Filters
                    </DrawerHeader>
                    <DrawerBody>
                        <SaveFilter
                            usersFilters={usersFilters}
                            isFilterApplied={isFilterApplied}
                            selectedFilterId={selectedFilterId}
                            onFilterSelected={onFilterSelected}
                            saveNewFilter={saveNewFilter}
                            deleteSelectedFilter={(id) => setDeletedFilterId(id)}
                            clearAllFilters={clearAllFilters}
                        />
                        {viewType !== "mindmap" && (
                            <div className="d-flex flex-column mb-2">
                                <div className="d-flex justify-content-between mb-2">
                                    <div className="filterbar-heading">Group By</div>
                                </div>
                                <span className="navi-item">
                                    <div className="navi-link">
                                        <div className="navi-text">
                                            <div className="font-weight-bold">
                                                <SingleSelectRadios
                                                    options={GROUPBY_OPTIONS}
                                                    selectedValue={selectedGroupByOption.value}
                                                    setSelectedOption={props.setSelectedGroupByOption}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        )}
                        {selectedGroupByOption?.value === "dueDate" && (
                            <div className="d-flex flex-column mb-2">
                                <div className="d-flex justify-content-between mb-2">
                                    <div className="filterbar-heading ">Sort By</div>
                                </div>
                                <SingleSelectRadios
                                    options={GROUPBYSORT_OPTIONS}
                                    selectedValue={selectedGroupBySortOption.value}
                                    setSelectedOption={props.setSelectedGroupBySortOption}
                                />
                            </div>
                        )}
                        <div className="mb-27 mb-md-8">
                            <div className="filterbar-heading  mb-2">Filter by Date</div>
                            <DateRangePicker
                                ref={datePickerRef}
                                className={styles.rdrDateRangePickerWrapper}
                                ranges={activityDateFilter}
                                staticRanges={customDateRanges()}
                                onChange={(item) => {
                                    setActivityDateFilter([item.selection]);
                                    // DOM manipulation to highlight "All Time"
                                    const range = document.querySelector(".rdrStaticRanges")?.firstElementChild;
                                    if (range && !item.selection.startDate && !item.selection.endDate) {
                                        range.classList.add("rdrStaticRangeSelected");
                                    } else {
                                        range.classList.remove("rdrStaticRangeSelected");
                                    }
                                }}
                                inputRanges={[]}
                                direction="vertical"
                            />
                        </div>
                        <div className="d-flex flex-column mb-2">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="filterbar-heading ">Filter By Priority</div>
                                {priorityFilter && priorityFilter.length > 0 && (
                                    <div
                                        className="text-muted cursor-pointer"
                                        onClick={() => props.setPriorityFilter([])}
                                    >
                                        X
                                    </div>
                                )}
                            </div>
                            <MultiSelectRadios
                                options={ACTIVITY_PRIORITY_OPTIONS}
                                selectedOptions={priorityFilter}
                                setSelectedOptions={props.setPriorityFilter}
                            />
                        </div>
                        <NewMultiSelectFilter
                            fn="companyUsers"
                            inputLabel="Filter By Creator"
                            ref={creatorRef}
                            selectedItems={filterByCreator}
                            setSelectedItems={props.setFilterByCreator}
                        />
                        <NewMultiSelectFilter
                            //fn="getTypesByModule"
                            inputLabel="Filter By Activity Type"
                            ref={activityTypeRef}
                            defaultOptions={activityTypes?.map((type) => ({ label: type, value: type }))}
                            selectedItems={selectedActivityTypes}
                            setSelectedItems={props.setSelectedActivityTypes}
                        />
                        <NewMultiSelectFilter
                            fn="getActivityTagList"
                            inputLabel="Filter By Tag"
                            ref={tagRef}
                            selectedItems={selectedTags}
                            setSelectedItems={props.setSelectedTags}
                        />
                        <div className="d-flex flex-column mt-4 mb-6">
                            <div className="filterbar-heading d-flex justify-content-between">
                                <div>Hide Action Plan</div>
                            </div>
                            <div className="mt-2" style={{ maxWidth: "425px" }}>
                                <RadioButtons
                                    options={HIDE_ACTION_PLAN_OPTIONS}
                                    currentSelected={hideActionPlan.value}
                                    onChange={setHideActionPlan}
                                    bgColorApplied
                                />
                            </div>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </CustomDrawer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        selectedGroupByOption: state.activities.selectedGroupByOption,
        selectedGroupBySortOption: state.activities.selectedGroupBySortOption,
        selectedAssignees: state.activities.selectedAssignees,
        selectedActivityTypes: state.activities.selectedActivityTypes,
        selectedTags: state.activities.selectedTags,
        filterByCreator: state.activities.filterByCreator,
        priorityFilter: state.activities.priorityFilter,
        dynamicActivityTypes: state.app.dynamicActivityTypes,
        staticActivityTypes: state.app.staticActivityTypes,
        activityDateFilter: state.activities.activityDateFilter,
        viewType: state.activities.viewType,
        hideActionPlan: state.activities.hideActionPlan,
        isActivitiesLoading: state.activities.isActivitiesLoading,
        filterByAssignee: state.activities.filterByAssignee,
        selectedFilterByOptions: state.activities.selectedFilterByOptions,
    };
};

const mapDispatchToProps = {
    ...authRedux.actions,
    ...activitiesRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDrawerFilters);
