import React, { useMemo, useState } from "react";
import AsideMenuList from "./AsideMenuList";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import SlidingMenuIndicatorProvider from "./SlidingMenuIndicator";
import { nameTag } from "app/popleads/app/functions/util";
import { useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "_metronic/_helpers";

export function AsideMenu({ disableScroll }) {
    const uiService = useHtmlClassService();
    const [showUserNameDropdown, setShowUserNameDropdown] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const history = useHistory();
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            asideMenuAttr: uiService.getAttributes("aside_menu"),
            ulClasses: uiService.getClasses("aside_menu_nav", true),
            asideClassesFromConfig: uiService.getClasses("aside_menu", true),
        };
    }, [uiService]);
    return (
        <>
            {/* begin::Menu Container */}
            <div
                id="kt_aside_menu"
                data-menu-vertical="1"
                className={`aside-menu mt-4 mb-2 position-relative ${showUserNameDropdown ? "custom-overflow" : ""}`}
                {...layoutProps.asideMenuAttr}
            >
                <SlidingMenuIndicatorProvider>
                    <AsideMenuList setShowUserNameDropdown={setShowUserNameDropdown} layoutProps={layoutProps} />
                </SlidingMenuIndicatorProvider>
            </div>

            {/* end::Menu Container */}

            <div className="d-flex flex-row">
                <div className="d-flex flex-column align-items-center align-self-end w-100 aside-menu-user">
                    {" "}
                    <div className="assignee-wrapper">
                        <span>{nameTag(user.name)}</span>
                    </div>
                    <span className="aside-menu-user-detail">
                        <span className="user-name-background font-weight-bold font-size-14px">{user.name}</span>
                        <span className="ml-2 mb-2 text-center mini-title">@{user?.company?.name}</span>
                        <div
                            className="signout"
                            onClick={() => {
                                history.push("/logout");
                            }}
                        >
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/log-out.svg")} />
                            <a className="border-0 font-weight-500 ml-3">Sign Out</a>
                        </div>
                    </span>
                </div>
            </div>
        </>
    );
}
