 
const courseImageMap = {
  Administration: "https://c.popleads.com/l3rn-online/brochure/Administration.jpg",
  "Audit, Regulation & Compliance": "https://c.popleads.com/l3rn-online/brochure/Audit-Regulation-and-Compliance.jpg",
  "Engineering & Maintenance": "https://c.popleads.com/l3rn-online/brochure/Engineering.jpg",
  "Facility Management": "https://c.popleads.com/l3rn-online/brochure/Facility-Management.jpg",
  "Finance & Accounting": "https://c.popleads.com/l3rn-online/brochure/Finance.jpg",
  "Financial Services": "https://c.popleads.com/l3rn-online/brochure/Financial-Services.jpg",
  Healthcare: "https://c.popleads.com/l3rn-online/brochure/Healthcare.jpg",
  "Human Resources": "https://c.popleads.com/l3rn-online/brochure/Human-Resources.jpg",
  "Management & Leadership": "https://c.popleads.com/l3rn-online/brochure/Management-and-Leadership.jpg",
  "Procurement & Contracts": "https://c.popleads.com/l3rn-online/brochure/Procurement-and-Contracts.jpg",
  Projects: "https://c.popleads.com/l3rn-online/brochure/Projects.jpg",
  "Quality Management": "https://c.popleads.com/l3rn-online/brochure/Quality.jpg",
  "Risk Management": "https://c.popleads.com/l3rn-online/brochure/Risk-Management.jpg",
  "Sales & Marketing": "https://c.popleads.com/l3rn-online/brochure/Sales-and-Marketing.jpg",
  "Supply Chain & Operations": "https://c.popleads.com/l3rn-online/brochure/Supply-Chain.jpg",
  "IT & Software": "https://c.popleads.com/l3rn-online/brochure/IT.jpg",
  "Technology & Development": "https://c.popleads.com/l3rn-online/course/Tech-Dev.jpg",
} 

export default function getCourseImage(knowledgeArea) {
  return courseImageMap[knowledgeArea] ? courseImageMap[knowledgeArea] : courseImageMap["Projects"]
}
