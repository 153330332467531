import React, { useState, useEffect } from "react";
import moment from "moment";

import { post } from "../../../../../../networking/RequestService";

import styles from "../Page4.module.scss";

const PercentSummary = ({ startDate, endDate, unitId, type }) => {
    const [percentageData, setPercentageData] = useState();

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "getPublicFulPriceCompanyPercent",
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            };

            if (unitId) requestObj["unitId"] = unitId;

            if (type === "Public") requestObj["campaignType"] = "public";
            if (type === "LVTPublic") requestObj["campaignType"] = "lvt public";

            post(requestObj)
                .then(({ data }) => {
                    const arr = [];
                    data?.fullPricePercent &&
                        arr.push({ title: "FULL PRICE", color: "#db406c", percent: data.fullPricePercent + "%" });
                    data?.discountPercent &&
                        arr.push({ title: "DISCOUNTED", color: "#3e4054", percent: data.discountPercent + "%" });
                    data?.selfPercent && arr.push({ title: "SELF", color: "#db406c", percent: data.selfPercent + "%" });
                    data?.companyPercent &&
                        arr.push({ title: "COMPANY", color: "#328456", percent: data.companyPercent + "%" });
                    setPercentageData(arr);
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="d-flex" style={{ gap: "56px" }}>
            {percentageData?.map(item => (
                <div key={item.title} className={styles.percentSumContainer}>
                    <div className={styles.percentGraph}>
                        <div
                            style={{ height: item.percent, backgroundColor: item.color }}
                            className={styles.itemPercent}></div>
                    </div>
                    <div>
                        <div style={{ color: item.color }} className={styles.percentRatio}>
                            {item.percent}
                        </div>
                        <div className={styles.percentCtg}>{item.title}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PercentSummary;
