import React from "react";
import { connect } from "react-redux";
import * as dealsRedux from "../../../../modules/deals/redux/dealsRedux";
import RadioButtons from "../../../../components/RadioButtons";
import { dealBooleanOptions } from "../../../deals/constants";

class DealSelfInvestmentFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: dealBooleanOptions,
             
        };
    }

    onChange = selectedOption => {
        // this.state.removeFilter ? this.props.setDealSelfInvestmentSelect("") : 
        this.props.setDealSelfInvestmentSelect(selectedOption.value);
    };

    componentDidMount() { }


    render() {
        // console.log(this.props.dealSelfInvestment,"selected");
        //  const isSelected = this.props.dealSelfInvestment === 1 || this.props.dealSelfInvestment === null || this.props.dealSelfInvestment === 0
        return (
            <div className="d-flex flex-column mt-4 mb-6">
                <div className="filterbar-heading d-flex justify-content-between">
                    <div>Self Investment</div>
                    <div className=" text-muted cursor-pointer" onClick={() => this.props.setDealSelfInvestmentSelect(undefined)}>
                        {this.props.dealSelfInvestment  ? "X" : ""}
                    </div>
                </div>
                <div className="mt-2" style={{ maxWidth: "425px" }}>
                    <RadioButtons
                        options={this.state.options}
                        currentSelected={this.state.removeFilter ? this.props.setDealSelfInvestmentSelect(undefined) : this.props.dealSelfInvestment}
                        onChange={this.onChange}
                        bgColorApplied
                        removeFilter={this.state.removeFilter}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        dealSelfInvestment: state.deals.dealSelfInvestment
    };
};

const mapDispatchToProps = {
    ...dealsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealSelfInvestmentFilter);
