import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import * as app from "../../../app/redux/appRedux";
import * as reportsRedux from "../../reports/redux/reportsRedux";
import { post } from "../../../networking/RequestService";
import * as util from "../../../app/functions/util";
import SelectInput from "../components/inputs/selectInput";

// const { ValueContainer, Placeholder } = components;
class EmployeeFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: "",
            inputValue: "",
            options: [],
        };
        this.switchCheckbox = this.switchCheckbox.bind(this);
    }

    onChange = (selectedOption) => {
        this.props.selectEmployee(selectedOption);
        this.setState({ employee: selectedOption });
        // console.log("this.props.employee:", this.props.employee)
    };

    onInputChange = (str, { action }) => {
        if (action === "set-value") return false;
        // console.log("str ", str)
        this.setState({ inputValue: str });
    };

    saveUsersToState(users) {
        const options = [];
        //options.push({ value: 0, label: "All Employees" });
        if (users) {
            for (let i = 0; i < users.length; i++) {
                const obj = { value: users[i].id, label: users[i].name };
                options.push(obj);
            }
        }
        this.setState({ options });

        /*if (!this.props.employee) {
            this.props.selectEmployee(this.state.options[0]);
        }*/
    }

    getUsers() {
        const companyUsers = {
            fn: "companyUsers",
        };
        if (this.props.department && this.props.department.length > 0) {
            companyUsers.fn = "getAllUserByUnitIds";
            companyUsers.unitId = util.getUnitIdsFilter(this.props.department);
        }
        if (this.props.isPageIdInquiry) {
            companyUsers.fn = "getIdUsers";
        }
        // if(!this.props.isMultiSelect){
        post(companyUsers).then(({ data: { users } }) => {
            this.saveUsersToState(users);
            this.props.saveCompanyUsers(users);
        });
        // }
        // return companyUsers
    }

    componentDidUpdate(prevProps) {
        if (util.isDepartmentChanged(prevProps.department, this.props.department)) {
            this.getUsers();
        }

        if (this.props.isPageIdInquiry !== prevProps.isPageIdInquiry) {
            this.getUsers();
            this.props.selectEmployee([]);
            this.setState({ employee: [] });
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    switchCheckbox() {
        this.props.setIsSelectAllEmployees(!this.props.isSelectAllEmployees);
    }

    render() {
        // const { inputValue } = this.state;
        // const isSelectAllEmployees = this.props.isSelectAllEmployees;
        return (
            <>
                {this.props.isMultiSelect ? (
                    // <Select
                    //     className="selectInputNewFilterC"
                    //     classNamePrefix="selectInputNewFilter"
                    //     options={this.state.options}
                    //     defaultValue={this.props.employee}
                    //     value={this.props.employee}
                    //     onChange={this.onChange}
                    //     closeMenuOnSelect={false}
                    //     isMulti={this.props.isMultiSelect}
                    //     isClearable={false}
                    //     components={{ Option, MultiValue, Control, Input, ValueContainer: CValueContainer }}
                    //     hideSelectedOptions={false}
                    //     backspaceRemovesValue={false}
                    //     blurInputOnSelect={false}
                    //     onInputChange={this.onInputChange}
                    //     inputValue={inputValue}
                    //     isSelectAllEmployees={isSelectAllEmployees}
                    //     setIsSelectAllEmployees={this.switchCheckbox}
                    //     allowSelectAll={true}
                    // />
                    <SelectInput
                        object={{
                            //  fn: this.getUsers().fn,
                            //  unitId: this.getUsers().unitId,
                            name: "Employee",
                            data: {
                                options: this.state.options,
                            },
                            value: this.props.employee,
                            multi: true,
                            allSelectable: true,
                            isLoading: this.props.isLoading,
                        }}
                        onChange={this.onChange}
                        onInputChange={this.onInputChange}
                        infinite={false}
                    />
                ) : (
                    <Select
                        className="selectInputNewFilter_single"
                        classNamePrefix="selectInputNewFilter"
                        options={this.state.options}
                        defaultValue={this.props.employee}
                        value={this.props.employee}
                        onChange={this.onChange}
                        closeMenuOnSelect={true}
                        isMulti={this.props.isMultiSelect}
                    />
                )}
            </>
        );
    }
}

// const Input = (props) => {
//     return (
//         <div>
//             <components.Input {...props} />
//         </div>
//     );
// };

// const Option = ({ ...props }) => {
//     return (
//         <div>
//             <components.Option {...props}>
//                 <input type="checkbox" checked={props.isSelected} onChange={(e) => null} />{" "}
//                 <label>{props.label} </label>
//             </components.Option>
//         </div>
//     );
// };

// const Control = ({ children, ...props }) => {
//     //console.log("props: ", props)
//     return (
//         <components.Control {...props}>
//             <input
//                 type="checkbox"
//                 name="employeeCheck"
//                 value="1"
//                 checked={!!props.selectProps.isSelectAllEmployees}
//                 onChange={(e) => {
//                     props.selectProps.setIsSelectAllEmployees();
//                     if (e.target.checked) props.setValue(props.options);
//                     else props.setValue([]);
//                 }}
//                 style={{ marginLeft: "10px" }}
//             />{" "}
//             {children}
//         </components.Control>
//     );
// };

// const MultiValue = ({ children, ...innerProps }) => {
//     return <></>;
// };

// const CValueContainer = ({ children, ...props }) => {
//     var placeholder = props.selectProps.placeholder;
//     return (
//         <ValueContainer {...props}>
//             {props.selectProps.inputValue === "" && (
//                 <Placeholder {...props} isFocused={props.isFocused}>
//                     {props.selectProps.placeholder}
//                 </Placeholder>
//             )}
//             {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
//         </ValueContainer>
//     );
// };

const mapStateToProps = (state) => {
    return {
        employee: state.reports.selectedEmployee,
        department: state.reports.selectedDepartment,
        companyUsers: state.app.companyUsers,
        isSelectAllEmployees: state.reports.isSelectAllEmployees,
        isPageIdInquiry: state.reports.isPageIdInquiry,
        salesPageLoading: state.reports.salesPageLoading,
    };
};

const mapDispatchToProps = {
    ...reportsRedux.actions,
    ...app.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeFilter);
