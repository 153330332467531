import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "app/popleads/components/Button";
import { actions } from "app/popleads/modules/campaigns/redux/campaignsRedux";
import { toAbsoluteUrl } from "_metronic/_helpers";
import CourseActionDropdown from "../components/CourseActionDropdown";
import Select from "app/popleads/app/partials/Select";

const CourseDetailActions = () => {
    const { user } = useSelector((state) => state.auth);
    const { isOpenCourseEditButton, language } = useSelector((state) => state.campaigns);

    const dispatch = useDispatch();

    if (user?.permissions?.CAMPAIGNTEMPLATE_MANAGER !== 1) return null;

    return (
        <div className="d-flex align-items-center">
            {!isOpenCourseEditButton ? (
                <>
                    <Button
                        variant="success"
                        icon
                        src={toAbsoluteUrl("/media/svg/icons/General/edit.svg")}
                        outline
                        onClick={() => dispatch(actions.toggleCourseEditButton(true))}
                        title="Edit"
                    />
                    <CourseActionDropdown />
                    <Button
                        icon
                        outline
                        variant="success"
                        onClick={() => dispatch(actions.setCoursesDetailExcel(true))}
                        className="excelIcon ml-2"
                        src="/media/svg/popleads/export_excel_icon.svg"
                    />
                </>
            ) : (
                <>
                    <Select
                        placeholder={"Language"}
                        className="customSelectInput--no-wrap "
                        value={language}
                        defaultValue={{ value: "en", label: "English" }}
                        isSearchable={false}
                        options={[
                            { value: "en", label: "English" },
                            { value: "ar", label: "Arabic" },
                        ]}
                        name="language"
                        onChange={(selected) => dispatch(actions.setLanguage(selected))}
                    />
                    <Button
                        className="ml-4"
                        variant="secondary"
                        outline
                        onClick={() => dispatch(actions.toggleCourseEditButton(false))}
                        title="Done"
                    />
                </>
            )}
        </div>
    );
};

export default CourseDetailActions;
