import React from "react";
import CheckRadioBtn from "./CheckRadioBtn";

/**
 * @typedef {Object} MultiSelectRadioButtonGroup
 * @property {Array<{value: string, label: string}>} options
 * @property {Array<{value: string, label: string}>} selectedOptions
 * @property {(options: Array<{value: string, label: string}>) => void} setSelectedOptions
 */

const MultiSelectRadios = ({ options, selectedOptions, setSelectedOptions }) => {
    const onChange = (clickedItem) => {
        const tempOptions = [...selectedOptions];
        if (tempOptions.find((item) => item.value === clickedItem.value)) {
            setSelectedOptions(tempOptions.filter((item) => item.value !== clickedItem.value));
        } else {
            setSelectedOptions([...tempOptions, clickedItem]);
        }
    };

    const getBeforeElement = (option) => {
        switch (option.label) {
            case "Assigned To Me":
                return <span className="d-inline-block activity-status-color-bar assigned-to-me rotate-90" />;
            case "Created By Me":
                return <span className="d-inline-block activity-status-color-bar created-by-me rotate-90" />;
            case "Followed By Me":
                return <span className="d-inline-block activity-status-color-bar followed-by-me rotate-90" />;
            case "Approved By Me":
                return <span className="d-inline-block activity-status-color-bar approved-by-me rotate-90" />;
            default:
                return "";
        }
    };

    return (
        <div
            className="d-flex flex-wrap"
            key={selectedOptions?.map((option) => option.value).join("-")}
            style={{ columnGap: "8px" }}
        >
            {options.map((option) => (
                <CheckRadioBtn
                    key={option.value}
                    option={option}
                    onChange={() => onChange(option)}
                    checked={selectedOptions?.map((item) => item.value).includes(option.value)}
                    before={getBeforeElement(option)} //optional
                />
            ))}
        </div>
    );
};

export default MultiSelectRadios;
