export const dealTypeOptions = [
    { value: "Marketing", label: "Marketing" },
    { value: "Cross-Sale", label: "Cross Sale" },
    { value: "Tender", label: "Tender" },
    { value: "E-Commerce", label: "E-Commerce" },
    { value: "Project Manager", label: "Project Manager" }
];

export const dealStatusOptions = [
    { value: 1, label: "Paid" },
    { value: "0", label: "Unpaid" }
];

export const dealBooleanOptions = [
    { value: 1, label: "Yes" },
    { value: "0", label: "No" }
];

// BELOW ARE MADE FOR ADD DEAL BUT NOT USED RIGHT NOW
export const dealStageOptions = [
    { value: "needAnalysis", label: "Need Analysis" },
    { value: "closedWon", label: "Closed Won" },
    { value: "lost", label: "Lost/Cancelled" }
];

export const campaignTypeOptions = [
    { value: "public", label: "Public", type: 1 },
    { value: "lvtPublic", label: "LVT Public", type: 1 },
    { value: "mentoring", label: "Mentoring", type: 1 },
    { value: "webinar", label: "Webinar", type: 1 },
    { value: "franchise", label: "Franchise", type: 1 },
    { value: "classroom", label: "Classroom/Online", type: 1 },
    { value: "online", label: "Online", type: 1 },
    { value: "other", label: "Other", type: 1 },
    { value: "inHouse", label: "In House", type: 2 },
    { value: "lvtHouse", label: "LVT In House", type: 2 },
    { value: "coaching", label: "Coaching", type: 2 },
    { value: "consulting", label: "Consulting", type: 2 },
    { value: "elearning", label: "E-learning", type: 2 }
];

export const splitDealsOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" }
];

// export const dealTypeOptions = [
//   {value: "yes", label: "Marketing"},
//   {value: "no", label: "Other"},
// ]
