import moment from "moment";
import React from "react";
import styles from "./Page3.module.scss";

const AnswerList = ({ answers }) => {
    return (
        <>
            {answers
                // Bu kısım biraz kafa karıştırıcı olabilir.
                // reduce array'i iki boyutlu chunk'a dönüştürüyor.

                .reduce((resultArray, item, index) => {
                    const chunkIndex = Math.floor(index / 20);

                    if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = [];
                    }

                    resultArray[chunkIndex].push(item);

                    return resultArray;
                }, [])
                .map((answerMapItem, i) => (<>
                    <div className={+i>0 ? "mt-20" : "mt-3"}>
                        <div className={`d-flex justify-content-between ${styles.answerListTitle}`}>
                            <p className={`${styles.responses} `}>Responses</p>
                            <p className={`${styles.responses} `}>Date</p>
                        </div>
                        <ol className={styles.answerList} start={(i*20)+1}>
                            {answerMapItem.map((answer, index) => {
                                return (
                                    <li key={"answer_list__" + (i+1)*index} className={"mt-5"}>
                                        <div className={`d-flex justify-content-between ${styles.answerListItem}`}>
                                            <div>{answer.text}</div>
                                            <div className="flex-shrink-0">
                                                {moment(answer.createdTime).format("DD/MM/YYYY hh:mm")}
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ol>
                    </div>
                    <div style={{pageBreakAfter:"always"}}></div>
                    </>
                ))}
        </>
    );
};

export default AnswerList;
