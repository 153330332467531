/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from "react";
import ApexCharts from "apexcharts";
import { FormattedNumber } from "react-intl";
import moment from "moment";
import { post } from "../../../../../../networking/RequestService";

import styles from "../Page3.module.scss";
import commonStyles from "../../../final-report-common.module.scss";

let colors = [
    { value: "primary-orange", label: "#DF8524" },
    { value: "primary-pink", label: "#DB406C" },
    { value: "primary-darkblue", label: "#3E4054" },
    { value: "primary-gray", label: "#777987" },
    { value: "primary-purple", label: "#A965E8" },
    { value: "primary-red", label: "#ff4949" },
    { value: "primary-yellow", label: "#ebb000" },
    { value: "secondary-green", label: "#98C1AA" },
    { value: "secondary-orange", label: "#c05300" },
    { value: "secondary-pink", label: "#FF8DC7" },
    { value: "secondary-darkblue", label: "#153462" },
    { value: "secondary-gray", label: "#B2B2B2" },
    { value: "secondary-purple", label: "#540375" },
    { value: "secondary-red", label: "#962626" },
    { value: "secondary-yellow", label: "#fff7a4" }
];

let chartOptions = {
    chart: {
        type: "donut",
        width: "350px",
        // height: "320px",
        toolbar: {
            tools: {
                download: false,
                zoomin: false,
                zoomout: false,
                reset: false,
                selection: false,
                pan: false
            }
        },
        selection: false,
        zoom: false
    },
    plotOptions: {
        pie: {
            donut: {
                size: "60%",
                labels: {
                    show: false,
                    name: { show: false }
                }
            }
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    tooltip: {
        enabled: true,
        x: {
            show: true,
            format: ""
        },
        y: {
            formatter: function(val) {
                return new Intl.NumberFormat("en").format(val.toFixed(0)) + "%";
            }
        }
    }
};

const DeliveredBreakdownTable = ({ startDate, endDate, unitId }) => {
    const [breakdownData, setBreakdownData] = useState();
    const [maxIndex, setMaxIndex] = useState();

    let chart = null;

    const renderChart = () => {
        const element = document.getElementById("deliveredBreakdownPie");
        if (!element) {
            return;
        }

        if (chart) {
            chart.destroy();
            chart = null;
        }

        chart = new ApexCharts(element, chartOptions);
        chart.render();
    };

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "getSalesBreakdownOfProjects",
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD"),
                isDelivered: 1
            };

            if (unitId) requestObj["unitId"] = unitId;

            post(requestObj)
                .then(({ data }) => {
                    const deliveredAmounts = data?.projects?.map(item => parseFloat(item?.revenue)) || [];
                    const maxValue = Math.max(...deliveredAmounts);
                    const maxIndex = deliveredAmounts.indexOf(maxValue);

                    colors.splice(maxIndex, 0, { value: "primary-green", label: "#328456" });

                    const labels = data?.projects?.map(item => item.type);

                    chartOptions.labels = labels;
                    chartOptions.series = data?.projects?.map(item => parseFloat(item?.revenue));
                    chartOptions.colors = colors.map(color => color.label);

                    renderChart();

                    setBreakdownData(data);
                    setMaxIndex(maxIndex);
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="row mx-0 justify-content-between">
            <div className="col-6">
                {breakdownData && breakdownData.projects && breakdownData.projects.length > 0 ? (
                    <div className="d-flex flex-column w-100">
                        <table className={`${commonStyles.commonTable} mt-5 ml-6`}>
                            <thead>
                                <tr>
                                    <th scope="col">Type</th>
                                    <th scope="col" className="text-right">
                                        Delivered
                                    </th>
                                    <th scope="col" className="text-right">
                                        %
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {breakdownData?.projects?.map((row, i) => (
                                    <tr key={row.type} className={i === maxIndex ? styles.highestItem : ""}>
                                        <td className={`${styles.deliveryType} ${styles[colors[i].value]}`}>
                                            {row.type}
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber
                                                style="currency"
                                                currency="USD"
                                                maximumFractionDigits={0}
                                                value={row.revenue}
                                            />
                                        </td>
                                        <td className="text-right">{row.percent + "%"}</td>
                                    </tr>
                                ))}
                                {breakdownData?.projects && (
                                    <tr className="font-weight-bolder">
                                        <td>Grand Total</td>
                                        <td className="text-right">
                                            <FormattedNumber
                                                style="currency"
                                                currency="USD"
                                                maximumFractionDigits={0}
                                                value={breakdownData?.grandAmount}
                                            />
                                        </td>
                                        <td className="text-right">100%</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>There is no data available.</div>
                )}
            </div>
            <div className="col-6 d-flex align-items-center justify-content-center">
                <div id="deliveredBreakdownPie" />
            </div>
        </div>
    );
};
export default DeliveredBreakdownTable;
