import moment from "moment";
import React, { useState, useEffect } from "react";
import { getUrlQueryParam, silentlyParseJSON } from "../../../app/functions/util";
import { post } from "../../../networking/RequestService";

import Page1 from "./components/Page1";
import Page2 from "./components/Page2";
import Page3 from "./components/Page3";
import Page4 from "./components/Page4";
import Page5 from "./components/Page5";
import Page6 from "./components/Page6";
import Page7 from "./components/Page7";

const FinalReport = () => {
    const startDate = getUrlQueryParam("startDate");
    const endDate = getUrlQueryParam("endDate");
    const unitId = getUrlQueryParam("unitId")?.split(",");

    const [platformImages, setPlatformImages] = useState();
    const [last3DealsData, setLast3DealsData] = useState();

    useEffect(() => {
        let requestObj = {
            fn: "getCompanyConfigByKeys",
            keys: ["PlatformImages"]
        };

        post(requestObj).then(({ data }) => {
            const images = silentlyParseJSON(data?.platformImages, "");
            setPlatformImages(images);
        });
    }, []);

    useEffect(() => {
        const getLast3DealsData = async () => {
            try {
                if (startDate && endDate) {
                    let requestObj = {
                        startDate: moment(startDate).format("YYYY-MM-DD"),
                        endDate: moment(endDate).format("YYYY-MM-DD"),
                        fn: "getCampaignTypeFinalReports"
                    };
                    if (unitId) requestObj["unitId"] = unitId;

                    const all3Data = await Promise.all([
                        post({ ...requestObj, campaignType: "consulting" }),
                        post({ ...requestObj, campaignType: "e-learning" }),
                        post({ ...requestObj, campaignType: "coaching" })
                    ]);

                    let modifiedall3Data = {};

                    all3Data.forEach(({ data }, i) => {
                        const modifiedData = {
                            totalAmount: +parseFloat(data?.amount || 0).toFixed(0),
                            items: [],
                            numberOfProject: data?.numberOfProjects
                        };
                        data?.topSeller &&
                            data.topSeller?.length > 0 &&
                            modifiedData.items.push({
                                title: "TOP SELLERS",
                                topSellers: data.topSeller,
                                amount: data.topSeller.length,
                                color: "primaryOrange"
                            });
                        data?.topClients &&
                            data.topClients.length > 0 &&
                            modifiedData.items.push({
                                title: "Top Clients",
                                topClients: data?.topClients,
                                sortData: [...data?.topClients].sort((a, b) => b?.amount - a.amount)
                            });

                        const key = i === 0 ? "Consulting" : i === 1 ? "Elearning" : "Coaching";
                        modifiedall3Data[key] = modifiedData;
                    });

                    setLast3DealsData(modifiedall3Data);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getLast3DealsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let last3TopSellersCount = 0;
    if (last3DealsData) {
        last3TopSellersCount = Object.values(last3DealsData).reduce(
            (acc, data) => acc + (data?.items[0]?.topSellers?.length || 0),
            0
        );
    }

    return (
        <div id="finalReport">
            <Page1 />
            <Page2 />
            <Page3 />
            <Page4 type="Public" />
            <Page4 type="LVTPublic" />
            <Page5 type="InHouse" />
            <Page5 type="LVTInHouse" />
            <Page7 last3DealsData={last3DealsData} last3TopSellersCount={last3TopSellersCount} />
            <Page6
                last3DealsData={last3DealsData}
                last3TopSellersCount={last3TopSellersCount}
                platformImages={platformImages}
            />
        </div>
    );
};
export default FinalReport;
