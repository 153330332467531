import React from 'react'
import SearchInput from '../../../../app/partials/SearchInput'

const IntermittentInputFilter = ({
  title,
  valueFirst,
  valueSecond,
  handleChangeFirst,
  handleChangeSecond,
  removeMin,
   customPlaceHolder

 }) => {
  // console.log(valueFirst,valueSecond,"valuesrf");
  // console.log(setMethod,"setmetg");
  
  return (
    <div className='mt-2 mb-6'>
      <div className='d-flex justify-content-between'>

        <div className="filter-heading">{title}</div>
        {valueFirst?.length > 0 ? (
          <div className="text-muted cursor-pointer" onClick={ removeMin}>
            X
          </div>
        ) : (valueSecond?.length > 0 ? <div className="text-muted cursor-pointer" onClick={ removeMin}> X </div> : "")}
      </div>
      <div className='row'>
        <div className="mt-2 col-12 d-flex">
          <SearchInput
            // minLength={2}
            debounceTimeout={500}
            onChange={handleChangeFirst}
            value={valueFirst}
             className="form-control mr-2 w-100"
            id="kt_datatable_search_query"
            type='number'
            inputName='Min'
            placeholder={customPlaceHolder}
          />

          <SearchInput
            // minLength={2}
            debounceTimeout={500}
            onChange={handleChangeSecond}
            value={valueSecond}
             className="form-control w-100"
            id="kt_datatable_search_query"
            type='number'
            inputName='Max'
            placeholder={customPlaceHolder}
          />
        </div>
      </div>
    </div>
  )
}

export default IntermittentInputFilter