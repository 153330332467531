/* eslint-disable react/style-prop-object */
import React from "react";
import { FormattedNumber } from "react-intl";

import styles from "../Page2.module.scss";
import commonStyles from "../../../final-report-common.module.scss";
import { useEffect } from "react";
import moment from "moment";
import { useState } from "react";
import { post } from "../../../../../../networking/RequestService";

const SalesBreakdownTable = ({ startDate, endDate, unitId }) => {
    const [breakdownData, setBreakdownData] = useState();

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "getSalesBreakdownOfProjects",
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            };

            if (unitId) requestObj["unitId"] = unitId;

            post(requestObj)
                .then(({ data }) => {
                    setBreakdownData(data);
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const revenues = breakdownData?.projects?.map(item => parseFloat(item.revenue)) || [];
    const maxRevenue = Math.max(...revenues);
    const maxIndex = revenues.indexOf(maxRevenue);

    return (
        <div className="d-flex flex-column w-100">
            <div className={styles.itemTitle}>SALES BREAKDOWN OF PROJECTS</div>
            {breakdownData && breakdownData.projects && breakdownData.projects.length > 0 ? (
                <table className={commonStyles.commonTable}>
                    <thead>
                        <tr>
                            <th scope="col">Type</th>
                            <th scope="col" className="text-right">
                                Revenue
                            </th>
                            <th scope="col" className="text-right">
                                %
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {breakdownData.projects.map((row, i) => (
                            <tr key={row.type} className={i === maxIndex ? styles.highestItem : ""}>
                                <td>{row.type}</td>
                                <td className="text-right">
                                    <FormattedNumber
                                        style="currency"
                                        currency="USD"
                                        maximumFractionDigits={0}
                                        value={row.revenue}
                                    />
                                </td>
                                <td className="text-right">{row.percent + "%"}</td>
                            </tr>
                        ))}
                        <tr className="font-weight-bolder">
                            <td>Grand Total</td>
                            <td className="text-right">
                                <FormattedNumber
                                    style="currency"
                                    currency="USD"
                                    maximumFractionDigits={0}
                                    value={breakdownData?.grandAmount}
                                />
                            </td>
                            <td className="text-right">100%</td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                <div>There is no data available.</div>
            )}
        </div>
    );
};
export default SalesBreakdownTable;
