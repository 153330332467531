import CheckRadioBtn from "app/popleads/components/CheckRadioBtn";
import React, { useRef } from "react";
import SelectInput from "./inputs/selectInput";

const SelectSearchFilter = ({ title, object, selectedOptions, setSelectedOptions }) => {
    const onChange = selectedOptions => {
        setSelectedOptions(selectedOptions);
    };

    const onSelectedChange = clickedItem => {
        const tempOptions = [...selectedOptions];
        if (tempOptions.find(item => item.value === clickedItem.value)) {
            setSelectedOptions(tempOptions.filter(item => item.value !== clickedItem.value));
        } else {
            setSelectedOptions([...tempOptions, clickedItem]);
        }
    };

    const dropdownRef = useRef();
    const scrollTo = () => {
        setTimeout(() => dropdownRef.current?.scrollIntoView({ behavior: "smooth" }, true), 20);
    };

    return (
        <div className="d-flex justify-content-between flex-column mt-2" ref={dropdownRef}>
            <div className="d-flex justify-content-between mb-2">
                <div className="filter-heading">
                    {title}
                    {selectedOptions?.length > 0 && `(${selectedOptions.length})`}
                </div>
            </div>
            <div onClick={scrollTo}>
                <SelectInput object={object} onChange={onChange} />
            </div>
            <div className="d-flex flex-wrap mt-2" style={{ columnGap: "6px" }}>
                <>
                    {selectedOptions &&
                        selectedOptions.length > 0 &&
                        selectedOptions.map(item => (
                            <CheckRadioBtn
                                key={item.value}
                                option={{
                                    value: item.value,
                                    label: item.label
                                }}
                                onChange={() => onSelectedChange(item)}
                                checked
                            />
                        ))}
                </>
            </div>
        </div>
    );
};

export default SelectSearchFilter;
