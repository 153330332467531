import moment from "moment";

export const actionTypes = {
    ToggleAddTrainerButton: "[Action] Toggle Add Trainer Button",
    ToggleCalendarButton: "[Action] Toggle Calendar Button",
    IsEditButtonExist: "[Action] Is Edit Button",
    ToggleEditButton: "[Action] Toggle Edit Button",
    SetTrainer: "[Action] Set Trainer",
    ToggleAddEventButton: "[Action] Toggle Add Event Button",
    TrainersFilter: "[Action] Trainers Filter",
    TrainerSelectDate: "[Action] Trainer Select Date",
};

const initialReportsState = {
    isOpenAddTrainerButton: false,
    isOpenCalendarButton: false,
    isEditButton: false,
    isOpenEditButton: false,
    trainer: {},
    isOpenAddEventButton: false,
    trainersFilter: false,
    trainerSelectedDate: {
        startDate: moment()
            .startOf("year")
            .format("YYYY-MM-DD"),
        endDate: moment()
            .endOf("year")
            .format("YYYY-MM-DD"),
    },
};

export const reducer = (state = initialReportsState, action) => {
    switch (action.type) {
        case actionTypes.ToggleAddTrainerButton: {
            const { isOpenAddTrainerButton } = action.payload;
            return { ...state, isOpenAddTrainerButton };
        }

        case actionTypes.ToggleCalendarButton: {
            const { isOpenCalendarButton } = action.payload;
            return { ...state, isOpenCalendarButton };
        }

        case actionTypes.IsEditButtonExist: {
            const { isEditButton } = action.payload;
            return { ...state, isEditButton };
        }

        case actionTypes.ToggleEditButton: {
            const { isOpenEditButton } = action.payload;
            return { ...state, isOpenEditButton };
        }

        case actionTypes.SetTrainer: {
            const { trainer } = action.payload;
            return { ...state, trainer };
        }

        case actionTypes.ToggleAddEventButton: {
            const { isOpenAddEventButton } = action.payload;
            return { ...state, isOpenAddEventButton };
        }
        case actionTypes.TrainersFilter: {
            const { trainersFilter } = action.payload;
            return { ...state, trainersFilter };
        }
        case actionTypes.TrainerSelectDate: {
            const { trainerSelectedDate } = action.payload;
            return { ...state, trainerSelectedDate };
        }

        default:
            return state;
    }
};

export const actions = {
    toggleAddTrainerButton: (isOpenAddTrainerButton) => ({
        type: actionTypes.ToggleAddTrainerButton,
        payload: { isOpenAddTrainerButton },
    }),

    toggleCalendarButton: (isOpenCalendarButton) => ({
        type: actionTypes.ToggleCalendarButton,
        payload: { isOpenCalendarButton },
    }),

    isEditButtonExist: (isEditButton) => ({
        type: actionTypes.IsEditButtonExist,
        payload: { isEditButton },
    }),

    toggleEditButton: (isOpenEditButton) => ({
        type: actionTypes.ToggleEditButton,
        payload: { isOpenEditButton },
    }),

    setTrainer: (trainer) => ({
        type: actionTypes.SetTrainer,
        payload: { trainer },
    }),

    toggleAddEventButton: (isOpenAddEventButton) => ({
        type: actionTypes.ToggleAddEventButton,
        payload: { isOpenAddEventButton },
    }),
    setTrainersFilter: (trainersFilter) => ({
        type: actionTypes.TrainersFilter,
        payload: { trainersFilter },
    }),
    setTrainerSelecedDate: (trainerSelectedDate) => ({
        type: actionTypes.TrainerSelectDate,
        payload: { trainerSelectedDate },
    }),
};
