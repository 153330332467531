/* eslint-disable react/style-prop-object */
import React from "react";
import { useState } from "react";
import ApexCharts from "apexcharts";
import { useEffect } from "react";
import moment from "moment";
import { FormattedNumber } from "react-intl";
import { post } from "../../../../../../networking/RequestService";
import { getChartSubtitle, isMonthly } from "../../../../../../app/functions/finalReportUtil";

import CompWithPrevious from "../../common/CompWithPrevious";

import styles from "../Page2.module.scss";

let chartOptions = {
    tooltip: {
        enabled: false,
        onDatasetHover: {
            highlightDataSeries: false
        }
    },
    chart: {
        type: "bar",
        width: "540px",
        height: "400px",
        toolbar: {
            tools: {
                download: false,
                zoomin: false,
                zoomout: false,
                reset: false,
                selection: false,
                pan: false
            }
        },
        selection: false,
        zoom: false
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
    xaxis: {
        labels: {
            style: {
                fontFamily: "Gilroy",
                fontSize: "12px",
                colors: "#82929B"
            }
        },
        axisBorder: {
            show: true,
            color: "#328456"
        }
    },
    yaxis: {
        labels: {
            style: {
                fontFamily: "Gilroy",
                fontSize: "12px",
                colors: "#82929B"
            },
            formatter: function(val) {
                const formatter = new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0
                });
                return formatter.format(val);
            }
        },
        axisBorder: {
            show: true,
            color: "#328456"
        }
    },
    grid: {
        show: true,
        borderColor: "#EFF3F5",
        strokeDashArray: 12
    },
    plotOptions: {
        bar: {
            distributed: true,
            borderRadius: 5,
            borderRadiusWhenStacked: "all"
        }
    }
};

const TotalSales = ({ startDate, endDate, unitId }) => {
    const [salesData, setSalesData] = useState();

    let chart = null;

    const renderChart = () => {
        const element = document.getElementById("totalSales");
        if (!element) {
            return;
        }

        if (chart) {
            chart.destroy();
            chart = null;
        }

        chart = new ApexCharts(element, chartOptions);
        chart.render();
    };

    useEffect(() => {
        if (startDate && endDate) {
            let requestObj = {
                fn: "getFinalTotalSalesReports",
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            };

            if (unitId) requestObj["unitId"] = unitId;

            post(requestObj)
                .then(({ data }) => {
                    setSalesData(data);

                    chartOptions.xaxis.categories = data.dates
                    //chartOptions.xaxis.categories = data.dates

                    chartOptions.series = [
                        {
                            data: data.amounts
                        }
                    ];
                    chartOptions.colors = [...Array(data.dates?.length - 1).fill("#DAF2E5"), "#328456"];
                    renderChart();
                })
                .catch(error => {
                    console.log("error: ", error);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const difference = parseFloat(salesData?.rangeAmount) - parseFloat(salesData?.lastYearRangeAmount);
    const comparison = {
        formattedAmount: new Intl.NumberFormat("en", { notation: "compact" }).format(Math.abs(difference)),
        isAbove: difference > 0,
        text: `${difference > 0 ? "Above" : "Below"} vs. Last Year`
    };

    const chartSubtitle = `SALES IN THIS ${getChartSubtitle(startDate, endDate)}`;

    return (
        <>
            <div id="totalSales" />
            {salesData && (
                <div className="d-flex align-items-center ml-6">
                    <div className="d-flex flex-column">
                        <span className={styles.salesThisMonthTitle}>{chartSubtitle}</span>
                        <span className={styles.salesThisMonthAmount}>
                            <FormattedNumber
                                value={parseInt(salesData?.rangeAmount)}
                                style="currency"
                                currency="USD"
                                maximumFractionDigits={0}
                            />
                        </span>
                    </div>
                    {difference !== 0 && (
                        <CompWithPrevious comparison={comparison} className="d-flex flex-column ml-6" />
                    )}
                </div>
            )}
        </>
    );
};

export default TotalSales;
