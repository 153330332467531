import React from "react";
import { connect } from "react-redux";
import * as travelRedux from "../travels/redux/travelRedux";
import { TooltipOverlay } from "app/popleads/components/TooltipOverlay";
import InlineSVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { Button as BootstrapButton } from "react-bootstrap";
import CustomDrawer, { DrawerBody, DrawerContent, DrawerHeader } from "app/popleads/components/drawer/CustomDrawer";
import useDisclosure from "app/popleads/hooks/useDisclosure";
import TravelSelectType from "../travels/components/TravelSelectType";
import NewMultiSelectFilter from "./components/NewMultiSelectFilter";
import CommonDateFilter from "./components/deal-filters/CommonDateFilter";
import styles from "../../../popleads/assets/css/design_3.0/CustomDatePicker.module.scss";

const BusinessTripsFilter = ({
    travelFilter,
    travelerFilter,
    travelSelectedDate,
    travelEmployee,
    travelUnits,
    travelCountries,
    travelCity,
    showTravelCountryFilter,
    travelVisaAndPassportFilter,
    travelSelectedFlightStatus,
    travelSelectedAccommodationStatus,
    visaAndPassportType,
    travelVisaAndPassportTypeSelect,
    setTravelSelectDate,
    setTravelEmployee,
    setTravelUnits,
    setTravelVisaAndPassportTypeSelect,
    setTravelCountries,
    travelFlightAndHotelStatusFilter,
    setTravelCity,
    setTravelFlightStatusSelect,
    completedOrInprogressType,
    setTravelAccommodationStatusSelect,
}) => {
    const { isOpen, onClose, onToggle } = useDisclosure();

    return (
        <>
            <TooltipOverlay placement="bottom" tooltip="Filters">
                <BootstrapButton variant="secondary" className="ml-1 ml-sm-4 customBootstrapBtn" onClick={onToggle}>
                    <InlineSVG src={toAbsoluteUrl("/media/svg/icons/Text/Filters2.svg")} />
                </BootstrapButton>
            </TooltipOverlay>
            <CustomDrawer position="right" size="md" open={isOpen} onClose={onClose}>
                <DrawerContent isLoading={false}>
                    <DrawerHeader onClose={onClose} sx={{ border: "none" }}>
                        <div>Filters</div>
                    </DrawerHeader>
                    <DrawerBody sx={{ paddingTop: 0 }}>
                        {travelVisaAndPassportFilter && (
                            <>
                                <CommonDateFilter
                                    dateObject={travelSelectedDate}
                                    setSelectedDateObject={setTravelSelectDate}
                                    travelFilter
                                    className={`${styles.rdrDateRangePickerWrapper} mt-4 mb-8`}
                                />
                                <NewMultiSelectFilter
                                    inputLabel="Employees"
                                    fn="companyUsers"
                                    selectedItems={travelEmployee}
                                    setSelectedItems={setTravelEmployee}
                                />
                                <NewMultiSelectFilter
                                    inputLabel="Unit"
                                    fn="getAllUnits"
                                    selectedItems={travelUnits}
                                    setSelectedItems={setTravelUnits}
                                />
                                <NewMultiSelectFilter
                                    inputLabel="Country"
                                    fn="getCampaignDynamicSchemaAllFields"
                                    selectedItems={travelCountries}
                                    setSelectedItems={setTravelCountries}
                                />
                                <TravelSelectType
                                    title={"Type"}
                                    selectedItem={travelVisaAndPassportTypeSelect}
                                    setState={setTravelVisaAndPassportTypeSelect}
                                    option={visaAndPassportType}
                                />
                            </>
                        )}
                        {travelFilter && (
                            <>
                                <CommonDateFilter
                                    className={`${styles.rdrDateRangePickerWrapper} mt-4 mb-8`}
                                    dateObject={travelSelectedDate}
                                    setSelectedDateObject={setTravelSelectDate}
                                    travelFilter
                                />

                                {travelerFilter === false && (
                                    <>
                                        <NewMultiSelectFilter
                                            inputLabel="Employees"
                                            fn="companyUsers"
                                            selectedItems={travelEmployee}
                                            setSelectedItems={setTravelEmployee}
                                        />

                                        <NewMultiSelectFilter
                                            inputLabel="Unit"
                                            fn="getAllUnits"
                                            selectedItems={travelUnits}
                                            setSelectedItems={setTravelUnits}
                                        />
                                    </>
                                )}
                                {showTravelCountryFilter === true && (
                                    <div>
                                        <NewMultiSelectFilter
                                            inputLabel="Country"
                                            fn="getCampaignDynamicSchemaAllFields"
                                            selectedItems={travelCountries}
                                            setSelectedItems={setTravelCountries}
                                        />
                                    </div>
                                )}
                                <NewMultiSelectFilter
                                    inputLabel="City"
                                    fn="getCampaignDynamicSchemaAllFields"
                                    selectedItems={travelCity}
                                    setSelectedItems={setTravelCity}
                                />
                                {travelFlightAndHotelStatusFilter && (
                                    <>
                                        <TravelSelectType
                                            title={"Flight Ticket"}
                                            selectedItem={travelSelectedFlightStatus}
                                            setState={setTravelFlightStatusSelect}
                                            option={completedOrInprogressType}
                                        />
                                        <div className="mt-8">
                                            <TravelSelectType
                                                title={"Accommodation"}
                                                selectedItem={travelSelectedAccommodationStatus}
                                                setState={setTravelAccommodationStatusSelect}
                                                option={completedOrInprogressType}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </DrawerBody>
                </DrawerContent>
            </CustomDrawer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        addNewTravelBtn: state.travel.addNewTravelBtn,
        travelFilter: state.travel.travelFilter,
        travelStatusConstant: state.travel.travelStatusConstant,
        travelerFilter: state.travel.travelerFilter,
        travelDateListButton: state.travel.travelDateListButton,
        travelGridButton: state.travel.travelGridButton,
        travelSelectedDate: state.travel.travelSelectedDate,
        travelEmployee: state.travel.travelEmployee,
        travelUnits: state.travel.travelUnits,
        travelSelectedStatus: state.travel.travelSelectedStatus,
        travelCountries: state.travel.travelCountries,
        travelCity: state.travel.travelCity,
        showTravelCountryFilter: state.travel.showTravelCountryFilter,
        travelVisaAndPassportFilter: state.travel.travelVisaAndPassportFilter,
        travelNewValidVisaForEmployee: state.travel.travelNewValidVisaForEmployee,
        travelNewPassportButton: state.travel.travelNewPassportButton,
        travelSelectedVisaStatus: state.travel.travelSelectedVisaStatus,
        travelSelectedFlightStatus: state.travel.travelSelectedFlightStatus,
        travelSelectedAccommodationStatus: state.travel.travelSelectedAccommodationStatus,
        travelVisaRequestModal: state.travel.travelVisaRequestModal,
        visaAndPassportType: state.travel.visaAndPassportType,
        travelVisaAndPassportTypeSelect: state.travel.travelVisaAndPassportTypeSelect,
        travelFlightAndHotelStatusFilter: state.travel.travelFlightAndHotelStatusFilter,
        completedOrInprogressType: state.travel.completedOrInprogressType,
    };
};

const mapDispatchToProps = {
    ...travelRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTripsFilter);
