import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import InlineSVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";

import SelectSearchFilter from "app/popleads/modules/filters/components/SelectSearchFilter";
import MultiOptionsFilter from "app/popleads/modules/filters/components/MultiOptionsFilter";
import CheckboxFilter from "../../modules/filters/components/courses-filter/CheckboxFilter";
import IntermittentInputFilter from "../../modules/filters/components/courses-filter/IntermittentInputFilter";
import {
    courseStatus,
    refundTypeOptions,
    subsidizedByHRDFOptions,
} from "app/popleads/modules/campaigns/courses/constants";
import { TooltipOverlay } from "app/popleads/components/TooltipOverlay";
import CustomDrawer, { DrawerBody, DrawerContent, DrawerHeader } from "app/popleads/components/drawer/CustomDrawer";
import useDisclosure from "app/popleads/hooks/useDisclosure";
import * as campaignsRedux from "../../modules/campaigns/redux/campaignsRedux";
import * as appRedux from "../../app/redux/appRedux";
import { post } from "app/popleads/networking/RequestService";

const CoursesFilterDrawer = (props) => {
    const { isOpen, onClose, onToggle } = useDisclosure();
    const [courseTypeOptions, setCourseTypeOptions] = useState([]);
    const [trainerList, setTrainerList] = useState([]);
    const [businessSectorList, setBusinessSectorList] = useState([]);

    const getCourseTypes = () => {
        let requestObj = {
            fn: "getTypesByModule",
            module: "campaign",
        };
        post(requestObj)
            .then(({ data: { typeList } }) => {
                const options = [];
                if (typeList) {
                    for (let i = 0; i < typeList.length; i++) {
                        const obj = { value: typeList[i].id, label: typeList[i].name };
                        options.push(obj);
                    }
                }
                setCourseTypeOptions(options);
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const getBusinessSectors = () => {
        let reqObj = {
            fn: "getCourseCategories",
        };
        post(reqObj)
            .then(({ data: { categories } }) => {
                let businessArray = [];
                for (let category of categories) {
                    // console.log(category);
                    const tempObj = {
                        value: category.id,
                        label: category.name,
                    };
                    businessArray.push(tempObj);
                }
                setBusinessSectorList(businessArray);
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    const getMasterTrainers = () => {
        if (trainerList.length === 0) {
            const obj = {
                fn: "getTrainerList",
                // search: search,
                start: 0,
                rowsPerPage: 100000,
            };
            post(obj).then(({ data: { trainerList } }) => {
                const options = [];
                if (trainerList) {
                    for (let i = 0; i < trainerList.length; i++) {
                        const obj = { value: trainerList[i].id, label: trainerList[i].name };
                        options.push(obj);
                    }
                }
                setTrainerList(options);
            });
        }
    };

    const filterValueList = [];
    if (props.courseBusinessSectorFilter && props.courseBusinessSectorFilter.length > 0) {
        filterValueList.push({
            fieldName: "Business Sector",
            fieldValue: props.courseBusinessSectorFilter.map((item) => item?.value).join("_¡_"),
            fieldType: "dynamic",
            fieldFormat: "string",
            fieldSign: "",
        });
    }
    /*if (props.courseMasterTrainerFilter && props.courseMasterTrainerFilter.length > 0) {
        filterValueList.push({
            fieldName: "Master Trainer",
            fieldValue: props.courseMasterTrainerFilter.map(item => item?.value).join("_¡_"),
            fieldType: "dynamic",
            fieldFormat: "string",
            fieldSign: ""
        });
    }*/

    if (props.courseDesignationFilter && props.courseDesignationFilter.length > 0) {
        filterValueList.push({
            fieldName: "Designation",
            fieldValue: props.courseDesignationFilter.map((item) => item?.value).join("_¡_"),
            fieldType: "dynamic",
            fieldFormat: "string",
            fieldSign: "",
        });
    }
    if (props.courseLanguageFilter && props.courseLanguageFilter.length > 0) {
        filterValueList.push({
            fieldName: "Language",
            fieldValue: props.courseLanguageFilter.map((item) => item?.value).join("_¡_"),
            fieldType: "dynamic",
            fieldFormat: "string",
            fieldSign: "",
        });
    }
    if (props.courseLevelTypeFilter && props.courseLevelTypeFilter.length > 0) {
        filterValueList.push({
            fieldName: "LevelType",
            fieldValue: props.courseLevelTypeFilter.map((item) => item?.value).join("_¡_"),
            fieldType: "dynamic",
            fieldFormat: "string",
            fieldSign: "",
        });
    }
    if (props.courseAcademyFilter && props.courseAcademyFilter.length > 0) {
        filterValueList.push({
            fieldName: "Academy",
            fieldValue: props.courseAcademyFilter.map((item) => item?.value).join("_¡_"),
            fieldType: "dynamic",
            fieldFormat: "string",
            fieldSign: "",
        });
    }
    if (props.courseProjectManagerFilter && props.courseProjectManagerFilter.length > 0) {
        filterValueList.push({
            fieldName: "PM Name For App",
            fieldValue: props.courseProjectManagerFilter.map((item) => item?.value).join("_¡_"),
            fieldType: "dynamic",
            fieldFormat: "string",
            fieldSign: "",
        });
    }
    if (props.subsidizedByHrdf) {
        filterValueList.push({
            fieldName: "Is Subsidized by HRDF",
            fieldValue: props.subsidizedByHrdf,
            fieldType: "dynamic",
            fieldFormat: "string",
            fieldSign: "",
        });
    }
    if (props.refundType) {
        filterValueList.push({
            fieldName: "Refund Type",
            fieldValue: props.refundType,
            fieldType: "dynamic",
            fieldFormat: "string",
            fieldSign: "",
        });
    }

    const allFiltersArr = [
        { name: "status", value: props.coursesStatusFilter },
        { name: "busSector", value: props.courseBusinessSectorFilter },
        { name: "masterTrainer", value: props.courseMasterTrainerFilter },
        { name: "designation", value: props.courseDesignationFilter },
        { name: "language", value: props.courseLanguageFilter },
        { name: "levelType", value: props.courseLevelTypeFilter },
        { name: "academy", value: props.courseAcademyFilter },
        { name: "pm", value: props.courseProjectManagerFilter },
        { name: "type", value: props.courseTypeFilter },
        { name: "hrdf", value: props.subsidizedByHrdf },
        { name: "refund", value: props.refundType },
        { name: "revenue", value: props.courseRevenueFilter },
        { name: "score", value: props.courseEvaluationScore },
        { name: "delegates", value: props.courseNumberOfDelegation },
        { name: "lvtPrice", value: props.courseLVTPrice },
        { name: "f2fPrice", value: props.courseF2Fprice },
    ];

    const obj = {
        fn: "campaignListHomePage",
        isTemplate: 1,
        status: props.coursesStatusFilter ? [].concat(props.coursesStatusFilter) : undefined,
        type: props.courseTypeFilter ? props.courseTypeFilter.map((item) => item?.label) : undefined,
        minRevenue: props.courseRevenueFilter.min,
        maxRevenue: props.courseRevenueFilter.max,
        minScore: props.courseEvaluationScore.min,
        maxScore: props.courseEvaluationScore.max,
        minDelegate: props.courseNumberOfDelegation.min,
        maxDelegate: props.courseNumberOfDelegation.max,
        minLVTPrice: props.courseLVTPrice.min,
        maxLVTPrice: props.courseLVTPrice.max,
        minF2FPrice: props.courseF2Fprice.min,
        maxF2FPrice: props.courseF2Fprice.max,
        multi: true,
        placeholder: "Search...",
    };
    // const busSectorOptionsObj = {
    //     ...obj,
    //     name: "Business Sector",
    //     columnName: "Business Sector",
    //     value: props.courseBusinessSectorFilter,
    //     filterValuesList: JSON.stringify(filterValueList.filter((filter) => filter.fieldName !== "Business Sector")),
    //     dependencies: allFiltersArr.filter((item) => item.name !== "busSector").map((item) => item.value),
    // };

    const busSectorOptionsObj = {
        placeholder: "Search...",
        name: "Business Sector",
        value: props.courseBusinessSectorFilter,
        //filterValuesList: JSON.stringify(filterValueList.filter(filter => filter.fieldName !== "Master Trainer")),
        //dependencies: allFiltersArr.filter(item => item.name !== "masterTrainer").map(item => item.value)
        data: { options: businessSectorList },
        infinite: false,
        multi: true,
    };

    const masterTrainerOptionsObj = {
        placeholder: "Search...",
        name: "Master Trainer",
        value: props.courseMasterTrainerFilter,
        //filterValuesList: JSON.stringify(filterValueList.filter(filter => filter.fieldName !== "Master Trainer")),
        //dependencies: allFiltersArr.filter(item => item.name !== "masterTrainer").map(item => item.value)
        data: { options: trainerList },
        infinite: false,
        multi: true,
    };

    const designationOptionsObj = {
        ...obj,
        name: "Designation",
        columnName: "Designation",
        value: props.courseDesignationFilter,
        filterValuesList: JSON.stringify(filterValueList.filter((filter) => filter.fieldName !== "Designation")),
        dependencies: allFiltersArr.filter((item) => item.name !== "designation").map((item) => item.value),
    };

    const languageOptionsObj = {
        ...obj,
        name: "Language",
        columnName: "Language",
        value: props.courseLanguageFilter,
        filterValuesList: JSON.stringify(filterValueList.filter((filter) => filter.fieldName !== "Language")),
        dependencies: allFiltersArr.filter((item) => item.name !== "language").map((item) => item.value),
    };

    const levelTypeOptionsObj = {
        ...obj,
        name: "LevelType",
        columnName: "LevelType",
        value: props.courseLevelTypeFilter,
        filterValuesList: JSON.stringify(filterValueList.filter((filter) => filter.fieldName !== "LevelType")),
        dependencies: allFiltersArr.filter((item) => item.name !== "levelType").map((item) => item.value),
    };

    const academyOptionsObj = {
        ...obj,
        name: "Academy",
        columnName: "Academy",
        value: props.courseAcademyFilter,
        filterValuesList: JSON.stringify(filterValueList.filter((filter) => filter.fieldName !== "Academy")),
        dependencies: allFiltersArr.filter((item) => item.name !== "academy").map((item) => item.value),
    };

    const pmOptionsObj = {
        ...obj,
        name: "PM Name For App",
        columnName: "PM Name For App",
        value: props.courseProjectManagerFilter,
        filterValuesList: JSON.stringify(filterValueList.filter((filter) => filter.fieldName !== "PM Name For App")),
        dependencies: allFiltersArr.filter((item) => item.name !== "pm").map((item) => item.value),
    };

    const refundTypeRef = useRef();
    useEffect(() => {
        if (!props.subsidizedByHrdf || props.subsidizedByHrdf === "0") {
            props.setRefundType(undefined);
        }
        if (props.subsidizedByHrdf === "1") {
            setTimeout(() => refundTypeRef.current?.scrollIntoView({ behavior: "smooth" }, true), 20);
        }
    }, [props.subsidizedByHrdf]);

    // const clearFilters = () => {
    //     props.setCourseStatusFilter(undefined);
    //     props.setCourseBusinessSectorFilter([]);
    //     props.setCourseMasterTrainer([]);
    //     props.setCourseDesignationFilter([]);
    //     props.setCourseLanguageFilter([]);
    //     props.setCourseLevelTypeFilter([]);
    //     props.setCourseAcademy([]);
    //     props.setCourseProjectManager([]);
    //     props.setCourseTypeFilter([]);
    //     props.setCourseRevenueFilter({ min: "", max: "" });
    //     props.setCourseEvaluationScore({ min: "", max: "" });
    //     props.setCourseNumberOfDelegation({ min: "", max: "" });
    //     props.setCourseLVTPrice({ min: "", max: "" });
    //     props.setCourseF2FPrice({ min: "", max: "" });
    //     props.setCourseSubsidized(undefined);
    //     props.setRefundType(undefined);
    // };

    useEffect(() => {
        getCourseTypes();
        getMasterTrainers();
        getBusinessSectors();
    }, []);

    return (
        <>
            <TooltipOverlay placement="bottom" tooltip="Filters">
                <Button variant="secondary" className="ml-1 ml-sm-4 customBootstrapBtn" onClick={onToggle}>
                    <InlineSVG src={toAbsoluteUrl("/media/svg/icons/Text/Filters2.svg")} />
                </Button>
            </TooltipOverlay>
            <CustomDrawer size="md" open={isOpen} onClose={onClose}>
                <DrawerContent>
                    <DrawerHeader onClose={onClose} sx={{ borderBottom: "none" }}>
                        Filters
                    </DrawerHeader>
                    <DrawerBody>
                        <CheckboxFilter
                            option={courseStatus}
                            title="Status"
                            currentState={props.coursesStatusFilter}
                            setMethod={props.setCourseStatusFilter}
                        />
                        <SelectSearchFilter
                            title="Business Sector"
                            object={busSectorOptionsObj}
                            selectedOptions={props.courseBusinessSectorFilter}
                            setSelectedOptions={props.setCourseBusinessSectorFilter}
                        />
                        <SelectSearchFilter
                            title="Master Trainer"
                            object={masterTrainerOptionsObj}
                            selectedOptions={props.courseMasterTrainerFilter}
                            setSelectedOptions={props.setCourseMasterTrainer}
                        />
                        <SelectSearchFilter
                            title="Designation"
                            object={designationOptionsObj}
                            selectedOptions={props.courseDesignationFilter}
                            setSelectedOptions={props.setCourseDesignationFilter}
                        />
                        <SelectSearchFilter
                            title="Language"
                            object={languageOptionsObj}
                            selectedOptions={props.courseLanguageFilter}
                            setSelectedOptions={props.setCourseLanguageFilter}
                        />
                        <SelectSearchFilter
                            title="Level"
                            object={levelTypeOptionsObj}
                            selectedOptions={props.courseLevelTypeFilter}
                            setSelectedOptions={props.setCourseLevelTypeFilter}
                        />
                        {/* <SearchInputFilter
                            title="Academy"
                            value={props.courseAcademyFilter}
                            setMethod={props.setCourseAcademy}
                            handleChange={e => props.setCourseAcademy(e.target.value)}
                        /> */}
                        <SelectSearchFilter
                            title="Academy"
                            object={academyOptionsObj}
                            selectedOptions={props.courseAcademyFilter}
                            setSelectedOptions={props.setCourseAcademy}
                        />
                        <SelectSearchFilter
                            title="Project Manager"
                            object={pmOptionsObj}
                            selectedOptions={props.courseProjectManagerFilter}
                            setSelectedOptions={props.setCourseProjectManager}
                        />
                        <MultiOptionsFilter
                            title="Type"
                            options={courseTypeOptions}
                            selectedOptions={props.courseTypeFilter}
                            setSelectedOptions={props.setCourseTypeFilter}
                        />
                        <IntermittentInputFilter
                            title="Total Revenue"
                            valueFirst={props.courseRevenueFilter.min}
                            valueSecond={props.courseRevenueFilter.max}
                            handleChangeFirst={(e) =>
                                props.setCourseRevenueFilter({ ...props.courseRevenueFilter, min: e.target.value })
                            }
                            handleChangeSecond={(e) =>
                                props.setCourseRevenueFilter({ ...props.courseRevenueFilter, max: e.target.value })
                            }
                            customPlaceHolder={"0"}
                            removeMin={() => props.setCourseRevenueFilter({ min: "", max: "" })}
                        />
                        <IntermittentInputFilter
                            title="Evaluation Score"
                            valueFirst={props.courseEvaluationScore.min}
                            valueSecond={props.courseEvaluationScore.max}
                            handleChangeFirst={(e) =>
                                props.setCourseEvaluationScore({ ...props.courseEvaluationScore, min: e.target.value })
                            }
                            handleChangeSecond={(e) =>
                                props.setCourseEvaluationScore({ ...props.courseEvaluationScore, max: e.target.value })
                            }
                            customPlaceHolder={"0"}
                            removeMin={() => props.setCourseEvaluationScore({ min: "", max: "" })}
                        />
                        <IntermittentInputFilter
                            title="Number Of Delegates"
                            valueFirst={props.courseNumberOfDelegation.min}
                            valueSecond={props.courseNumberOfDelegation.max}
                            handleChangeFirst={(e) =>
                                props.setCourseNumberOfDelegation({
                                    ...props.courseNumberOfDelegation,
                                    min: e.target.value,
                                })
                            }
                            handleChangeSecond={(e) =>
                                props.setCourseNumberOfDelegation({
                                    ...props.courseNumberOfDelegation,
                                    max: e.target.value,
                                })
                            }
                            removeMin={() => props.setCourseNumberOfDelegation({ min: "", max: "" })}
                            customPlaceHolder={"0"}
                        />
                        <IntermittentInputFilter
                            title="LVT Price"
                            valueFirst={props.courseLVTPrice.min}
                            valueSecond={props.courseLVTPrice.max}
                            handleChangeFirst={(e) =>
                                props.setCourseLVTPrice({ ...props.courseLVTPrice, min: e.target.value })
                            }
                            handleChangeSecond={(e) =>
                                props.setCourseLVTPrice({ ...props.courseLVTPrice, max: e.target.value })
                            }
                            removeMin={() => props.setCourseLVTPrice({ min: "", max: "" })}
                            customPlaceHolder={"$0"}
                        />
                        <IntermittentInputFilter
                            title="F2F Price"
                            valueFirst={props.courseF2Fprice.min}
                            valueSecond={props.courseF2Fprice.max}
                            handleChangeFirst={(e) =>
                                props.setCourseF2FPrice({ ...props.courseF2Fprice, min: e.target.value })
                            }
                            handleChangeSecond={(e) =>
                                props.setCourseF2FPrice({ ...props.courseF2Fprice, max: e.target.value })
                            }
                            removeMin={() => props.setCourseF2FPrice({ min: "", max: "" })}
                            customPlaceHolder={"$0"}
                        />
                        <CheckboxFilter
                            option={subsidizedByHRDFOptions}
                            title="Subsidized by HRDF"
                            currentState={props.subsidizedByHrdf}
                            setMethod={props.setCourseSubsidized}
                        />
                        {props.subsidizedByHrdf === "1" && (
                            <>
                                <div ref={refundTypeRef} />
                                <CheckboxFilter
                                    option={refundTypeOptions}
                                    title="Refund Type"
                                    currentState={props.refundType}
                                    setMethod={props.setRefundType}
                                />
                            </>
                        )}
                    </DrawerBody>
                </DrawerContent>
            </CustomDrawer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        isCoursesActionButtons: state.campaigns.isCoursesActionButtons,
        gridOrTable: state.campaigns.gridOrTable,
        dynamicSchemaDataManagement: state.app.dynamicSchemaDataManagement,
        coursesStatusFilter: state.campaigns.coursesStatusFilter,
        courseBusinessSectorFilter: state.campaigns.courseBusinessSectorFilter,
        courseLanguageFilter: state.campaigns.courseLanguageFilter,
        courseLevelTypeFilter: state.campaigns.courseLevelTypeFilter,
        courseTypeFilter: state.campaigns.courseTypeFilter,
        courseDesignationFilter: state.campaigns.courseDesignationFilter,
        courseRevenueFilter: state.campaigns.courseRevenueFilter,
        courseMasterTrainerFilter: state.campaigns.courseMasterTrainerFilter,
        courseAcademyFilter: state.campaigns.courseAcademyFilter,
        courseProjectManagerFilter: state.campaigns.courseProjectManagerFilter,
        courseEvaluationScore: state.campaigns.courseEvaluationScore,
        courseNumberOfDelegation: state.campaigns.courseNumberOfDelegation,
        courseLVTPrice: state.campaigns.courseLVTPrice,
        courseF2Fprice: state.campaigns.courseF2Fprice,
        subsidizedByHrdf: state.campaigns.subsidizedByHrdf,
        refundType: state.campaigns.refundType,
        courseExcelExport: state.campaigns.courseExcelExport,
    };
};

const mapDispatchToProps = {
    ...campaignsRedux.actions,
    ...appRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoursesFilterDrawer);
