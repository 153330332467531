import ApexCharts from "apexcharts";
import React from "react";
import styles from "./Page3.module.scss";

const colors = ["#db5a65", "#dfaa3f", "#d1d9ca", "#6bc8cd", "#5bde7a"];

// const mock_count = [0, 1, 0, 4, 4];
const ColumnChart = ({ answer, fields }) => {
    const ref = React.useRef(null);
    const [, setChart] = React.useState(null);
    const [graphValues, setGraphValues] = React.useState([]);
    const [answerChoises, setAnswerChoises] = React.useState([]);
    const [options] = React.useState({
        series: [
            {
                name: "",
                data: [
                    parseFloat(answer?.onePercentage),
                    parseFloat(answer?.twoPercentage),
                    parseFloat(answer?.threePercentage),
                    parseFloat(answer?.fourPercentage),
                    parseFloat(answer?.fivePercentage)
                ]
            }
        ],
        chart: {
            height: 350,
            width: 600,
            type: "bar",

            toolbar: {
                show: false
            }
        },
        colors: colors,
        plotOptions: {
            bar: {
                columnWidth: "25%",
                distributed: true
            }
        },
        legend: {
            position: "left",
            offsetX: -300,
            offsetY: 190,
            formatter: (choice, opts) => {
                setAnswerChoises(prevState => {
                    // console.log("data", opts.w.config.series[0].data)
                    if (opts.w.config.series[0].data.length !== prevState.length) prevState = [...prevState, choice];
                    return prevState;
                });
            },
            shape: "circle"
        },
        dataLabels: {
            formatter: (val, opts) => {
                setGraphValues(prevState => {
                    // console.log("data", opts.w.config.series[0].data)
                    if (opts.w.config.series[0].data.length !== prevState.length) prevState = [...prevState, val];
                    return prevState;
                });
            }
        },
        tooltip: {
            enabled: false
        },
        xaxis: {
            show: false,
            categories: fields?.hasOwnProperty(answer.questionType)
                ? JSON.parse(fields[answer.questionType])
                : ["1", "2", "3", "4", "5"],
            labels: {
                show: false,
                style: {
                    colors: colors,
                    fontSize: "12px"
                }
            }
        },
        yaxis: {
            min: 0,
            max: 100
        }
    });

    // console.log("graphValues", graphValues)
    // console.log("answerChoises", answerChoises)
    React.useEffect(() => {
        if (ref.current && options) {
            const chart = new ApexCharts(ref.current, options);
            chart.render();
            setChart(chart);
        }
        return () => {
            setChart(prevChart => {
                if (prevChart && typeof prevChart.destroy === "function") {
                    prevChart.destroy();
                    return null;
                }
                return prevChart;
            });
        };
    }, [options]);

    return (
        <div id={styles["pdf"]}>
            <div className="d-flex flex-row align-items-end">
                <div>
                    <p className={`${styles.responses} ml-10`}>Answer Choices</p>
                    <ul className={`${styles.responseList} ${styles["list-margin"]}`}>
                        {answerChoises.map((value, index) => {
                            return (
                                <li
                                    key={"graphValue___" + index}
                                    className={styles.listItem}
                                    style={{ color: colors[index] }}>
                                    <div className={styles.listItemValue}>{value}</div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div ref={ref} />

                <div className="d-flex ">
                    <div>
                        <p className={`${styles.responses} ml-10 `}>Responses</p>
                        <ul className={`${styles.responseList} mb-8`}>
                            <li className={`${answer?.oneCount === 0 ? styles.zeroValue : styles.value} `}>
                                <div className="d-flex flex-row justify-content-between">
                                    <div>{parseInt(answer?.onePercentage).toFixed(2) + "%"}</div>
                                    <div>{answer?.oneCount}</div>
                                </div>
                            </li>
                            <li className={`${answer?.twoCount === 0 ? styles.zeroValue : styles.value} `}>
                                <div className="d-flex flex-row justify-content-between">
                                    <div>{parseInt(answer?.twoPercentage).toFixed(2) + "%"}</div>
                                    <div>{answer?.twoCount}</div>
                                </div>
                            </li>
                            <li className={`${answer?.threeCount === 0 ? styles.zeroValue : styles.value} `}>
                                <div className="d-flex flex-row justify-content-between">
                                    <div>{parseInt(answer?.threePercentage).toFixed(2) + "%"}</div>
                                    <div>{answer?.threeCount}</div>
                                </div>
                            </li>
                            <li className={`${answer?.fourCount === 0 ? styles.zeroValue : styles.value} `}>
                                <div className="d-flex flex-row justify-content-between">
                                    <div>{parseInt(answer?.fourPercentage).toFixed(2) + "%"}</div>
                                    <div>{answer?.fourCount}</div>
                                </div>
                            </li>
                            <li className={`${answer?.fiveCount === 0 ? styles.zeroValue : styles.value} `}>
                                <div className="d-flex flex-row justify-content-between">
                                    <div>{parseInt(answer?.fivePercentage).toFixed(2) + "%"}</div>
                                    <div>{answer?.fiveCount}</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* <div>
                        <p className={styles.responses}></p>
                        <ul className={styles.countList}>
                            {.map((count, index) => {
                                return (
                                    <li key={"count__" + index} className={` ${count === 0 ? styles.zeroValue : styles.countItem}`}>

                                    </li>

                                )
                            })}
                        </ul>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ColumnChart;
