import React from "react";
import { connect } from "react-redux";
import Button from "app/popleads/components/Button";
import * as campaignsRedux from "../../modules/campaigns/redux/campaignsRedux";
import { post } from "app/popleads/networking/RequestService";
import * as util from "../../app/functions/util";

const AttendanceActions = ({ selectedAttendanceListFromFilter, user }) => {
    const downloadAttendanceReport = () => {
        const requestObj = {
            fn: "getCampaign",
            campaignId: selectedAttendanceListFromFilter[0].value,
        };
        // isCampaignOver ?
        post(requestObj).then((response) => {
            const reportUrl =
                user.company.companyConfig.ecommerceSiteURL + "/export-attendance/" + response.data.campaign.slug;
            const name = response.data.campaign.slug + ".pdf";
            const authToken = util.getCookieValue(util.getCookieName());
            const url =
                window.location.protocol +
                "//" +
                window.location.host +
                "/api/ws/download/" +
                name +
                "?token=" +
                authToken +
                "&url=" +
                reportUrl;
            window.open(url, "_blank");
        });
        // : toast.error("Course is not over yet.");
    };

    const getQrCode = () => {
        const requestObj = {
            fn: "getUniqueLinkForSurvey",
            campaignId: selectedAttendanceListFromFilter[0].value,
        };

        post(requestObj).then((response) => {
            window.open("/api/ws/createQrCode?url=" + response.data.link);
        });
    };

    return (
        <div>
            <div className="pdf-mobile ml-2">
                <Button
                    icon
                    outline
                    variant="secondary"
                    className="mr-2 svg-button"
                    src={"/media/svg/popleads/qr-english.svg"}
                    onClick={getQrCode}
                />

                <Button
                    icon={true}
                    outline
                    variant="danger"
                    src="/media/svg/popleads/pdf.svg"
                    onClick={downloadAttendanceReport}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedAttendanceListFromFilter: state.campaigns.selectedAttendanceListFromFilter,
        isCampaignOver: state.campaigns.isCampaignOver,
        user: state.auth.user,
    };
};

const mapDispatchToProps = {
    ...campaignsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttendanceActions);
