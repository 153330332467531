import React from "react";
import { nameTag } from "app/popleads/app/functions/util";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "_metronic/_helpers";

import styles from "./ChatItem.module.scss";
import moment from "moment";

const ChatItem = ({ role, content, timestamp }) => {
    const { user } = useSelector((state) => state.auth);
    return (
        <div className={`${styles.chatItem} ${role === "user" ? styles.user : styles.assistant}`}>
            <div className={styles.avatar}>
                {role === "assistant" ? (
                    <SVG src={toAbsoluteUrl("/media/svg/popleads/hr_assistant_sarah.svg")} />
                ) : (
                    nameTag(user?.name)
                )}
            </div>
            <div className={`${styles.messageBubble} ${role === "user" ? styles.userBubble : styles.assistantBubble}`}>
                <div dangerouslySetInnerHTML={{ __html: content }} className={`${styles.message} text-card`} />

                <div className={`${styles.timestamp} just-small-text`}>{moment(timestamp).format("HH:mm")}</div>
            </div>
        </div>
    );
};

export default ChatItem;
