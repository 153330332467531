import React from "react";
import RadioButtons from "../../../../components/RadioButtons";

const CheckboxFilter = ({ title, option, setMethod, currentState }) => {
    const handleChange = selectedOption => {
        setMethod(selectedOption.value);
    };

    const removeFilter = () => {
        setMethod(undefined);
    };

    return (
        <div className="d-flex flex-column mt-4 mb-4">
            <div className="filter-heading d-flex justify-content-between">
                <div>{title}</div>
                <div className=" text-muted cursor-pointer" onClick={removeFilter}>
                    {!!currentState ? "X" : ""}
                </div>
            </div>
            <div className="mt-2" style={{ maxWidth: "425px" }}>
                <RadioButtons options={option} currentSelected={currentState} onChange={handleChange} bgColorApplied />
            </div>
        </div>
    );
};

export default CheckboxFilter;
