import React from "react";
import { NavLink } from "react-router-dom";
import Button from "../../components/Button";
import CoursesFilterDrawer from "../drawers/CoursesFilterDrawer";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/popleads/modules/campaigns/redux/campaignsRedux";
import ViewTypeToggle from "../components/ViewTypeToggle";

const CourseListActions = () => {
    const { user } = useSelector((state) => state.auth);
    const { gridOrTable } = useSelector((state) => state.campaigns);

    const dispatch = useDispatch();

    return (
        <div className="d-flex align-items-center">
            {user?.permissions?.CAMPAIGNTEMPLATE_MANAGER === 1 && (
                <Button
                    icon
                    outline
                    variant="success"
                    onClick={() => dispatch(actions.setCoursesExcelExport(true))}
                    className="excelIcon ml-2"
                    src="/media/svg/popleads/export_excel_icon.svg"
                />
            )}
            <CoursesFilterDrawer />
            <ViewTypeToggle
                viewType={gridOrTable}
                setViewType={(viewType) => dispatch(actions.gridOrTableButton(viewType))}
            />
            {user?.permissions?.CAMPAIGNTEMPLATE_MANAGER === 1 && (
                <NavLink to="/campaigns/courses/add" className="cardToolbarMuted">
                    {/* FOR MOBILE + TABLET */}
                    <Button
                        className="d-flex d-lg-none justify-content-center align-items-center ml-1 ml-sm-4"
                        variant="success"
                        icon
                        src="/media/svg/popleads/plus.svg"
                        outline
                    />
                    {/* FOR LG SCREEN AND ABOVE */}
                    <Button
                        className="d-none d-lg-flex ml-1 ml-sm-4"
                        title="Add New Course"
                        variant="success"
                        icon
                        src="/media/svg/popleads/plus.svg"
                        outline
                    />
                </NavLink>
            )}
        </div>
    );
};

export default CourseListActions;
