import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as dealsRedux from "../deals/redux/dealsRedux";
import * as authRedux from "../../../modules/Auth/_redux/authRedux";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import SimpleModal from "../modal/simpleModal";
import { get, post } from "../../networking/RequestService";
import { customDateRanges, silentlyParseJSON } from "../../app/functions/util";
import DealFullPrice from "./components/deal-filters/DealFullPrice";
import DealSearchFilter from "./components/deal-filters/DealSearchFilter";
import DealSelectType from "./components/deal-filters/DealSelectType";
import DealSelfInvFilter from "./components/deal-filters/DealSelfInvFilter";
import DealStatus from "./components/deal-filters/DealStatus";
import NewMultiSelectFilter from "./components/NewMultiSelectFilter";
import SaveFilter from "./components/SaveFilter";
import { dealTypeOptions } from "../deals/constants";
import CustomDrawer, { DrawerBody, DrawerContent, DrawerHeader } from "app/popleads/components/drawer/CustomDrawer";
import InlineSVG from "react-inlinesvg";
import { Button as BootstrapButton } from "react-bootstrap";
import { TooltipOverlay } from "app/popleads/components/TooltipOverlay";
import useDisclosure from "app/popleads/hooks/useDisclosure";
import { toAbsoluteUrl } from "_metronic/_helpers";
import { DateRangePicker } from "react-date-range";
import styles from "../../../popleads/assets/css/design_3.0/CustomDatePicker.module.scss";

const DealsFilters = ({
    user,
    employees,
    units,
    dates,
    dealName,
    status,
    dealTypes,
    marketingChannels,
    accounts,
    titles,
    selfInvestment,
    fullPrice,
    campaignNames,
    campaignOptions,
    campaignTypes,
    courseNames,
    partners,
    ...props
}) => {
    // const [isLoading, setIsLoading] = useState(false);
    const [usersFilters, setUsersFilters] = useState([]);
    const [selectedFilterId, setSelectedFilterId] = useState(null);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [deletedFilterId, setDeletedFilterId] = useState(null);

    const employeesRef = useRef();
    const unitRef = useRef();
    const marketingRef = useRef();
    const accountRef = useRef();
    const titleRef = useRef();
    const campaignNameRef = useRef();
    const courseNameRef = useRef();
    const partnerRef = useRef();

    const { isOpen, onClose, onToggle } = useDisclosure();

    const onFilterSelected = (filter) => {
        const { id, filters } = filter;

        setSelectedFilterId(id);

        props.setDealSelectedEmployees(filters.employees);
        props.setDealsSelectedUnits(filters.units);
        props.setSelectedDate(filters.dates);
        props.setDealQueryString(filters.dealName);
        props.setDealsSelectedStatus(filters.status);
        props.setDealsTypeNameSelect(filters.dealTypes);
        props.setDealsSelectedMarketingChannels(filters.marketingChannels);
        props.setDealsSelectedAccounts(filters.accounts);
        props.setDealsSelectedTitles(filters.titles);
        props.setDealSelfInvestmentSelect(filters.selfInvestment);
        props.setDealFullPrice(filters.fullPrice);
        props.setDealsCampaignNameSelect(filters.campaignNames);
        props.setDealsCampaignTypeSelect(filters.campaignTypes);
        props.setDealsCourseNameSelect(filters.courseNames);
        props.setDealsTablePartnerSelect(filters.partners);
    };

    const saveNewFilter = async (name) => {
        try {
            // setIsLoading(true);
            const newId = uuidv4();
            const newFilter = {
                id: newId,
                name: name,
                createdTime: moment().valueOf(),
                filters: {
                    employees,
                    units,
                    dates,
                    dealName,
                    status,
                    dealTypes,
                    marketingChannels,
                    accounts,
                    titles,
                    selfInvestment,
                    fullPrice,
                    campaignNames,
                    campaignTypes,
                    courseNames,
                    partners,
                },
            };
            const reqObj = {
                fn: "setUserSettings",
                settingsKey: "deals_filters_settings",
                settingsValue: JSON.stringify([newFilter, ...usersFilters]),
            };
            await post(reqObj);
            const { data } = await get("getLoggedinUser");
            if (data.user) {
                props.setUser(data.user);
            }
            setSelectedFilterId(newId);
        } catch (error) {
            // console.log(error);
        } finally {
            // setIsLoading(false);
        }
    };

    const deleteSelectedFilter = async () => {
        try {
            // setIsLoading(true);
            const updatedArr = usersFilters.filter((item) => item.id !== deletedFilterId);
            const reqObj = {
                fn: "setUserSettings",
                settingsKey: "deals_filters_settings",
                settingsValue: JSON.stringify(updatedArr),
            };
            await post(reqObj);
            const { data } = await get("getLoggedinUser");
            if (data.user) {
                props.setUser(data.user);
            }
            clearAllFilters();
        } catch (error) {
            // console.log(error);
        } finally {
            // setIsLoading(false);
        }
    };

    const clearAllFilters = () => {
        setSelectedFilterId(null);

        employeesRef.current.clearSearch();
        unitRef.current.clearSearch();
        marketingRef.current.clearSearch();
        accountRef.current.clearSearch();
        titleRef.current.clearSearch();
        campaignNameRef.current.clearSearch();
        courseNameRef.current.clearSearch();
        partnerRef.current.clearSearch();

        props.setDealSelectedEmployees([{ value: user.id, label: "My Deals" }]);
        props.setDealsSelectedUnits([]);
        props.setSelectedDate({
            startDate: moment()
                .startOf("year")
                .toDate()
                .valueOf(),
            endDate: moment()
                .endOf("year")
                .toDate()
                .valueOf(),
        });
        props.setDealQueryString("");
        props.setDealsSelectedStatus(undefined);
        props.setDealsTypeNameSelect([]);
        props.setDealsSelectedMarketingChannels([]);
        props.setDealsSelectedAccounts([]);
        props.setDealsSelectedTitles([]);
        props.setDealSelfInvestmentSelect(undefined);
        props.setDealFullPrice(undefined);
        props.setDealsCampaignNameSelect([]);
        props.setDealsCampaignTypeSelect([]);
        props.setDealsCourseNameSelect([]);
        props.setDealsTablePartnerSelect([]);
    };

    useEffect(() => {
        setUsersFilters(silentlyParseJSON(user.dealsFilters, "[]"));
    }, [user.dealsFilters]);

    useEffect(() => {
        if (
            (employees && employees.length > 0 && !(employees.length === 1 && employees[0].value === user.id)) ||
            (units && units.length > 0) ||
            dates?.startDate !==
                moment()
                    .startOf("year")
                    .toDate()
                    .valueOf() ||
            dates?.endDate !==
                moment()
                    .endOf("year")
                    .toDate()
                    .valueOf() ||
            dealName ||
            status ||
            (dealTypes && dealTypes.length > 0) ||
            (marketingChannels && marketingChannels.length > 0) ||
            (accounts && accounts.length > 0) ||
            (titles && titles.length > 0) ||
            selfInvestment ||
            fullPrice ||
            (campaignNames && campaignNames.length > 0) ||
            (campaignTypes && campaignTypes.length > 0) ||
            (courseNames && courseNames.length > 0) ||
            (partners && partners.length > 0)
        ) {
            setIsFilterApplied(true);
        } else {
            setIsFilterApplied(false);
        }
    }, [
        user,
        employees,
        units,
        dates,
        dealName,
        status,
        dealTypes,
        marketingChannels,
        accounts,
        titles,
        selfInvestment,
        fullPrice,
        campaignNames,
        campaignTypes,
        courseNames,
        partners,
    ]);
    useEffect(() => {
        if (props.showDealsActions) {
            const range = document.querySelector(".rdrStaticRanges")?.firstElementChild;
            if (range && !dates?.startDate && !dates?.endDate) {
                range && range.classList.add("rdrStaticRangeSelected");
            } else if (dates?.startDate && dates?.endDate) {
                range && range.classList.remove("rdrStaticRangeSelected");
            }
        }
    }, [dates]);
    return (
        <>
            {deletedFilterId && (
                <SimpleModal
                    title=""
                    body="Are you sure you want to delete this filter?<br>"
                    className="d-flex flex-column align-items-center"
                    bodyClass="font-weight-semibold font-size-20px"
                    primaryButton="Delete"
                    secondaryButton="Cancel"
                    onClose={(resp) => {
                        if (resp === "OK") deleteSelectedFilter();
                        setDeletedFilterId(null);
                    }}
                />
            )}
            <TooltipOverlay placement="bottom" tooltip="Filters">
                <BootstrapButton variant="secondary" className="ml-1 ml-sm-4 customBootstrapBtn" onClick={onToggle}>
                    <InlineSVG src={toAbsoluteUrl("/media/svg/icons/Text/Filters2.svg")} />
                </BootstrapButton>
            </TooltipOverlay>
            <CustomDrawer position="right" size="md" open={isOpen} onClose={onClose}>
                <DrawerContent isLoading={false}>
                    <DrawerHeader onClose={onClose} sx={{ border: "none" }}>
                        <div>Filters</div>
                    </DrawerHeader>
                    <DrawerBody sx={{ paddingTop: 0 }}>
                        <SaveFilter
                            usersFilters={usersFilters}
                            isFilterApplied={isFilterApplied}
                            selectedFilterId={selectedFilterId}
                            onFilterSelected={onFilterSelected}
                            saveNewFilter={saveNewFilter}
                            deleteSelectedFilter={(id) => setDeletedFilterId(id)}
                            clearAllFilters={clearAllFilters}
                        />
                        <NewMultiSelectFilter
                            inputLabel="Unit"
                            fn="getAllUnits"
                            selectedItems={units}
                            setSelectedItems={props.setDealsSelectedUnits}
                            ref={unitRef}
                            disabledSearch={
                                (employees?.length > 0 ? true : false) ||
                                (units?.length === 2 ? true : false) ||
                                (units.length === 1 && employees?.length === 2 ? true : false)
                            }
                        />
                        <NewMultiSelectFilter
                            inputLabel="Employees"
                            fn="companyUsers"
                            selectedItems={employees}
                            setSelectedItems={props.setDealSelectedEmployees}
                            ref={employeesRef}
                            disabledSearch={
                                (units.length > 0 || employees?.length === 2 ? true : false) ||
                                (units.length === 1 && employees?.length === 2 ? true : false)
                            }
                        />
                        <div className="filterbar-heading  mb-2">Filter by Date</div>
                        <DateRangePicker
                            className={`${styles.rdrDateRangePickerWrapper} mt-4 mb-8`}
                            ranges={[
                                {
                                    startDate: dates?.startDate ? moment(dates.startDate).toDate() : null,
                                    endDate: dates?.endDate ? moment(dates.endDate).toDate() : null,
                                    key: "selection",
                                },
                            ]}
                            staticRanges={customDateRanges()}
                            onChange={(item) => {
                                props.setSelectedDate({
                                    startDate: item.selection.startDate
                                        ? moment(item.selection.startDate)
                                              .startOf("days")
                                              .valueOf()
                                        : null,
                                    endDate: item.selection.endDate
                                        ? moment(item.selection.endDate)
                                              .endOf("days")
                                              .valueOf()
                                        : null,
                                });
                            }}
                            inputRanges={[]}
                            direction="vertical"
                        />
                        <DealSearchFilter
                            heading="Deal Name"
                            value={dealName}
                            handleChange={(e) => props.setDealQueryString(e.target.value)}
                            dealRemoveFilterType={() => props.setDealQueryString("")}
                        />
                        <DealStatus />
                        <DealSelectType
                            constant={dealTypeOptions}
                            heading="Deal Type"
                            selected={dealTypes}
                            setSelected={props.setDealsTypeNameSelect}
                        />
                        <NewMultiSelectFilter
                            inputLabel="Marketing Channel"
                            fn="searchDealFields"
                            selectedItems={marketingChannels}
                            setSelectedItems={props.setDealsSelectedMarketingChannels}
                            ref={marketingRef}
                        />
                        <NewMultiSelectFilter
                            inputLabel="Account"
                            fn="accountListHomePage"
                            selectedItems={accounts}
                            setSelectedItems={props.setDealsSelectedAccounts}
                            ref={accountRef}
                            from={"dealsFilters"}
                        />
                        <NewMultiSelectFilter
                            inputLabel="Title"
                            fn="searchDealFields"
                            selectedItems={titles}
                            setSelectedItems={props.setDealsSelectedTitles}
                            ref={titleRef}
                        />
                        <DealSelfInvFilter />
                        <DealFullPrice />
                        <NewMultiSelectFilter
                            inputLabel="Campaign Name"
                            fn="campaignListHomePage"
                            isForDeals={true}
                            selectedItems={campaignNames}
                            setSelectedItems={props.setDealsCampaignNameSelect}
                            ref={campaignNameRef}
                        />
                        <DealSelectType
                            constant={campaignOptions}
                            heading="Campaign Type"
                            selected={campaignTypes}
                            setSelected={props.setDealsCampaignTypeSelect}
                        />
                        <NewMultiSelectFilter
                            inputLabel="Course Name"
                            fn="getCampaignTemplate"
                            selectedItems={courseNames}
                            setSelectedItems={props.setDealsCourseNameSelect}
                            ref={courseNameRef}
                        />
                        <NewMultiSelectFilter
                            inputLabel="Partner"
                            fn="companyUsers"
                            selectedItems={partners}
                            setSelectedItems={props.setDealsTablePartnerSelect}
                            ref={partnerRef}
                        />
                    </DrawerBody>
                </DrawerContent>
            </CustomDrawer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        employees: state.deals.dealSelectedEmployees,
        units: state.deals.dealsSelectedUnits,
        dates: state.deals.dealsSelectedDate,
        status: state.deals.dealsSelectedStatus,
        dealName: state.deals.dealQueryString,
        dealTypes: state.deals.dealSelectedType,
        marketingChannels: state.deals.dealsSelectedMarketingChannels,
        accounts: state.deals.dealsSelectedAccounts,
        titles: state.deals.dealsSelectedTitles,
        selfInvestment: state.deals.dealSelfInvestment,
        fullPrice: state.deals.dealFullPrice,
        campaignNames: state.deals.dealsSelectedCampaignNames,
        campaignOptions: state.deals.dealCampaignConstant,
        campaignTypes: state.deals.dealSelectedCampaignType,
        courseNames: state.deals.dealsCourseNameSelect,
        partners: state.deals.dealPartnerSelect,
        dealExcelExport: state.deals.dealExcelExport,
        showDealsActions: state.deals.showDealsActions,
    };
};

const mapDispatchToProps = {
    ...authRedux.actions,
    ...dealsRedux.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealsFilters);
