import React from "react";
import { getUrlQueryParam } from "../../../../../app/functions/util";

import YoySessionCompTable from "./components/YoySessionCompTable";
import DeliveredBreakdownTable from "./components/DeliveredBreakdownTable";
import FinalReportHeader from "../common/FinalReportHeader";
import TotalDelivered from "./components/TotalDelivered";

import commonStyles from "../../final-report-common.module.scss";
import styles from "./Page3.module.scss";
import { getChartSubtitle } from "../../../../../app/functions/finalReportUtil";

const Page3 = () => {
    const startDate = getUrlQueryParam("startDate");
    const endDate = getUrlQueryParam("endDate");
    const unitId = getUrlQueryParam("unitId")?.split(",");

    return (
        <div className={commonStyles.page}>
            <FinalReportHeader />
            <div className={commonStyles.content}>
                <div className="mb-10">
                    <h2 className={`${styles.itemTitle} mb-6`}>HISTORICAL FIGURES DELIVERED</h2>
                    <TotalDelivered startDate={startDate} endDate={endDate} unitId={unitId} />
                </div>
                <div className="mb-10">
                    <h2 className={styles.itemTitle}>BREAKDOWN OF DELIVERED PROJECTS</h2>
                    <DeliveredBreakdownTable startDate={startDate} endDate={endDate} unitId={unitId} />
                </div>
                <div>
                    <h2 className={styles.itemTitle}>
                        {"YOY SESSION COMPARISON FOR THIS " + getChartSubtitle(startDate, endDate)}
                    </h2>
                    <YoySessionCompTable startDate={startDate} endDate={endDate} unitId={unitId} />
                </div>
            </div>
        </div>
    );
};

export default Page3;
