import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { post } from "../../networking/RequestService";
import { connect } from "react-redux";
import * as trainingRedux from "../training/redux/trainingRedux";
import * as accountsRedux from "../accounts/redux/accountsRedux";
import * as util from "../../app/functions/util";
import DOMPurify from "dompurify";
import PopleadsButton from "./../../components/Button";

function modal(props) {
    function SimpleModal() {
        const [show, setShow] = useState(true);

        const handleClose = resp => {
            props.onClose(resp);
        };
        const handleShow = () => setShow(true);

        const [errorMessage, setErrorMessage] = useState(false);
        const [isLoading, setIsLoading] = useState(false);

        function createBodyHTML(html) {
            // Always sanitize user input before rendering to DOM with dangerouslySetInnerHTML
            return { __html: DOMPurify.sanitize(props.body) };
        }

        return (
            <>
                <Modal
                    animation={false}
                    enforceFocus={false}
                    show={show}
                    onHide={() => handleClose("Cancel")}
                    size={props.size ? props.size : "md"}>
                    <Modal.Header closeButton>
                        <Modal.Title className="simpleModalTitle page-heading">{props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={`${props.className ? props.className : ""}`}>
                        {props.bodySvgUrl && (
                            <span className={`svg-icon-6x `}>
                                <SVG title="Info" src={toAbsoluteUrl(props.bodySvgUrl)} />
                            </span>
                        )}
                        <div
                            className={`simpleModalBody ${props.bodyClass ? props.bodyClass : ""}`}
                            dangerouslySetInnerHTML={createBodyHTML()}></div>
                        {props.errorMessage && <span className="errorMessage">{props.errorMessage}</span>}
                    </Modal.Body>
                    <Modal.Footer>
                        {props.buttonWithDisableColor && (
                            <button
                                className="disable-color font-size-14 font-weight-semibold cancelButton"
                                onClick={() => handleClose("Cancel")}>
                                {props.buttonWithDisableColor}
                            </button>
                        )}
                        {props.primaryButton && (
                            <Button variant="primary" className="saveButtonModal" onClick={() => handleClose("OK")}>
                                {props.primaryButton}
                            </Button>
                        )}
                        {props.secondaryButton && (
                            <Button
                                variant="secondary"
                                className="cancelButtonModal"
                                onClick={() => handleClose("Cancel")}>
                                {props.secondaryButton}
                            </Button>
                        )}
                        {props.secondaryConfirmButton && (
                            <Button variant="secondary" className="cancelButtonModal" onClick={() => handleClose("OK")}>
                                {props.secondaryConfirmButton}
                            </Button>
                        )}
                        {props.secondaryOutlinedButton && (
                            <PopleadsButton
                                variant="secondary"
                                outline
                                title={props.secondaryOutlinedButton}
                                onClick={() => handleClose("OK")}
                            />
                        )}
                        {props.successOutlinedButton && (
                            <PopleadsButton
                                title={props.successOutlinedButton}
                                outline
                                variant="success"
                                onClick={() => handleClose("OK")}
                            />
                        )}
                        {props.primaryCancelButton && (
                            <PopleadsButton
                                title={props.primaryCancelButton}
                                variant="secondary"
                                outline
                                onClick={() => handleClose("Cancel")}
                            />
                        )}
                        {props.dangerButton && (
                            <PopleadsButton
                                title={props.dangerButton}
                                variant="danger"
                                onClick={() => handleClose("OK")}
                            />
                        )}
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    return <SimpleModal />;
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(modal);
