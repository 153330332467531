import moment from "moment";

export const actionTypes = {
    ShowActionButtons: "[Action] Show Quick Action Buttons",
    ViewType: "[Action] View Type",
    ShowAddActivityModal: "[Action] Show Add Activity Modal",
    SearchQuery: "[Action] Search Query",
    GroupByFilter: "[Action] Group By Filter",
    GroupBySortFilter: "[Action] Group By Sort Filter",
    FilterByFilter: "[Action] Filter By Filter",
    AssigneeFilter: "[Action] Assignee Filter",
    PriorityFilter: "[Action] Priority Filter",
    TagsFilter: "[Action] Tags Filter",
    ActivityTypeFilter: "[Action] Activity Type Filter",
    ShowAddSubActivity: "[Action] Show Add Sub Activity",
    ParentActivityId: "[Action] Parent Activity Id",
    IsTagModalShown: "[Action] Is Tag Modal Shown",
    IsActivityDetailModalShown: "[Action] Is Activity Detail Modal Shown",
    IsCampaignListDrawerShown: "[Action] Is Campaign List Drawer Shown",
    IsAssigneeDrawerOpen: "[Action] Is Assignee Drawer Open",
    IsDeleteDrawerOpen: "[Action] Is Delete Drawer Open",
    ActivityId: "[Action] Activity Id",
    ActivityAddModalAssignees: "[Action] Activity Add Modal Assignees",
    FetchData: "[Action] Fetch Data",
    SelectedActivity: "[Action] Selected Activity",
    MainActivityData: "[Action] Main Activity Data",
    IsActivityDrawerOpen: "[Action] Is Activity Drawer Open",
    IsActivityDetailDrawerShown: "[Action] Is Activity Detail Drawer Shown",
    IsPriorityDrawerShown: "[Action] Is Priority Drawer Shown",
    IsStatusDrawerShown: "[Action] Is Status Drawer Shown",
    IsFollowerDrawerShown: "[Action] Is Follower Drawer Shown",
    IsNotesDrawerShown: "[Action] Is Notes Drawer Shown",
    IsEditDrawerShown: "[Action] Is Edit Drawer Shown",
    IsMoveDrawerShown: "[Action] Is Move Drawer Shown",
    IsSelectApproverDrawerShown: "[Action] Is Select Approver Drawer Shown",
    IsDuplicateDrawerShown: "[Action] Is Duplicate Drawer Shown",
    IsDeleteModalShown: "[Action] Is Delete Modal Shown",
    Notes: "[Action] Notes",
    IsLostFocusModalShown: "[Action] Is Lost Focus Modal Shown",
    IsSubActivity: "[Action] Is Sub Activity",
    IsTagDrawerShown: "[Action] Is Tag Drawer Shown",
    IsDuplicateModalShown: "[Action] Is Duplicate Modal Shown",
    DuplicatedActivity: "[Action] Duplicated Activity ",
    SelectedCampaignFilter: "[Action] Campaign Filter",
    CampaignListForFilter: "[Action] Campaign List For Filter",
    selectedCampaignListFromFilter: "[Action] Selected Campaign List From Filter",
    IsDurationDrawerOpen: "[Action] Is Duration Drawer Open",
    RefreshData: "[Action] Refresh Data",
    ErrorMessage: "[Action] Error Message",
    Durations: "[Action] Durations",
    IsDurationInfoDrawerOpen: "[Action] Is Duration Info Drawer Open",
    IsDeleteDocumentDrawerOpen: "[Action] Is Delete Document Drawer Open",
    IsApproveActivityModalShown: "[Action] Is Approve Activity Modal Shown",
    FilterByAssignee: "[Action] Filter By Assignee",
    FilterByCreator: "[Action] Filter By Creator",
    NewActivityLoading: "[Action] New Activity Loading",
    IsNewActivity: "[Action] Is New Activity",
    IsDeleteNoteDrawerShown: "[Action] Is Delete Note Drawer Shown",
    SingleDuration: "[Action] Single Duration",
    SelectedDurationTab: "[Action] Selected Duration Tab",
    DurationInput: "[Action] Duration Input",
    RangeInputs: "[Action] Range Inputs",
    Date: "[Action] Date Range",
    IsDeleteActivityModalShown: "[Action] Is Delete Activity Modal Shown",
    ActivityAddModalFollowers: "[Action] Activity Add Modal Followers",
    IsMarkAsUnreadDrawerShown: "[Action] Is Mark As Unread Drawer Shown",
    IsApproveDrawerShown: "[Action] Is Approve Drawer Shown",
    CompanyUsersAndGroupsData: "[Action] Company Users And Groups Data",
    CompanyUsersData: "[Action] Company Users Data",
    CompanyGroupsData: "[Action] Company Groups Data",
    SelectedFiles: "[Action] Selected Files",
    IsDeleteDocumentModalShown: "[Action] Is Delete Document Modal Shown",
    CanActivityDrawerClose: "[Action] Can Activity Drawer Close",
    IsDeleteCompleted: "[Action] Is Delete Completed",
    ChangedFields: "[Action] Changed Fields",
    FetchActivityDetailAgain: "[Action] Fetch Activity Detail Again",
    TableDataCount: "[Action] Table Data Count",
    IsActivitiesLoading: "[Action] Activities Loading",
    IsEditingActivityDetail: "[Action] Is Editing Activity Detail",
    ActivityDateFilter: "[Action] Activity Date Filter",
    IsFilterByCampaignModalShown: "[Action] Is Filter By Campaign Modal Shown",
    CampaignDetailModalId: "[Action] Campaign Detail Modal Id",
    IsMindmapActionPlanChecked: "[Action] Is Mindmap Action Plan Checked",
    IsMindmapActivitiesChecked: "[Action] Is Mindmap Activities Checked",
    OrderBy: "[Action] Order By",
    RefetchDocumentsFlag: "[Action] Refetch Documents Flag",
    ActivityStatusOptions: "[Action] Activity Status Options",
    ActivityData: "[Action] Activity Data",
    EditActivityListAssignees: "[Action] Edit Activity List Assignees",
    EditActivityListTags: "[Action] Edit Activity List Tags",
    EditedActivity: "[Action] Edited Activity",
    HideActionPlan: "[Action] Hide Action Plan",
    ShowCampaignSidebar: "[Action] Show Campaign Sidebar",
    IsFileDragging: "[Action] Is File Dragging",
    MentionedUsers: "[Action] Mentioned Users",
    MentionableUsers: "[Action] Mentionable Users",
    RefetchMentionableUsers: "[Action] Refetch Mentionable Users",
    DocumentsList: "[Action] Documents List",
    IsStatusDocumentDrawerOpen: "[Action] Is Status Document Drawer Open",
    campaignStatus: "[Action] Campaign Status",
    campaignId: "[Action] Campaign Id",
    campaignStatus: "[Action] Campaign Status",
    campaignDATA: "[Action] Campaign DATA",
};

const initialReportsState = {
    viewType: null,
    showActionButtons: false,
    showAddActivityModal: false,
    searchQuery: "",
    selectedGroupByOption: {
        value: "status",
        label: "Status",
    },
    priorityFilter: [],
    selectedGroupBySortOption: {
        value: "desc",
        label: "Descending",
    },
    selectedFilterByOptions: [
        {
            value: "assignedToMe",
            label: "Assigned To Me",
        },
    ],
    selectedAssignees: [],
    selectedActivityTypes: [],
    selectedTags: [],
    showAddSubActivity: false,
    isTagModalShown: false,
    isActivityDetailModalShown: false,
    isCampaignListDrawerShown: false,
    parentActivityId: null,
    isAssigneeDrawerOpen: false,
    isDeleteDrawerOpen: false,
    activityId: null,
    activityAddModalAssignees: [],
    fetchData: false,
    selectedActivity: null,
    mainActivityData: null,
    isActivityDrawerOpen: false,
    isActivityDetailDrawerShown: false,
    isPriorityDrawerShown: false,
    isStatusDrawerShown: false,
    isFollowerDrawerShown: false,
    isNotesDrawerShown: false,
    isEditDrawerShown: false,
    isMoveDrawerShown: false,
    isSelectApproverDrawerShown: false,
    isDuplicateDrawerShown: false,
    isDeleteModalShown: false,
    notes: [],
    isLostFocusModalShown: false,
    isSubActivity: false,
    isTagDrawerShown: false,
    isDuplicateModalShown: false,
    duplicatedActivity: null,
    selectedCampaignFilter: {
        status: [],
        campaignType: [],
        year: [],
        month: [],
        week: [],
        campaignAccount: [],
        campaignPm: [],
        campaignAssistant: [],
        campaignCountry: [],
        campaignTrainer: [],
    },
    campaignListForFilter: [],
    selectedCampaignListFromFilter: [],
    isDurationDrawerOpen: false,
    refreshData: false,
    errorMessage: "",
    durations: [],
    isDurationInfoDrawerOpen: false,
    isDeleteDocumentDrawerOpen: false,
    isStatusDocumentDrawerOpen: false,
    isApproveActivityModalShown: false,
    filterByAssignee: [],
    filterByCreator: [],
    newActivityLoading: false,
    isNewActivity: false,
    isDeleteNoteDrawerShown: false,
    singleDuration: null,
    selectedDurationTab: "duration",
    durationInput: null,
    rangeInputs: {
        start: "",
        end: "",
    },
    date: null,
    isDeleteActivityModalShown: false,
    activityAddModalFollowers: [],
    isMarkAsUnreadDrawerShown: false,
    isApproveDrawerShown: false,
    companyUsersAndGroupsData: [],
    companyUserssData: [],
    companyGroupsData: [],
    selectedFiles: [],
    isDeleteDocumentModalShown: false,
    canActivityDrawerClose: true,
    isDeleteCompleted: false,
    changedFields: {},
    fetchActivityDetailAgain: false,
    tableDataCount: "",
    isActivitiesLoading: false,
    isEditingActivityDetail: false,
    activityDateFilter: [
        {
            startDate: moment()
                .startOf("year")
                .toDate(),
            endDate: moment()
                .endOf("year")
                .toDate(),
            key: "selection",
        },
    ],
    isFilterByCampaignModalShown: false,
    CampaignDetailModalId: undefined,
    isMindmapActionPlanChecked: false,
    isMindmapActivitiesChecked: false,
    orderBy: {},
    refetchDocumentsFlag: false,
    activityStatusOptions: [],
    activityData: null,
    editedActivity: null,
    hideActionPlan: {
        label: "No",
        value: "0",
    },
    showCampaignSidebar: true,
    isFileDragging: false,
    mentionableUsers: [],
    refetchMentionableUsers: false,
    documentsList: [],
    campaignStatus: false,
    campaignId: undefined,
};

export const reducer = (state = initialReportsState, action) => {
    switch (action.type) {
        case actionTypes.ViewType: {
            const { viewType } = action.payload;
            return {
                ...state,
                viewType,
            };
        }
        case actionTypes.ShowActionButtons: {
            const { showActionButtons } = action.payload;
            return {
                ...state,
                showActionButtons,
            };
        }
        case actionTypes.ShowAddActivityModal: {
            const { showAddActivityModal } = action.payload;
            return {
                ...state,
                showAddActivityModal,
            };
        }

        case actionTypes.SearchQuery: {
            const { searchQuery } = action.payload;
            return {
                ...state,
                searchQuery,
            };
        }
        case actionTypes.GroupByFilter: {
            const { selectedGroupByOption } = action.payload;
            return {
                ...state,
                selectedGroupByOption,
            };
        }
        case actionTypes.GroupBySortFilter: {
            const { selectedGroupBySortOption } = action.payload;
            return {
                ...state,
                selectedGroupBySortOption,
            };
        }
        case actionTypes.FilterByFilter: {
            const { selectedFilterByOptions } = action.payload;
            return {
                ...state,
                selectedFilterByOptions,
            };
        }
        case actionTypes.AssigneeFilter: {
            const { selectedAssignees } = action.payload;
            return {
                ...state,
                selectedAssignees,
            };
        }
        case actionTypes.ActivityTypeFilter: {
            const { selectedActivityTypes } = action.payload;
            return {
                ...state,
                selectedActivityTypes,
            };
        }
        case actionTypes.TagsFilter: {
            const { selectedTags } = action.payload;
            return {
                ...state,
                selectedTags,
            };
        }

        case actionTypes.ShowAddSubActivity: {
            const { showAddSubActivity } = action.payload;
            return {
                ...state,
                showAddSubActivity,
            };
        }
        case actionTypes.IsTagModalShown: {
            const { isTagModalShown } = action.payload;
            return {
                ...state,
                isTagModalShown,
            };
        }
        case actionTypes.IsActivityDetailModalShown: {
            const { isActivityDetailModalShown } = action.payload;
            return {
                ...state,
                isActivityDetailModalShown,
            };
        }
        case actionTypes.IsCampaignListDrawerShown: {
            const { isCampaignListDrawerShown } = action.payload;
            return {
                ...state,
                isCampaignListDrawerShown,
            };
        }

        case actionTypes.ParentActivityId: {
            const { parentActivityId } = action.payload;
            return {
                ...state,
                parentActivityId,
            };
        }

        case actionTypes.IsAssigneeDrawerOpen: {
            const { isAssigneeDrawerOpen } = action.payload;
            return {
                ...state,
                isAssigneeDrawerOpen,
            };
        }
        case actionTypes.IsDeleteDrawerOpen: {
            const { isDeleteDrawerOpen } = action.payload;
            return {
                ...state,
                isDeleteDrawerOpen,
            };
        }

        case actionTypes.ActivityId: {
            const { activityId } = action.payload;
            return {
                ...state,
                activityId,
            };
        }

        case actionTypes.ActivityAddModalAssignees: {
            const { activityAddModalAssignees } = action.payload;
            return {
                ...state,
                activityAddModalAssignees,
            };
        }
        case actionTypes.FetchData: {
            const { fetchData } = action.payload;
            return {
                ...state,
                fetchData,
            };
        }
        case actionTypes.SelectedActivity: {
            const { selectedActivity } = action.payload;
            return {
                ...state,
                selectedActivity,
            };
        }
        case actionTypes.MainActivityData: {
            const { mainActivityData } = action.payload;
            return {
                ...state,
                mainActivityData,
            };
        }

        case actionTypes.IsActivityDrawerOpen: {
            const { isActivityDrawerOpen } = action.payload;
            return {
                ...state,
                isActivityDrawerOpen,
            };
        }
        case actionTypes.IsActivityDetailDrawerShown: {
            const { isActivityDetailDrawerShown } = action.payload;
            return {
                ...state,
                isActivityDetailDrawerShown,
            };
        }
        case actionTypes.IsPriorityDrawerShown: {
            const { isPriorityDrawerShown } = action.payload;
            return {
                ...state,
                isPriorityDrawerShown,
            };
        }
        case actionTypes.IsStatusDrawerShown: {
            const { isStatusDrawerShown } = action.payload;
            return {
                ...state,
                isStatusDrawerShown,
            };
        }
        case actionTypes.IsFollowerDrawerShown: {
            const { isFollowerDrawerShown } = action.payload;
            return {
                ...state,
                isFollowerDrawerShown,
            };
        }
        case actionTypes.IsNotesDrawerShown: {
            const { isNotesDrawerShown } = action.payload;
            return {
                ...state,
                isNotesDrawerShown,
            };
        }
        case actionTypes.IsEditDrawerShown: {
            const { isEditDrawerShown } = action.payload;
            return {
                ...state,
                isEditDrawerShown,
            };
        }
        case actionTypes.IsMoveDrawerShown: {
            const { isMoveDrawerShown } = action.payload;
            return {
                ...state,
                isMoveDrawerShown,
            };
        }
        case actionTypes.IsSelectApproverDrawerShown: {
            const { isSelectApproverDrawerShown } = action.payload;
            return {
                ...state,
                isSelectApproverDrawerShown,
            };
        }
        case actionTypes.IsDuplicateDrawerShown: {
            const { isDuplicateDrawerShown } = action.payload;
            return {
                ...state,
                isDuplicateDrawerShown,
            };
        }
        case actionTypes.IsDeleteModalShown: {
            const { isDeleteModalShown } = action.payload;
            return {
                ...state,
                isDeleteModalShown,
            };
        }
        case actionTypes.Notes: {
            const { notes } = action.payload;
            return {
                ...state,
                notes,
            };
        }
        case actionTypes.IsLostFocusModalShown: {
            const { isLostFocusModalShown } = action.payload;
            return {
                ...state,
                isLostFocusModalShown,
            };
        }
        case actionTypes.IsSubActivity: {
            const { isSubActivity } = action.payload;
            return {
                ...state,
                isSubActivity,
            };
        }
        case actionTypes.IsTagDrawerShown: {
            const { isTagDrawerShown } = action.payload;
            return {
                ...state,
                isTagDrawerShown,
            };
        }
        case actionTypes.IsDuplicateModalShown: {
            const { isDuplicateModalShown } = action.payload;
            return {
                ...state,
                isDuplicateModalShown,
            };
        }
        case actionTypes.IsDeleteActivityModalShown: {
            const { isDeleteActivityModalShown } = action.payload;
            return {
                ...state,
                isDeleteActivityModalShown,
            };
        }
        case actionTypes.DuplicatedActivity: {
            const { duplicatedActivity } = action.payload;
            return {
                ...state,
                duplicatedActivity,
            };
        }
        case actionTypes.SelectedCampaignFilter: {
            const { selectedCampaignFilter } = action.payload;
            return {
                ...state,
                selectedCampaignFilter,
            };
        }
        case actionTypes.CampaignListForFilter: {
            const { campaignListForFilter } = action.payload;
            return {
                ...state,
                campaignListForFilter,
            };
        }

        case actionTypes.selectedCampaignListFromFilter: {
            const { selectedCampaignListFromFilter } = action.payload;
            return {
                ...state,
                selectedCampaignListFromFilter,
            };
        }
        case actionTypes.IsDurationDrawerOpen: {
            const { isDurationDrawerOpen } = action.payload;
            return {
                ...state,
                isDurationDrawerOpen,
            };
        }
        case actionTypes.RefreshData: {
            const { refreshData } = action.payload;
            return {
                ...state,
                refreshData,
            };
        }
        case actionTypes.ErrorMessage: {
            const { errorMessage } = action.payload;
            return {
                ...state,
                errorMessage,
            };
        }
        case actionTypes.Durations: {
            const { durations } = action.payload;
            return {
                ...state,
                durations,
            };
        }
        case actionTypes.IsDurationInfoDrawerOpen: {
            const { isDurationInfoDrawerOpen } = action.payload;
            return {
                ...state,
                isDurationInfoDrawerOpen,
            };
        }
        case actionTypes.IsDeleteDocumentDrawerOpen: {
            const { isDeleteDocumentDrawerOpen } = action.payload;
            return {
                ...state,
                isDeleteDocumentDrawerOpen,
            };
        }
        case actionTypes.IsStatusDocumentDrawerOpen: {
            const { isStatusDocumentDrawerOpen } = action.payload;
            return { ...state, isStatusDocumentDrawerOpen };
        }
        case actionTypes.IsStatusDocumentDrawerOpen: {
            const { isStatusDocumentDrawerOpen } = action.payload;
            return { ...state, isStatusDocumentDrawerOpen };
        }
        case actionTypes.IsApproveActivityModalShown: {
            const { isApproveActivityModalShown } = action.payload;
            return {
                ...state,
                isApproveActivityModalShown,
            };
        }
        case actionTypes.FilterByAssignee: {
            const { filterByAssignee } = action.payload;
            return {
                ...state,
                filterByAssignee,
            };
        }
        case actionTypes.FilterByCreator: {
            const { filterByCreator } = action.payload;
            return {
                ...state,
                filterByCreator,
            };
        }
        case actionTypes.NewActivityLoading: {
            const { newActivityLoading } = action.payload;
            return {
                ...state,
                newActivityLoading,
            };
        }
        case actionTypes.IsNewActivity: {
            const { isNewActivity } = action.payload;
            return {
                ...state,
                isNewActivity,
            };
        }
        case actionTypes.IsDeleteNoteDrawerShown: {
            const { isDeleteNoteDrawerShown } = action.payload;
            return {
                ...state,
                isDeleteNoteDrawerShown,
            };
        }
        case actionTypes.SingleDuration: {
            const { singleDuration } = action.payload;
            return {
                ...state,
                singleDuration,
            };
        }
        case actionTypes.SelectedDurationTab: {
            const { selectedDurationTab } = action.payload;
            return {
                ...state,
                selectedDurationTab,
            };
        }
        case actionTypes.DurationInput: {
            const { durationInput } = action.payload;
            return {
                ...state,
                durationInput,
            };
        }
        case actionTypes.RangeInputs: {
            const { rangeInputs } = action.payload;
            return {
                ...state,
                rangeInputs,
            };
        }
        case actionTypes.Date: {
            const { date } = action.payload;
            return {
                ...state,
                date,
            };
        }
        case actionTypes.IsDeleteNoteModalShown: {
            const { isDeleteNoteModalShown } = action.payload;
            return {
                ...state,
                isDeleteNoteModalShown,
            };
        }
        case actionTypes.ActivityAddModalFollowers: {
            const { activityAddModalFollowers } = action.payload;
            return {
                ...state,
                activityAddModalFollowers,
            };
        }
        case actionTypes.IsMarkAsUnreadDrawerShown: {
            const { isMarkAsUnreadDrawerShown } = action.payload;
            return {
                ...state,
                isMarkAsUnreadDrawerShown,
            };
        }
        case actionTypes.IsApproveDrawerShown: {
            const { isApproveDrawerShown } = action.payload;
            return {
                ...state,
                isApproveDrawerShown,
            };
        }
        case actionTypes.CompanyUsersAndGroupsData: {
            const { companyUsersAndGroupsData } = action.payload;
            return {
                ...state,
                companyUsersAndGroupsData,
            };
        }
        case actionTypes.CompanyUsersData: {
            const { companyUsersData } = action.payload;
            return {
                ...state,
                companyUsersData,
            };
        }
        case actionTypes.CompanyGroupsData: {
            const { companyGroupsData } = action.payload;
            return {
                ...state,
                companyGroupsData,
            };
        }
        case actionTypes.SelectedFiles: {
            const { selectedFiles } = action.payload;
            return {
                ...state,
                selectedFiles,
            };
        }
        case actionTypes.IsDeleteDocumentModalShown: {
            const { isDeleteDocumentModalShown } = action.payload;
            return {
                ...state,
                isDeleteDocumentModalShown,
            };
        }

        case actionTypes.PriorityFilter: {
            const { priorityFilter } = action.payload;
            return {
                ...state,
                priorityFilter,
            };
        }
        case actionTypes.CanActivityDrawerClose: {
            const { canActivityDrawerClose } = action.payload;
            return {
                ...state,
                canActivityDrawerClose,
            };
        }
        case actionTypes.IsDeleteCompleted: {
            const { isDeleteCompleted } = action.payload;
            return {
                ...state,
                isDeleteCompleted,
            };
        }
        case actionTypes.ChangedFields: {
            const { changedFields } = action.payload;
            return {
                ...state,
                changedFields,
            };
        }

        case actionTypes.FetchActivityDetailAgain: {
            const { fetchActivityDetailAgain } = action.payload;
            return {
                ...state,
                fetchActivityDetailAgain,
            };
        }
        case actionTypes.TableDataCount: {
            const { tableDataCount } = action.payload;
            return {
                ...state,
                tableDataCount,
            };
        }

        case actionTypes.IsActivitiesLoading: {
            const { isActivitiesLoading } = action.payload;
            return {
                ...state,
                isActivitiesLoading,
            };
        }
        case actionTypes.IsEditingActivityDetail: {
            const { isEditingActivityDetail } = action.payload;
            return {
                ...state,
                isEditingActivityDetail,
            };
        }
        case actionTypes.ActivityDateFilter: {
            const { activityDateFilter } = action.payload;
            return {
                ...state,
                activityDateFilter,
            };
        }

        case actionTypes.ShowCampaignSidebar: {
            const { showCampaignSidebar } = action.payload;
            return {
                ...state,
                showCampaignSidebar,
            };
        }

        case actionTypes.IsFilterByCampaignModalShown: {
            const { isFilterByCampaignModalShown } = action.payload;
            return {
                ...state,
                isFilterByCampaignModalShown,
            };
        }

        case actionTypes.CampaignDetailModalId: {
            const { campaignDetailModalId } = action.payload;
            return {
                ...state,
                campaignDetailModalId,
            };
        }

        case actionTypes.IsMindmapActionPlanChecked: {
            const { isMindmapActionPlanChecked } = action.payload;
            return {
                ...state,
                isMindmapActionPlanChecked,
            };
        }

        case actionTypes.IsMindmapActivitiesChecked: {
            const { isMindmapActivitiesChecked } = action.payload;
            return {
                ...state,
                isMindmapActivitiesChecked,
            };
        }

        case actionTypes.OrderBy: {
            const { orderBy } = action.payload;
            return {
                ...state,
                orderBy,
            };
        }

        case actionTypes.RefetchDocumentsFlag: {
            return {
                ...state,
                refetchDocumentsFlag: !state.refetchDocumentsFlag,
            };
        }
        case actionTypes.EditedActivity: {
            const { editedActivity } = action.payload;

            return {
                ...state,
                editedActivity,
            };
        }

        case actionTypes.ActivityStatusOptions: {
            const { activityStatusOptions } = action.payload;
            return {
                ...state,
                activityStatusOptions,
            };
        }
        case actionTypes.ActivityData: {
            const { activityData } = action.payload;
            return {
                ...state,
                activityData,
            };
        }
        case actionTypes.EditActivityListAssignees: {
            const { activityId, type, newValue } = action.payload;
            return {
                ...state,
                activityData: state.activityData?.map((item) => ({
                    ...item,
                    activities: item?.activities?.map((activity) =>
                        activity.id === activityId
                            ? {
                                  ...activity,
                                  assignees:
                                      type === "add"
                                          ? [...(activity.assignees ?? []), newValue]
                                          : type === "remove"
                                          ? activity?.assignees?.filter((assignee) => assignee.id !== newValue)
                                          : activity.assignees,
                              }
                            : activity
                    ),
                })),
            };
        }
        case actionTypes.EditActivityListTags: {
            const { activityId, type, newValue } = action.payload;
            return {
                ...state,
                activityData: state.activityData?.map((item) => ({
                    ...item,
                    activities: item?.activities?.map((activity) =>
                        activity.id === activityId
                            ? {
                                  ...activity,
                                  tags:
                                      type === "add"
                                          ? [...(activity.tags ?? []), newValue]
                                          : type === "remove"
                                          ? activity?.tags?.filter((tag) => tag.id !== newValue)
                                          : activity.tags,
                              }
                            : activity
                    ),
                })),
            };
        }
        case actionTypes.HideActionPlan: {
            const { hideActionPlan } = action.payload;
            return {
                ...state,
                hideActionPlan,
            };
        }
        case actionTypes.IsFileDragging: {
            const { isFileDragging } = action.payload;
            return {
                ...state,
                isFileDragging,
            };
        }
        case actionTypes.MentionableUsers: {
            const { mentionableUsers } = action.payload;
            return {
                ...state,
                mentionableUsers,
            };
        }
        case actionTypes.RefetchMentionableUsers: {
            const { refetchMentionableUsers } = action.payload;
            return {
                ...state,
                refetchMentionableUsers,
            };
        }
        case actionTypes.DocumentsList: {
            const { documentsList } = action.payload;
            return { ...state, documentsList };
        }
        case actionTypes.DocumentsList: {
            const { documentsList } = action.payload;
            return { ...state, documentsList };
        }
        case actionTypes.campaignStatus: {
            const { campaignStatus } = action.payload;
            return { ...state, campaignStatus };
        }
        case actionTypes.campaignDATA: {
            const { campaignDATA } = action.payload;
            return { ...state, campaignDATA };
        }
        case actionTypes.campaignStatus: {
            const { campaignStatus } = action.payload;
            return { ...state, campaignStatus };
        }
        case actionTypes.campaignDATA: {
            const { campaignDATA } = action.payload;
            return { ...state, campaignDATA };
        }

        default:
            return state;
    }
};

export const actions = {
    setViewType: (viewType) => ({
        type: actionTypes.ViewType,
        payload: { viewType },
    }),
    setShowActionButtons: (showActionButtons) => ({
        type: actionTypes.ShowActionButtons,
        payload: { showActionButtons },
    }),
    setShowAddActivityModal: (showAddActivityModal) => ({
        type: actionTypes.ShowAddActivityModal,
        payload: { showAddActivityModal },
    }),

    setSearchQuery: (searchQuery) => ({
        type: actionTypes.SearchQuery,
        payload: { searchQuery },
    }),
    setSelectedGroupByOption: (selectedGroupByOption) => ({
        type: actionTypes.GroupByFilter,
        payload: { selectedGroupByOption },
    }),
    setSelectedGroupBySortOption: (selectedGroupBySortOption) => ({
        type: actionTypes.GroupBySortFilter,
        payload: { selectedGroupBySortOption },
    }),
    setSelectedFilterByOptions: (selectedFilterByOptions) => ({
        type: actionTypes.FilterByFilter,
        payload: { selectedFilterByOptions },
    }),
    setSelectedAssignees: (selectedAssignees) => ({
        type: actionTypes.AssigneeFilter,
        payload: { selectedAssignees },
    }),
    setSelectedActivityTypes: (selectedActivityTypes) => ({
        type: actionTypes.ActivityTypeFilter,
        payload: { selectedActivityTypes },
    }),
    setSelectedTags: (selectedTags) => ({
        type: actionTypes.TagsFilter,
        payload: { selectedTags },
    }),

    setShowAddSubActivity: (showAddSubActivity) => ({
        type: actionTypes.ShowAddSubActivity,
        payload: { showAddSubActivity },
    }),
    setIsTagModalShown: (isTagModalShown) => ({
        type: actionTypes.IsTagModalShown,
        payload: { isTagModalShown },
    }),
    setIsActivityDetailModalShown: (isActivityDetailModalShown) => ({
        type: actionTypes.IsActivityDetailModalShown,
        payload: { isActivityDetailModalShown },
    }),
    setIsCampaignListDrawerShown: (isCampaignListDrawerShown) => ({
        type: actionTypes.IsCampaignListDrawerShown,
        payload: { isCampaignListDrawerShown },
    }),

    setParentActivityId: (parentActivityId) => ({
        type: actionTypes.ParentActivityId,
        payload: { parentActivityId },
    }),

    setIsAssigneeDrawerOpen: (isAssigneeDrawerOpen) => ({
        type: actionTypes.IsAssigneeDrawerOpen,
        payload: { isAssigneeDrawerOpen },
    }),
    setIsDeleteDrawerOpen: (isDeleteDrawerOpen) => ({
        type: actionTypes.IsDeleteDrawerOpen,
        payload: { isDeleteDrawerOpen },
    }),

    setActivityId: (activityId) => ({
        type: actionTypes.ActivityId,
        payload: { activityId },
    }),

    setActivityAddModalAssignees: (activityAddModalAssignees) => ({
        type: actionTypes.ActivityAddModalAssignees,
        payload: { activityAddModalAssignees },
    }),

    setFetchData: (fetchData) => ({
        type: actionTypes.FetchData,
        payload: { fetchData },
    }),
    setSelectedActivity: (selectedActivity) => ({
        type: actionTypes.SelectedActivity,
        payload: { selectedActivity },
    }),
    setMainActivityData: (mainActivityData) => ({
        type: actionTypes.MainActivityData,
        payload: { mainActivityData },
    }),
    setIsActivityDrawerOpen: (isActivityDrawerOpen) => ({
        type: actionTypes.IsActivityDrawerOpen,
        payload: { isActivityDrawerOpen },
    }),
    setIsActivityDetailDrawerShown: (isActivityDetailDrawerShown) => ({
        type: actionTypes.IsActivityDetailDrawerShown,
        payload: { isActivityDetailDrawerShown },
    }),
    setIsPriorityDrawerShown: (isPriorityDrawerShown) => ({
        type: actionTypes.IsPriorityDrawerShown,
        payload: { isPriorityDrawerShown },
    }),
    setIsStatusDrawerShown: (isStatusDrawerShown) => ({
        type: actionTypes.IsStatusDrawerShown,
        payload: { isStatusDrawerShown },
    }),
    setIsStatusDocumentDrawerOpen: (isStatusDocumentDrawerOpen) => ({
        type: actionTypes.IsStatusDocumentDrawerOpen,
        payload: { isStatusDocumentDrawerOpen },
    }),

    setIsFollowerDrawerShown: (isFollowerDrawerShown) => ({
        type: actionTypes.IsFollowerDrawerShown,
        payload: { isFollowerDrawerShown },
    }),
    setIsNotesDrawerShown: (isNotesDrawerShown) => ({
        type: actionTypes.IsNotesDrawerShown,
        payload: { isNotesDrawerShown },
    }),
    setIsEditDrawerShown: (isEditDrawerShown) => ({
        type: actionTypes.IsEditDrawerShown,
        payload: { isEditDrawerShown },
    }),
    setIsMoveDrawerShown: (isMoveDrawerShown) => ({
        type: actionTypes.IsMoveDrawerShown,
        payload: { isMoveDrawerShown },
    }),
    setIsDuplicateDrawerShown: (isDuplicateDrawerShown) => ({
        type: actionTypes.IsDuplicateDrawerShown,
        payload: { isDuplicateDrawerShown },
    }),
    setIsSelectApproverDrawerShown: (isSelectApproverDrawerShown) => ({
        type: actionTypes.IsSelectApproverDrawerShown,
        payload: { isSelectApproverDrawerShown },
    }),
    setIsDeleteModalShown: (isDeleteModalShown) => ({
        type: actionTypes.IsDeleteModalShown,
        payload: { isDeleteModalShown },
    }),
    setNotes: (notes) => ({
        type: actionTypes.Notes,
        payload: { notes },
    }),
    setIsLostFocusModalShown: (isLostFocusModalShown) => ({
        type: actionTypes.IsLostFocusModalShown,
        payload: { isLostFocusModalShown },
    }),

    setIsSubActivity: (isSubActivity) => ({
        type: actionTypes.IsSubActivity,
        payload: { isSubActivity },
    }),
    setIsTagDrawerShown: (isTagDrawerShown) => ({
        type: actionTypes.IsTagDrawerShown,
        payload: { isTagDrawerShown },
    }),
    setIsDuplicateModalShown: (isDuplicateModalShown) => ({
        type: actionTypes.IsDuplicateModalShown,
        payload: { isDuplicateModalShown },
    }),
    setDuplicatedActivity: (duplicatedActivity) => ({
        type: actionTypes.DuplicatedActivity,
        payload: { duplicatedActivity },
    }),
    setSelectedCampaignFilter: (selectedCampaignFilter) => ({
        type: actionTypes.SelectedCampaignFilter,
        payload: { selectedCampaignFilter },
    }),
    setCampaignListForFilter: (campaignListForFilter) => ({
        type: actionTypes.CampaignListForFilter,
        payload: { campaignListForFilter },
    }),
    setSelectedCampaignListFromFilter: (selectedCampaignListFromFilter) => ({
        type: actionTypes.selectedCampaignListFromFilter,
        payload: { selectedCampaignListFromFilter },
    }),
    setIsDurationDrawerOpen: (isDurationDrawerOpen) => ({
        type: actionTypes.IsDurationDrawerOpen,
        payload: { isDurationDrawerOpen },
    }),
    setRefreshData: (refreshData) => ({
        type: actionTypes.RefreshData,
        payload: { refreshData },
    }),
    setErrorMessage: (errorMessage) => ({
        type: actionTypes.ErrorMessage,
        payload: { errorMessage },
    }),
    setDurations: (durations) => ({
        type: actionTypes.Durations,
        payload: { durations },
    }),
    setIsDurationInfoDrawerOpen: (isDurationInfoDrawerOpen) => ({
        type: actionTypes.IsDurationInfoDrawerOpen,
        payload: { isDurationInfoDrawerOpen },
    }),
    setIsDeleteDocumentDrawerOpen: (isDeleteDocumentDrawerOpen) => ({
        type: actionTypes.IsDeleteDocumentDrawerOpen,
        payload: { isDeleteDocumentDrawerOpen },
    }),
    setIsApproveActivityModalShown: (isApproveActivityModalShown) => ({
        type: actionTypes.IsApproveActivityModalShown,
        payload: { isApproveActivityModalShown },
    }),
    setFilterByAssignee: (filterByAssignee) => ({
        type: actionTypes.FilterByAssignee,
        payload: { filterByAssignee },
    }),
    setFilterByCreator: (filterByCreator) => ({
        type: actionTypes.FilterByCreator,
        payload: { filterByCreator },
    }),
    setNewActivityLoading: (newActivityLoading) => ({
        type: actionTypes.NewActivityLoading,
        payload: { newActivityLoading },
    }),
    setIsNewActivity: (isNewActivity) => ({
        type: actionTypes.IsNewActivity,
        payload: { isNewActivity },
    }),
    setIsDeleteNoteDrawerShown: (isDeleteNoteDrawerShown) => ({
        type: actionTypes.IsDeleteNoteDrawerShown,
        payload: { isDeleteNoteDrawerShown },
    }),
    setSingleDuration: (singleDuration) => ({
        type: actionTypes.SingleDuration,
        payload: { singleDuration },
    }),
    setSelectedDurationTab: (selectedDurationTab) => ({
        type: actionTypes.SelectedDurationTab,
        payload: { selectedDurationTab },
    }),
    setDurationInput: (durationInput) => ({
        type: actionTypes.DurationInput,
        payload: { durationInput },
    }),

    setRangeInputs: (rangeInputs) => ({
        type: actionTypes.RangeInputs,
        payload: { rangeInputs },
    }),
    setDate: (date) => ({
        type: actionTypes.Date,
        payload: { date },
    }),
    setIsDeleteActivityModalShown: (isDeleteActivityModalShown) => ({
        type: actionTypes.IsDeleteActivityModalShown,
        payload: { isDeleteActivityModalShown },
    }),
    setActivityAddModalFollowers: (activityAddModalFollowers) => ({
        type: actionTypes.ActivityAddModalFollowers,
        payload: { activityAddModalFollowers },
    }),

    setIsMarkAsUnreadDrawerShown: (isMarkAsUnreadDrawerShown) => ({
        type: actionTypes.IsMarkAsUnreadDrawerShown,
        payload: { isMarkAsUnreadDrawerShown },
    }),
    setIsApproveDrawerShown: (isApproveDrawerShown) => ({
        type: actionTypes.IsApproveDrawerShown,
        payload: { isApproveDrawerShown },
    }),
    setCompanyUsersAndGroupsData: (companyUsersAndGroupsData) => ({
        type: actionTypes.CompanyUsersAndGroupsData,
        payload: { companyUsersAndGroupsData },
    }),
    setCompanyUsersData: (companyUsersData) => ({
        type: actionTypes.CompanyUsersData,
        payload: { companyUsersData },
    }),
    setCompanyGroupsData: (companyGroupsData) => ({
        type: actionTypes.CompanyGroupsData,
        payload: { companyGroupsData },
    }),
    setSelectedFiles: (selectedFiles) => ({
        type: actionTypes.SelectedFiles,
        payload: { selectedFiles },
    }),
    setIsDeleteDocumentModalShown: (isDeleteDocumentModalShown) => ({
        type: actionTypes.IsDeleteDocumentModalShown,
        payload: { isDeleteDocumentModalShown },
    }),

    setPriorityFilter: (priorityFilter) => ({
        type: actionTypes.PriorityFilter,
        payload: { priorityFilter },
    }),
    setCanActivityDrawerClose: (canActivityDrawerClose) => ({
        type: actionTypes.CanActivityDrawerClose,
        payload: { canActivityDrawerClose },
    }),

    setIsDeleteCompleted: (isDeleteCompleted) => ({
        type: actionTypes.IsDeleteCompleted,
        payload: { isDeleteCompleted },
    }),

    setChangedFields: (changedFields) => ({
        type: actionTypes.ChangedFields,
        payload: { changedFields },
    }),
    setFetchActivityDetailAgain: (fetchActivityDetailAgain) => ({
        type: actionTypes.FetchActivityDetailAgain,
        payload: { fetchActivityDetailAgain },
    }),
    setTableDataCount: (tableDataCount) => ({
        type: actionTypes.TableDataCount,
        payload: { tableDataCount },
    }),

    setActivitiesLoading: (isActivitiesLoading) => ({
        type: actionTypes.IsActivitiesLoading,
        payload: { isActivitiesLoading },
    }),
    setEditingActivityDetail: (isEditingActivityDetail) => ({
        type: actionTypes.IsEditingActivityDetail,
        payload: { isEditingActivityDetail },
    }),
    setActivityDateFilter: (activityDateFilter) => ({
        type: actionTypes.ActivityDateFilter,
        payload: { activityDateFilter },
    }),
    setShowCampaignSidebar: (showCampaignSidebar) => ({
        type: actionTypes.ShowCampaignSidebar,
        payload: { showCampaignSidebar },
    }),
    setIsFilterByCampaignModalShown: (isFilterByCampaignModalShown) => ({
        type: actionTypes.IsFilterByCampaignModalShown,
        payload: { isFilterByCampaignModalShown },
    }),
    setCampaignDetailModalId: (campaignDetailModalId) => ({
        type: actionTypes.CampaignDetailModalId,
        payload: { campaignDetailModalId },
    }),
    setIsMindmapActionPlanChecked: (isMindmapActionPlanChecked) => ({
        type: actionTypes.IsMindmapActionPlanChecked,
        payload: { isMindmapActionPlanChecked },
    }),
    setIsMindmapActivitiesChecked: (isMindmapActivitiesChecked) => ({
        type: actionTypes.IsMindmapActivitiesChecked,
        payload: { isMindmapActivitiesChecked },
    }),
    setOrderBy: (orderBy) => ({
        type: actionTypes.OrderBy,
        payload: { orderBy },
    }),
    toggleRefetchDocuments: () => ({
        type: actionTypes.RefetchDocumentsFlag,
    }),
    setActivityStatusOptions: (activityStatusOptions) => ({
        type: actionTypes.ActivityStatusOptions,
        payload: { activityStatusOptions },
    }),
    setActivityData: (activityData) => ({
        type: actionTypes.ActivityData,
        payload: { activityData },
    }),
    updateActivityList: (activityId, editedFields, editedActivity) => ({
        type: actionTypes.UpdateActivityList,
        payload: { activityId, editedFields, editedActivity },
    }),
    editActivityListAssignees: (activityId, type, newValue) => ({
        type: actionTypes.EditActivityListAssignees,
        payload: { activityId, type, newValue },
    }),
    editActivityListTags: (activityId, type, newValue) => ({
        type: actionTypes.EditActivityListTags,
        payload: { activityId, type, newValue },
    }),
    setEditedActivity: (editedActivity) => ({
        type: actionTypes.EditedActivity,
        payload: { editedActivity },
    }),
    setHideActionPlan: (hideActionPlan) => ({
        type: actionTypes.HideActionPlan,
        payload: { hideActionPlan },
    }),
    setIsFileDragging: (isFileDragging) => ({
        type: actionTypes.IsFileDragging,
        payload: { isFileDragging },
    }),
    setCampaignModalStatus: (campaignStatus) => ({
        type: actionTypes.campaignStatus,
        payload: { campaignStatus },
    }),
    setCampaignId: (campaignId) => ({
        type: actionTypes.campaignId,
        payload: { campaignId },
    }),
    setMentionableUsers: (mentionableUsers) => ({
        type: actionTypes.MentionableUsers,
        payload: { mentionableUsers },
    }),
    setRefetchMentionableUsers: (refetchMentionableUsers) => ({
        type: actionTypes.RefetchMentionableUsers,
        payload: { refetchMentionableUsers },
    }),
    setCampaignDATA: (campaignDATA) => ({
        type: actionTypes.campaignDATA,
        payload: { campaignDATA },
    }),
    setDocumentsList: (documentsList) => ({
        type: actionTypes.DocumentsList,
        payload: { documentsList },
    }),
};
