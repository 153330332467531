import React from "react";

/**
 * @typedef {Object} RadioButtons
 * @property {Array<{value: string, label: string}>} options
 * @property { value: string } currentSelected
 * @property {(selectedOption) => void} onChange
 * @property { boolean } bgColorApplied   // optional: to fill selected item's background with color
 */

const RadioButtons = props => {
    return (
        <div className="btn-group w-100" data-toggle="buttons">
            {props.options.map((option, i) => {
                let position = "middle";
                if (i === 0 || i % 3 === 0) {
                    position = "left";
                } else if (i === props.options.length - 1 || i % 3 === 2) {
                    position = "right";
                }
                const isActive = props.currentSelected === option.value;

                return (
                    <button
                        key={`sortBy_${option.value}`}
                        type="button"
                        className={`dealsRadioButtons ${position} ${
                            isActive ? (props.bgColorApplied ? "active__inverseColor" : "active") : ""
                        } `}
                        onClick={() => props.onChange(option)}>
                        <span>{option.label}</span>
                    </button>
                );
            })}
        </div>
    );
};

export default RadioButtons;
