import React from 'react';
import Select, { components } from 'react-select';
import { connect } from "react-redux";
import * as app from "../../../app/redux/appRedux";
import * as reportsRedux from "../../reports/redux/reportsRedux";
import { post } from "../../../networking/RequestService";
import SelectInput from "../components/inputs/selectInput"

const { ValueContainer, Placeholder } = components;

class FranchiseFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: "",
            options: [],
        }
        this.switchCheckbox = this.switchCheckbox.bind(this);
    }

    getCompanies() {
        const companyQuery = {
            fn: "getFranchiseCompanies"
        }

        post(companyQuery).then(({ data: { ids, names } }) => {
            const options = [];
            for (let i = 0; i < ids.length; i++) {
                const company = { value: ids[i], label: names[i] }
                options.push(company);
            }
            this.setState({ options });
        });
    }

    componentDidMount() {
        this.getCompanies();
    }


    onChange = selectedOption => {
        this.props.selectFranchise(selectedOption);
        this.setState({ type: selectedOption })
    };

    onInputChange = (str, { action }) => {
        if (action === "set-value") return false;
        console.log("str ", str)
        this.setState({ inputValue: str });
    };

    switchCheckbox() {
        this.props.setIsSelectAllFranchise(!this.props.isSelectAllFranchise)
    }

    render() {
        const { inputValue } = this.state;
        const isSelectAllFranchise = this.props.isSelectAllFranchise;
        return (
            <>
                {this.props.isMultiSelect ?
                    <SelectInput
                        object={{
                            //  fn: this.getUsers().fn,
                            //  unitId: this.getUsers().unitId,
                            name: "Franchise",
                            data: {
                                options: this.state.options,
                            },
                            multi: true,
                            allSelectable: true
                        }}
                        onChange={this.onChange}
                        onInputChange={this.onInputChange}
                        infinite={false}
                    />
                    :
                    <Select
                        className="selectInputNewFilter_single"
                        classNamePrefix="selectInputNewFilter"
                        options={this.state.options}
                        defaultValue={this.props.franchise}
                        value={this.props.franchise}
                        onChange={this.onChange}
                        closeMenuOnSelect={true}
                        isMulti={this.props.isMultiSelect}

                    />
                }
            </>

        );
    }
}

const Input = props => {
    return (
        <div>
            <components.Input {...props} />
        </div>
    );
};

const Option = ({ ...props }) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={e => null}
                />{" "}
                <label>{props.label} </label>
            </components.Option>
        </div>
    );
};

const Control = ({ children, ...props }) => {
    //console.log("props: ", props)
    return (
        <components.Control  {...props}>
            <input
                type="checkbox"
                name='franchiseCheck'
                value='1'
                checked={!!props.selectProps.isSelectAllFranchise}
                onChange={e => {
                    props.selectProps.setIsSelectAllFranchise()
                    if (e.target.checked) props.setValue(props.options);
                    else props.setValue([]);
                    console.log("p: ", props)
                }}
                style={{ marginLeft: "10px" }}
            />{" "}

            {children}
        </components.Control>

    );
};

const MultiValue = ({ children, ...innerProps }) => {
    return (
        <></>
    )
};

const CValueContainer = ({ children, ...props }) => {
    var placeholder = props.selectProps.placeholder
    return (
        <ValueContainer {...props}>
            {props.selectProps.inputValue === "" &&
                <Placeholder {...props} isFocused={props.isFocused}>
                    {/* {props.selectProps.placeholder} */}
                    Franchise
                </Placeholder>
            }
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    )
};

const mapStateToProps = (state) => {
    return {
        franchise: state.reports.selectedFranchise,
        isSelectAllFranchise: state.reports.isSelectAllFranchise
    };
};

const mapDispatchToProps = {
    ...reportsRedux.actions,
    ...app.actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(FranchiseFilter);