import React from "react";
import SVG from "react-inlinesvg";

import { toAbsoluteUrl } from "./../../../../../../../_metronic/_helpers/AssetsHelpers";
import commonStyles from "../../final-report-common.module.scss"
import { getUrlQueryParam } from "../../../../../app/functions/util";
import { getFormattedDate } from "../../../../../app/functions/finalReportUtil";

const FinalReportHeader = () => {
    const startDate = getUrlQueryParam("startDate");
    const endDate = getUrlQueryParam("endDate");
    const formattedDate = getFormattedDate(startDate, endDate).toUpperCase();

    return (
        <div className={`d-flex ${commonStyles.header}`}>
            <span className={commonStyles.leoronLogo}>
                <SVG src={toAbsoluteUrl("/media/svg/popleads/learn-online.svg")} />
            </span>
            <span className={commonStyles.headerDivider} />
            <span className={commonStyles.headerText}>{`FINAL RESULTS - ${formattedDate}`}</span>
        </div>
    );
};

export default FinalReportHeader;
